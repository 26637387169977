import { QuizAPI } from "./http-common";
import authHeader from "./authHeader";

const GetByQuestionID = (id: string, type?: string) => {
  return QuizAPI.get(
    `/Answer/GetByQuestionID/${id}${type}`,
    {
      headers: authHeader() as any,
    }
  );
};

const getAnswerByID = (id: string) => {
  return QuizAPI.get(`/Answer/${id}`, {
    headers: authHeader() as any,
  });
};

const createAnswer = (data: any) => {
  return QuizAPI.post("/Answer", data, {
    headers: authHeader() as any,
  });
};

const updateAnswer = (id: string, data: any) => {
  return QuizAPI.put(`/Answer/${id}`, data, {
    headers: authHeader() as any,
  });
};

const updateAnswerOrder = (data: any) => {
  return QuizAPI.put("/Answer/UpdateOrder", data, {
    headers: authHeader() as any,
  });
};

const deleteAnswer = (id: string) => {
  return QuizAPI.delete(`/Answer/${id}`, {
    headers: authHeader() as any,
  });
};

const AnswerService = {
  GetByQuestionID,
  getAnswerByID,
  createAnswer,
  updateAnswer,
  updateAnswerOrder,
  deleteAnswer,
};

export default AnswerService;
