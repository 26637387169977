import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {};

// here we declare thunk as middleware but it is not used in the app
const middleware = [thunk];

const store = createStore(
  // root reducer is the index file in the reducer folder where we combine all the reducers together
  rootReducer,
  initialState,
  // here we compose the redux dev tools with our app
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
export type RootState = ReturnType<typeof rootReducer>;
