export default function authHeader() {
  // get user from browser localStorage:
  const user = JSON.parse(localStorage.getItem("user")!);

  // check if the user token is expired
  var exp = new Date(user?.expiration);
  var now = new Date();

  if (exp.getTime() < now.getTime()) {
    localStorage.removeItem("user");
    window.location.reload();
  }

  // check if we have a logged in user then return to the backend the token:
  if (user && user.token) {
    return {
      "Content-type": "application/json",
      Authorization: "bearer " + user.token,
    };
  } else {
    return {};
  }
}
