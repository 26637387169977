import "./HelpPopup.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeHelpPopup,
  setHelpPopup,
} from "../../store/actions/helpPopupAction";
import {
  addContrast,
  removeContrast,
} from "../../store/actions/contrastAction";
import { ChangeFontSize } from "../../store/actions/fontSizeAction";

//Icons
// import text from "../../assets/Icons/Popup/text.png";
// import eye_icon from "../../assets/Icons/Popup/eye-icon.png";
import color_mode_icon from "../../assets/Icons/SettingPopup/menu-icon_color-mode.png";
import checkmark_button from "../../assets/Icons/SettingPopup/button_checkmark_white.png";
import contrast_mode_icon from "../../assets/Icons/SettingPopup/menu-icon_contrast-mode.png";
import leichte_sprache_icon from "../../assets/Icons/SettingPopup/menu-icon_leichte-sprache.png";
import black_icon from "../../assets/Icons/SettingPopup/BlackCircle.png";
import close_button from "../../assets/Icons/Popup/white-close-button.png";
// import information_icon from "../../assets/Icons/Popup/information-icon.png";
// import contrastWhiteIcon from "../../assets/Icons/contrast-white.png";
// import contrastIcon from "../../assets/Icons/Popup/contrast-white-rotated.png";

import Loader from "../Loader/Loader";

export default function HelpPopup() {
  // tabIndex variables
  const topTabTrap = useRef() as any;
  const bottomTabTrap = useRef() as any;
  const firstFocusableElement = useRef() as any;
  const lastFocusableElement = useRef() as any;
  const FocusableElement = useRef() as any;
  // get the id of help popup container
  let helpModalFocusOn = document.getElementById("help-modal") as any;

  const dispatch = useDispatch<any>();

  //redux state
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { helpPopup } = useSelector((state: any) => state.HelpPopupReducer);
  const contrast = useSelector((state: any) => state.ContrastReducer);
  const fontSize = useSelector((state: any) => state.FontSizeReducer);

  //local state
  const [languageState, setLanguageState] = useState("");

  const fontSizeValues = fontSize === "28" ? "fontSize-28" : "fontSize-22";

  // function that shows and hides the popup:
  const toggleModal = () => {
    helpPopup && dispatch(removeHelpPopup());
    !helpPopup && dispatch(setHelpPopup());

    document.body.classList.remove("active-modal");
    document.body.style.overflowY = "visible";
  };

  const cancelPopup = () => {
    closeModal();
    toggleModal();
  };

  if (helpPopup) {
    document.body.classList.add("active-modal");
    document.body.style.overflowY = "hidden";
  } else {
    document.body.classList.remove("active-modal");
    document.body.style.overflowY = "visible";
  }

  if (helpModalFocusOn) {
    FocusableElement.current.focus();
  }

  //add closeModal function to enable the scroll feature after closing the modal
  const closeModal = () => {
    const body = document.body;
    const scrollY = body.style.top;
    // When the modal is hidden, we want to remain at the top of the scroll position
    body.style.position = "";
    body.style.top = "";
    body.style.height = "";
    body.style.overflowY = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  };

  const ChangeLanguageState = () => {
    if (languageState === "On") {
      localStorage.setItem("isNormalLanguage", "Off");
      setLanguageState("Off");
    } else {
      localStorage.setItem("isNormalLanguage", "On");
      setLanguageState("On");
    }
  };

  //on click add contrast
  // const handleAddContrast = (level: any) => {
  //   //we toggle the enable-contrast class to add it and remove it on click
  //   if (document.body.classList.contains("enable-contrast"))
  //     dispatch(removeContrast());
  //   else dispatch(addContrast());
  //   document.body.classList.toggle("enable-contrast");
  // };

  //on click change fontsize
  const handleChangeFontSize = (fontSize: any) => {
    //set the fontSize to the local storage
    localStorage.setItem("fontSize", fontSize);
    dispatch(ChangeFontSize(fontSize.toString()));
  };

  useEffect(() => {
    contrast &&
      contrast.isContrast === "true" &&
      document.body.classList.add("enable-contrast");

    setLanguageState(localStorage.getItem("isNormalLanguage")!);
  }, [contrast]);

  useEffect(() => {
    //call addEventListener method on the focusin event
    document.addEventListener("focusin", trapFocus);
    // Remove the eventlistener on the unmount
    return function cleanup() {
      document.removeEventListener("focusin", trapFocus);
    };

    function trapFocus(event: any) {
      //if the user has currently tapped the topTabTrap , then focus on the last element
      if (event.target === topTabTrap.current) {
        lastFocusableElement.current.focus();
      }
      //if the user has currently tapped the bottomTabTrap , then focus on the first element
      if (event.target === bottomTabTrap.current) {
        firstFocusableElement.current.focus();
      }
    }
  }, [firstFocusableElement, lastFocusableElement]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="modal">
            <div onClick={toggleModal} className="overlay"></div>
            <span ref={topTabTrap} tabIndex={0} />
            <div
              id="help-modal"
              className="help-popup-modal-content"
              ref={FocusableElement}
              tabIndex={0}
            >
              {/* Place at the top of the help popup */}
              <div className="popup-header">
                <div className="help-popup-header-title">
                  <h2 tabIndex={0} ref={firstFocusableElement}>
                    Benötigst du Hilfe ?
                  </h2>
                </div>
                <div className="help-popup-header-image">
                  <img
                    ref={lastFocusableElement}
                    src={close_button}
                    alt="Hilfe-beenden"
                    title="Hilfe beenden"
                    onClick={cancelPopup}
                    tabIndex={0}
                    onKeyPress={cancelPopup}
                  />
                </div>
              </div>
              <div className="help-popup-body">
                <div className="contrast-font-size-container">
                  <div className="contrast-container">
                    <div className="contrast-title">
                      <h3 className={` text-content ${fontSizeValues}`}>
                        Darstellung
                      </h3>
                    </div>
                    <div className="contrast-images">
                      <img
                        src={color_mode_icon}
                        alt="Farbmodus "
                        title="Farbmodus."
                        tabIndex={0}
                        onKeyPress={() => {
                          // handleAddContrast(0);
                          dispatch(removeContrast());
                          document.body.classList.remove("enable-contrast");
                          localStorage.setItem("isContrast", "false");
                        }}
                        onClick={() => {
                          // handleAddContrast(0);
                          dispatch(removeContrast());
                          document.body.classList.remove("enable-contrast");
                          localStorage.setItem("isContrast", "false");
                        }}
                      />
                      <img
                        src={contrast_mode_icon}
                        alt="Kontrastmodus"
                        title="Kontrastmodus."
                        tabIndex={0}
                        onKeyPress={() => {
                          // handleAddContrast(1);
                          dispatch(addContrast());
                          document.body.classList.add("enable-contrast");
                          localStorage.setItem("isContrast", "true");
                        }}
                        onClick={() => {
                          // handleAddContrast(1);
                          dispatch(addContrast());
                          document.body.classList.add("enable-contrast");
                          localStorage.setItem("isContrast", "true");
                        }}
                      />
                    </div>
                    <div className="contrast-check-marks-icons">
                      <img
                        src={
                          contrast && contrast.isContrast === "true"
                            ? black_icon
                            : checkmark_button
                        }
                        alt="Kontrollkästchen zum Entfernen des Kontrasts"
                        title="Auswahlfeld"
                        className="check-marks-icons"
                        tabIndex={0}
                        onKeyPress={() => {
                          // handleAddContrast(0);
                          dispatch(removeContrast());
                          document.body.classList.remove("enable-contrast");
                          localStorage.setItem("isContrast", "false");
                        }}
                        onClick={() => {
                          // handleAddContrast(0);
                          dispatch(removeContrast());
                          document.body.classList.remove("enable-contrast");
                          localStorage.setItem("isContrast", "false");
                        }}
                      />
                      <img
                        src={
                          contrast && contrast.isContrast === "true"
                            ? checkmark_button
                            : black_icon
                        }
                        alt="Auswahlfeld, um Kontrast hinzuzufügen"
                        title="Auswahlfeld"
                        className="check-marks-icons"
                        tabIndex={0}
                        onKeyPress={() => {
                          // handleAddContrast(1);
                          dispatch(addContrast());
                          document.body.classList.add("enable-contrast");
                          localStorage.setItem("isContrast", "true");
                        }}
                        onClick={() => {
                          // handleAddContrast(1);
                          dispatch(addContrast());
                          document.body.classList.add("enable-contrast");
                          localStorage.setItem("isContrast", "true");
                        }}
                      />
                    </div>
                  </div>
                  <div className="font-size-container">
                    <div className="font-size-title">
                      <h3 className={`text-content ${fontSizeValues}`}>
                        Schriftgröße
                      </h3>
                    </div>
                    <div className="">
                      <div className="font-size-letters">
                        <span
                          className="letter1"
                          tabIndex={0}
                          onKeyPress={() => {
                            handleChangeFontSize(22);
                          }}
                          onClick={() => {
                            handleChangeFontSize(22);
                          }}
                        >
                          A
                        </span>
                        <span
                          className="letter2"
                          onClick={() => {
                            handleChangeFontSize(28);
                          }}
                          tabIndex={0}
                          onKeyPress={() => {
                            handleChangeFontSize(28);
                          }}
                        >
                          A
                        </span>
                        {/* <span className="letter3">A</span>
                        <span className="letter4">A</span> */}
                      </div>
                      <div className="font-size-images">
                        <img
                          src={
                            fontSize === "22" ? checkmark_button : black_icon
                          }
                          alt="Auswahlfeld 22"
                          title="Auswahlfeld"
                          className="font-size-check-marks-icons "
                          tabIndex={0}
                          onKeyPress={() => {
                            handleChangeFontSize(22);
                          }}
                          onClick={() => {
                            handleChangeFontSize(22);
                          }}
                        />
                        <img
                          src={
                            fontSize === "28" ? checkmark_button : black_icon
                          }
                          alt="Auswahlfeld 28"
                          title="Auswahlfeld"
                          className="font-size-check-marks-icons"
                          tabIndex={0}
                          onKeyPress={() => {
                            handleChangeFontSize(28);
                          }}
                          onClick={() => {
                            handleChangeFontSize(28);
                          }}
                        />
                        {/* <img
                          src={
                            fontSize === "30" ? checkmark_button : black_icon
                          }
                          alt="Check Mark Icon"
                          title="Check Mark Icon"
                          className="font-size-check-marks-icons"
                        />
                        <img
                          src={
                            fontSize === "34" ? checkmark_button : black_icon
                          }
                          alt="Check Mark Icon"
                          title="Check Mark Icon"
                          className="font-size-check-marks-icons"
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="language-container">
                  <div className="language-icon-title">
                    <img
                      src={leichte_sprache_icon}
                      alt="Leichte Sprache Icon"
                      title="Leichte-Sprache-Icon"
                    />
                    <h3
                      className={`language-title text-content ${fontSizeValues}`}
                    >
                      Leichte Sprache
                    </h3>
                  </div>
                  <div className="language-check-icon">
                    <img
                      src={
                        languageState === "On" ? checkmark_button : black_icon
                      }
                      alt="Auswahlfeld."
                      title="Auswahlfeld"
                      tabIndex={0}
                      onKeyPress={() => {
                        ChangeLanguageState();
                      }}
                      onClick={() => {
                        ChangeLanguageState();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Place at the bootom of the help popup */}
            <span ref={bottomTabTrap} tabIndex={0} />
          </div>
        </>
      )}
    </>
  );
}
