// Hassan: 25-8-2022: BITV Notes
export const version = "1.1.3.0";

// Hassan: 7-6-2022: User zone Responsive design
// export const version = "1.1.2.0";

// Hassan: 6-14-2022: footer pages TTS - Cookies Popup
// export const version = "1.1.1.0";

// Hassan: 6-3-2022: home page under construction - footer pages - slide image size proportions - BITV improvements
// export const version = "1.1.0.0";
