import authHeader from "./authHeader";
import { CoursesAPI } from "./http-common";

const getLearningModuleByID = (id: string) => {
  return CoursesAPI.get(`/LearningModule/${id}`, {
    headers: authHeader() as any,
  });
};

const getAllLearningModules = () => {
  return CoursesAPI.get(`/LearningModule`, { headers: authHeader() as any });
};

const createLearningModule = async (data: any) => {
  const res = await CoursesAPI.post("/LearningModule", data, {
    headers: authHeader() as any,
  });
  return res;
};

const updateLearningModule = (id: string, data: any) => {
  return CoursesAPI.put(`/LearningModule/${id}`, data, {
    headers: authHeader() as any,
  });
};

const deleteLearningModule = (id: string) => {
  return CoursesAPI.delete(`/LearningModule/${id}`, {
    headers: authHeader() as any,
  });
};

const LearningModuleService = {
  getLearningModuleByID,
  getAllLearningModules,
  createLearningModule,
  updateLearningModule,
  deleteLearningModule,
};

export default LearningModuleService;
