import "./CreateEditUser.css";
import Select from "react-select";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import UserService from "services/userService";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, Fragment } from "react";
import { getAllUsers } from "store/actions/userAction";
import { setMessage } from "store/actions/messageAction";
import { UserSchema, EditUserSchema } from "validations/adminValidations";
import { removeLoading, setLoading } from "store/actions/loadingAction";

// Components:
import Loader from "components/Loader/Loader";
import DeletePopup from "components/DefaultPopup/DefaultPopup";
import DefaultButton from "components/DefaultButton/DefaultButton";
import ChangePasswordPopup from "components/ChangePasswordPopup/ChangePasswordPopup";
import MessageNotification from "components/MessageNotification/MessageNotification";

//martial-ui
import { Tooltip } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

function CreateEditUser() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  // redux states
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //local state
  const [userState, setUserState] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    roles: [],
  });

  // states for user Roles
  const [savedRoles, setSavedRoles] = useState<any>();
  const [selectedRoles, setSelectedRoles] = useState<any>();

  const [isDeletePopup, setDeletePopup] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [changePasswordPopup, setChangePasswordPopup] = useState({
    changePasswordPopup: false,
    userId: "",
  });

  // get id from the url
  let params: any = useParams();
  const { id } = params;
  const userID = id;

  // constant to know if the form is on creation or editing user:
  const isAddMode = !userID;

  // if the form is on edit, so we get user data from DB:
  const getUser = (id: string) => {
    dispatch(setLoading());
    UserService.getUser(id)
      .then((response: any) => {
        if (response.data.roles !== undefined) {
          const saved_roles = [] as any;
          response.data.roles.forEach(function (item: any) {
            saved_roles.push({ label: item, value: item });
          });
          setSavedRoles(saved_roles);
        }

        setUserState(() => {
          return {
            firstname: response.data.firstname,
            lastname: response.data.lastname,
            username: response.data.username,
            email: response.data.email,
            password: response.data.password,
            roles: response.data.roles,
          };
        });
      })
      .catch((e: any) => {
        console.log(e);
        const message =
          (e.response && e.response.data && e.response.data.message) ||
          e.message ||
          e.toString();
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      });
  };

  useEffect(() => {
    dispatch(getAllUsers());
    !isAddMode && getUser(userID);
    // eslint-disable-next-line
  }, [dispatch, isAddMode, userID]);

  // onSubmit general function that checks if the form is created or edited and gives the correct function:
  function onSubmit(
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) {
    if (isAddMode) {
      CreateUser(fields, setSubmitting);
    } else {
      UpdateUser(userID, fields, setSubmitting);
    }
  }

  // create user:
  function CreateUser(fields: any, setSubmitting: any) {
    dispatch(setLoading());
    // convert the object array of roles into string
    var user_role_titles = selectedRoles.map(function (item: any) {
      return item["value"];
    });

    const data = {
      firstname: fields.firstname,
      lastname: fields.lastname,
      username: fields.username,
      email: fields.email,
      password: fields.password,
      roles: user_role_titles,
    };

    UserService.createUser(data)
      .then((response: any) => {
        if (response) {
          navigate("/");
          dispatch(
            setMessage("Die Benutzer wurde erfolgreich erstellt.", "success")
          );
        }
      })
      .catch((e: any) => {
        console.log(e);
        const message =
          (e.response && e.response.data && e.response.data.message) ||
          e.message ||
          e.toString();
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      });
  }

  // update user
  function UpdateUser(id: string, fields: any, setSubmitting: any) {
    dispatch(setLoading());
    // convert the object array of selected roles into string
    var user_role_titles;
    if (selectedRoles !== undefined) {
      user_role_titles = selectedRoles.map(function (item: any) {
        return item["value"];
      });
    } else {
      user_role_titles = savedRoles.map(function (item: any) {
        return item["value"];
      });
    }

    const data = {
      firstname: fields.firstname,
      lastname: fields.lastname,
      username: fields.username,
      email: fields.email,
      roles: user_role_titles,
    };

    UserService.updateUser(id, data)
      .then((response: any) => {
        navigate("/");
        dispatch(
          setMessage("Die Benutzer wurde erfolgreich erstellt.", "success")
        );
      })
      .catch((e: any) => {
        console.log(e);
        const message =
          (e.response && e.response.data && e.response.data.message) ||
          e.message ||
          e.toString();
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      });
  }

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  // fill the react-select dropdown with user roles
  let userRoles = [
    { value: "Admin", label: "Admin" },
    { value: "Editor", label: "Editor" },
    { value: "User", label: "User" },
  ];

  // reset the password to default one
  const resetUserPassword = (userID: any) => {
    UserService.resetUserPassword({ userID })
      .then(() => {
        dispatch(setMessage("Passwort wurde zurückgesetzt.", "success"));
        setDeletePopup(false);
      })
      .catch((err: any) => console.log(err));
  };

  //used setTimeout to schedule the callback to be run asynchronously, after the shortest possible delay
  setTimeout(() => {
    //custom validation for roles (react-select)
    if (
      (selectedRoles && selectedRoles.length !== 0) ||
      (savedRoles && savedRoles.length !== 0)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, 1);

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {isDeletePopup ? (
        <DeletePopup
          //added type prop
          method="delete"
          label="Möchten Sie das Passwort wirklich zurücksetzen?"
          action={() => resetUserPassword(userID)}
          closePopup={() => setDeletePopup(false)}
          yesButtonText="Ja ich bin mir sicher"
          noButtonText="Nein, abbrechen"
        />
      ) : null}

      {changePasswordPopup.changePasswordPopup ? (
        <ChangePasswordPopup
          closeChangePasswordPopup={() =>
            setChangePasswordPopup({
              changePasswordPopup: false,
              userId: userID,
            })
          }
          userId={userID}
        />
      ) : null}

      <div className="create-edit-container">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Formik
              initialValues={userState}
              validationSchema={isAddMode ? UserSchema : EditUserSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => {
                return (
                  <>
                    <div className="form-title text-content">
                      {isAddMode ? (
                        <h1>Benutzer erstellen:</h1>
                      ) : (
                        <h1>Benutzer ändern:</h1>
                      )}
                    </div>
                    <Form className="create-edit-form">
                      <Tooltip title={<div className="tool-tip">Zurück</div>}>
                        <KeyboardBackspaceIcon
                          className="hover-icon"
                          fontSize="large"
                          onClick={goBack}
                        ></KeyboardBackspaceIcon>
                      </Tooltip>
                      <div className="required-star float-right">
                        (*): Erforderlich
                      </div>
                      <div className="form-input">
                        <label
                          htmlFor="firstname"
                          className="form-label text-content"
                        >
                          Vorname <span className="required-star">*</span>
                        </label>
                        <div className="form-field">
                          <Field
                            type="text"
                            id="firstname"
                            name="firstname"
                            value={values.firstname}
                            aria_labelledby="firstname"
                            aria-required={true}
                            aria-label="firstname"
                            tabindex="1"
                          />
                          {touched.firstname && errors.firstname ? (
                            <div className="error-message" role="alert">
                              {errors.firstname}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-input">
                        <label
                          htmlFor="lastname"
                          className="form-label text-content"
                        >
                          Nachname <span className="required-star">*</span>
                        </label>
                        <div className="form-field">
                          <Field
                            type="text"
                            id="lastname"
                            name="lastname"
                            value={values.lastname}
                            aria-required={true}
                            aria-label="lastname"
                            tabindex="2"
                          />
                          {touched.lastname && errors.lastname ? (
                            <div className="error-message" role="alert">
                              {errors.lastname}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-input">
                        <label
                          htmlFor="username"
                          className="form-label text-content"
                        >
                          Username <span className="required-star">*</span>
                        </label>
                        <div className="form-field">
                          <Field
                            type="text"
                            id="username"
                            name="username"
                            value={values.username}
                            aria-required={true}
                            aria-label="username"
                            tabindex="3"
                          />
                          {touched.username && errors.username ? (
                            <div className="error-message" role="alert">
                              {errors.username}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-input">
                        <label
                          htmlFor="email"
                          className="form-label text-content"
                        >
                          Email <span className="required-star">*</span>
                        </label>
                        <div className="form-field">
                          <Field
                            type="text"
                            id="email"
                            name="email"
                            value={values.email}
                            aria-required={true}
                            aria-label="email"
                            tabindex="4"
                          />
                          {touched.email && errors.email ? (
                            <div className="error-message" role="alert">
                              {errors.email}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {isAddMode ? (
                        <div className="form-input">
                          <label
                            htmlFor="password"
                            className="form-label text-content"
                          >
                            Passwort <span className="required-star">*</span>
                          </label>
                          <div className="form-field">
                            <Field
                              type="text"
                              id="password"
                              name="password"
                              value={values.password}
                              aria-required={true}
                              aria-label="password"
                              tabindex="5"
                            />
                            {touched.password && errors.password ? (
                              <div className="error-message" role="alert">
                                {errors.password}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="form-input react-select">
                        <label
                          htmlFor="roles"
                          className="form-label text-content"
                        >
                          Rollen <span className="required-star">*</span>
                        </label>
                        <Select
                          id="roles"
                          name="roles"
                          options={userRoles}
                          value={savedRoles ? savedRoles : selectedRoles}
                          placeholder="Select User Roles"
                          isMulti={true}
                          onChange={(value) => {
                            setSavedRoles(undefined);
                            setSelectedRoles(value);
                          }}
                          tabIndex={6}
                        />
                        {isDisabled ? (
                          <div className="error-message" role="alert">
                            Rolle ist Erforderlich!
                          </div>
                        ) : null}
                      </div>
                      <div className="submit-container">
                        <DefaultButton disabled={isDisabled} type="submit">
                          Speichern
                        </DefaultButton>
                        {!isAddMode ? (
                          <div className="float-right">
                            <div
                              className="my-button"
                              onClick={() =>
                                setChangePasswordPopup({
                                  changePasswordPopup: true,
                                  userId: userID,
                                })
                              }
                            >
                              Passwort zurücksetzen
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </>
        )}
      </div>
    </>
  );
}

export default CreateEditUser;
