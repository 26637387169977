import './MobileTranslationPopup.css'

import redCloseIcon from "assets/Icons/Header/button_close_red.png";
import contrastCloseButton from "assets/Icons/Header/close_contrast.png";

type MobileTranslationPopupProps = {
    translatedTerm?: any,
    fontSizeValues?: any,
    contrast?: any,
    setTranslatedText?: any,
    translatedTextSlide?: any,
}

function MobileTranslationPopup(props: MobileTranslationPopupProps) {
  return (
    <div className="mobile-translation-popup-container">
      <div className="mobile-translation-text-popup">
        <div className="mobile-translation-text-popup-header">
          <div className="mobile-translated-term-header">
            <h4
              className={`mobile-translated-term-slide-popup ${props.fontSizeValues}`}
            >
              {props.translatedTerm}
            </h4>
          </div>

          <div className="mobile-translation-text-popup-header2">
            <img
              src={
                props.contrast && props.contrast.isContrast === "true"
                  ? contrastCloseButton
                  : redCloseIcon
              }
              alt="Beenden"
              title="Beenden"
              className="mobile-translated-text-close-button"
              onClick={() => {
                props.setTranslatedText();
              }}
              tabIndex={0}
            />
          </div>
        </div>
        <div
          className={`mobile-translated-text-user-popup ${props.fontSizeValues}`}
        >
          {props.translatedTextSlide}
        </div>
      </div>
    </div>
  );
}

export default MobileTranslationPopup