import "./CreateEditModuleSubject.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// services
import SubjectService from "services/subjectService";

// actions
import {
  getLearningModules,
  updateLearningModule,
} from "store/actions/learningModuleAction";
import { setMessage } from "store/actions/messageAction";
import LearningModuleService from "services/learningModuleService";
import { removeLoading, setLoading } from "store/actions/loadingAction";

//icons:
import add from "assets/Icons/Sidebar/add-icon.png";
import inEdit from "assets/Icons/Sidebar/in-edit.png";
import deleteIcon from "assets/Icons/delete-course-icon.png";
import trash from "assets/Icons/Sidebar/menu-icon_deleted.png";
import publishedIcon from "assets/Icons/Sidebar/published.png";
import readyToBePublished from "assets/Icons/Sidebar/ready-to-be-published.png";

// Images:
import defaultImage from "assets/Images/default-image.png";

// Components:
import Header from "components/Header/Header";
import Loader from "components/Loader/Loader";
import SideBar from "components/SideBar/SideBar";
import DefaultPopup from "components/DefaultPopup/DefaultPopup";
import EditableTitle from "components/EditableTitle/EditableTitle";
import CreateEditLearningModule from "./CreateEditLearningModule/CreateEditLearningModule";
import MessageNotification from "components/MessageNotification/MessageNotification";

function CreateEditModuleSubject() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux states
  const learningModules = useSelector(
    (state: any) => state.LearningModuleReducer
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  // local state

  // state where we have the subjects of certain learning module
  const [subjects, setSubjects] = useState<any>();

  // state for the learning module for this page:
  const [learningModule, setLearningModule] = useState<any>();

  // state for the general popup used for many things delete, publish
  const [isPopup, setPopup] = useState({
    isPopup: false,
    id: "",
    type: "",
    method: "",
    label: "",
    yesButtonText: "",
    noButtonText: "",
  });

  // get the learningModuleID from the url parameter
  let params: any = useParams();
  const { learningModuleID } = params;

  // needed to kill any call after return in useEffect
  const abortController = new AbortController();
  const signal = abortController.signal;

  // constant to know if we are if create or update mode
  const isAddMode = !learningModuleID;

  //get  learning module:
  const GetLearningModuleByID = (learningModuleID: string, signal: any) => {
    dispatch(setLoading());
    // API call
    LearningModuleService.getLearningModuleByID(learningModuleID)
      .then(
        (response: any) => {
          setLearningModule(response.data);
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log(err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // function that get all subjects for certain module
  const getSubjectsByLearningModuleID = (
    learningModuleID: any,
    signal: any
  ) => {
    dispatch(setLoading());
    // API call
    SubjectService.getSubjectByLearningModuleID(learningModuleID)
      .then(
        (response: any) => {
          setSubjects(response.data);
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: string) => {
        console.log("err", err);
        dispatch(removeLoading());
      });
  };

  // create LearningModule function:
  const createLearningModuleLocal = (learningModule: any) => {
    const { name } = learningModule;
    // create data as specific typescript data type that support images in it:
    let data = new FormData();
    data.append("Name", name);
    // here we call the API directly without redux cz i need the returned object
    //  and it returns undefined from action response
    LearningModuleService.createLearningModule(data).then(
      (res: any) => {
        // get the learning module to update redux after creating new one
        dispatch(getLearningModules());
        setLearningModule(res.data);
        dispatch(setMessage("Learnmodul erfolgreich erstellt", "success"));
        navigate(`/create-edit-module-subject/${res.data.learningModuleID}`);
        dispatch(removeLoading());
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  };

  // Update learning Module function:
  const updateLearningModuleLocal = (id: string, learningModule: any) => {
    const {
      type,
      name,
      alt,
      image,
      title,
      videoName,
      extension,
      filePath,
      mimeType,
      vtt,
    } = learningModule;
    let data = new FormData();
    if (type === "icon") {
      data.append("Name", name);
      data.append("Icon.Title", alt);
      data.append("Icon.Name", alt);
      data.append("Icon.Alt", alt);
      data.append("Icon.Image", image);
    } else if (type === "thumbnail") {
      data.append("Name", name);
      data.append("Thumbnail.Title", alt);
      data.append("Thumbnail.Name", alt);
      data.append("Thumbnail.Alt", alt);
      data.append("Thumbnail.Image", image);
    } else if (type === "video") {
      data.append("Name", name);
      data.append("VideoInputModel.Title", title);
      data.append("VideoInputModel.Name", videoName);
      data.append("VideoInputModel.MimeType", mimeType);
      data.append("VideoInputModel.Extension", extension);
      data.append("VideoInputModel.FilePath", filePath);
      // vtt
      data.append("VideoInputModel.VttFile", vtt);
    } else {
      data.append("Name", name);
    }
    // API call using redux dispatch
    dispatch(updateLearningModule(id, data) as any)
      .then(
        (res: any) => {
          navigate(`/create-edit-module-subject/${learningModuleID}`);
          dispatch(setMessage("Learnmodul erfolgreich editiert", "success"));
          // get learning module by ID
          learningModuleID &&
            GetLearningModuleByID(learningModuleID, { signal: signal });
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: string) => {
        console.log(err);
      });
  };

  // create subject function:
  const createSubject = (data: any) => {
    const { newTitle, learningModuleID } = data;

    let formData = new FormData();
    formData.append("Name", newTitle);
    formData.append("learningModuleID", learningModuleID);
    dispatch(setLoading());
    // API call
    SubjectService.createSubject(formData)
      .then(
        (response: any) => {
          dispatch(setMessage("Thema erfolgreich erstellt", "success"));
          dispatch(getLearningModules());
          getSubjectsByLearningModuleID(learningModuleID, {
            signal: signal,
          });
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(setMessage(err.message, "error"));
        dispatch(removeLoading());
      });
  };

  // Update Subject function:
  const updateSubject = (subjectID: string, data: any) => {
    const { newTitle, learningModuleID } = data;
    let formData = new FormData();
    formData.append("Name", newTitle);
    formData.append("learningModuleID", learningModuleID);
    dispatch(setLoading());
    // API call
    SubjectService.updateSubject(subjectID, formData)
      .then(
        (response: any) => {
          dispatch(setMessage("Thema erfolgreich editiert", "success"));
          getSubjectsByLearningModuleID(learningModuleID, {
            signal: signal,
          });
          dispatch(getLearningModules());
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // Delete Subject function:
  const deleteSubject = (subjectID: string) => {
    dispatch(setLoading());
    // API call
    SubjectService.deleteSubject(subjectID)
      .then(
        (response: any) => {
          dispatch(setMessage("Theme erfolgreich gelöscht", "success"));
          getSubjectsByLearningModuleID(learningModuleID, {
            signal: signal,
          });
          dispatch(getLearningModules());
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  //set the page title
  useEffect(() => {
    document.title = "Armaturenbrett -ILLUME ";
  }, []);

  // remove getCourseByStatus function
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    // get learning module by ID
    learningModuleID &&
      GetLearningModuleByID(learningModuleID, { signal: signal });
    // get subjects by learning module ID
    learningModuleID &&
      getSubjectsByLearningModuleID(learningModuleID, {
        signal: signal,
      });
    if (learningModules.length === 0) {
      dispatch(getLearningModules());
    }
    return function cleanup() {
      abortController.abort();
      // empty local states
      setLearningModule("");
      setSubjects("");
    };
    // eslint-disable-next-line
  }, [learningModuleID]);

  return (
    <>
      <Header />
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {isPopup.isPopup && (
        <DefaultPopup
          label={isPopup.label}
          method={isPopup.method}
          yesButtonText={isPopup.yesButtonText}
          noButtonText={isPopup.noButtonText}
          action={() => deleteSubject(isPopup.id)}
          closePopup={() =>
            setPopup({
              isPopup: false,
              id: "",
              type: "",
              method: "",
              label: "",
              yesButtonText: "",
              noButtonText: "",
            })
          }
        />
      )}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="create-edit-module-subject-container">
            <div className="create-edit-module-subject-sidebar">
              <SideBar
                learningModules={learningModules}
                learningModuleID={learningModuleID}
              />
            </div>

            <div className="create-edit-module-subject-body">
              <section>
                <div>
                  <>
                    <CreateEditLearningModule
                      action={
                        isAddMode
                          ? createLearningModuleLocal
                          : updateLearningModuleLocal
                      }
                      learningModule={learningModule}
                      learningModuleID={learningModuleID}
                      setLearningModule={setLearningModule}
                    />

                    {subjects
                      ? subjects.map((subject: any, index: any) => (
                          <div className="editor-one-subject" key={index}>
                            <div className="editor-subject-name-container">
                              <EditableTitle
                                colorCode={learningModule?.normal}
                                type="subject"
                                title={subject.name}
                                subjectID={subject.subjectID}
                                learningModuleID={learningModuleID}
                                action={updateSubject}
                                isOpen={false}
                              />
                              <img
                                className="editor-subject-delete-icon"
                                src={deleteIcon}
                                alt="theme löschen"
                                title="theme löschen"
                                onClick={() => {
                                  setPopup({
                                    isPopup: true,
                                    id: subject.subjectID,
                                    method: "delete",
                                    type: "course",
                                    yesButtonText: "Ja ich bin mir sicher",
                                    noButtonText: "Nein, abbrechen",
                                    label:
                                      "Möchtest du diesen theme wirklich löschen?",
                                  });
                                }}
                              />
                            </div>

                            <div className="courses-list" key={index}>
                              {subject.courses &&
                                subject.courses.map(
                                  (course: any, index: any) => (
                                    <div key={index} className="my-one-course">
                                      <div className="float-left course-icon-container">
                                        <img
                                          style={{
                                            backgroundColor:
                                              learningModule?.normal,
                                          }}
                                          onClick={() =>
                                            navigate(
                                              `/create-edit-course-chapter-slide/${learningModuleID}/${subject.subjectID}/${course.courseID}/`
                                            )
                                          }
                                          className="course-icon"
                                          src={
                                            course.icon?.image
                                              ? "data:image/jpeg;base64," +
                                                course.icon?.image
                                              : defaultImage
                                          }
                                          alt={course.icon?.alt}
                                          title={course.icon?.title}
                                        />
                                      </div>
                                      <div
                                        className="float-left course-desc-container"
                                        onClick={() =>
                                          navigate(
                                            `/create-edit-course-chapter-slide/${learningModuleID}/${subject.subjectID}/${course.courseID}/`
                                          )
                                        }
                                      >
                                        <h4
                                          className="course-desc"
                                          style={{
                                            color: learningModule?.normal,
                                          }}
                                        >
                                          {course.title}
                                        </h4>
                                      </div>
                                      {course.status ===
                                      "ReadyToBePublished" ? (
                                        <img
                                          className="course-status-icon"
                                          src={readyToBePublished}
                                          alt={course.title}
                                          title="ReadyToBePublished"
                                        />
                                      ) : course.status === "InEdit" ? (
                                        <img
                                          className="course-status-icon"
                                          src={inEdit}
                                          alt={course.title}
                                          title="Kurse in Bearbeitung"
                                        />
                                      ) : course.status === "Deleted" ? (
                                        <img
                                          className="course-status-icon"
                                          src={trash}
                                          alt={course.title}
                                          title="Gelöscht"
                                        />
                                      ) : (
                                        <img
                                          className="course-status-icon"
                                          src={publishedIcon}
                                          alt={course.title}
                                          title="Veröffentlicht"
                                        />
                                      )}
                                    </div>
                                  )
                                )}
                              <div
                                key={index}
                                className="my-new-one-course"
                                onClick={() =>
                                  navigate(
                                    `/create-edit-course-chapter-slide/${learningModuleID}/${subject.subjectID}/`
                                  )
                                }
                              >
                                <div className="new-course-container">
                                  <img
                                    className="new-course-icon"
                                    src={add}
                                    alt="neuer Kurs"
                                    title="neuer Kurs"
                                  />
                                  <h4>Neuer Kurs</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : ""}

                    <br />
                    <br />

                    {learningModule && (
                      <div className="editor-subject-name-container">
                        <EditableTitle
                          learningModuleID={learningModuleID}
                          type="subject"
                          action={createSubject}
                          isOpen={true}
                          colorCode={learningModule?.normal}
                        />
                      </div>
                    )}
                  </>
                </div>
              </section>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CreateEditModuleSubject;
