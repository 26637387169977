import { QuizAPI } from "./http-common";
import authHeader from "./authHeader";

const getQuestionByQuizID = (id: string) => {
  return QuizAPI.get(`/Question/GetByQuizID/${id}`, {
    headers: authHeader() as any,
  });
};

const getQuestionByID = (id: string) => {
  return QuizAPI.get(`/Question/${id}`, {
    headers: authHeader() as any,
  });
};

const createQuestion = (data: any) => {
  return QuizAPI.post("/Question", data, {
    headers: authHeader() as any,
  });
};

const updateQuestion = (id: string, data: any) => {
  return QuizAPI.put(`/Question/${id}`, data, {
    headers: authHeader() as any,
  });
};

const updateQuestionOrder = (data: any) => {
  return QuizAPI.put("/Question/UpdateOrder", data, {
    headers: authHeader() as any,
  });
};

const deleteQuestion = (id: string) => {
  return QuizAPI.delete(`/Question/${id}`, {
    headers: authHeader() as any,
  });
};

const QuestionService = {
  getQuestionByQuizID,
  getQuestionByID,
  createQuestion,
  updateQuestion,
  updateQuestionOrder,
  deleteQuestion,
};

export default QuestionService;
