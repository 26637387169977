import "./AboutUsPage.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setHelpPopup } from "store/actions/helpPopupAction";

// Images:
import helpLogo from "assets/Icons/Header/helpLogo.png";
import contrastHelpLogo from "assets/Icons/Header/help_contrast.png";
import redCloseIcon from "assets/Icons/Header/button_close_red.png";
import contrastCloseButton from "assets/Icons/Header/close_contrast.png";

//icons
import speaker from "assets/Icons/speaker-tts-icon.png";
import contrastSpeaker from "assets/Icons/ttsblack.png";
import greenSpeaker from "assets/Icons/speaker-icon.png";
import greenContrastSpeaker from "assets/Icons/speaker-contrast-icon.png";

function AboutUsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //redux state
  const contrast = useSelector((state: any) => state.ContrastReducer);
  const fontSize = useSelector((state: any) => state.FontSizeReducer);
  //set the fontSizeValues to 22px and 28px
  const fontSizeValues = fontSize === "28" ? "fontSize-28" : "fontSize-22";

  // declaring utterance that will be used for TTS
  var utterance = new SpeechSynthesisUtterance();

  // word index that will be used for highlighting based in word index
  var wordIndex = 0;

  // variable used for stopping onclick on buttons  when  in function
  var clicked = 0;

  //  variable j to change the p tag taken from the div
  let j = 0;

  // declaring text to be read by TTS
  let text = " ";

  // word by word reading function
  function highlight_wordByWord_style2(
    text4: any,
    length: any,
    text5: any,
    text6: any
  ) {
    // used to know when reading and to stop reading onclick
    clicked = 1;

    // like taking backup of the origin text
    let text2 = text4;

    // for returning the p tag to its origin at end of highlighting.
    let origin = text2?.innerText;

    //taking the text sent in the p tag
    text = text2?.innerHTML;

    // splitting the main text by spaces
    var words = text?.split(" ");

    // give the tts library the text
    utterance.text = text;

    // give the tts library the lang to be used
    utterance.lang = "de-DE";

    // start speaking the given text
    speechSynthesis.speak(utterance);

    // onboundary means:
    utterance.onboundary = async function (event) {
      // call the draw text to panel function, we put it outside the on boundary it colors all the worlds
      drawTextInPanel(words, text2);

      // if clicked = 0 so speaking = true, so we cancel it and we return the original text
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // else if the event is a word (needed in the library cz we have "word" and "sentence")
      else if (event.name === "word") {
        try {
          // we give the specified word background color yellow
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.backgroundColor = "yellow";

          // we give the specified word color black
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.color = "black";
        } catch (e) {}

        // increment the word index to get the next word
        wordIndex++;
      }
    };

    // onend:
    utterance.onend = function () {
      // condition to let it stop if we re click the speaker
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // condition to let it open only at the end
      else if (wordIndex > 0) {
        if (text2) {
          //returning the p tag to its origin
          text2.innerHTML = origin;
        }

        // length here is the length of the parent div
        if (j < length) {
          // then we read the image alt, at the onClick we start by reading the h2 then we read the alt here, then from inside this we re-call the read word by word to read the h4
          highlight_wordByWord_style2(text5?.children[j], length, text5, text6);
        }

        // if j = length we stop the reading, so the div's are finished
        if (j === length) {
          // j is the counter
          j = 0;
          speechSynthesis.cancel();
          // we re put the original speaker
          text6[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === "true"
              ? contrastSpeaker
              : speaker
          );
          // allow onclick to be pressed
          clicked = 0;
        }

        // we increment the j to get the next child
        j++;
      }
      // and we put the word index to 0 cz we will start from the beginning in the next step
      wordIndex = 0;
    };
  }

  // draw text in panel function: we give it words_array, and the text: the original text, and it split it and give each word a span and we used it to give background color
  function drawTextInPanel(words_array: any, text: any) {
    // accepting array of splatted words of the text and the text p tag
    if (text) {
      // clearing the p tag for filling it with the text but each word of a span
      text.innerHTML = "";
    }
    // panel is container with the spans for each word
    var panel = text;

    // inserting the new text in the panel
    for (var i = 0; i < words_array?.length; i++) {
      // for loop for filling the panel(p tag) with text each word of a span
      var html =
        // eslint-disable-next-line no-useless-concat
        '<span id="word_span_' + i + '">' + words_array[i] + " " + "</span>";
      (panel as any).innerHTML += html;
    }
  }

  const cancelSpeaking = () => {
    clicked = 0;
    speechSynthesis.cancel();
    let speaker1 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p1"
    );
    speaker1[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker2 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p2"
    );
    speaker2[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker3 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p3"
    );
    speaker3[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker4 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p4"
    );
    speaker4[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker5 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p5"
    );
    speaker5[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker6 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p6"
    );
    speaker6[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker7 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p7"
    );
    speaker7[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker8 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p8"
    );
    speaker8[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker9 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p9"
    );
    speaker9[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );

    clicked = 0;
    speechSynthesis.cancel();
  };

  const ttsOnClick = (textClassName: string, speakerIconClassName: string) => {
    let length;
    if (clicked === 0) {
      let t = document.getElementsByClassName(textClassName);
      let chapterTitleText = t[0].children[0];
      j = 1;
      length = t[0].children.length;
      let speakerIcon = document.getElementsByClassName(speakerIconClassName);
      highlight_wordByWord_style2(chapterTitleText, length, t[0], speakerIcon);
      // console.log("speakerIcon", speakerIcon);

      speakerIcon[0].setAttribute(
        "src",
        contrast && contrast.isContrast === "true"
          ? greenContrastSpeaker
          : greenSpeaker
      );
    } else {
      cancelSpeaking();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    contrast &&
      contrast.isContrast === "true" &&
      document.body.classList.add("enable-contrast");
  }, [contrast]);

    window.addEventListener("scroll", (e) => {
      const nav = document.querySelector(".footer-page-header");
      const title = document.getElementById("footer-page-header-title");
      if (window.innerWidth <= 800) {
        if (window.pageYOffset > 0) {
          nav?.classList.add("add-shadow");
          title?.classList.add("title-show");
        } else {
          nav?.classList.remove("add-shadow");
          title?.classList.remove("title-show");
        }
      }
    });

  return (
    <main className="footer-pages-main-container background-color">
      <article className="footer-pages-main">
        {/* Help Logo */}
        <div tabIndex={0} className="footer-page-header">
          <div
            className="footer-page-header-title"
            id="footer-page-header-title"
          >
            Impressum
          </div>
          <img
            role="button"
            className="user-close-logo"
            src={
              contrast && contrast.isContrast === "true"
                ? contrastCloseButton
                : redCloseIcon
            }
            onClick={() => {
              cancelSpeaking();
              navigate("/");
            }}
            tabIndex={0}
            onKeyPress={() => {
              navigate("/");
              cancelSpeaking();
            }}
            alt="Impressum beenden"
            title="Impressum beenden."
          />
          <img
            role="button"
            className="user-footer-help-logo"
            src={
              contrast && contrast.isContrast === "true"
                ? contrastHelpLogo
                : helpLogo
            }
            onClick={() => {
              dispatch(setHelpPopup());
              cancelSpeaking();
            }}
            tabIndex={0}
            onKeyPress={() => {
              dispatch(setHelpPopup());
              cancelSpeaking();
            }}
            alt="Hilfe-Einstellungen"
            title="Hilfe Einstellungen"
          />
        </div>

        {/* Page title */}
        <div className="footer-pages-header-container">
          <h1 className="footer-page-title">Impressum</h1>
        </div>

        {/* Body */}
        <div className="footer-pages-body-container">
          <>
            <div className="about-us-p1-main">
              <img
                onClick={() =>
                  ttsOnClick("about-us-p1", "footer-pages-speaker-icon-p1")
                }
                className="footer-pages-speaker-icon-p1"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="speaker-icon"
                title="speaker-icon"
              />
              <section className="about-us-p1 text-content">
                <h1 className={` ${fontSizeValues}`}>
                  ILLUME Projektkoordination
                </h1>
                <div className={` ${fontSizeValues}`}>
                  <h2>Inhaltlich Verantwortlicher gem. ß 55 Abs. 2 RStV:</h2>
                </div>

                <p className={` ${fontSizeValues}`}>
                  Tobias Marczinzik (Leitung PIKSL), Ludwig Erhard Allee 14,
                  40227 Düsseldorf In der Gemeinde leben gGmbH
                </p>

                <div className={` ${fontSizeValues}`}>
                  <h2>Hilfen für Menschen mit Behinderungen</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>Ludwig Erhard Allee 14</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>40227 Düsseldorf</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>Tel: 0211/913640</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>Fax: 0211/91364-111</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>E-Mail: info@igl-duesseldorf.de</h2>
                </div>
              </section>
            </div>

            {/* ------------------------------------------------------------------------------------------------------ */}

            <section className="about-us-p2-main">
              <img
                onClick={() =>
                  ttsOnClick("about-us-p2", "footer-pages-speaker-icon-p2")
                }
                className="footer-pages-speaker-icon-p2"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="speaker-icon"
                title="speaker-icon"
              />
              <article className="about-us-p2 text-content">
                <h1 className={` ${fontSizeValues}`}>Geschäftsführer:in</h1>
                <div className={` ${fontSizeValues}`}>
                  <h2>Catrin Dreyer</h2>
                </div>

                <div className={` ${fontSizeValues}`}>
                  <h2>Andreas Diederichs</h2>
                </div>

                <div className={` ${fontSizeValues}`}>
                  <h2>Amtsgericht Düsseldorf, HRB 41835</h2>
                </div>

                <p className={`${fontSizeValues}`}>
                  Umsatzsteuer-Identifikationsnummer: 133/5907/1565
                </p>
              </article>
            </section>

            {/* ------------------------------------------------------------------------------------------------------ */}

            <section className="about-us-p3-main">
              <img
                onClick={() =>
                  ttsOnClick("about-us-p3", "footer-pages-speaker-icon-p3")
                }
                className="footer-pages-speaker-icon-p3"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion."
                title="Vorlesefunktion"
              />
              <article className="about-us-p3 text-content">
                <h1 className={` ${fontSizeValues}`}>Gesellschafter</h1>
                <div className={` ${fontSizeValues}`}>
                  <h2>Diakonie Düsseldorf</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>
                    Gemeindedienst der evangelischen Kirchengemeinden e.V.
                  </h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>Platz der Diakonie 1</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>40233 Düsseldorf</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>v. Bodelschwinghsche Stiftungen Bethel</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>Königsweg 1</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>33545 Bielefeld</h2>
                </div>
              </article>
            </section>

            {/* ------------------------------------------------------------------------------------------------------ */}

            <section className="about-us-p4-main">
              <img
                onClick={() =>
                  ttsOnClick("about-us-p4", "footer-pages-speaker-icon-p4")
                }
                className="footer-pages-speaker-icon-p4"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion."
                title="Vorlesefunktion"
              />
              <div className="about-us-p4 text-content">
                <h1 className={` ${fontSizeValues}`}>
                  Gestaltung und Entwicklung
                </h1>
                <p className={` ${fontSizeValues}`}>
                  Dominik Mycielski, Sensrec Service UG, ILLUME Team
                </p>
              </div>
            </section>

            {/* ------------------------------------------------------------------------------------------------------ */}

            <section className="about-us-p5-main">
              <img
                onClick={() =>
                  ttsOnClick("about-us-p5", "footer-pages-speaker-icon-p5")
                }
                className="footer-pages-speaker-icon-p5"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion."
                title="Vorlesefunktion"
              />
              <div className="about-us-p5 text-content">
                <h1 className={` ${fontSizeValues}`}>Programmierung</h1>
                <div className={` ${fontSizeValues}`}>
                  <h2>Sensrec Service UG</h2>
                </div>
              </div>
            </section>

            {/* ------------------------------------------------------------------------------------------------------ */}

            <section className="about-us-p6-main">
              <img
                onClick={() =>
                  ttsOnClick("about-us-p6", "footer-pages-speaker-icon-p6")
                }
                className="footer-pages-speaker-icon-p6"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion"
                title="Vorlesefunktion"
              />
              <div className="about-us-p6 text-content">
                <h1 className={` ${fontSizeValues}`}>
                  Fotografien und Illustrationen unter anderem von
                </h1>
                <div className={` ${fontSizeValues}`}>
                  <h2>Dominik Mycielski</h2>
                </div>
              </div>
            </section>

            {/* ------------------------------------------------------------------------------------------------------ */}

            <section className="about-us-p7-main">
              <img
                onClick={() =>
                  ttsOnClick("about-us-p7", "footer-pages-speaker-icon-p7")
                }
                className="footer-pages-speaker-icon-p7"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion."
                title="Vorlesefunktion"
              />
              <article className="about-us-p7 text-content">
                <h1 className={` ${fontSizeValues}`}>Haftungsausschluss</h1>
                <div className={` ${fontSizeValues}`}>
                  <h2>Haftung für Inhalte</h2>
                </div>

                <p className={` ${fontSizeValues}`}>
                  Die Inhalte unserer Seiten wurden mit größter Sorgfalt
                  erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität
                  der Inhalte können wir jedoch keine Gewähr übernehmen. Als
                  Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                  Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                  verantwortlich. Nach § § 8 bis 10 TMG sind wir als
                  Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                  gespeicherte fremde Informationen zu überwachen oder nach
                  Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                  hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                  Nutzung von Informationen nach den allgemeinen Gesetzen
                  bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                  jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                  Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                  Rechtsverletzungen werden wir diese Inhalte umgehend
                  entfernen.
                </p>
              </article>
            </section>

            {/* ------------------------------------------------------------------------------------------------------ */}

            <section className="about-us-p8-main">
              <img
                onClick={() =>
                  ttsOnClick("about-us-p8", "footer-pages-speaker-icon-p8")
                }
                className="footer-pages-speaker-icon-p8"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion"
                title="Vorlesefunktion."
              />
              <div className="about-us-p8 text-content">
                <h1 className={` ${fontSizeValues}`}>Haftung für Links</h1>
                <p className={` ${fontSizeValues}`}>
                  Unser Angebot enthält Links zu externen Webseiten Dritter, auf
                  deren Inhalte wir keinen Einfluss haben. Deshalb können wir
                  für diese fremden Inhalte auch keine Gewähr übernehmen. Für
                  die Inhalte der verlinkten Seiten ist stets der jeweilige
                  Anbieter oder Betreiber der Seiten verantwortlich. Die
                  verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
                  mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren
                  zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                  inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
                  konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
                  Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                  Links umgehend entfernen.
                </p>
              </div>
            </section>

            {/* ------------------------------------------------------------------------------------------------------ */}

            <section className="about-us-p9-main">
              <img
                onClick={() =>
                  ttsOnClick("about-us-p9", "footer-pages-speaker-icon-p9")
                }
                className="footer-pages-speaker-icon-p9"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion."
                title="Vorlesefunktion"
              />
              <div className="about-us-p9 text-content">
                <h1 className={` ${fontSizeValues}`}>Urheberrecht</h1>
                <p className={` ${fontSizeValues}`}>
                  Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                  diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                  Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                  Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen
                  der schriftlichen Zustimmung des jeweiligen Autors bzw.
                  Erstellers. Downloads und Kopien dieser Seite sind nur für den
                  privaten, nicht kommerziellen Gebrauch gestattet. Soweit die
                  Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
                  werden die Urheberrechte Dritter beachtet. Insbesondere werden
                  Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                  trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                  bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
                  von Rechtsverletzungen werden wir derartige Inhalte umgehend
                  entfernen. Der Nutzung von im Rahmen der Impressumspflicht
                  veröffentlichten Kontaktdaten durch Dritte zur Übersendung von
                  nicht ausdrücklich angeforderter Werbung und
                  Informationsmaterialien wird hiermit ausdrücklich
                  widersprochen. Die Betreiber der Seiten behalten sich
                  ausdrücklich rechtliche Schritte im Falle der unverlangten
                  Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
                </p>
                <p className={` ${fontSizeValues}`}>
                  Quelle: Disclaimer von eRecht24, dem Portal zum Internetrecht
                  von Rechtsanwalt Sören Siebert.
                </p>
              </div>
            </section>
          </>
        </div>
      </article>
    </main>
  );
}

export default AboutUsPage;
