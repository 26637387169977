import "./UserOverviewModuleSubject.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import SubjectService from "services/subjectService";
import { setHelpPopup } from "store/actions/helpPopupAction";
import LearningModuleService from "services/learningModuleService";
import { getLearningModules } from "store/actions/learningModuleAction";
import { removeLoading, setLoading } from "store/actions/loadingAction";

// Components:
import Loader from "components/Loader/Loader";
import SideBar from "components/SideBar/SideBar";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import MessageNotification from "components/MessageNotification/MessageNotification";
import CookiesPopup from "components/CookiesPopup/CookiesPopup";

// Images:
import helpLogo from "assets/Icons/Header/helpLogo.png";
import defaultImage from "assets/Images/default-image.png";
import contrastHelpLogo from "assets/Icons/Header/help_contrast.png";

import speaker from "assets/Icons/speaker-tts-icon.png";
// import homeImage from "assets/Images/home-page-image.jpeg";
import contrastSpeaker from "assets/Icons/ttsblack.png";
import greenSpeaker from "assets/Icons/speaker-icon.png";
import greenContrastSpeaker from "assets/Icons/speaker-contrast-icon.png";
import burgerMenuIcon from "assets/Icons/burger-menu-icon.png";
import redCloseIcon from "assets/Icons/Header/button_close_red.png";

function OverviewSubjects() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // redux states
  const learningModules = useSelector(
    (state: any) => state.LearningModuleReducer
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );
  const contrast = useSelector((state: any) => state.ContrastReducer);
  const fontSize = useSelector((state: any) => state.FontSizeReducer);
  const isCookiesPopup = useSelector((state: any) => state.cookiesReducer);

  //set the fontSizeValues to 22px and 28px
  const fontSizeValues = fontSize === "28" ? "fontSize-28" : "fontSize-22";

  //local state
  const [learningModule, setLearningModule] = useState<any>();
  const [subjects, setSubjects] = useState<any>();

  const [isBurgerMenuOpened, setBurgerMenuOpened] = useState(false);

  //get the saved objects from the local storage
  // const getLastVisitedCourses = localStorage.getItem("Courses");

  // let coursesViewed =
  // getLastVisitedCourses !== null ? JSON.parse(getLastVisitedCourses) : [];

  // get the learningModuleID from the url parameter
  let params: any = useParams();
  const { learningModuleID } = params;

  // declaring utterance that will be used for TTS
  var utterance = new SpeechSynthesisUtterance();

  // word index that will be used for highlighting based in word index
  var wordIndex = 0;

  // variable used for stopping onclick on buttons  when  in function
  var clicked = 0;

  //  variable j to change the p tag taken from the div
  let j = 0;

  // declaring text to be read by TTS
  let text = " ";

  // word by word reading function
  function highlight_wordByWord_style2(text4: any, length: any, text5: any) {
    // used to know when reading and to stop reading onclick
    clicked = 1;

    // like taking backup of the origin text
    let text2 = text4;

    // for returning the p tag to its origin at end of highlighting.
    let origin = text2?.innerText;

    //taking the text sent in the p tag
    text = text2?.innerHTML;

    // splitting the main text by spaces
    var words = text?.split(" ");

    // give the tts library the text
    utterance.text = text;

    // give the tts library the lang to be used
    utterance.lang = "de-DE";

    // start speaking the given text
    speechSynthesis.speak(utterance);

    // onboundary means:
    utterance.onboundary = async function (event) {
      // call the draw text to panel function, we put it outside the on boundary it colors all the worlds
      drawTextInPanel(words, text2);

      // if clicked = 0 so speaking = true, so we cancel it and we return the original text
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // else if the event is a word (needed in the library cz we have "word" and "sentence")
      else if (event.name === "word") {
        try {
          // we give the specified word background color yellow
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.backgroundColor = "yellow";

          // we give the specified word color black
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.color = "black";
        } catch (e) {}

        // increment the word index to get the next word
        wordIndex++;
      }
    };

    // onend:
    utterance.onend = function () {
      // condition to let it stop if we re click the speaker
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // condition to let it open only at the end
      else if (wordIndex > 0) {
        if (text2) {
          //returning the p tag to its origin
          text2.innerHTML = origin;
        }

        // length here is the length of the parent div
        if (j < length) {
          // then we read the image alt, at the onClick we start by reading the h2 then we read the alt here, then from inside this we re-call the read word by word to read the h4
          read_img_alt(
            text5.children[2].children[j].children[0].children[0].getAttribute(
              "alt"
            ),
            // text5.children[2].children[j].children[0].children[0].attributes[2],
            length,
            text5
          );
        }

        // if j = length we stop the reading, so the div's are finished
        if (j === length) {
          // j is the counter
          j = 0;
          speechSynthesis.cancel();
          // we re put the original speaker
          text5?.children[0].setAttribute(
            "src",
            contrast && contrast.isContrast === "true"
              ? contrastSpeaker
              : speaker
          );
          // allow onclick to be pressed
          clicked = 0;
        }

        // we increment the j to get the next child
        j++;
      }
      // and we put the word index to 0 cz we will start from the beginning in the next step
      wordIndex = 0;
    };
  }

  // read image alt function
  function read_img_alt(text4: any, length: any, text5: any) {
    // for stopping onclick
    clicked = 1;
    let text2 = text4;
    // textContent to read the alt not the innerHTML
    // text = text2?.textContent;
    text = text2;
    utterance.text = text;
    utterance.lang = "de-DE";
    speechSynthesis.speak(utterance);

    // we give the image a background color yellow, we access the children throw there place in the div
    text5.children[2].children[j].children[0].children[0].setAttribute(
      "style",
      "background-color: yellow;"
    );
    // onboundary means: boundary of each word
    utterance.onboundary = async function (event) {
      if (clicked === 0) {
        speechSynthesis.cancel();
      }
    };
    // onend: open to it at the start and end of the speech (we put condition to open only at the end)
    utterance.onend = function () {
      if (clicked === 0) {
        // we return the image background color the original one got from the DB
        text5?.children[2]?.children[
          j - 1
        ]?.children[0]?.children[0]?.setAttribute(
          "style",
          `background-color: ${learningModule && learningModule.normal};`
        );
        speechSynthesis.cancel();
      } else {
        // from here we go to read the h4, (j-1) cz we increment it at the function n the top
        highlight_wordByWord_style2(
          text5?.children[2].children[j - 1].children[0].children[1],
          length,
          text5
        );
      }

      // we return the image background color the original one got from the DB
      text5?.children[2]?.children[
        j - 1
      ]?.children[0]?.children[0]?.setAttribute(
        "style",
        `background-color: ${learningModule && learningModule.normal};`
      );
    };
  }

  // draw text in panel function: we give it words_array, and the text: the original text, and it split it and give each word a span and we used it to give background color
  function drawTextInPanel(words_array: any, text: any) {
    // accepting array of splatted words of the text and the text p tag
    if (text) {
      // clearing the p tag for filling it with the text but each word of a span
      text.innerHTML = "";
    }
    // panel is container with the spans for each word
    var panel = text;

    // inserting the new text in the panel
    for (var i = 0; i < words_array?.length; i++) {
      // for loop for filling the panel(p tag) with text each word of a span
      var html =
        // eslint-disable-next-line no-useless-concat
        '<span id="word_span_' + i + '">' + words_array[i] + " " + "</span>";
      (panel as any).innerHTML += html;
    }
  }

  const cancelSpeaking = () => {
    clicked = 0;
    speechSynthesis.cancel();
    let subjectsContainer = document.getElementsByClassName(
      "learning-module-content"
    );
    for (let i = 0; i < subjectsContainer[0]?.children.length; i++) {
      subjectsContainer[0]?.children[i]?.children[0]?.children[0]?.setAttribute(
        "src",
        contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
      );
    }
  };

  // function that gets all subjects
  const getByLearningModuleWithPublishedCourses = (
    learningModuleID: any,
    signal: any
  ) => {
    dispatch(setLoading());

    SubjectService.getByLearningModuleWithPublishedCourses(learningModuleID)
      .then((response: any) => {
        setSubjects(response.data);
        dispatch(removeLoading());
      })
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
      });
  };

  useEffect(() => {
    document.title = "Armaturenbrett - ILLUME";
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    getByLearningModuleWithPublishedCourses(learningModuleID, {
      signal: signal,
    });
    if (learningModules.length === 0) {
      dispatch(getLearningModules());
    }

    LearningModuleService.getLearningModuleByID(learningModuleID)
      .then((response: any) => {
        setLearningModule(response.data);
      })
      .catch((err: any) => {
        console.log("err", err);
      });

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, [learningModuleID]);

  const burgerMenuClicked = () => {
    let sidebar = document.getElementById("learning-module-side-bar");
    if (!isBurgerMenuOpened) {
      sidebar && sidebar.classList.add("show");
      setBurgerMenuOpened(true);
    } else {
      sidebar && sidebar.classList.remove("show");
      setBurgerMenuOpened(false);
    }
  };

  window.addEventListener("scroll", (e) => {
    const nav = document.querySelector(".user-overview-subjects-burger-menu");

    if (window.pageYOffset > 0) {
      nav?.classList.add("add-shadow");
    } else {
      nav?.classList.remove("add-shadow");
    }
  });

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {!isCookiesPopup && !loading && (
        <>
          <CookiesPopup />
        </>
      )}

      <>
        {loading && (
          <>
            <Loader />
            <div className="overlay"></div>
          </>
        )}

        <main
          className={
            isBurgerMenuOpened && window.innerWidth <= 915
              ? "user-overview-subjects-container-hidden"
              : "user-overview-subjects-container"
          }
        >
          {/* User sidebar */}
          <div
            className="user-overview-subjects-sidebar"
            id="learning-module-side-bar"
            style={{
              backgroundColor:
                contrast && contrast.isContrast === "true" ? "#000" : "#fff",
            }}
          >
            <div className="user-overview-subjects-sidebar-header">
              <h1 className="user-overview-subjects-sidebar-header-title">
                ILLUME
              </h1>
              <img
                className="user-overview-subjects-sidebar-header-close-button"
                role="button"
                src={redCloseIcon}
                alt="Hilfe-beenden"
                title="Hilfe beenden"
                onClick={burgerMenuClicked}
              />
            </div>
            <SideBar
              learningModules={learningModules}
              learningModuleID={learningModuleID}
              cancelSpeaking={cancelSpeaking}
              mobileVersionHideSidebar={burgerMenuClicked}
            />
          </div>
          {/* tablet and phone version header */}
          <div
            className="user-overview-subjects-burger-menu"
            style={{ backgroundColor: learningModule && learningModule.normal }}
          >
            <img
              role="button"
              src={burgerMenuIcon}
              alt="burger-menu-icon"
              title="Burger Menu Icon"
              className="user-overview-subjects-burger-menu-icon"
              onClick={burgerMenuClicked}
            />
            <h1 className="user-overview-subjects-burger-menu-title">ILLUME</h1>
          </div>

          {/* User overview module subjects body */}
          <div
            className="user-overview-subjects-body background-color"
            style={{
              backgroundColor: learningModule && learningModule.light,
            }}
          >
            {/* Help Logo */}
            <div>
              <img
                role="button"
                className="user-help-logo"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastHelpLogo
                    : helpLogo
                }
                onClick={() => dispatch(setHelpPopup())}
                tabIndex={0}
                onKeyPress={() => {
                  dispatch(setHelpPopup());
                }}
                alt="Hilfe-Einstellungen"
                title="Hilfe Einstellungen "
              />
            </div>
            {/* check first if the learning module has a video then show video else show thumbnail */}

            {learningModule?.video !== undefined ? (
              <>
                <div
                  style={{
                    backgroundColor: learningModule && learningModule.normal,
                  }}
                  className="user-learning-module-video-container"
                >
                  {/* we send video information as props*/}
                  <VideoPlayer
                    videoTitle={learningModule?.name}
                    filePath={learningModule?.video?.filePath}
                    mimeType={learningModule?.video?.mimeType}
                    vttFile={learningModule?.vttFile}
                    colorCode={learningModule?.normal}
                    cancelSpeaking={cancelSpeaking}
                  />
                </div>
              </>
            ) : learningModule?.thumbnail !== undefined ? (
              <>
                {/* Check if it is tablet or phone remove the thumbnail picture and set the learning module background color */}
                {window.innerWidth > 915 ? (
                  <div
                    role="button"
                    className="user-learning-module-thumbnail-container"
                    style={{
                      // backgroundColor: learningModule && learningModule.normal,
                      backgroundImage: learningModule?.thumbnail?.image
                        ? `url(data:image/jpeg;base64,${learningModule?.thumbnail?.image})`
                        : "",
                      height: "300px",
                    }}
                  >
                    <h1
                      className="learning-module-name-above-video"
                      style={{
                        color: learningModule && learningModule.normal,
                      }}
                    >
                      {learningModule?.name}
                    </h1>
                  </div>
                ) : (
                  <div
                    role="button"
                    className="user-learning-module-thumbnail-container"
                    style={{
                      backgroundColor: learningModule && learningModule.normal,
                    }}
                  >
                    <h1
                      className="learning-module-name-above-video"
                      style={{
                        color: learningModule && learningModule.normal,
                      }}
                    >
                      {learningModule?.name}
                    </h1>
                  </div>
                )}

                {/* <img
                    // className="user-learning-module-video-container"
                    src={
                      learningModule?.thumbnail?.image
                        ? "data:image/jpeg;base64," +
                          learningModule?.thumbnail?.image
                        : ""
                    }
                    alt={learningModule?.thumbnail?.alt}
                    title={learningModule?.thumbnail?.title}
                  /> */}
              </>
            ) : // check if there is neither a video nor a thumbnail then display the default Image
            learningModule?.video === undefined &&
              learningModule?.thumbnail === undefined ? (
              <div
                style={{
                  backgroundColor: learningModule && learningModule.normal,
                }}
                className="user-learning-module-thumbnail-container"
              >
                <h1
                  className="learning-module-name-above-video"
                  style={{
                    color: learningModule && learningModule.normal,
                  }}
                >
                  {learningModule?.name}
                </h1>
              </div>
            ) : null}

            <div className="learning-module-content  background-color">
              {subjects ? (
                subjects.map((subject: any, index: any) => (
                  <div key={subject.subjectID}>
                    <div className="user-one-subject" id={subject.subjectID}>
                      <img
                        role="button"
                        onClick={() => {
                          let length;
                          if (clicked === 0) {
                            let t = document.getElementsByClassName(
                              "user-subject-courses-list"
                            );
                            let text = document.getElementById(
                              subject.subjectID
                            );
                            length = t[index].children.length;
                            j = 0;
                            highlight_wordByWord_style2(
                              text?.children[1],
                              length,
                              text
                            );
                            // setSubjectID(subject.subjectID);

                            text?.children[0].setAttribute(
                              "src",

                              contrast && contrast.isContrast === "true"
                                ? greenContrastSpeaker
                                : greenSpeaker
                            );
                          } else {
                            let subjectsContainer =
                              document.getElementsByClassName(
                                "learning-module-content"
                              );
                            for (
                              let i = 0;
                              i < subjectsContainer[0].children.length;
                              i++
                            ) {
                              subjectsContainer[0].children[
                                i
                              ].children[0].children[0].setAttribute(
                                "src",
                                contrast && contrast.isContrast === "true"
                                  ? contrastSpeaker
                                  : speaker
                              );
                            }
                            // setSubjectID("");
                            let text = document.getElementById(
                              subject.subjectID
                            );
                            text?.children[0].setAttribute(
                              "src",
                              contrast && contrast.isContrast === "true"
                                ? contrastSpeaker
                                : speaker
                            );
                            clicked = 0;
                            speechSynthesis.cancel();
                          }
                        }}
                        className="user-speaker-icon"
                        src={
                          contrast && contrast.isContrast === "true"
                            ? contrastSpeaker
                            : speaker
                        }
                        // src={
                        //   subjectID === subject.subjectID
                        //     ? greenSpeaker
                        //     : speaker
                        // }
                        alt="Vorlesefunktion"
                      />
                      {/* ----------------------------------------------------------------------------------------------------------------------------- */}
                      <h1
                        style={{
                          color:
                            contrast && contrast.isContrast === "true"
                              ? ""
                              : learningModule && learningModule.normal,
                        }}
                        className={`user-subject-name text-content ${fontSizeValues}`}
                      >
                        {subject.name}
                      </h1>
                      <div className="user-subject-courses-list">
                        {subject.courses ? (
                          subject.courses.map((course: any, index: any) => (
                            <div
                              className="user-one-course"
                              key={course.courseID}
                            >
                              <div
                                className="course-icon-desc-container"
                                onClick={() => {
                                  navigate(
                                    `/course-details/${learningModuleID}/${course.courseID}/`
                                  );
                                  cancelSpeaking();
                                  // clicked = 0;
                                  // speechSynthesis.cancel();
                                }}
                                tabIndex={0}
                                onKeyPress={() =>
                                  navigate(
                                    `/course-details/${learningModuleID}/${course.courseID}/`
                                  )
                                }
                              >
                                <img
                                  style={{
                                    backgroundColor:
                                      learningModule && learningModule.normal,
                                  }}
                                  className="user-course-icon"
                                  src={
                                    course.icon?.image
                                      ? "data:image/jpeg;base64," +
                                        course.icon?.image
                                      : defaultImage
                                  }
                                  alt={`Icon für ${
                                    course.icon?.alt ? course.icon?.alt : ""
                                  }`}
                                  title={`${course?.icon?.title} `}
                                />
                                <h2
                                  style={{
                                    // color:
                                    //   learningModule && learningModule.normal,
                                    color:
                                      contrast && contrast.isContrast === "true"
                                        ? "black"
                                        : learningModule &&
                                          learningModule.normal,
                                  }}
                                  className={`course-title-container ${fontSizeValues}`}
                                >
                                  {course.title}
                                </h2>
                              </div>
                            </div>
                          ))
                        ) : (
                          <h3>Keine Kurse gefunden!!</h3>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h3>Keine Themen gefunden!!</h3>
              )}
            </div>
          </div>
        </main>
      </>
    </>
  );
}

export default OverviewSubjects;
