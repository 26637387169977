import "./Statistics.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditorSideBar from "components/SideBar/SideBar";
import { getLearningModules } from "store/actions/learningModuleAction";

// Components:
import Loader from "components/Loader/Loader";
import MessageNotification from "components/MessageNotification/MessageNotification";
import Header from "components/Header/Header";

//import chart library
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

function Statistics() {
  const data01 = [
    { name: "Published ", value: 400 },
    { name: "Ready to Be Published", value: 200 },
    { name: "In Edit ", value: 100 },
  ];

  const data02 = [
    { name: "Published ", value: 400 },
    { name: "Ready to Be Published", value: 200 },
    { name: "In Edit ", value: 100 },
  ];

  const data = [
    {
      name: "Computer",
      course: 50,
      subject: 100,
    },
    {
      name: "Test",
      course: 30,
      subject: 198,
    },
    {
      name: "Page C",
      course: 100,
      subject: 200,
    },
    {
      name: "Page D",
      course: 80,
      subject: 108,
    },
    {
      name: "Page E",
      course: 10,
      subject: 50,
    },
  ];


  const dispatch = useDispatch();

  // redux states
  const learningModules = useSelector(
    (state: any) => state.LearningModuleReducer
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  useEffect(() => {
    document.title = "Statistiken - ILLUME";
  }, []);

  useEffect(() => {
    if (learningModules.length === 0) {
      dispatch(getLearningModules());
    }
  }, [dispatch, learningModules.length]);

  return (
    <>
      <Header />
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="statistic-container">
            <div className="statistic-sidebar">
              <EditorSideBar learningModules={learningModules} />
            </div>

            <div className="statistic-body text-content">
              <h1 style={{ fontSize: "6rem" }}>Statistiken</h1>
              <div className="pie-container">
                <ResponsiveContainer width="100%" aspect={3}>
                  <PieChart>
                    <text
                      x={270}
                      y={10}
                      fill="black"
                      textAnchor="middle"
                      dominantBaseline="central"
                      fontSize={14}
                    >
                      Gesamtzahl der veröffentlichten, zum Veröffentlichen und
                      in Bearbeitung Kurse
                    </text>
                    <Pie
                      dataKey="value"
                      isAnimationActive={false}
                      data={data01}
                      cx="15%"
                      cy="40%"
                      outerRadius={80}
                      fill="#8884d8"
                      label
                    />
                    <Pie
                      dataKey="value"
                      data={data02}
                      cx={600}
                      cy={160}
                      innerRadius={40}
                      outerRadius={80}
                      fill="#82ca9d"
                    />
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className="pie-chart-text">
                <text
                  x={270}
                  y={10}
                  fill="black"
                  textAnchor="middle"
                  dominantBaseline="central"
                  fontSize={14}
                >
                  Gesamtzahl der Thmen und Kurse in Lernmodulen
                </text>
              </div>

              <div className="bar-chart">
                <ResponsiveContainer width="47%" aspect={1}>
                  <BarChart
                    width={100}
                    height={500}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Thema" fill="#8884d8" />
                    <Bar dataKey="Kurs" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
                <ResponsiveContainer width="47%" aspect={1}>
                  <BarChart
                    width={100}
                    height={100}
                    data={data}
                    margin={{
                      top: 20,
                      right: 0,
                      left: 30,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Thema" stackId="a" fill="#8884d8" />
                    <Bar dataKey="Kurs" stackId="a" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Statistics;
