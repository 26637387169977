import "./UserDictionary.css";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setMessage } from "store/actions/messageAction";
import { setHelpPopup } from "store/actions/helpPopupAction";
import TranslationService from "services/translationService";
import { getLearningModules } from "store/actions/learningModuleAction";
import { removeLoading, setLoading } from "store/actions/loadingAction";

// Components:
import Loader from "components/Loader/Loader";
import SideBar from "components/SideBar/SideBar";
import MessageNotification from "components/MessageNotification/MessageNotification";
import CookiesPopup from "components/CookiesPopup/CookiesPopup";

// Images:
import speaker from "assets/Icons/speaker-tts-icon.png";
import contrastSpeaker from "assets/Icons/ttsblack.png";
import helpLogo from "assets/Icons/Header/helpLogo.png";
import greenSpeaker from "assets/Icons/speaker-icon.png";
import greenContrastSpeaker from "assets/Icons/speaker-contrast-icon.png";
import redCloseIcon from "assets/Icons/Header/button_close_red.png";
import contrastCloseButton from "assets/Icons/Header/close_contrast.png";
import contrastHelpLogo from "assets/Icons/Header/help_contrast.png";
import burgerMenuIcon from "assets/Icons/burger-menu-icon.png";

function UserDictionary() {
  const dispatch = useDispatch();

  // redux states
  const learningModules = useSelector(
    (state: any) => state.LearningModuleReducer
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );
  const contrast = useSelector((state: any) => state.ContrastReducer);
  const fontSize = useSelector((state: any) => state.FontSizeReducer);
  const isCookiesPopup = useSelector((state: any) => state.cookiesReducer);

  //set the fontSizeValues to 22px and 28px
  const fontSizeValues = fontSize === "28" ? "fontSize-28" : "fontSize-22";

  //local state
  const [isTranslatedText, setTranslatedText] = useState(false);

  const [translationsGroups, setTranslationsGroups] = useState<any>();

  const [selectedTranslation, setSelectedTranslation] = useState<any>();

  const [isBurgerMenOpened, setBurgerMenuOpened] = useState(false);

  // declaring utterance that will be used for TTS
  var utterance = new SpeechSynthesisUtterance();

  // word index that will be used for highlighting based in word index
  var wordIndex = 0;

  // variable used for stopping onclick on buttons  when  in function
  var clicked = 0;

  //  variable j to change the p tag taken from the div
  let j = 0;

  // declaring text to be read by TTS
  let text = " ";

  // tabIndex variables
  const topTapTrap = useRef() as any;
  const bottomTapTrap = useRef() as any;
  const firstFocusableElement = useRef() as any;
  const lastFocusableElement = useRef() as any;

  // word by word reading function
  function highlight_wordByWord_style2(
    text4: any,
    length: any,
    text5: any,
    text6: any
  ) {
    // used to know when reading and to stop reading onclick
    clicked = 1;

    // like taking backup of the origin text
    let text2 = text4;

    // for returning the p tag to its origin at end of highlighting.
    let origin = text2?.innerText;

    //taking the text sent in the p tag
    text = text2?.innerHTML;

    // splitting the main text by spaces
    var words = text?.split(" ");

    // give the tts library the text
    utterance.text = text;

    // give the tts library the lang to be used
    utterance.lang = "de-DE";

    // start speaking the given text
    speechSynthesis.speak(utterance);

    // onboundary means:
    utterance.onboundary = async function (event) {
      // call the draw text to panel function, we put it outside the on boundary it colors all the worlds
      drawTextInPanel(words, text2);

      // if clicked = 0 so speaking = true, so we cancel it and we return the original text
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // else if the event is a word (needed in the library cz we have "word" and "sentence")
      else if (event.name === "word") {
        try {
          // we give the specified word background color yellow
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.backgroundColor = "yellow";

          // we give the specified word color black
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.color = "black";
        } catch (e) {}

        // increment the word index to get the next word
        wordIndex++;
      }
    };

    // onend:
    utterance.onend = function () {
      // condition to let it stop if we re click the speaker
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // condition to let it open only at the end
      else if (wordIndex > 0) {
        if (text2) {
          //returning the p tag to its origin
          text2.innerHTML = origin;
        }

        // length here is the length of the parent div
        if (j < length) {
          // then we read the image alt, at the onClick we start by reading the h2 then we read the alt here, then from inside this we re-call the read word by word to read the h4
          highlight_wordByWord_style2(text5?.children[j], length, text5, text6);
        }

        // if j = length we stop the reading, so the div's are finished
        if (j === length) {
          // j is the counter
          j = 0;
          speechSynthesis.cancel();
          // we re put the original speaker
          text6?.children[0]?.children[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === "true"
              ? contrastSpeaker
              : speaker
          );
          // allow onclick to be pressed
          clicked = 0;
        }

        // we increment the j to get the next child
        j++;
      }
      // and we put the word index to 0 cz we will start from the beginning in the next step
      wordIndex = 0;
    };
  }

  // word by word reading function
  function highlight_wordByWord_style2_popup(
    text4: any,
    length: any,
    text5: any,
    text6: any
  ) {
    // used to know when reading and to stop reading onclick
    clicked = 1;

    // like taking backup of the origin text
    let text2 = text4;

    // for returning the p tag to its origin at end of highlighting.
    let origin = text2?.innerText;

    //taking the text sent in the p tag
    text = text2?.innerHTML;

    // splitting the main text by spaces
    var words = text?.split(" ");

    // give the tts library the text
    utterance.text = text;

    // give the tts library the lang to be used
    utterance.lang = "de-DE";

    // start speaking the given text
    speechSynthesis.speak(utterance);

    // onboundary means: enter here on each word
    utterance.onboundary = async function (event) {
      // call the draw text to panel function, we put it outside the on boundary it colors all the worlds
      // this one only add spans to the text, we put it inside the onboundary to highlight word by word cz it remove the old highlight each time, if we put it outside the onboundary it highlight all the text
      drawTextInPanel_popup(words, text2);

      // if clicked = 0 so speaking = true, so we cancel it and we return the original text
      if (clicked === 0) {
        speechSynthesis.cancel();
        // here we return the text to the original form without spans
        text2.innerHTML = origin;
      }
      // else if the event is a word (needed in the library cz we have "word" and "sentence")
      else if (event.name === "word") {
        try {
          // we give the specified word background color yellow
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.backgroundColor = "yellow";

          // we give the specified word color black
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.color = "black";
        } catch (e) {}

        // increment the word index to get the next word
        wordIndex++;
      }
    };

    // onend: enter here at the end of the last word in the text
    utterance.onend = function () {
      // condition to let it stop if we re click the speaker
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // condition to let it open only at the end
      else if (wordIndex > 0) {
        if (text2) {
          //returning the p tag to its origin
          text2.innerHTML = origin;
        }

        // length here is the length of the parent div
        if (j < length) {
          // then we read the image alt, at the onClick we start by reading the h2 then we read the alt here, then from inside this we re-call the read word by word to read the h4
          highlight_wordByWord_style2_popup(text5, length, text5, text6);
        }

        // if j = length we stop the reading, so the div's are finished
        if (j === length) {
          // j is the counter
          j = 0;
          speechSynthesis.cancel();
          // we re put the original speaker
          text6[0]?.children[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === "true"
              ? contrastSpeaker
              : speaker
          );
          // allow onclick to be pressed
          clicked = 0;
        }

        // we increment the j to get the next child
        j++;
      }
      // and we put the word index to 0 cz we will start from the beginning in the next step
      wordIndex = 0;
    };
  }

  // draw text in panel function: we give it words_array, and the text: the original text, and it split it and give each word a span and we used it to give background color
  function drawTextInPanel(words_array: any, text: any) {
    // accepting array of splatted words of the text and the text p tag
    if (text) {
      // clearing the p tag for filling it with the text but each word of a span
      text.innerHTML = "";
    }
    // panel is container with the spans for each word
    var panel = text;

    // inserting the new text in the panel
    for (var i = 0; i < words_array?.length; i++) {
      // for loop for filling the panel(p tag) with text each word of a span
      var html =
        // eslint-disable-next-line no-useless-concat
        '<span id="word_span_' + i + '">' + words_array[i] + " " + "</span>";
      (panel as any).innerHTML += html;
    }
  }
  // draw text in panel function: we give it words_array, and the text: the original text, and it split it and give each word a span and we used it to give background color
  function drawTextInPanel_popup(words_array: any, text: any) {
    // accepting array of splatted words of the text and the text p tag
    if (text) {
      // clearing the p tag for filling it with the text but each word of a span
      text.innerHTML = "";
    }
    // panel is container with the spans for each word
    var panel = text;

    // inserting the new text in the panel
    for (var i = 0; i < words_array?.length; i++) {
      // for loop for filling the panel(p tag) with text each word of a span
      var html;
      if (j === 0) {
        html =
          // eslint-disable-next-line no-useless-concat
          '<span id="word_span_' +
          i +
          '">' +
          words_array[i] +
          "\xa0" +
          "</span>";
        (panel as any).innerHTML += html;
      } else {
        html =
          // eslint-disable-next-line no-useless-concat
          '<span id="word_span_' + i + '">' + words_array[i] + " " + "</span>";
        (panel as any).innerHTML += html;
      }
    }
  }

  const cancelSpeaking = () => {
    clicked = 0;
    speechSynthesis.cancel();
    let translationContainer = document.getElementsByClassName(
      "user-dictionary-terms-side"
    );
    for (let i = 0; i < translationContainer[0].children.length; i++) {
      translationContainer[0].children[i].children[0].children[0].setAttribute(
        "src",
        contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
      );
    }
    clicked = 0;
    speechSynthesis.cancel();
  };

  // function that get all subjects
  const getTranslations = (signal: any) => {
    dispatch(setLoading());

    TranslationService.getAllTranslationsGrouped()
      .then(
        (response: any) => {
          setTranslationsGroups(response.data);
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
      });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    document.title = "Armaturenbrett - ILLUME";

    getTranslations({
      signal: signal,
    });

    if (learningModules.length === 0) {
      dispatch(getLearningModules());
    }

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  const burgerMenuClicked = () => {
    let sidebar = document.getElementById("learning-module-side-bar");
    let container = document.getElementById("user-dictionary-container");
    if (!isBurgerMenOpened && !isTranslatedText && !loading) {
      window.scrollTo(0, 0);
      sidebar && sidebar.classList.add("show");
      container?.classList.add("translated");
      setBurgerMenuOpened(true);
    } else {
      sidebar && sidebar.classList.remove("show");
      container?.classList.remove("translated");
      setBurgerMenuOpened(false);
    }
  };

  window.addEventListener("scroll", (e) => {
    const nav = document.querySelector(".user-dictionary-burger-menu");
    if (window.pageYOffset > 0) {
      nav?.classList.add("add-shadow");
    } else {
      nav?.classList.remove("add-shadow");
    }
  });

  const openDictionaryPopup = () => {
    let container = document.getElementById("user-dictionary-container");
    if (window.innerWidth <= 915) {
      if (isTranslatedText) {
        container?.classList.remove("translated");
      } else {
        container?.classList.add("translated");
      }
    }
  };

  useEffect(() => {
    //call addEventListener method where the event is focusin and pass the trapFocus function
    document.addEventListener("focusin", trapFocus);

    return function cleanup() {
      document.addEventListener("focusin", trapFocus);
    };

    /**
     *
     * @param event
     * Check the event if it's on the top or on the bottom
     * and focus the current element
     */

    function trapFocus(event: any) {
      if (event.target === topTapTrap.current) {
        lastFocusableElement.current.focus();
      }
      if (event.target === bottomTapTrap.current) {
        firstFocusableElement.current.focus();
      }
    }
  }, [firstFocusableElement, lastFocusableElement]);

  const checkFocusOnDictionary = () => {
    const dictionaryModal = document.getElementById("focusDictionary");
    if (dictionaryModal != null) {
      dictionaryModal.focus({ focusVisible: true } as any);
    }
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {!isCookiesPopup && !loading && (
        <>
          <CookiesPopup />
        </>
      )}

      <>
        {loading && (
          <>
            <Loader />
            <div className="overlay"></div>
          </>
        )}

        <div
          className={"user-dictionary-container"}
          id={"user-dictionary-container"}
          // style={{
          //   height: isTranslatedText ? "100%" : "auto",
          //   overflow: isTranslatedText ? "hidden" : "auto",
          // }}
        >
          {/* User sidebar */}
          <div
            className="user-dictionary-sidebar"
            id="learning-module-side-bar"
            style={{
              backgroundColor:
                contrast && contrast.isContrast === "true" ? "#000" : "#fff",
            }}
          >
            <div className="user-dictionary-sidebar-header">
              <h1 className="user-dictionary-sidebar-header-title">ILLUME</h1>
              <img
                className="user-dictionary-sidebar-header-close-button"
                role="button"
                src={redCloseIcon}
                alt="Hilfe-beenden"
                title="Hilfe beenden"
                onClick={burgerMenuClicked}
              />
            </div>
            <SideBar
              learningModules={learningModules}
              cancelSpeaking={cancelSpeaking}
              mobileVersionHideSidebar={burgerMenuClicked}
            />
          </div>

          <div className="user-dictionary-burger-menu">
            <img
              role="button"
              src={burgerMenuIcon}
              alt="burger-menu-icon"
              title="Burger Menu Icon"
              className="user-dictionary-burger-menu-icon"
              onClick={burgerMenuClicked}
            />
            <h1 className="user-dictionary-burger-menu-title">ILLUME</h1>
          </div>

          {/* User overview module subjects body */}
          <div className="user-dictionary-body-container background-color">
            {/* Help Logo */}
            <div>
              <img
                role="button"
                className="user-help-logo"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastHelpLogo
                    : helpLogo
                }
                onClick={() => dispatch(setHelpPopup())}
                tabIndex={0}
                onKeyPress={() => {
                  dispatch(setHelpPopup());
                }}
                alt="Hilfe-Einstellungen"
                title="Hilfe Einstellungen"
              />
            </div>
            {/* header + title */}
            <div className="user-dictionary-header">
              <h1 className="user-dictionary-title">Wörterbuch</h1>
            </div>

            <div className="user-dictionary-body">
              <div className="user-dictionary-terms-side">
                {translationsGroups &&
                  translationsGroups.map(
                    (translationsGroup: any, index: number) => {
                      return (
                        <div key={index} id={translationsGroup.character}>
                          <div className="user-dictionary-speaker-character-container">
                            <img
                              role="button"
                              tabIndex={0}
                              onClick={() => {
                                let length;
                                if (clicked === 0) {
                                  let t = document.getElementsByClassName(
                                    "user-dictionary-character"
                                  );
                                  let character = t[index];
                                  let terms = document.getElementsByClassName(
                                    "user-dictionary-terms"
                                  );
                                  let text = document.getElementById(
                                    translationsGroup.character
                                  );
                                  length = terms[index].children.length;
                                  highlight_wordByWord_style2(
                                    character,
                                    length,
                                    terms[index],
                                    text
                                  );

                                  j = 0;

                                  text?.children[0].children[0].setAttribute(
                                    "src",
                                    contrast && contrast.isContrast === "true"
                                      ? greenContrastSpeaker
                                      : greenSpeaker
                                  );
                                  let mySpeaker =
                                    document.getElementsByClassName(
                                      "translation-text-popup-header2"
                                    );
                                  mySpeaker[0]?.children[0].setAttribute(
                                    "src",
                                    contrast && contrast.isContrast === "true"
                                      ? contrastSpeaker
                                      : speaker
                                  );
                                } else {
                                  let mySpeaker =
                                    document.getElementsByClassName(
                                      "translation-text-popup-header2"
                                    );
                                  mySpeaker[0]?.children[0].setAttribute(
                                    "src",
                                    contrast && contrast.isContrast === "true"
                                      ? contrastSpeaker
                                      : speaker
                                  );
                                  let translationContainer =
                                    document.getElementsByClassName(
                                      "user-dictionary-terms-side"
                                    );
                                  for (
                                    let i = 0;
                                    i < translationContainer[0].children.length;
                                    i++
                                  ) {
                                    translationContainer[0].children[
                                      i
                                    ].children[0].children[0].setAttribute(
                                      "src",
                                      contrast && contrast.isContrast === "true"
                                        ? contrastSpeaker
                                        : speaker
                                    );
                                  }
                                  clicked = 0;
                                  speechSynthesis.cancel();
                                }
                              }}
                              onKeyPress={() => {
                                let length;
                                if (clicked === 0) {
                                  let t = document.getElementsByClassName(
                                    "user-dictionary-character"
                                  );
                                  let character = t[index];

                                  let terms = document.getElementsByClassName(
                                    "user-dictionary-terms"
                                  );
                                  let text = document.getElementById(
                                    translationsGroup.character
                                  );
                                  length = terms[index].children.length;
                                  highlight_wordByWord_style2(
                                    character,
                                    length,
                                    terms[index],
                                    text
                                  );

                                  j = 0;

                                  text?.children[0].children[0].setAttribute(
                                    "src",
                                    contrast && contrast.isContrast === "true"
                                      ? greenContrastSpeaker
                                      : greenSpeaker
                                  );
                                  let mySpeaker =
                                    document.getElementsByClassName(
                                      "translation-text-popup-header2"
                                    );
                                  mySpeaker[0]?.children[0].setAttribute(
                                    "src",
                                    contrast && contrast.isContrast === "true"
                                      ? contrastSpeaker
                                      : speaker
                                  );
                                } else {
                                  let mySpeaker =
                                    document.getElementsByClassName(
                                      "translation-text-popup-header2"
                                    );
                                  mySpeaker[0]?.children[0].setAttribute(
                                    "src",
                                    contrast && contrast.isContrast === "true"
                                      ? contrastSpeaker
                                      : speaker
                                  );
                                  let translationContainer =
                                    document.getElementsByClassName(
                                      "user-dictionary-terms-side"
                                    );
                                  for (
                                    let i = 0;
                                    i < translationContainer[0].children.length;
                                    i++
                                  ) {
                                    translationContainer[0].children[
                                      i
                                    ].children[0].children[0].setAttribute(
                                      "src",
                                      contrast && contrast.isContrast === "true"
                                        ? contrastSpeaker
                                        : speaker
                                    );
                                  }
                                  clicked = 0;
                                  speechSynthesis.cancel();
                                }
                              }}
                              className="user-dictionary-speaker-icon"
                              src={
                                contrast && contrast.isContrast === "true"
                                  ? contrastSpeaker
                                  : speaker
                              }
                              alt="Vorlesefunktion."
                              title="Vorlesefunktion "
                            />
                            <h2
                              className="user-dictionary-character text-content"
                              tabIndex={0}
                            >
                              {translationsGroup.character.toUpperCase()}
                            </h2>
                          </div>

                          <ul className="user-dictionary-terms">
                            {translationsGroup.translations.map(
                              (translation: any) => {
                                return (
                                  <li
                                    key={translation.translationID}
                                    onClick={() => {
                                      setSelectedTranslation(translation);
                                      // openDictionaryPopup();
                                      setTranslatedText(true);
                                      cancelSpeaking();
                                      let mySpeaker =
                                        document.getElementsByClassName(
                                          "translation-text-popup-header2"
                                        );
                                      mySpeaker[0]?.children[0].setAttribute(
                                        "src",
                                        contrast &&
                                          contrast.isContrast === "true"
                                          ? contrastSpeaker
                                          : speaker
                                      );
                                    }}
                                    onKeyPress={() => {
                                      setSelectedTranslation(translation);
                                      // openDictionaryPopup();
                                      setTranslatedText(true);
                                      cancelSpeaking();
                                      let mySpeaker =
                                        document.getElementsByClassName(
                                          "translation-text-popup-header2"
                                        );
                                      mySpeaker[0]?.children[0].setAttribute(
                                        "src",
                                        contrast &&
                                          contrast.isContrast === "true"
                                          ? contrastSpeaker
                                          : speaker
                                      );
                                      checkFocusOnDictionary();
                                    }}
                                    className={`user-dictionary-one-translationTerm sidebar-list-content ${fontSizeValues}`}
                                    tabIndex={0}
                                  >
                                    {translation.term}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      );
                    }
                  )}
              </div>

              <div
                className="user-dictionary-translation-container"
                id="dictionary-modal"
              >
                {isTranslatedText && (
                  <div
                    id="focusDictionary"
                    className="user-dictionary-popup"
                    ref={topTapTrap}
                    tabIndex={0}
                  >
                    <div className="translation-text-popup-header">
                      <div className="translated-term-header">
                        <h3
                          tabIndex={0}
                          ref={firstFocusableElement}
                          className={`translated-term-slide-popup ${fontSizeValues}`}
                        >
                          {selectedTranslation.term}
                        </h3>
                      </div>

                      <div className="translation-text-popup-header2">
                        <img
                          src={
                            contrast && contrast.isContrast === "true"
                              ? contrastSpeaker
                              : speaker
                          }
                          alt="Vorlesefunktion"
                          title="Vorlesefunktion."
                          className="translated-text-speaker-button"
                          onClick={() => {
                            if (clicked === 0) {
                              let myTerm = document.getElementsByClassName(
                                "translated-term-slide-popup"
                              );

                              let mtText = document.getElementsByClassName(
                                "translated-text-user-popup"
                              );

                              let mySpeaker = document.getElementsByClassName(
                                "translation-text-popup-header2"
                              );

                              highlight_wordByWord_style2_popup(
                                myTerm[0],
                                1,
                                mtText[0],
                                mySpeaker
                              );

                              j = 0;

                              mySpeaker[0]?.children[0].setAttribute(
                                "src",
                                contrast && contrast.isContrast === "true"
                                  ? greenContrastSpeaker
                                  : greenSpeaker
                              );

                              // to remove green close btn from the terms side
                              let translationContainer =
                                document.getElementsByClassName(
                                  "user-dictionary-terms-side"
                                );
                              for (
                                let i = 0;
                                i < translationContainer[0].children.length;
                                i++
                              ) {
                                translationContainer[0].children[
                                  i
                                ].children[0].children[0].setAttribute(
                                  "src",
                                  contrast && contrast.isContrast === "true"
                                    ? contrastSpeaker
                                    : speaker
                                );
                              }
                            } else {
                              // to remove green close btn from the terms side
                              let translationContainer =
                                document.getElementsByClassName(
                                  "user-dictionary-terms-side"
                                );
                              for (
                                let i = 0;
                                i < translationContainer[0].children.length;
                                i++
                              ) {
                                translationContainer[0].children[
                                  i
                                ].children[0].children[0].setAttribute(
                                  "src",
                                  contrast && contrast.isContrast === "true"
                                    ? contrastSpeaker
                                    : speaker
                                );
                              }
                              clicked = 0;
                              speechSynthesis.cancel();
                              let mySpeaker = document.getElementsByClassName(
                                "translation-text-popup-header2"
                              );
                              mySpeaker[0]?.children[0].setAttribute(
                                "src",
                                contrast && contrast.isContrast === "true"
                                  ? contrastSpeaker
                                  : speaker
                              );
                            }
                          }}
                          tabIndex={0}
                          onKeyPress={() => {
                            if (clicked === 0) {
                              let myTerm = document.getElementsByClassName(
                                "translated-term-slide-popup"
                              );

                              let mtText = document.getElementsByClassName(
                                "translated-text-user-popup"
                              );

                              let mySpeaker = document.getElementsByClassName(
                                "translation-text-popup-header2"
                              );

                              highlight_wordByWord_style2_popup(
                                myTerm[0],
                                1,
                                mtText[0],
                                mySpeaker
                              );

                              j = 0;

                              mySpeaker[0]?.children[0].setAttribute(
                                "src",
                                contrast && contrast.isContrast === "true"
                                  ? greenContrastSpeaker
                                  : greenSpeaker
                              );

                              // to remove green close btn from the terms side
                              let translationContainer =
                                document.getElementsByClassName(
                                  "user-dictionary-terms-side"
                                );
                              for (
                                let i = 0;
                                i < translationContainer[0].children.length;
                                i++
                              ) {
                                translationContainer[0].children[
                                  i
                                ].children[0].children[0].setAttribute(
                                  "src",
                                  contrast && contrast.isContrast === "true"
                                    ? contrastSpeaker
                                    : speaker
                                );
                              }
                            } else {
                              // to remove green close btn from the terms side
                              let translationContainer =
                                document.getElementsByClassName(
                                  "user-dictionary-terms-side"
                                );
                              for (
                                let i = 0;
                                i < translationContainer[0].children.length;
                                i++
                              ) {
                                translationContainer[0].children[
                                  i
                                ].children[0].children[0].setAttribute(
                                  "src",
                                  contrast && contrast.isContrast === "true"
                                    ? contrastSpeaker
                                    : speaker
                                );
                              }
                              clicked = 0;
                              speechSynthesis.cancel();
                              let mySpeaker = document.getElementsByClassName(
                                "translation-text-popup-header2"
                              );
                              mySpeaker[0]?.children[0].setAttribute(
                                "src",
                                contrast && contrast.isContrast === "true"
                                  ? contrastSpeaker
                                  : speaker
                              );
                            }
                          }}
                        />
                        <img
                          src={
                            contrast && contrast.isContrast === "true"
                              ? contrastCloseButton
                              : redCloseIcon
                          }
                          alt="Hilfe-beenden"
                          title="Hilfe beenden"
                          className="translated-text-close-button"
                          onClick={() => {
                            openDictionaryPopup();
                            setTranslatedText(false);
                            cancelSpeaking();
                          }}
                          tabIndex={0}
                          ref={lastFocusableElement}
                          onKeyPress={() => {
                            setTranslatedText(false);
                            cancelSpeaking();
                          }}
                        />
                      </div>
                    </div>
                    <p
                      tabIndex={0}
                      className={`translated-text-user-popup ${fontSizeValues}`}
                    >
                      {selectedTranslation.translationText}
                    </p>
                    <span ref={bottomTapTrap} tabIndex={0} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default UserDictionary;
