import "./SlideTypePopup.css";
import { useEffect, useState } from "react";
import DefaultButton from "../DefaultButton/DefaultButton";

//images
import textImage from "../../assets/Images/textImage.png";
import videoImage from "../../assets/Images/videoType.png";

type SlideTypePopupProps = {
  closePopup: () => void;
  action: any;
  type: string;
};

function SlideTypePopup(props: SlideTypePopupProps) {
  //local state
  const [slideTypePopup, setSlideTypePopup] = useState(true);

  const toggleModal = () => {
    setSlideTypePopup(!slideTypePopup);
  };

  const cancelPopup = () => {
    closeModal();
    toggleModal();
    props.closePopup();
  };

  useEffect(() => {
    return () => {
      closeModal();
      toggleModal();
    }; // eslint-disable-next-line
  }, []);

  if (slideTypePopup) {
    document.body.classList.add("active-modal");
    document.body.style.overflowY = "hidden";
  }

  //add closeModal function to enable the scroll feature after closing the modal
  const closeModal = () => {
    const body = document.body;
    const scrollY = body.style.top;
     window.scrollTo(0, parseInt(scrollY || "0") * -1);
    document.body.classList.remove("active-modal");
    document.body.style.overflowY = "visible";
  };

  return (
    <>
      <div className="modal">
        <div
          onClick={toggleModal}
          className={
            props.type === "delete" ? "overlay delete-background" : "overlay"
          }
        ></div>

        <div className="modal-content slide-type-popup-container">
          <div className="slide-type-popup">
            <div
              className="model-text slide-text-image-type"
              onClick={() =>
                props.action({ type: "text-image", isOpen: false })
              }
            >
              <h1>Text/Bild Slide</h1>

              {/* eslint-disable-next-line */}
              <img
                className="slide-text-image"
                src={textImage}
                alt="TextSlide oder BildSlide"
                title="TextSlide oder BildSlide"
              />
            </div>

            <div
              className="model-text slide-video-type"
              onClick={() => props.action({ type: "video", isOpen: false })}
            >
              <h1>Video Slide</h1>
              <img
                className="slide-text-image"
                src={videoImage}
                alt="Video-Slide"
                title="Video-Slide"
              />
            </div>
          </div>
          <DefaultButton onClick={cancelPopup} className="cancel">
            Abbrechen
          </DefaultButton>
        </div>
      </div>
    </>
  );
}

export default SlideTypePopup;
