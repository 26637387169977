import { setMessage } from "./messageAction";
import { removeLoading, setLoading } from "./loadingAction";
import {
  CREATE_LEARNING_MODULE,
  GET_LEARNING_MODULE,
  DELETE_LEARNING_MODULE,
} from "./types";
import LearningModuleService from "../../services/learningModuleService";

export const createLearningModule = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading());
    await LearningModuleService.createLearningModule(data).then(
      (res: any) => {
        dispatch({
          type: CREATE_LEARNING_MODULE,
          payload: res.data,
        });
        dispatch(removeLoading());

        return Promise.resolve(res);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
        return Promise.reject(error);
      }
    );
  } catch (err: any) {
    dispatch(setMessage(err, "error"));
    dispatch(removeLoading());

    return Promise.reject(err);
  }
};

export const getLearningModules = () => (dispatch: any) => {
  try {
    dispatch(setLoading());
    LearningModuleService.getAllLearningModules().then(
      (res) => {
        dispatch({
          type: GET_LEARNING_MODULE,
          payload: res.data,
        });

        dispatch(removeLoading());
        return Promise.resolve(res.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());

        return Promise.reject(error);
      }
    );
  } catch (err: any) {
    dispatch(setMessage(err, "error"));
    console.log(err);
    dispatch(removeLoading());
  }
};

export const updateLearningModule =
  (id: string, data: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading());
      await LearningModuleService.updateLearningModule(id, data).then(
        (res) => {
          dispatch(getLearningModules());
          dispatch(removeLoading());

          return Promise.resolve(res);
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
          return Promise.reject(error);
        }
      );
    } catch (err: any) {
      dispatch(setMessage(err, "error"));
      dispatch(removeLoading());

      return Promise.reject(err);
    }
  };

export const deleteLearningModule = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading());

    await LearningModuleService.deleteLearningModule(id).then(
      (data) => {
        dispatch({
          type: DELETE_LEARNING_MODULE,
          payload: { id },
        });
        dispatch(getLearningModules());
        dispatch(setMessage("Learning Module Deleted successfully", "success"));
        dispatch(removeLoading());
        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
        return Promise.reject();
      }
    );
  } catch (err) {
    console.log(err);
  }
};
