import { GET_USERS } from "../actions/types";

const initialState = [] as any;


type UserReducerAction = {
  type: "GET_USERS" ;
  payload: string;
};

function UserReducer(users=initialState, action: UserReducerAction) {
  const { type, payload } = action;

  switch (type) {
    case GET_USERS:
      return payload;

    default:
      return users;
  }
}

export default UserReducer;
