import "./CreateTranslationPopUp.css";
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../store/actions/messageAction";
import TranslationService from "../../services/translationService";
import { getAllTranslations } from "../../store/actions/translationAction";
import { CreateTranslationSchema } from "../../validations/editorValidations";
import { removeLoading, setLoading } from "../../store/actions/loadingAction";

// Components:
import Loader from "../Loader/Loader";
import DefaultButton from "../DefaultButton/DefaultButton";

type CreateTranslationPopupProps = {
  type: string;
  label: string;
  action: any;
  term: string;
  translationText: string;
  closeCreateTranslationPopup: () => void;
};

export default function CreateTranslationPopup(
  props: CreateTranslationPopupProps
) {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  //redux states
  const { loading } = useSelector((state: any) => state.LoadingReducer);

  //local states
  // eslint-disable-next-line
  const [translation, setTranslation] = useState({
    term: props.term,
    translationText: props.translationText,
  });
  const [createTranslationPopup, setCreateTranslationPopup] = useState(true);

  const toggleModal = () => {
    setCreateTranslationPopup(!createTranslationPopup);
  };

  const onSubmit = (fields: any) => {
    let formData = new FormData();
    formData.append("term", fields.term);
    formData.append("translationText", fields.translationText);

    dispatch(setLoading());
    TranslationService.createTranslation(formData)
      .then((response: any) => {
        if (response) {
          navigate("/create-edit-translations");
          dispatch(
            setMessage("Die Übersetzung wurde erfolgreich erstellt.", "success")
          );
          cancelPopup();
          dispatch(getAllTranslations());
          dispatch(removeLoading());
        }
      })
      .catch((e: any) => {
        console.log(e);
        const message =
          (e.response && e.response.data && e.response.data.message) ||
          e.message ||
          e.toString();
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      });
  };

  const cancelPopup = () => {
    closeModal();
    props.closeCreateTranslationPopup();
  };

  if (createTranslationPopup) {
    document.body.classList.add("active-modal");
    document.body.style.overflowY = "hidden";
  } else {
    document.body.classList.remove("active-modal");
    document.body.style.overflowY = "visible";
  }

  const closeModal = () => {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
    document.body.classList.remove("active-modal");
    document.body.style.overflowY = "visible";
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={translation}
            validationSchema={CreateTranslationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form>
                    <div className="modal">
                      <div onClick={toggleModal} className="overlay"></div>
                      <div className="modal-content change-password">
                        <h2>Neue Übersetzung anlegen:</h2>

                        <div className="form-input">
                          <label
                            htmlFor="term"
                            className="form-label text-content"
                          >
                            Wort <span className="required-star">*</span>
                          </label>
                          <div className="form-field">
                            <Field
                              type="text"
                              id="term"
                              name="term"
                              aria-required={true}
                              aria-label="term"
                              tabindex="1"
                              autoFocus={true}
                            />
                            {touched.term && errors.term ? (
                              <div className="error-message" role="alert">
                                {errors.term}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="form-input">
                          <label
                            htmlFor="translationText"
                            className="form-label text-content"
                          >
                            Übersetzung
                            <span className="required-star">*</span>
                          </label>
                          <div className="form-field">
                            <Field
                              as="textarea"
                              type="text"
                              id="translationText"
                              name="translationText"
                              aria-required={true}
                              aria-label="translationText"
                              tabindex="2"
                              className="translation-text-area"
                            />
                            {touched.translationText &&
                            errors.translationText ? (
                              <div className="error-message" role="alert">
                                {errors.translationText}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="required-star">(*): Erforderlich</div>
                        <div className="popup-upload-buttons">
                          <DefaultButton type="submit" onClick={closeModal}>
                            Speichern
                          </DefaultButton>
                          <DefaultButton
                            onClick={cancelPopup}
                            className="cancel-reset-password"
                          >
                            Abbrechen
                          </DefaultButton>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
}
