import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// components
import Login from "./pages/Login/Login";
import Header from "./components/Header/Header";

// Admin
import UserList from "./pages/Admin/UserList/UserList";
import CreateEditUser from "./pages/Admin/CreateEditUser/CreateEditUser";

// Editor
import Statistics from "./pages/Editor/Statistics/Statistics";
import FilterCoursePage from "./pages/Editor/FilterCourses/FilterCourses";
import CreateEditTranslations from "./pages/Editor/CreateEditTranslations/CreateEditTranslations";
import CreateEditModuleSubject from "./pages/Editor/CreateEditModuleSubject/CreateEditModuleSubject";
import CreateEditCourseChapterSlide from "./pages/Editor/CreateEditCourseChapterSlide/CreateEditCourseChapterSlide";

// User
import UserQuiz from "./pages/User/UserQuiz/UserQuiz";
import HelpPopup from "./components/HelpPopup/HelpPopup";
import AboutUsPage from "./pages/User/FooterPages/AboutUsPage";
import UserHomePage from "./pages/User/UserHomePage/UserHomePage";
import CourseDetails from "./pages/User/CourseDetails/CourseDetails";
import UserDictionary from "./pages/User/UserDictionary/UserDictionary";
import PrivacyPolicyPage from "./pages/User/FooterPages/PrivacyPolicyPage";
import AccessibilityPage from "./pages/User/FooterPages/AccessibilityPage";
import UserOverviewModuleSubject from "./pages/User/UserOverviewModuleSubject/UserOverviewModuleSubject";

function App() {
  // redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const { helpPopup } = useSelector((state: any) => state.HelpPopupReducer);

  return (
    <div className="main-container">
      <BrowserRouter>
        {helpPopup && <HelpPopup />}

        {!isLoggedIn ||
        (isLoggedIn && user?.userRoles && user.userRoles?.includes("User")) ? (
          <>
            <Routes>
              <Route
                path="/login"
                element={
                  <>
                    <Header />
                    <Login />
                  </>
                }
              />
              <Route
                path="/"
                element={
                  <>
                    <UserHomePage />
                  </>
                }
              />
              <Route
                path="/overview-module-subject/:learningModuleID/"
                element={
                  <>
                    <UserOverviewModuleSubject />
                  </>
                }
              />
              <Route
                path="/user-dictionary/"
                element={
                  <>
                    <UserDictionary />
                  </>
                }
              />
              <Route
                path="/course-details/:learningModuleID/:courseID/"
                element={<CourseDetails />}
              >
                <Route
                  path="/course-details/:learningModuleID/:courseID/:chapterIndex/:slideIndex"
                  element={<CourseDetails />}
                />
              </Route>
              <Route
                path="/course-quiz/:learningModuleID/:courseID/:chapterIndex/:slideIndex"
                element={<UserQuiz />}
              />
              <Route
                path="/accessibility/"
                element={<AccessibilityPage />}
              />
              <Route
                path="/privacy-policy/"
                element={<PrivacyPolicyPage />}
              />
              <Route
                path="/about-us/"
                element={<AboutUsPage />}
              />
              <Route path="*" element={<Login />} />
            </Routes>
          </>
        ) : isLoggedIn &&
          user?.userRoles &&
          user?.userRoles?.includes("Admin") ? (
          <>
            <Header />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<UserList />} />
              <Route path="/create-edit-user" element={<CreateEditUser />}>
                <Route
                  path="/create-edit-user/:id/"
                  element={<CreateEditUser />}
                />
              </Route>
            </Routes>
          </>
        ) : isLoggedIn &&
          user?.userRoles &&
          user?.userRoles?.includes("Editor") ? (
          <>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Statistics />} />
              <Route
                path="/create-edit-translations"
                element={<CreateEditTranslations />}
              />
              <Route
                path="/filter-courses-page/:status"
                element={<FilterCoursePage />}
              />
              <Route
                path="/create-edit-module-subject"
                element={<CreateEditModuleSubject />}
              >
                <Route
                  path="/create-edit-module-subject/:learningModuleID"
                  element={<CreateEditModuleSubject />}
                />
              </Route>

              <Route
                path="/create-edit-course-chapter-slide/:learningModuleID/:subjectID"
                element={<CreateEditCourseChapterSlide />}
              >
                <Route
                  path="/create-edit-course-chapter-slide/:learningModuleID/:subjectID"
                  element={<CreateEditCourseChapterSlide />}
                />
                <Route
                  path="/create-edit-course-chapter-slide/:learningModuleID/:subjectID/:courseID"
                  element={<CreateEditCourseChapterSlide />}
                />
                <Route
                  path="/create-edit-course-chapter-slide/:learningModuleID/:subjectID/:courseID/:status"
                  element={<CreateEditCourseChapterSlide />}
                />
              </Route>
              <Route path="*" element={<Login />} />
            </Routes>
          </>
        ) : (
          <>
            <Header />
            <Routes>
              <Route path="/login" element={<Login />} />
            </Routes>
          </>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
