import "./UserQuiz.css";
import React,{ useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuizService from "services/quizService";
import { useNavigate, useParams } from "react-router-dom";
import LearningModuleService from "services/learningModuleService";
import { removeLoading, setLoading } from "store/actions/loadingAction";
import { setMessage } from "store/actions/messageAction";
import { setHelpPopup } from "store/actions/helpPopupAction";

// Components:
import Loader from "components/Loader/Loader";
import AnswerService from "services/answerService";
import MessageNotification from "components/MessageNotification/MessageNotification";

// Images:
import answerUnLike from "assets/Icons/Quiz/wrong_thumb.png";
import oneFlower from "assets/Icons/Quiz/one_flower.png";
import allFlowers from "assets/Icons/Quiz/many_followers.png";
import answerLike from "assets/Icons/Quiz/right_thumb.png";
import circleWhite from "assets/Icons/Quiz/circle-white.png";
import answerSelected from "assets/Icons/Quiz/button_checkmark_green.png";
import wrongAnswer from "assets/Icons/Quiz/button_checkmark_red.png";
import contrastCloseButton from "assets/Icons/Header/close_contrast.png";
import answerLikeMobile from "assets/Icons/Quiz/thumbs-up-mobile.png";
import answerUnLikeMobile from "assets/Icons/Quiz/thumbs-down-mobile.png";
// add sound icon
import speaker from "assets/Icons/speaker-tts-icon.png";
import contrastSpeaker from "assets/Icons/ttsblack.png";
import greenSpeaker from "assets/Icons/speaker-icon.png";
import greenContrastSpeaker from "assets/Icons/speaker-contrast-icon.png";

//icons
import helpLogo from "assets/Icons/Header/helpLogo.png";
import contrastHelpLogo from "assets/Icons/Header/help_contrast.png";

//close icons learning module colors
import redCloseIcon from "assets/Icons/Header/button_close_red.png";

function UserQuiz() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let params: any = useParams();
  const { learningModuleID, courseID, chapterIndex, slideIndex } = params;

  // redux states
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );
  const contrast = useSelector((state: any) => state.ContrastReducer);
  // const { helpPopup } = useSelector((state: any) => state.HelpPopupReducer);
  const fontSize = useSelector((state: any) => state.FontSizeReducer);

  //set the fontSizeValues to 22px and 28px
  const fontSizeValues = fontSize === "28" ? "fontSize-28" : "fontSize-22";

  //local states
  const [learningModule, setLearningModule] = useState<any>();

  const [questions, setQuestions] = useState<any>();

  const [question, setQuestion] = useState<any>();

  const [questionID, setQuestionID] = useState<any>();

  const [answers, setAnswers] = useState<any>();

  const [status, setStatus] = useState<any>();

  const [questionIndex, setQuestionIndex] = useState<any>(0);
  const [results, setResults] = useState<any>([]);
  const [transcript, setTranscript] = useState<any>();

  //set a new state to display Wissen-Quiz after 3sec
  const [fadeOutText, setFadeOutText] = useState(true);

  //set a new state to check the answers's type
  const [isTextType, setIsTextType] = useState(false);

  // declaring utterance that will be used for TTS
  var utterance = new SpeechSynthesisUtterance();

  // word index that will be used for highlighting based in word index
  var wordIndex = 0;

  // variable used for stopping onclick on buttons  when  in function
  var clicked = 0;

  //  variable j to change the p tag taken from the div
  let j = 0;

  // declaring text to be read by TTS
  let text = " ";

  // word by word reading function
  function highlightWordByWordTexts(
    readDirectlyFromOnClick: any,
    lengthOfTheNextTextToRead: any,
    theSecondElementToRead: any
  ) {
    // used to know when reading and to stop reading onclick
    clicked = 1;

    // for returning the p tag to its origin at end of highlighting.
    let origin = readDirectlyFromOnClick?.innerText;

    //taking the text sent in the p tag
    text = readDirectlyFromOnClick?.innerHTML;

    // splitting the main text by spaces
    var words = text?.split(" ");

    // give the tts library the text
    utterance.text = text;

    // give the tts library the lang to be used
    utterance.lang = "de-DE";

    // start speaking the given text
    speechSynthesis.speak(utterance);

    // onboundary means:
    utterance.onboundary = async function (event) {
      // call the draw text to panel function, we put it outside the on boundary it colors all the worlds
      drawTextInPanel(words, readDirectlyFromOnClick);

      // if clicked = 0 so speaking = true, so we cancel it and we return the original text
      if (clicked === 0) {
        speechSynthesis.cancel();
        readDirectlyFromOnClick.innerHTML = origin;
      }
      // else if the event is a word (needed in the library cz we have "word" and "sentence")
      else if (event.name === "word") {
        try {
          // we give the specified word background color yellow
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.backgroundColor = "yellow";

          // we give the specified word color black
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.color = "black";
        } catch (e) {}

        // increment the word index to get the next word
        wordIndex++;
      }
    };

    // onend:
    utterance.onend = function () {
      // condition to let it stop if we re click the speaker
      if (clicked === 0) {
        // console.log("Texts: clicked === 0");

        speechSynthesis.cancel();
        readDirectlyFromOnClick.innerHTML = origin;
      } else if (wordIndex > 0) {
        if (readDirectlyFromOnClick) {
          //returning the p tag to its origin
          readDirectlyFromOnClick.innerHTML = origin;
        }

        if (
          status !== undefined &&
          status.isRight === false &&
          lengthOfTheNextTextToRead === 100
        ) {
          let myNextButton = document.getElementsByClassName(
            "user-quiz-next-question-button"
          );
          // we put length -1 here cz it decrement the j at the end of this function and we need to be the j === length
          highlightWordByWordTexts(myNextButton[0], -1, theSecondElementToRead);
        }
        // length here is the length of the parent div
        else if (j > lengthOfTheNextTextToRead) {
          // console.log("Texts: j > length");

          if (lengthOfTheNextTextToRead === 1) {
            // console.log("Texts: length===1");
            highlightWordByWordTexts(
              theSecondElementToRead.children[1],
              0,
              theSecondElementToRead
            );

            j += 1;
          } else if (j === 0) {
            // console.log("Texts: j === 0");

            let checkAnswersButton = document.getElementsByClassName(
              "user-quiz-button user-quiz-check-answer-button"
            );
            highlightWordByWordTexts(
              checkAnswersButton[0],
              lengthOfTheNextTextToRead,
              theSecondElementToRead
            );
          } else {
            // console.log("Texts: else");

            let myAnswers = document.getElementsByClassName(
              "user-quiz-one-answer-text black-border"
            );
            let i = j - 1;
            highlightWordByWordTexts(
              myAnswers[i]?.children[0],
              -1,
              theSecondElementToRead
            );
          }
        }
        //to read the transcript finish button
        if (lengthOfTheNextTextToRead === 1000) {
          highlightWordByWordTexts(
            theSecondElementToRead,
            -1,
            theSecondElementToRead
          );
        }

        //to read the transcript repeat quz button
        if (lengthOfTheNextTextToRead === 999) {
          let quizBeeden = document.getElementsByClassName(
            "user-quiz-check-transcript-button"
          );
          highlightWordByWordTexts(theSecondElementToRead, 998, quizBeeden[0]);
        }

        //to read the transcript finish button
        if (lengthOfTheNextTextToRead === 998) {
          highlightWordByWordTexts(
            theSecondElementToRead,
            -2,
            theSecondElementToRead
          );
        }

        // here is the end of all the functions
        // if j = length we stop the reading, so the div's are finished
        if (j === lengthOfTheNextTextToRead) {
          // console.log("Texts: j === length");
          // j is the counter
          j = 0;
          speechSynthesis.cancel();
          let mySpeaker = document.getElementsByClassName(
            "quiz-header-sound-button"
          );
          mySpeaker[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === "true"
              ? contrastSpeaker
              : speaker
          );
          // allow onclick to be pressed
          clicked = 0;
        }
        // we decrement the j cz we start from myAnswers.length -1 so we start in reverse cz we have the the answers in reverse
        j--;
      }

      // and we put the word index to 0 cz we will start from the beginning in the next step
      wordIndex = 0;
    };
  }

  function highlightWordByWordImages(text4: any, length: any, text5: any) {
    // used to know when reading and to stop reading onclick
    clicked = 1;

    // like taking backup of the origin text
    let text2 = text4;

    // for returning the p tag to its origin at end of highlighting.
    let origin = text2?.innerText;

    //taking the text sent in the p tag
    text = text2?.innerHTML;

    // splitting the main text by spaces
    var words = text?.split(" ");

    // give the tts library the text
    utterance.text = text;

    // give the tts library the lang to be used
    utterance.lang = "de-DE";

    // start speaking the given text
    speechSynthesis.speak(utterance);

    // onboundary means:
    utterance.onboundary = async function (event) {
      // call the draw text to panel function, we put it outside the on boundary it colors all the worlds
      drawTextInPanel(words, text2);

      // if clicked = 0 so speaking = true, so we cancel it and we return the original text
      if (clicked === 0) {
        // console.log("Images: clicked === 0");

        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // else if the event is a word (needed in the library cz we have "word" and "sentence")
      else if (event.name === "word") {
        try {
          // we give the specified word background color yellow
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.backgroundColor = "yellow";

          // we give the specified word color black
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.color = "black";
        } catch (e) {}

        // increment the word index to get the next word
        wordIndex++;
      }
    };

    // onend:
    utterance.onend = function () {
      // condition to let it stop if we re click the speaker
      if (clicked === 0) {
        // console.log("Images: clicked === 0");
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      } else if (wordIndex > 0) {
        if (text2) {
          //returning the p tag to its origin
          text2.innerHTML = origin;
        }
        if (
          status !== undefined &&
          status.isRight === false &&
          length === 100
        ) {
          let myNextButton = document.getElementsByClassName(
            "user-quiz-next-question-button"
          );
          // we put length -1 here cz it decrement the j at the end of this function and we need to be the j === length
          highlightWordByWordImages(myNextButton[0], -1, text5);
        }
        // length here is the length of the parent div
        else if (j > length) {
          // console.log("Images: j > length");

          if (length === 1) {
            // console.log("Images: length===1");

            highlightWordByWordImages(text5.children[1], -1, text5);

            j += 1;
          } else {
            // console.log("Images: else");

            let myAnswers = document.getElementsByClassName(
              "user-quiz-one-answer-image"
            );

            read_img_alt_image(
              myAnswers[j - 1]?.children[0].getAttribute("alt"),
              0,
              myAnswers
            );
          }
        }

        // here is the end of all the functions
        // if j = length we stop the reading, so the div's are finished
        if (j === length) {
          // console.log("Images: j === length");
          // j is the counter
          j = 0;
          speechSynthesis.cancel();
          let mySpeaker = document.getElementsByClassName(
            "quiz-header-sound-button"
          );
          mySpeaker[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === "true"
              ? contrastSpeaker
              : speaker
          );
          // allow onclick to be pressed
          clicked = 0;
        }
        // we decrement the j cz we start from myAnswers.length -1 so we start in reverse cz we have the the answers in reverse
        j--;
      }

      // and we put the word index to 0 cz we will start from the beginning in the next step
      wordIndex = 0;
    };
  }

  const ttsOnClick = () => {
    if (clicked === 0) {
      if (isTextType && !transcript) {
        if (status === undefined) {
          // code................................................................................
          // console.log("No status yet");

          let t = document.getElementsByClassName("user-quiz-question-section");
          let myAnswers = document.getElementsByClassName(
            "user-quiz-one-answer-text black-border"
          );
          j = myAnswers.length;
          let mySpeaker = document.getElementsByClassName(
            "quiz-header-sound-button"
          );

          mySpeaker[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === true
              ? greenContrastSpeaker
              : greenSpeaker
          );

          highlightWordByWordTexts(t[0]?.children[0], 1, t[0]);
        } else if (
          status !== undefined &&
          status.isRight === true &&
          !transcript
        ) {
          // code................................................................................
          // console.log("status right");
          let mySpeaker = document.getElementsByClassName(
            "quiz-header-sound-button"
          );
          j = 0;
          mySpeaker[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === true
              ? greenContrastSpeaker
              : greenSpeaker
          );
          let thumbsUp = document.getElementsByClassName(
            "user-quiz-answer-text-right-answer"
          );
          read_img_alt(thumbsUp[0].getAttribute("alt"), 1, thumbsUp);
        } else if (
          status !== undefined &&
          status.isRight === false &&
          !transcript
        ) {
          // code................................................................................
          // console.log("status wrong");

          let mySpeaker = document.getElementsByClassName(
            "quiz-header-sound-button"
          );
          j = 0;
          mySpeaker[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === true
              ? greenContrastSpeaker
              : greenSpeaker
          );
          let thumbsDown = document.getElementsByClassName(
            "user-quiz-wrong-answer"
          );
          read_img_alt(
            thumbsDown[0].children[0].getAttribute("alt"),
            1,
            thumbsDown
          );
        }
      } else if (!isTextType && !transcript) {
        // start the tts of images
        if (status === undefined) {
          // code................................................................................
          // console.log("No status yet image");

          let t = document.getElementsByClassName("user-quiz-question-section");
          let myAnswers = document.getElementsByClassName(
            "user-quiz-one-answer-image"
          );
          j = myAnswers.length;
          let mySpeaker = document.getElementsByClassName(
            "quiz-header-sound-button"
          );
          mySpeaker[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === true
              ? greenContrastSpeaker
              : greenSpeaker
          );
          highlightWordByWordImages(t[0]?.children[0], 1, t[0]);
        } else if (status !== undefined && status.isRight === true) {
          // code................................................................................
          // console.log("status right image");
          let mySpeaker = document.getElementsByClassName(
            "quiz-header-sound-button"
          );
          j = 0;
          mySpeaker[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === true
              ? greenContrastSpeaker
              : greenSpeaker
          );
          let thumbsUp = document.getElementsByClassName(
            "user-quiz-answer-text-right-answer"
          );
          read_img_alt(
            thumbsUp[0].getAttribute("alt"),
            // text5.children[2].children[j].children[0].children[0].attributes[2],
            1,
            thumbsUp
          );

          // highlight_wordByWord_style2(checkAnswersButton[0], length, text5);
        } else if (status !== undefined && status.isRight === false) {
          // code...
          // console.log("status wrong image");

          let mySpeaker = document.getElementsByClassName(
            "quiz-header-sound-button"
          );
          j = 0;
          mySpeaker[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === true
              ? greenContrastSpeaker
              : greenSpeaker
          );
          let thumbsDown = document.getElementsByClassName(
            "user-quiz-wrong-answer"
          );
          read_img_alt(
            thumbsDown[0].children[0].getAttribute("alt"),
            // text5.children[2].children[j].children[0].children[0].attributes[2],
            1,
            thumbsDown
          );
        }
      } else if (transcript) {
        // code................................................................................
        // console.log("Result page:");

        if (transcript.score === 100) {
          // code...
          let t = document.getElementsByClassName(
            "user-quiz-transcript-section"
          );
          let mySpeaker = document.getElementsByClassName(
            "quiz-header-sound-button"
          );
          let quizBeeden = document.getElementsByClassName(
            "user-quiz-check-transcript-button"
          );

          j = 0;
          mySpeaker[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === true
              ? greenContrastSpeaker
              : greenSpeaker
          );
          highlightWordByWordTexts(t[0]?.children[0], 1000, quizBeeden[0]);
        } else {
          // code...
          let t = document.getElementsByClassName(
            "user-quiz-transcript-section"
          );
          let mySpeaker = document.getElementsByClassName(
            "quiz-header-sound-button"
          );
          let quizWiederholen = document.getElementsByClassName(
            "user-quiz-repeat-question-button"
          );
          j = 0;
          mySpeaker[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === true
              ? greenContrastSpeaker
              : greenSpeaker
          );
          highlightWordByWordTexts(t[0]?.children[0], 999, quizWiederholen[0]);
        }
      }
    } else {
      let mySpeaker = document.getElementsByClassName(
        "quiz-header-sound-button"
      );
      mySpeaker[0]?.setAttribute(
        "src",
        contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
      );
      clicked = 0;
      speechSynthesis.cancel();
    }
  };

  // read image alt function
  function read_img_alt_image(text4: any, length: any, text5: any) {
    // for stopping onclick
    clicked = 1;
    let text2 = text4;
    // textContent to read the alt not the innerHTML
    // text = text2?.textContent;
    text = text2;
    utterance.text = text;
    utterance.lang = "de-DE";
    speechSynthesis.speak(utterance);

    // we give the image a background color yellow, we access the children throw there place in the div
    text5[j - 1].children[1].setAttribute("style", "background-color: yellow;");

    // onboundary means: boundary of each word
    utterance.onboundary = async function (event) {
      if (clicked === 0) {
        // console.log("Texts Alt: clicked===0");
        speechSynthesis.cancel();
      }
    };
    // onend: open to it at the start and end of the speech (we put condition to open only at the end)
    utterance.onend = function () {
      if (clicked === 0) {
        // console.log("Images Alt: clicked===0");
        speechSynthesis.cancel();
      }
      text5[j].children[1].setAttribute("style", "background-color: none;");

      if (j > length) {
        // console.log("Images Alt: j > length");

        let myAnswers = document.getElementsByClassName(
          "user-quiz-one-answer-image"
        );

        read_img_alt_image(
          myAnswers[j - 1]?.children[0].getAttribute("alt"),
          0,
          text5
        );
      } else if (j === 0) {
        // console.log("Images Alt: j===0");
        // here it read the button at teh end
        let checkAnswersButton = document.getElementsByClassName(
          "user-quiz-button user-quiz-check-answer-button"
        );
        // we send the length = -1 cz the j = -1 also after the decrement
        highlightWordByWordImages(checkAnswersButton[0], -1, text5);
      }

      j--;
    };
  }

  // read image alt function
  function read_img_alt(text4: any, length: any, text5: any) {
    // for stopping onclick
    clicked = 1;
    let text2 = text4;
    // textContent to read the alt not the innerHTML
    // text = text2?.textContent;
    text = text2;
    utterance.text = text;
    utterance.lang = "de-DE";
    speechSynthesis.speak(utterance);

    if (status !== undefined && status.isRight === true) {
      // we give the image a background color yellow, we access the children throw there place in the div
      text5[0].setAttribute("style", "background-color: yellow;");
    } else if (status !== undefined && status.isRight === false) {
      text5[0].children[0].setAttribute("style", "background-color: yellow;");
    }

    // onboundary means: boundary of each word
    utterance.onboundary = async function (event) {
      if (clicked === 0) {
        speechSynthesis.cancel();
      }
    };
    // onend: open to it at the start and end of the speech (we put condition to open only at the end)
    utterance.onend = function () {
      if (clicked === 0) {
        // console.log("Texts Alt: clicked===0");

        // we return the image background color the original one got from the DB
        text5[1]?.setAttribute("style", "background-color:none;");
        speechSynthesis.cancel();
      } else if (status !== undefined && status.isRight === true) {
        // console.log("Texts Alt Right");

        // from here we go to read the h4, (j-1) cz we increment it at the function n the top
        let myNextButton = document.getElementsByClassName(
          "user-quiz-next-question-button"
        );

        highlightWordByWordTexts(myNextButton[0], 0, text5);
      } else if (status !== undefined && status.isRight === false) {
        // console.log("Texts Alt false");

        let myRepeatButton = document.getElementsByClassName(
          "user-quiz-repeat-question-button"
        );

        highlightWordByWordTexts(myRepeatButton[0], 100, text5);
        // highlight_wordByWord_style2(myNextButton[0], 0, text5);
      }

      if (status !== undefined && status.isRight === true) {
        // console.log("Texts Alt: thumbs background True");

        // we give the image a background color yellow, we access the children throw there place in the div
        text5[0].setAttribute("style", "background-color: none;");
      } else {
        // console.log("Texts Alt: thumbs background False");

        text5[0].children[0].setAttribute("style", "background-color: none;");
      }
    };
  }

  // draw text in panel function: we give it words_array, and the text: the original text, and it split it and give each word a span and we used it to give background color
  function drawTextInPanel(words_array: any, text: any) {
    // accepting array of splatted words of the text and the text p tag
    if (text) {
      // clearing the p tag for filling it with the text but each word of a span
      text.innerHTML = "";
    }
    // panel is container with the spans for each word
    var panel = text;

    // inserting the new text in the panel
    for (var i = 0; i < words_array?.length; i++) {
      // for loop for filling the panel(p tag) with text each word of a span
      var html =
        // here we add a space at the end of the word cz we trim the space from the highlight function
        // eslint-disable-next-line no-useless-concat
        '<span id="word_span_' + i + '">' + words_array[i] + " " + "</span>";
      (panel as any).innerHTML += html;
    }
  }

  const cancelSpeaking = () => {
    let mySpeaker = document.getElementsByClassName("quiz-header-sound-button");
    mySpeaker[0]?.setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    clicked = 0;
    speechSynthesis.cancel();
  };
  //state for audio sound when thumbs (up or down) appears
  //audio sounds
  //state for audio sound when thumbs (up or down) appears
  // const [correctAnswerSound] = useState(new Audio("assets/sounds/correct_answer.wav"));
  // const [wrongAnswerSound] = useState(new Audio("assets/sounds/wrong_answer.mp3"));
  let correctAnswerSound = new Audio("/sounds/correct_answer.wav");
  let wrongAnswerSound = new Audio("/sounds/wrong_answer.mp3");

  // function that add the selected and selectedOrder property to the answers
  const addSelectedProps = (arr: any) =>
    arr.map((obj: any) => ({ ...obj, selected: false, selectedOrder: null }));

  const handleAnswerClick = (answerIndex: number) => {
    cancelSpeaking();

    if (!status) {
      // this is the clicked answer
      let clickedAnswer = answers[answerIndex];

      // if the clicked answer not selected so we put it as selected true
      if (clickedAnswer.selected === false) {
        setAnswers(
          answers.map((answer: any, index: number) =>
            answerIndex === index
              ? {
                  ...clickedAnswer,
                  selected: true,
                }
              : answer
          )
        );
      } else {
        // else if the clicked answer is selected so we put it as selected false
        setAnswers(
          answers.map((answer: any, index: number) =>
            answerIndex === index
              ? {
                  ...clickedAnswer,
                  selected: false,
                }
              : answer
          )
        );
      }
    }
  };

  const handleAnswersSubmit = (answers: any) => {
    // let question = questions[questionIndex];
    cancelSpeaking();
    let answerResult = answers.every(
      (answer: any) => answer["isRight"] === answer["selected"]
    );

    //add active class to do animation based on the asnwer
    var thumbsDiv = answerResult
      ? document.getElementById("thumb-up-image")
      : document.getElementById("thumb-down-image");

    thumbsDiv && thumbsDiv.classList.add("active");

    answerResult
      ? setStatus({
          isRight: true,
          statusText: "Die Antwort ist richtig. Good Job!",
        })
      : setStatus({
          isRight: false,
          statusText: "Die Antwort ist leider nicht richtig. Hard Luck!",
        });

    answerResult ? correctAnswerSound.play() : wrongAnswerSound.play();

    setResults([...results, answerResult]);
  };

  const handleNextQuestion = () => {
    if (questionIndex < questions.length - 1) {
      cancelSpeaking();
      setQuestionIndex(questionIndex + 1);
      setQuestion(questions[questionIndex + 1]);
      GetAnswersByQuestionID(questions[questionIndex + 1].questionID);
      setStatus(undefined);
    } else {
      // setTranscript("results");
    }
  };

  const handleRepeatQuestion = () => {
    setAnswers(addSelectedProps(answers));
    cancelSpeaking();

    setStatus(undefined);
    if (results.length === 1) {
      setResults(results.splice(1, -1));
    } else {
      setResults(results.splice(results?.length - 2, 1));
    }
  };

  const handleCheckResult = () => {
    // cancelSpeaking();

    let correctAnswers = results.filter(
      (answer: any) => answer === true
    ).length;

    let score = Math.floor((correctAnswers / results.length) * 100);
    if (score === 100) {
      setTranscript({
        correctAnswersNumber: correctAnswers,
        score: score,
        transcriptText:
          "Super!\n Du hast alle Fragen richtig beantwortet und den Wissens-Quiz bestanden.",
      });
    } else {
      setTranscript({
        correctAnswersNumber: correctAnswers,
        score: score,
        transcriptText: `Du hast ${correctAnswers} von ${questions.length} Fragen richtig beantwortet.`,
      });
    }
    // let passQuiz = score > props.minScore;
    // let message = passQuiz ? props.passMessage : props.failMessage;
    // return { score, passQuiz, message };
  };

  // get quiz function by ID:
  const GetQuizByCourseID = (courseID: string) => {
    dispatch(setLoading());
    // API call
    QuizService.getQuizByCourseID(courseID)
      .then(
        (response: any) => {
          //   setQuiz(response.data);
          setQuestions(response.data.questions);
          setQuestion(response.data.questions[0]);
          setQuestionID(response.data.questions[0].questionID);
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          if (message !== "Request fehlgeschlagen. Statuscode 404") {
            dispatch(setMessage(message, "error"));
          }
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log(err);
        dispatch(removeLoading());
        // dispatch(setMessage(err.message, "error"));
      });
  };

  // get answers function by question ID:
  const GetAnswersByQuestionID = (questionID: string) => {
    dispatch(setLoading());
    // API call
    AnswerService.GetByQuestionID(questionID, "?orderQueryParameter=desc")
      .then(
        (response: any) => {
          setAnswers(addSelectedProps(response.data));
          // check the answer's type
          response.data[0].text ? setIsTextType(true) : setIsTextType(false);

          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          if (message !== "Request fehlgeschlagen. Statuscode 404") {
            dispatch(setMessage(message, "error"));
          }
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  const handleRepeatQuiz = () => {
    setQuestion(questions[0]);
    setQuestionID(questions[0].questionID);
    setQuestionIndex(0);
    setResults([]);
    setStatus(null);
    setTranscript(null);
    GetAnswersByQuestionID(questions[0].questionID);

    setFadeOutText(true);
    const timer = setTimeout(() => {
      setFadeOutText(false);
    }, 3000);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    courseID && GetQuizByCourseID(courseID);
    questionID && GetAnswersByQuestionID(questionID);
    LearningModuleService.getLearningModuleByID(learningModuleID)
      .then((response) => {
        setLearningModule(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseID, questionID, dispatch]);

  //call the setTimeOut function
  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOutText(false);
    }, 3000);
    contrast &&
      contrast.isContrast === "true" &&
      document.body.classList.add("enable-contrast");
    return () => clearTimeout(timer);
  });

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      <>
        {loading && (
          <>
            <Loader />
            <div className="overlay"></div>
          </>
        )}
        <main
          aria-label="Quiz Page"
          className="user-quiz-main-page-container background-color"
          style={{
            backgroundColor: learningModule && learningModule.light,
          }}
        >
          {/* to display the title after 3sec */}
          {fadeOutText ? (
            <div className="user-quiz-question-container">
              <div className="user-quiz-title-for-3sec">
                <h1
                  style={{
                    color:
                      contrast && contrast.isContrast === "true"
                        ? "black"
                        : learningModule && learningModule.normal,
                  }}
                >
                  Wissens-Quiz
                </h1>
              </div>
            </div>
          ) : (
            <>
              <div className="user-quiz-title" id="user-quiz-title">
                <h1
                  style={{
                    color:
                      contrast && contrast.isContrast === "true"
                        ? "#fff"
                        : learningModule && learningModule.normal,
                  }}
                >
                  Wissens-Quiz
                </h1>
              </div>
              <div className="user-quiz-question-container">
                {transcript ? (
                  <div className="user-quiz-transcript-container">
                    <div className="user-quiz-question-header-container">
                      <div className="user-quiz-transcript-section">
                        <h2
                          style={{
                            color:
                              contrast && contrast.isContrast === "true"
                                ? "black"
                                : learningModule && learningModule.normal,
                          }}
                        >
                          {transcript.transcriptText}
                        </h2>
                      </div>

                      <div className="user-quiz-help-close-buttons-section">
                        <img
                          tabIndex={0}
                          className="quiz-header-sound-button"
                          onClick={ttsOnClick}
                          src={
                            contrast && contrast.isContrast === "true"
                              ? contrastSpeaker
                              : speaker
                          }
                          alt="Vorlesefunktion"
                          title="Vorlesefunktion."
                        />
                        <img
                          className="quiz-header-help-logo"
                          src={
                            contrast && contrast.isContrast === "true"
                              ? contrastHelpLogo
                              : helpLogo
                          }
                          onClick={() => dispatch(setHelpPopup())}
                          tabIndex={0}
                          onKeyPress={() => dispatch(setHelpPopup())}
                          alt="Hilfe-Einstellungen"
                          title="Hilfe Einstellungen"
                        />
                        <img
                          onClick={() =>
                            navigate(
                              `/course-details/${learningModuleID}/${courseID}/${chapterIndex}/${slideIndex}/`
                            )
                          }
                          tabIndex={0}
                          onKeyPress={() =>
                            navigate(
                              `/course-details/${learningModuleID}/${courseID}/${chapterIndex}/${slideIndex}/`
                            )
                          }
                          className="quiz-header-close-button"
                          src={
                            contrast && contrast.isContrast === "true"
                              ? contrastCloseButton
                              : redCloseIcon
                          }
                          alt="Quiz beenden"
                          title="Quiz beenden."
                        />
                      </div>
                    </div>
                    {window.innerWidth <= 915 &&
                    transcript.score === 0 ? null : (
                      <div className="user-quiz-transcript">
                        {transcript.score !== 0 ? (
                          <>
                            <img
                              src={
                                transcript.score === 100
                                  ? allFlowers
                                  : oneFlower
                              }
                              alt="Blumen!"
                              title="Blumen "
                            />
                          </>
                        ) : (
                          <div className="user-quiz-transcript-min-height"></div>
                        )}
                      </div>
                    )}

                    <div className="user-quiz-check-transcript-button-container">
                      {transcript.score !== 100 &&
                      questionIndex === questions?.length - 1 ? (
                        <button
                          className={`user-quiz-button user-quiz-repeat-question-button ${fontSizeValues}`}
                          onClick={handleRepeatQuiz}
                          tabIndex={0}
                          onKeyPress={handleRepeatQuiz}
                          style={{
                            backgroundColor:
                              contrast && contrast.isContrast === "true"
                                ? "black"
                                : learningModule && learningModule.normal,
                          }}
                        >
                          Quiz wiederholen
                        </button>
                      ) : (
                        ""
                      )}

                      <button
                        className={`user-quiz-button user-quiz-check-transcript-button ${fontSizeValues}`}
                        onClick={() =>
                          navigate(
                            `/course-details/${learningModuleID}/${courseID}/${chapterIndex}/${slideIndex}/`
                          )
                        }
                        tabIndex={0}
                        onKeyPress={() =>
                          navigate(
                            `/course-details/${learningModuleID}/${courseID}/${chapterIndex}/${slideIndex}/`
                          )
                        }
                        style={{
                          backgroundColor:
                            contrast && contrast.isContrast === "true"
                              ? "black"
                              : learningModule && learningModule.normal,
                        }}
                      >
                        Quiz beenden
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="user-quiz-question-answer-container">
                    <div className="user-quiz-question-header-container">
                      {/* Question text section */}
                      <div className="user-quiz-question-section">
                        <h1
                          style={{
                            color:
                              contrast && contrast.isContrast === "true"
                                ? "black"
                                : learningModule && learningModule.normal,
                          }}
                        >
                          Frage {questionIndex + 1}
                        </h1>
                        <h2
                          style={{
                            color:
                              contrast && contrast.isContrast === "true"
                                ? "black"
                                : learningModule && learningModule.normal,
                          }}
                        >
                          {question?.text}
                        </h2>
                      </div>
                      <div className="user-quiz-help-close-buttons-section">
                        <img
                          tabIndex={0}
                          className="quiz-header-sound-button"
                          src={
                            contrast && contrast.isContrast === "true"
                              ? contrastSpeaker
                              : speaker
                          }
                          alt="Vorlesefunktion"
                          title="Vorlesefunktion."
                          onClick={ttsOnClick}
                        />
                        <img
                          className="quiz-header-help-logo"
                          src={
                            contrast && contrast.isContrast === "true"
                              ? contrastHelpLogo
                              : helpLogo
                          }
                          onClick={() => dispatch(setHelpPopup())}
                          tabIndex={0}
                          onKeyPress={() => dispatch(setHelpPopup())}
                          alt="Hilfe-Einstellungen"
                          title="Hilfe Einstellungen"
                        />
                        <img
                          onClick={() =>
                            navigate(
                              `/course-details/${learningModuleID}/${courseID}/${chapterIndex}/${slideIndex}/`
                            )
                          }
                          tabIndex={0}
                          onKeyPress={() =>
                            navigate(
                              `/course-details/${learningModuleID}/${courseID}/${chapterIndex}/${slideIndex}/`
                            )
                          }
                          className="quiz-header-close-button"
                          src={
                            contrast && contrast.isContrast === "true"
                              ? contrastCloseButton
                              : redCloseIcon
                          }
                          alt="Quiz beenden"
                          title="Quiz beenden."
                        />
                      </div>
                    </div>

                    {/* answer status section  */}
                    <div id={"thumb-up-image"}>
                      <div className="user-quiz-right-answer">
                        {status !== undefined && status?.isRight === true ? (
                          window.innerWidth > 600 ? (
                            <img
                              className="user-quiz-answer-text-right-answer"
                              src={answerLike}
                              alt="Die Antwort ist richtig"
                              title="Die Antwort ist richtig."
                            />
                          ) : (
                            <img
                              className="user-quiz-answer-text-right-answer"
                              src={answerLikeMobile}
                              alt="Die Antwort ist richtig"
                              title="Die Antwort ist richtig."
                            />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div id={"thumb-down-image"}>
                      <div className="user-quiz-wrong-answer">
                        {status !== undefined && status?.isRight === false ? (
                          window.innerWidth > 600 ? (
                            <img
                              className="user-quiz-answer-text-right-answer"
                              src={answerUnLike}
                              alt="Die Antwort ist leider nicht richtig"
                              title="Die Antwort ist leider nicht richtig"
                            />
                          ) : (
                            <img
                              className="user-quiz-answer-text-right-answer"
                              src={answerUnLikeMobile}
                              alt="Die Antwort ist leider nicht richtig"
                              title="Die Antwort ist leider nicht richtig"
                            />
                          )
                        ) : (
                          ""
                        )}
                      </div>{" "}
                    </div>

                    {/* answers container */}
                    {/* check the type of the answer ,to give different thumb design */}
                    <div
                      className={
                        isTextType
                          ? `user-quiz-answer-container user-quiz-answer-text-content-container ${fontSizeValues}`
                          : "user-quiz-answer-container user-quiz-answer-image-content-container"
                      }
                    >
                      {answers &&
                        answers.map((answer: any, index: number) => {
                          return (
                            <div
                              key={index}
                              className={
                                answer.text &&
                                answer.selected &&
                                answer.isRight &&
                                status
                                  ? "user-quiz-one-answer-text correct-selected"
                                  : answer.text &&
                                    answer.selected &&
                                    !answer.isRight &&
                                    status
                                  ? "user-quiz-one-answer-text wrong-selected"
                                  : answer.text
                                  ? "user-quiz-one-answer-text black-border"
                                  : answer.image.image &&
                                    answer.selected &&
                                    answer.isRight &&
                                    status
                                  ? "user-quiz-one-answer-image correct-selected"
                                  : answer.image.image &&
                                    answer.selected &&
                                    !answer.isRight &&
                                    status
                                  ? "user-quiz-one-answer-image  wrong-selected"
                                  : "user-quiz-one-answer-image"
                              }
                              onClick={() => handleAnswerClick(index)}
                              tabIndex={0}
                              onKeyPress={() => handleAnswerClick(index)}
                            >
                              {/* check if the answer have text or image */}
                              {answer.text ? (
                                <h3 className={`${fontSizeValues}`}>
                                  {answer?.text}
                                </h3>
                              ) : (
                                // answer Image
                                <img
                                  className="user-quiz-answer-image"
                                  src={
                                    answer.image.image
                                      ? "data:image/jpeg;base64," +
                                        answer.image.image
                                      : ""
                                  }
                                  alt={`${answer.image.alt}`}
                                  title={`Title für ${answer.image.title}`}
                                />
                              )}

                              {/* selection indicator image */}
                              <img
                                className="user-quiz-answer-selected-indicator"
                                src={
                                  answer.selected && !answer.isRight && status
                                    ? wrongAnswer
                                    : answer.selected
                                    ? answerSelected
                                    : circleWhite
                                }
                                alt={
                                  answer.selected && !answer.isRight && status
                                    ? "Falsch"
                                    : answer.selected &&
                                      answer.isRight &&
                                      status
                                    ? "Richtig"
                                    : answer.selected
                                    ? "Antwort ausgewählt"
                                    : "Antwort nicht ausgewählt"
                                }
                                title={
                                  answer.selected && !answer.isRight && status
                                    ? "Falsch."
                                    : answer.selected &&
                                      answer.isRight &&
                                      status
                                    ? "Richtig."
                                    : answer.selected
                                    ? "Antwort ausgewählt."
                                    : "Antwort nicht ausgewählt."
                                }
                              />
                            </div>
                          );
                        })}
                    </div>

                    {/* Buttons section */}
                    <div
                      className={
                        !status
                          ? "user-quiz-check-answer-button-container-center"
                          : status.isRight
                          ? "user-quiz-check-answer-button-container-end"
                          : "user-quiz-check-answer-button-container"
                      }
                    >
                      {/* Repeat Question Button */}
                      {status && status.isRight === false && (
                        <button
                          className={`user-quiz-button user-quiz-repeat-question-button ${fontSizeValues}`}
                          onClick={() => {
                            document
                              .getElementById("user-quiz-title")
                              ?.scrollIntoView();
                            handleRepeatQuestion();
                          }}
                          tabIndex={0}
                          onKeyPress={() => handleRepeatQuestion()}
                          style={{
                            backgroundColor:
                              contrast && contrast.isContrast === "true"
                                ? "black"
                                : learningModule && learningModule.normal,
                          }}
                        >
                          Frage wiederholen
                        </button>
                      )}

                      {/* Check Answer Button */}
                      {!status && (
                        <button
                          tabIndex={0}
                          className={`user-quiz-button user-quiz-check-answer-button ${fontSizeValues}`}
                          onClick={() => {
                            document
                              .getElementById("user-quiz-title")
                              ?.scrollIntoView();
                            handleAnswersSubmit(answers);
                            cancelSpeaking();
                          }}
                          onKeyPress={() => {
                            handleAnswersSubmit(answers);
                            cancelSpeaking();
                          }}
                          style={{
                            backgroundColor:
                              contrast && contrast.isContrast === "true"
                                ? "black"
                                : learningModule && learningModule.normal,
                          }}
                        >
                          Antwort prüfen
                        </button>
                      )}

                      {/* Next Button */}
                      {status && questionIndex !== questions?.length - 1 && (
                        <button
                          tabIndex={0}
                          onKeyPress={handleNextQuestion}
                          className={`user-quiz-button user-quiz-next-question-button ${fontSizeValues}`}
                          onClick={handleNextQuestion}
                          style={{
                            backgroundColor:
                              contrast && contrast.isContrast === "true"
                                ? "black"
                                : learningModule && learningModule.normal,
                          }}
                        >
                          Nächste Frage
                        </button>
                      )}

                      {/* Check Results Button */}
                      {status && questionIndex === questions?.length - 1 && (
                        <button
                          className={`user-quiz-button user-quiz-next-question-button ${fontSizeValues}`}
                          onClick={handleCheckResult}
                          tabIndex={0}
                          onKeyPress={handleCheckResult}
                          style={{
                            backgroundColor:
                              contrast && contrast.isContrast === "true"
                                ? "black"
                                : learningModule && learningModule.normal,
                          }}
                        >
                          Zum Ergebnis
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </main>
      </>
    </>
  );
}

export default UserQuiz;
