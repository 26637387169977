import "./TranslationLinkPopup.css";
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../store/actions/messageAction";
import TranslationService from "../../services/translationService";
import { CreateTranslationLinkSchema } from "../../validations/editorValidations";
import { removeLoading, setLoading } from "../../store/actions/loadingAction";

//components
import Loader from "../Loader/Loader";
import DefaultButton from "../DefaultButton/DefaultButton";

type TranslationLinkPopupProps = {
  label: string;
  setSelectedTermLink: any;
  setSelectedTerm: any;
  selectedTermLink: any;
  selectedTermTag: any;
  selectedTermParentNode: any;
  selectedTerm: string;
  closeTranslationLinkPopup: () => void;
  onSubmit: any;
  newFields: any;
  slideNormalEasyTexts: any;
};

function TranslationLinkPopup(props: TranslationLinkPopupProps) {
  const dispatch = useDispatch<any>();

  //redux state
  const { loading } = useSelector((state: any) => state.LoadingReducer);

  //local state
  const [translation, setTranslation] = useState({
    id: "",
    selectedTerm: props.selectedTerm,
    translationText: "",
  });

  const [createTranslationPopup, setCreateTranslationPopup] = useState(true);

  const getTranslationByTerm = (selectedTerm: any) => {
    dispatch(setLoading);
    TranslationService.getTranslationByTerm(props.selectedTerm)
      .then((res: any) => {
        setTranslation({
          ...translation,
          id: res.data.id,
          translationText: res.data.translationText,
        });
      })
      .catch((err: any) => {
        dispatch(removeLoading());
        console.log(err);
      });
  };

  useEffect(() => {
    getTranslationByTerm(props.selectedTerm);

    return () => {};
    // eslint-disable-next-line
  }, [props.selectedTerm]);

  const toggleModal = () => {
    setCreateTranslationPopup(!createTranslationPopup);
  };

  const onSubmit = (fields: any) => {
    //formData is used to handle the data when it's submitted
    let formData = new FormData();
    formData.append("term", fields.selectedTerm);
    formData.append("translationText", fields.translationText);

    dispatch(setLoading());
    if (translation.id) {
      props.onSubmit(props.slideNormalEasyTexts);

      TranslationService.updateTranslation(translation.id, formData)
        .then(
          (response: any) => {
            if (response) {
              dispatch(
                setMessage("Die Übersetzung erfolgreich editiert!", "success")
              );
              setTranslation({
                selectedTerm: response.data.term,
                id: response.data.translationID,
                translationText: response.data.translationText,
              });
              dispatch(removeLoading());
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            dispatch(setMessage(message, "error"));
            dispatch(removeLoading());
          }
        )
        .catch((e: any) => {
          console.log(e);
          dispatch(removeLoading());
        });
    } else {
      props.onSubmit(props.slideNormalEasyTexts);
      TranslationService.createTranslation(formData)
        .then(
          (response: any) => {
            if (response) {
              dispatch(
                setMessage(
                  "Die Translation wurde erfolgreich erstellt.",
                  "success"
                )
              );
              setTranslation({
                selectedTerm: response.data.term,
                id: response.data.translationID,
                translationText: response.data.translationText,
              });
              dispatch(removeLoading());
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            dispatch(setMessage(message, "error"));
            dispatch(removeLoading());
          }
        )
        .catch((e: any) => {
          console.log(e);
          dispatch(removeLoading());
        });
    }
  };

  const cancelPopup = () => {
    closeModal();
    props.closeTranslationLinkPopup();
  };

  if (createTranslationPopup) {
    document.body.classList.add("active-modal");
    document.body.style.overflowY = "hidden";
  } else {
    document.body.classList.remove("active-modal");
    document.body.style.overflowY = "visible";
  }

  //add closeModal function to enable the scroll feature after closing the modal
  const closeModal = () => {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
    document.body.classList.remove("active-modal");
    document.body.style.overflowY = "visible";
  };

  // -------------------------------------------------------------------------------------------------------------------------------------------
  // insert new html tag instead of the normal text:
  const insertTranslationLink = () => {
    cancelPopup();

    // variable that holds the href content: function that prevents anchor from clicking and the translation id
    let href = `javascript:void(0)translationID=${translation.id}` as any;

    //set the selectedTermLink as  the html tag that makes it a link:
    props.setSelectedTermLink(
      // eslint-disable-next-line
      `<a href=\"${href}\" target=\"_self\">${props.selectedTerm}</a>`
    );
  };

  // -------------------------------------------------------------------------------------------------------------------------------------------

  const removeTranslationLink = () => {
    cancelPopup();

    // variable that hold the href content: function that prevent anchor from clicking and the translation id
    let href = `javascript:void(0)translationID=${translation.id}` as any;

    // check if the link was bold so we remove the anchor tag but we keep it bold
    if (props.selectedTermParentNode.includes("font-weight: bold")) {
      props.setSelectedTerm(
        // eslint-disable-next-line
        `<a href=\"${href}\" target=\"_self\"><strong>${props.selectedTerm}</strong></a>`
      );
      props.setSelectedTermLink(
        // eslint-disable-next-line
        `<strong>${props.selectedTerm}</strong>`
      );
    }

    // check if the link was italic so we remove the anchor tag but we keep it italic
    else if (props.selectedTermParentNode.includes("font-style: italic;")) {
      props.setSelectedTerm(
        // eslint-disable-next-line
        `<a href=\"${href}\" target=\"_self\"><em>${props.selectedTerm}</em></a>`
      );
      props.setSelectedTermLink(
        // eslint-disable-next-line
        `<em>${props.selectedTerm}</em>`
      );
    }

    // check if the link was underline so we remove the anchor tag but we keep it underline
    else if (
      props.selectedTermParentNode.includes("text-decoration: underline;")
    ) {
      props.setSelectedTerm(
        // eslint-disable-next-line
        `<a href=\"${href}\" target=\"_self\"><ins>${props.selectedTerm}</ins></a>`
      );
      props.setSelectedTermLink(
        // eslint-disable-next-line
        `<ins>${props.selectedTerm}</ins>`
      );
    }
    // here we remove the link and we put only he old text without html tags
    else {
      props.setSelectedTerm(
        // eslint-disable-next-line
        `<a href=\"${href}\" target=\"_self\">${props.selectedTerm}</a>`
      );

      props.setSelectedTermLink(
        // eslint-disable-next-line
        `${props.selectedTerm}`
      );
    }
  };

  // -------------------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={translation}
            validationSchema={CreateTranslationLinkSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form>
                    <div className="modal">
                      <div onClick={toggleModal} className="overlay"></div>
                      <div className="modal-content change-password">
                        <h2>
                          {translation.id
                            ? "Übersetzung editieren :"
                            : "Übersetzung erstellen :"}
                        </h2>
                        {/* <h2>{props.label}</h2> */}

                        <div className="form-input">
                          <label
                            htmlFor="term"
                            className="form-label text-content"
                          >
                            Begriff <span className="required-star">*</span>
                          </label>
                          <div className="form-field">
                            <Field
                              type="text"
                              id="selectedTerm"
                              name="selectedTerm"
                              aria-required={true}
                              aria-label="selectedTerm"
                              tabIndex="1"
                              autoFocus={true}
                            />
                            {touched.selectedTerm && errors.selectedTerm ? (
                              <div className="error-message" role="alert">
                                {errors.selectedTerm}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="form-input">
                          <label
                            htmlFor="translationText"
                            className="form-label text-content"
                          >
                            Übersetzungstext
                            <span className="required-star">*</span>
                          </label>
                          <div className="form-field">
                            <Field
                              as="textarea"
                              type="text"
                              id="translationText"
                              name="translationText"
                              aria-required={true}
                              aria-label="translationText"
                              tabIndex="2"
                              className="translation-text-area"
                            />
                            {touched.translationText &&
                            errors.translationText ? (
                              <div className="error-message" role="alert">
                                {errors.translationText}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="required-star">(*): Erforderlich</div>
                        {/* changed classname from popup-btn to popup-upload-buttons */}

                        <div className="popup-upload-buttons">
                          <DefaultButton
                            onClick={
                              props.selectedTermTag === "A"
                                ? removeTranslationLink
                                : insertTranslationLink
                            }
                            disabled={!translation.id}
                          >
                            {/* Inset Translation Link */}
                            {props.selectedTermTag === "A"
                              ? "Übersetzungslink löschen"
                              : "Übersetzungslink erstellen"}
                          </DefaultButton>

                          {/* Added closeModal on Upload button because the scroll bar wasn't appearing after clicking on the upload button  */}
                          <DefaultButton type="submit" onClick={closeModal}>
                            {translation.id ? "Editieren" : "Erstellen"}
                          </DefaultButton>

                          <DefaultButton
                            onClick={cancelPopup}
                            className="cancel-reset-password"
                          >
                            Abbrechen
                          </DefaultButton>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
}

export default TranslationLinkPopup;
