import "./AccessibilityPage.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setHelpPopup } from "store/actions/helpPopupAction";

// Images:
import helpLogo from "assets/Icons/Header/helpLogo.png";
import contrastHelpLogo from "assets/Icons/Header/help_contrast.png";
import redCloseIcon from "assets/Icons/Header/button_close_red.png";
import contrastCloseButton from "assets/Icons/Header/close_contrast.png";
import { useEffect } from "react";

//Icons
import speaker from "assets/Icons/speaker-tts-icon.png";
import contrastSpeaker from "assets/Icons/ttsblack.png";
import greenSpeaker from "assets/Icons/speaker-icon.png";
import greenContrastSpeaker from "assets/Icons/speaker-contrast-icon.png";

function AccessibilityPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //redux state
  const contrast = useSelector((state: any) => state.ContrastReducer);
  const fontSize = useSelector((state: any) => state.FontSizeReducer);
  //set the fontSizeValues to 22px and 28px
  const fontSizeValues = fontSize === "28" ? "fontSize-28" : "fontSize-22";

  // declaring utterance that will be used for TTS
  var utterance = new SpeechSynthesisUtterance();

  // word index that will be used for highlighting based in word index
  var wordIndex = 0;

  // variable used for stopping onclick on buttons  when  in function
  var clicked = 0;

  //  variable j to change the p tag taken from the div
  let j = 0;

  // declaring text to be read by TTS
  let text = " ";

  // word by word reading function
  function highlight_wordByWord_style2(
    text4: any,
    length: any,
    text5: any,
    text6: any
  ) {
    // used to know when reading and to stop reading onclick
    clicked = 1;

    // like taking backup of the origin text
    let text2 = text4;

    // for returning the p tag to its origin at end of highlighting.
    let origin = text2?.innerText;

    //taking the text sent in the p tag
    text = text2?.innerHTML;

    // splitting the main text by spaces
    var words = text?.split(" ");

    // give the tts library the text
    utterance.text = text;

    // give the tts library the lang to be used
    utterance.lang = "de-DE";

    // start speaking the given text
    speechSynthesis.speak(utterance);

    // onboundary means:
    utterance.onboundary = async function (event) {
      // call the draw text to panel function, we put it outside the on boundary it colors all the worlds
      drawTextInPanel(words, text2);

      // if clicked = 0 so speaking = true, so we cancel it and we return the original text
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // else if the event is a word (needed in the library cz we have "word" and "sentence")
      else if (event.name === "word") {
        try {
          // we give the specified word background color yellow
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.backgroundColor = "yellow";

          // we give the specified word color black
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.color = "black";
        } catch (e) {}

        // increment the word index to get the next word
        wordIndex++;
      }
    };

    // onend:
    utterance.onend = function () {
      // condition to let it stop if we re click the speaker
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // condition to let it open only at the end
      else if (wordIndex > 0) {
        if (text2) {
          //returning the p tag to its origin
          text2.innerHTML = origin;
        }

        // length here is the length of the parent div
        if (j < length) {
          // then we read the image alt, at the onClick we start by reading the h2 then we read the alt here, then from inside this we re-call the read word by word to read the h4
          highlight_wordByWord_style2(text5?.children[j], length, text5, text6);
        }

        // if j = length we stop the reading, so the div's are finished
        if (j === length) {
          // j is the counter
          j = 0;
          speechSynthesis.cancel();
          // we re put the original speaker
          text6[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === "true"
              ? contrastSpeaker
              : speaker
          );
          // allow onclick to be pressed
          clicked = 0;
        }

        // we increment the j to get the next child
        j++;
      }
      // and we put the word index to 0 cz we will start from the beginning in the next step
      wordIndex = 0;
    };
  }

  // draw text in panel function: we give it words_array, and the text: the original text, and it split it and give each word a span and we used it to give background color
  function drawTextInPanel(words_array: any, text: any) {
    // accepting array of splatted words of the text and the text p tag
    if (text) {
      // clearing the p tag for filling it with the text but each word of a span
      text.innerHTML = "";
    }
    // panel is container with the spans for each word
    var panel = text;

    // inserting the new text in the panel
    for (var i = 0; i < words_array?.length; i++) {
      // for loop for filling the panel(p tag) with text each word of a span
      var html =
        // eslint-disable-next-line no-useless-concat
        '<span id="word_span_' + i + '">' + words_array[i] + " " + "</span>";
      (panel as any).innerHTML += html;
    }
  }

  const cancelSpeaking = () => {
    clicked = 0;
    speechSynthesis.cancel();
    let speaker1 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p1"
    );
    speaker1[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker2 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p2"
    );
    speaker2[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker3 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p3"
    );
    speaker3[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker4 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p4"
    );
    speaker4[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker5 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p5"
    );
    speaker5[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker6 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p6"
    );
    speaker6[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker7 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p7"
    );
    speaker7[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );

    clicked = 0;
    speechSynthesis.cancel();
  };

  const ttsOnClick = (textClassName: string, speakerIconClassName: string) => {
    let length;
    if (clicked === 0) {
      let t = document.getElementsByClassName(textClassName);
      let chapterTitleText = t[0].children[0];
      // console.log("chapterTitleText", chapterTitleText);
      // console.log("t[0].children.length", t[0].children.length);
      // console.log("t[0].children[1]", t[0].children[1]);
      j = 1;
      length = t[0].children.length;
      // let pTagsContainer = document.getElementsByClassName("user-slide-text");
      // let slideText = pTagsContainer[0];
      // length = slideText?.children[0]?.children?.length
      //   ? slideText?.children[0]?.children?.length
      //   : 0;

      let speakerIcon = document.getElementsByClassName(speakerIconClassName);
      highlight_wordByWord_style2(chapterTitleText, length, t[0], speakerIcon);
      // console.log("speakerIcon", speakerIcon);

      speakerIcon[0].setAttribute(
        "src",
        contrast && contrast.isContrast === "true"
          ? greenContrastSpeaker
          : greenSpeaker
      );
    } else {
      cancelSpeaking();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    contrast &&
      contrast.isContrast === "true" &&
      document.body.classList.add("enable-contrast");
  }, [contrast]);

 window.addEventListener("scroll", (e) => {
   const nav = document.querySelector(".footer-page-header");
   const title = document.getElementById("footer-page-header-title");
   if (window.innerWidth <= 800) {
     if (window.pageYOffset > 0) {
       nav?.classList.add("add-shadow");
       title?.classList.add("title-show");
     } else {
       nav?.classList.remove("add-shadow");
       title?.classList.remove("title-show");
     }
   }
 });

  return (
    <main className="footer-pages-main-container background-color">
      <div className="footer-pages-main">
        {/* Help Logo */}
        <div className="footer-page-header" tabIndex={0}>
          <div
            className="footer-page-header-title"
            id="footer-page-header-title"
          >
            Barrierefreiheit
          </div>
          <img
            role="button"
            className="user-close-logo"
            src={
              contrast && contrast.isContrast === "true"
                ? contrastCloseButton
                : redCloseIcon
            }
            onClick={() => {
              cancelSpeaking();
              navigate("/");
            }}
            tabIndex={0}
            onKeyPress={() => {
              navigate("/");
              cancelSpeaking();
            }}
            alt="Barrierefreiheit beenden."
            title="Barrierefreiheit beenden."
          />
          <img
            role="button"
            className="user-footer-help-logo"
            src={
              contrast && contrast.isContrast === "true"
                ? contrastHelpLogo
                : helpLogo
            }
            onClick={() => {
              dispatch(setHelpPopup());
              cancelSpeaking();
            }}
            tabIndex={0}
            onKeyPress={() => {
              dispatch(setHelpPopup());
              cancelSpeaking();
            }}
            alt="Hilfe-Einstellungen"
            title="Hilfe Einstellungen"
          />
        </div>

        {/* Page title */}
        <div className="footer-pages-header-container">
          <h1 className="footer-page-title">Barrierefreiheit</h1>
        </div>

        {/* Body */}
        <section className="footer-pages-body-container">
          <>
            <section className="accessibility-p1-main">
              <img
                onClick={() =>
                  ttsOnClick("accessibility-p1", "footer-pages-speaker-icon-p1")
                }
                className="footer-pages-speaker-icon-p1"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Hilfe-Einstellungen"
                title="Hilfe Einstellungen"
              />
              <article className="accessibility-p1 text-content">
                <h1 className={` ${fontSizeValues}`}>Barrierefreiheit</h1>
                <p className={` ${fontSizeValues}`}>
                  Das ILLUME Projekt hat den Anspruch, seine Website
                  entsprechend der EU-Richtlinie '2016/2102' barrierefrei
                  zugänglich zu machen.
                </p>
                <p className={` ${fontSizeValues}`}>
                  Die folgende Erklärung zur Barrierefreiheit dokumentiert den
                  Umsetzungsstand für die Website www.illume.nrw.
                </p>
                <p className={` ${fontSizeValues}`}>
                  gemäß der Verordnung zur Schaffung barrierefreier
                  Informationstechnik nach dem Behindertengleichstellungsgesetz
                  Nordrhein-Westfalen.
                </p>
              </article>
            </section>

            {/* --------------------------------------------------------------------------- */}
            <section className="accessibility-p2-main">
              <img
                onClick={() =>
                  ttsOnClick("accessibility-p2", "footer-pages-speaker-icon-p2")
                }
                className="footer-pages-speaker-icon-p2"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion."
                title="Vorlesefunktion"
              />
              <article className="accessibility-p2 text-content">
                <h1 className={` ${fontSizeValues}`}>
                  Stand der Vereinbarkeit mit den Anforderungen
                </h1>
                <p className={` ${fontSizeValues}`}>
                  Die Website ist mit der EU-Richtlinie 2016/2102 konform.
                </p>
                <p className={` ${fontSizeValues}`}>
                  Methodik der Prüfung: Automatisierte Tests (W3C conformance
                  checks, WAI WAVE), Selbstbewertung BITV 2.0, User Tests
                </p>
              </article>
            </section>

            {/* ---------------------------------------------------------------------------- */}

            <section className="accessibility-p3-main">
              <img
                onClick={() =>
                  ttsOnClick("accessibility-p3", "footer-pages-speaker-icon-p3")
                }
                className="footer-pages-speaker-icon-p3"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion."
                title="Vorlesefunktion"
              />
              <article className="accessibility-p3 text-content">
                <h1 className={` ${fontSizeValues}`}>
                  Nicht barrierefreie Inhalte
                </h1>
                <p className={` ${fontSizeValues}`}>
                  Der Web-Auftritt des ILLUME Projektes wird laufend
                  überarbeitet, sodass er die grundlegenden Normen und
                  technischen Spezifikationen zukünftig erfüllt.
                </p>
                <div className={` ${fontSizeValues}`}>
                  <h2>Erstellt am: 28.02.2022</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>Zuletzt geprüft am: 28.02.2022</h2>
                </div>
              </article>
            </section>
            {/* ------------------------------------------------------------------------------- */}

            <section className="accessibility-p4-main">
              <img
                onClick={() =>
                  ttsOnClick("accessibility-p4", "footer-pages-speaker-icon-p4")
                }
                className="footer-pages-speaker-icon-p4"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion"
                title="Vorlesefunktion"
              />
              <article className="accessibility-p4 text-content">
                <h1 className={` ${fontSizeValues}`}>Feedback und Kontakt</h1>
                <p className={` ${fontSizeValues}`}>
                  Sie können uns Mängel bei der Einhaltung der Anforderungen an
                  die Barrierefreiheit mitteilen und Informationen zu Inhalten
                  anfordern, die von der Richtlinie ausgenommen sind. Wir werden
                  dann mit Ihnen Kontakt aufnehmen, um zeitnah eine Lösung zu
                  finden.
                </p>
              </article>
            </section>
            {/* --------------------------------------------------------------------------------- */}

            <section className="accessibility-p5-main">
              <img
                onClick={() =>
                  ttsOnClick("accessibility-p5", "footer-pages-speaker-icon-p5")
                }
                className="footer-pages-speaker-icon-p5"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion"
                title="Vorlesefunktion"
              />
              <article className="accessibility-p5 text-content">
                <h1 className={` ${fontSizeValues}`}>Kontakt</h1>
                <div className={` ${fontSizeValues}`}>
                  <h2>Inga Gebert</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>In der Gemeinde leben gGmbH</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>Hilfen für Menschen mit Behinderungen</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>Ludwig-Erhard-Allee 14</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>40227 Düsseldorf</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>Telefon: 0162-2066131</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>inga.gebert@igl-duesseldorf.de</h2>
                </div>
              </article>
            </section>

            {/* ------------------------------------------------------------------------------------- */}

            <section className="accessibility-p6-main">
              <img
                onClick={() =>
                  ttsOnClick("accessibility-p6", "footer-pages-speaker-icon-p6")
                }
                className="footer-pages-speaker-icon-p6"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion"
                title="Vorlesefunktion"
              />
              <article className="accessibility-p6 text-content">
                <h1 className={` ${fontSizeValues}`}>Durchsetzungsverfahren</h1>
                <p className={` ${fontSizeValues}`}>
                  Sollten Sie der Ansicht sein, durch eine nicht ausreichende
                  barrierefreie Gestaltung dieser Webseite benachteiligt zu
                  sein, können Sie sich an die Schlichtungsstelle zum
                  Behindertengleichstellungsgesetz BGG beim Beauftragten der
                  Bundesregierung für die Belange von Menschen mit Behinderungen
                  wenden.
                </p>
                <p className={` ${fontSizeValues}`}>
                  Bei der Schlichtungsstelle können Sie einen Antrag auf
                  Einleitung eines Schlichtungsverfahrens stellen. Die
                  Antragstellung ist durch das Ausfüllen eines Online-Formulars,
                  schriftlich auf dem Postweg, per E-Mail oder zur Niederschrift
                  in der Geschäftsstelle der Schlichtungsstelle möglich.
                </p>
              </article>
            </section>

            {/* ------------------------------------------------------------------------------------------------- */}

            <section className="accessibility-p7-main">
              <img
                onClick={() =>
                  ttsOnClick("accessibility-p7", "footer-pages-speaker-icon-p7")
                }
                className="footer-pages-speaker-icon-p7"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion"
                title="Vorlesefunktion"
              />
              <article className="accessibility-p7 text-content">
                <h1 className={` ${fontSizeValues}`}>
                  Unter dieser Adresse erreichen Sie die Schlichtungsstelle
                </h1>
                <div className={` ${fontSizeValues}`}>
                  <h2>Schlichtungsstelle BGG</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>Mauerstraße 53</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>10117 Berlin</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>Telefon: + 49 (0) 3018527-2805</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>Fax: + 49 (0) 3018527-2901</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>E-Mail: info(at)schlichtungsstelle-bgg.de</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>Internet: www.schlichtungsstelle-bgg.de</h2>
                </div>
              </article>
            </section>
          </>
        </section>
      </div>
    </main>
  );
}

export default AccessibilityPage;
