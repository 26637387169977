import { SHOW_HELPPOPUP, REMOVE_HELPPOPUP } from "../actions/types";

const initialState = { helpPopup: false };

type HelpPopupAction = {
  type: 'SHOW_HELPPOPUP' | 'REMOVE_HELPPOPUP';
};

const HelpPopupReducer = (state = initialState, action: HelpPopupAction) => {
  const { type } = action;

  switch (type) {
    case SHOW_HELPPOPUP:
      return {
        ...state,
        helpPopup: true,
      };
    case REMOVE_HELPPOPUP:
      return {
        ...state,
        helpPopup: false,
      };
    default:
      return state;
  }
};
export default HelpPopupReducer;
