import "./CookiesPopup.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { acceptCookies } from "store/actions/cookiesAction";

// Images:
import speaker from "assets/Icons/speaker-tts-icon.png";
import cookieImage from "assets/Images/cookie.png";
import contrastSpeaker from "assets/Icons/ttsblack.png";
import greenSpeaker from "assets/Icons/speaker-icon.png";
import greenContrastSpeaker from "assets/Icons/speaker-contrast-icon.png";

function CookiesPopup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      cancelSpeaking();
    };
  });

  //redux states
  const contrast = useSelector((state: any) => state.ContrastReducer);

  // declaring utterance that will be used for TTS
  var utterance = new SpeechSynthesisUtterance();

  // word index that will be used for highlighting based in word index
  var wordIndex = 0;

  // variable used for stopping onclick on buttons  when  in function
  var clicked = 0;

  //  variable j to change the p tag taken from the div
  let j = 0;

  // declaring text to be read by TTS
  let text = " ";

  // word by word reading function
  function highlight_wordByWord_style2(
    text4: any,
    length: any,
    p1: any,
    speakerIcon: any
  ) {
    // used to know when reading and to stop reading onclick
    clicked = 1;

    // like taking backup of the origin text
    let text2 = text4;

    // for returning the p tag to its origin at end of highlighting.
    let origin = text2?.innerHTML;

    //taking the text sent in the p tag
    text = text2?.innerText;

    // splitting the main text by spaces
    var words = text?.split(" ");

    // give the tts library the text
    utterance.text = text;

    // give the tts library the lang to be used
    utterance.lang = "de-DE";

    // start speaking the given text
    speechSynthesis.speak(utterance);

    // onboundary means:
    utterance.onboundary = async function (event) {
      // call the draw text to panel function, we put it outside the on boundary it colors all the worlds
      drawTextInPanel(words, text2);

      // if clicked = 0 so speaking = true, so we cancel it and we return the original text
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // else if the event is a word (needed in the library cz we have "word" and "sentence")
      else if (event.name === "word") {
        try {
          // we give the specified word background color yellow
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.backgroundColor = "yellow";

          // we give the specified word color black
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.color = "black";
        } catch (e) {}

        // increment the word index to get the next word
        wordIndex++;
      }
    };

    // onend:
    utterance.onend = function () {
      // condition to let it stop if we re click the speaker
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // condition to let it open only at the end
      else if (wordIndex > 0) {
        if (text2) {
          //returning the p tag to its origin
          text2.innerHTML = origin;
        }

        // length here is the length of the parent div
        if (j < length) {
          if (j === 1) {
            // then we read the image alt, at the onClick we start by reading the h2 then we read the alt here, then from inside this we re-call the read word by word to read the h4
            highlight_wordByWord_style2(p1, length, p1, speakerIcon);
          } else if (j === 2) {
            let p2 = document.getElementsByClassName("p2");

            // then we read the image alt, at the onClick we start by reading the h2 then we read the alt here, then from inside this we re-call the read word by word to read the h4
            highlight_wordByWord_style2(p2[0], length, p1, speakerIcon);
          } else if (j === 3) {
            let firstButton = document.getElementsByClassName(
              "cookies-first-button"
            );
            // then we read the image alt, at the onClick we start by reading the h2 then we read the alt here, then from inside this we re-call the read word by word to read the h4
            highlight_wordByWord_style2(
              firstButton[0],
              length,
              p1,
              speakerIcon
            );
          } else if (j === 4) {
            let p3 = document.getElementsByClassName("p3");
            // then we read the image alt, at the onClick we start by reading the h2 then we read the alt here, then from inside this we re-call the read word by word to read the h4
            highlight_wordByWord_style2(p3[0], length, p1, speakerIcon);
          } else if (j === 5) {
            let secondButton = document.getElementsByClassName(
              "cookies-second-button"
            );
            // then we read the image alt, at the onClick we start by reading the h2 then we read the alt here, then from inside this we re-call the read word by word to read the h4
            highlight_wordByWord_style2(
              secondButton[0],
              length,
              p1,
              speakerIcon
            );
          }
        }

        // if j = length we stop the reading, so the div's are finished
        if (j === length) {
          // j is the counter
          j = 0;
          speechSynthesis.cancel();
          // we re put the original speaker
          speakerIcon[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === "true"
              ? contrastSpeaker
              : speaker
          );
          let textLink: any =
            document.getElementsByClassName("cookies-popup-link");
          if (textLink[0]) {
            textLink[0].onclick = function () {
              cancelSpeaking();
              MyNavigate();
            };
          }
          // allow onclick to be pressed
          clicked = 0;
        }

        // we increment the j to get the next child
        j++;
      }
      // and we put the word index to 0 cz we will start from the beginning in the next step
      wordIndex = 0;
    };
  }

  const MyNavigate = () => {
    cancelSpeaking();
    navigate("/privacy-policy");
  };

  // draw text in panel function: we give it words_array, and the text: the original text, and it split it and give each word a span and we used it to give background color
  function drawTextInPanel(words_array: any, text: any) {
    // accepting array of splatted words of the text and the text p tag
    if (text) {
      // clearing the p tag for filling it with the text but each word of a span
      text.innerHTML = "";
    }
    // panel is container with the spans for each word
    var panel = text;

    // inserting the new text in the panel
    for (var i = 0; i < words_array?.length; i++) {
      // for loop for filling the panel(p tag) with text each word of a span
      var html =
        // eslint-disable-next-line no-useless-concat
        '<span id="word_span_' + i + '">' + words_array[i] + " " + "</span>";
      (panel as any).innerHTML += html;
    }
  }

  const cancelSpeaking = () => {
    clicked = 0;
    speechSynthesis.cancel();
    let speaker1 = document.getElementsByClassName("cookies-speaker-button");
    speaker1[0]?.setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );

    clicked = 0;
    speechSynthesis.cancel();
  };

  const ttsOnClick = () => {
    let length;
    if (clicked === 0) {
      let t = document.getElementsByClassName("cookies-popup-header");
      let p1 = document.getElementsByClassName("cookies-paragraph-1");
      let chapterTitleText = t[0].children[0];
      j = 1;
      length = 6;
      let speakerIcon = document.getElementsByClassName(
        "cookies-speaker-button"
      );
      highlight_wordByWord_style2(chapterTitleText, length, p1[0], speakerIcon);

      speakerIcon[0].setAttribute(
        "src",
        contrast && contrast.isContrast === "true"
          ? greenContrastSpeaker
          : greenSpeaker
      );
    } else {
      cancelSpeaking();
    }
  };

  return (
    <div className="modal">
      <div className="overlay"></div>

      <div className="cookies-modal-content">
        {/* Header */}
        <div className="cookies-popup-header">
          <h4 style={{ fontSize: 32, fontWeight: "bold" }}>
            Cookies, Zeit für Kekse
          </h4>
          <img
            role="button"
            tabIndex={0}
            onKeyPress={ttsOnClick}
            onClick={ttsOnClick}
            src={
              contrast && contrast.isContrast === "true"
                ? contrastSpeaker
                : speaker
            }
            alt="Vorlesefunktion"
            title="Vorlesefunktion."
            className="cookies-speaker-button"
          />
        </div>

        {/* First Paragraph */}
        <p className="cookies-paragraph-1">
          Die ILLUME Akademie verwendet Cookies. Du kannst entscheiden, welche
          Cookies du akzeptieren möchtest. Egal wie du dich entscheidest, es
          werden keine Daten an Dritte weitergegeben und es werden keine Cookies
          verwendet, um dir Werbung anzuzeigen.
        </p>
        <div className="cookies-image-container">
          <img
            src={cookieImage}
            className="cookies-image"
            alt="Cookie"
            title="Cookies – Zeit für Kekse"
          />
        </div>

        {/* Second Paragraph */}
        <div className="cookies-paragraph-2">
          <p className="p2">
            Wenn du auf diesen Button klickst, werden nur die wirklich
            notwendigen Cookies gespeichert. Die notwendigen Cookies braucht die
            ILLUME Akademie, damit die Seite funktioniert.
          </p>
          <div className="cookies-popup-buttons-container">
            <button
              className="cookies-first-button"
              onKeyPress={() => {
                dispatch(acceptCookies());
                localStorage.setItem("isCookies", JSON.stringify(true));
              }}
              onClick={() => {
                dispatch(acceptCookies());
                localStorage.setItem("isCookies", JSON.stringify(true));
              }}
            >
              Nur notwendige Cookies akzeptieren
            </button>
          </div>
        </div>

        {/* Third Paragraph */}
        <div className="cookies-paragraph-3">
          <p className="p3">
            Wenn du auf diesen Button klickst, erlaubst du ILLUME Akademie,
            statistische Daten zu speichern, z.B. darüber, wie du die Seite
            nutzt. Mit diesen Daten kannst du uns helfen die
            Benutzerfreundlichkeit der ILLUME Akademie zu verbessern. Mehr
            Informationen zu Cookies findest du unter{" "}
            <span
              className="cookies-popup-link"
              onClick={() => {
                cancelSpeaking();
                MyNavigate();
              }}
            >
              Datenschutz.
            </span>
          </p>
          <div className="cookies-popup-buttons-container">
            <button
              className="cookies-second-button"
              tabIndex={0}
              onKeyPress={() => {
                dispatch(acceptCookies());
                localStorage.setItem("isCookies", JSON.stringify(true));
              }}
              onClick={() => {
                dispatch(acceptCookies());
                localStorage.setItem("isCookies", JSON.stringify(true));
              }}
            >
              Alle Cookies akzeptieren
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CookiesPopup;
