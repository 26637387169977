import "./VideoUploadPopup.css";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import authHeader from "../../services/authHeader";
import { Formik, Form, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { StreamingAPI } from "../../services/http-common";
import { setMessage } from "../../store/actions/messageAction";
import { UploadVideoSchema } from "../../validations/editorValidations";

//react bootstrap
import { ProgressBar } from "react-bootstrap";

//Images
import publishIcon from "../../assets/Icons/Sidebar/published.png";

// Components:
import Loader from "../Loader/Loader";
import DefaultButton from "../DefaultButton/DefaultButton";

type VideoUploadPopupProps = {
  id: string;
  type: any;
  label: string;
  action: any;
  closeVideoUploadPopup: any;
  title?: string;
  object: any;
};

export default function VideoUploadPopup(props: VideoUploadPopupProps) {
  const dispatch = useDispatch();

  //redux states
  const { loading } = useSelector((state: any) => state.LoadingReducer);

  //local states
  const [video, setVideo] = useState({
    title: "",
    name: "",
    extension: "",
    mimeType: "",
    filePath: "",
    vtt: "",
  });

  // constant that declare the chunk size
  const chunkSize = 1048576 * 3; //its 3MB, increase the number measure in mb

  const [showProgress, setShowProgress] = useState(false);

  // number of time that the upload happened
  const [counter, setCounter] = useState(1);

  // this is the file or video that the user input it
  const [fileToBeUpload, setFileToBeUpload] = useState<any>({});

  // value that save the next start of each chunk
  const [beginningOfTheChunk, setBeginningOfTheChunk] = useState(0);

  // value that save the next end of each chunk
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);

  // name of the file or video
  const [fileGuid, setFileGuid] = useState("");

  // default file or video size
  const [fileSize, setFileSize] = useState(0);

  // count of chunks for each video = video size / chunk size
  const [chunkCount, setChunkCount] = useState(0);

  const [progress, setProgress] = useState(0);

  const progressInstance = (
    <ProgressBar animated now={progress} label={`${progress.toFixed()}%`} />
  );

  const [videoUploadPopup, setVideoUploadPopup] = useState(true);

  // used for the function that check the video duration
  // window.URL = window.URL || window.webkitURL;

  const getVideoDuration = (file: any) =>
    new Promise((resolve, reject) => {
      const reader: any = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error: any) => reject(error);
    });

  // function that upload the chunks
  const fileUpload = (counter: number) => {
    // we increase the counter or the number of uploads
    setCounter(counter + 1);

    // the counter still smaller than the needed chunk count so we need to upload, else so we finish the needed chunks
    if (counter <= chunkCount) {
      // we slice the file form specified index declared as beginning and end of the chunk
      var chunk = fileToBeUpload.slice(beginningOfTheChunk, endOfTheChunk);

      // call the upload function
      uploadChunk(chunk);
    }
  };

  // function that get the file from the event
  const getFileContext = (e: any) => {
    // getting the file from the event
    const _file = e.target.files[0];
    setShowProgress(false);

    // create a name or fileID to the file
    const _fileID = uuidv4() + "." + _file?.name.split(".").pop();

    // set the fileID in the fileGuid state
    setFileGuid(_fileID);

    getVideoDuration(_file).then((duration: any) => {
      // check if the selected file is bigger then 20MB
      if (_file?.size > 20000000) {
        const videoInput: any = document.getElementById("filePath");
        videoInput.value = "";

        // then we return and we show message:
        return dispatch(
          setMessage(
            "Die Videogröße überschreitet die maximal erlaubte Größe (20 mb)",
            "error"
          )
        );
      }
      // check if the selected file is with not supported type
      else if (
        _file.type !== "video/mp4" &&
        _file.type !== "video/mov" &&
        _file.type !== "video/webm"
      ) {
        const videoInput: any = document.getElementById("filePath");
        videoInput.value = "";

        // then we return and we show message:
        return dispatch(setMessage("Video-Typ ist nicht unterstütz", "error"));
      } else if (duration / 60 > 2) {
        const videoInput: any = document.getElementById("filePath");
        videoInput.value = "";

        // then we return and we show message:
        return dispatch(
          setMessage(
            "Die Videodauer überschreitet die maximal erlaubten Minuten (2 Minuten)",
            "error"
          )
        );
      } else {
        // first after each chunk we reset old data
        resetChunkProperties();
        // put the file size state = file.size
        setFileSize(_file?.size);

        // calculate the total chunk count, by dividing the size by each chunk size
        const _totalCount =
          _file?.size % chunkSize === 0
            ? _file?.size / chunkSize
            : Math.floor(_file?.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file

        // set total count is the chunk count state
        setChunkCount(_totalCount);

        // set the file or video in the fileToBUploaded state
        setFileToBeUpload(_file);
      }
    });
  };

  //function that upload the chunks
  const uploadChunk = async (chunk: number) => {
    try {
      const response = await StreamingAPI.post("/Upload/Chunk", chunk, {
        params: {
          chunkNumber: counter,
          tempFileName: fileGuid,
          mediaType: "videos",
        },
        headers: authHeader() as {},
      });

      const data = response.data;
      if (data.statusCode === 200) {
        setBeginningOfTheChunk(endOfTheChunk);
        setEndOfTheChunk(endOfTheChunk + chunkSize);

        if (counter === chunkCount) {
          await uploadCompleted();
        } else {
          var percentage = (counter / chunkCount) * 100;
          setProgress(percentage);
        }
      } else {
        console.log("Error Occurred:", data.errorMessage);
        dispatch(setMessage(data.errorMessage, "error"));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // function that upload the complete chunk
  const uploadCompleted = () => {
    var formData = new FormData();
    formData.append("originalFileName", fileGuid);
    formData.append("tempFileName", fileGuid);
    formData.append("mediaType", "videos");

    StreamingAPI.post(
      "/Upload/Complete",
      {
        originalFileName: fileGuid,
        tempFileName: fileGuid,
        mediaType: "videos",
      },
      {
        headers: authHeader() as {},
      }
    )
      .then(
        (response) => {
          dispatch(setMessage("Video erfolgreich hochgeladen!", "success"));
          setProgress(100);
          setVideo((prevState: any) => {
            return {
              ...prevState,
              title: props.title,
              name: props.title,
              extension: response.data.fileExtension,
              filePath: response.data.filePath,
              mimeType: response.data.mimeType,
            };
          });
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
        }
      )
      .catch((err) => {
        console.log("err", err);
      });
  };

  // function that reset all states and properties
  const resetChunkProperties = () => {
    setShowProgress(true);
    setCounter(1);
    setProgress(0);
    setBeginningOfTheChunk(0);
    setEndOfTheChunk(chunkSize);
  };

  useEffect(() => {
    if (fileSize > 0) {
      fileUpload(counter);
    }

    // eslint-disable-next-line
  }, [props.id, fileToBeUpload, progress]);

  // handle the vtt change:
  const handleVttChange = async (event: any) => {
    const file = event.target.files[0];
    let extension = file?.name.slice(file?.name.length - 4);

    if (extension !== ".vtt") {
      const vttInput: any = document.getElementById("vtt");
      vttInput.value = "";
      // then we return and we show message:
      return dispatch(setMessage("vtt-Typ ist nicht unterstützt", "error"));
    } else {
      setVideo({
        ...video,
        vtt: file,
      });
    }
  };

  // function that change the password
  const UploadVideo = (values: any, errors: any) => {
    (video.filePath || video.vtt) &&
      props.action(
        props.type,
        video.title,
        video.name,
        video.extension,
        video.filePath,
        video.mimeType,
        video.vtt
      );
    setVideoUploadPopup(false);
    document.body.classList.remove("active-modal");
    document.body.style.overflowY = "visible";
  };

  const toggleModal = () => {
    setVideoUploadPopup(!videoUploadPopup);
  };

  const cancelPopup = () => {
    closeModal();
    props.closeVideoUploadPopup();
  };

  if (videoUploadPopup) {
    document.body.classList.add("active-modal");
    //add overflowY="hidden" style to hide the scrollbar when opening the modal
    document.body.style.overflowY = "hidden";
  }

  //add closeModal function to enable the scroll feature after closing the modal
  const closeModal = () => {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = "";
    body.style.top = "";
    body.style.height = "";
    body.style.overflowY = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={video}
            validationSchema={
              props.object.video?.filePath && props.object?.vttFile
                ? ""
                : UploadVideoSchema
            }
            onSubmit={UploadVideo}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form>
                    <div className="modal">
                      <div onClick={toggleModal} className="overlay"></div>
                      <div className="modal-content change-password">
                        <h2>Hochladen {props.label}:</h2>

                        <div className="form-input text-content">
                          <label
                            htmlFor="leaning-module-video-vtt"
                            className="form-label text-content"
                          >
                            Video VTT:
                            <span className="required-star">*</span>
                          </label>
                          <div className="form-field">
                            <input
                              type="file"
                              id="vtt"
                              name="vtt"
                              onChange={handleVttChange}
                              aria-label="leaning-module-video-vtt"
                            ></input>
                            <div role="alert">
                              <ErrorMessage
                                name="vtt"
                                component="div"
                                className="validation-error-message"
                              />
                            </div>
                            <div className="vtt-green-icon">
                              {video.vtt ? (
                                <img
                                  src={publishIcon}
                                  alt="Illume Video"
                                  className="vtt-yes-icon"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="form-input">
                          <label htmlFor="image" className="form-label">
                            <div>
                              Video:<span className="required-star">*</span>
                            </div>
                            <div className="form-field">
                              <input
                                type="file"
                                id="filePath"
                                name="filePath"
                                onChange={getFileContext}
                                aria-label="leaning-module-video"
                                disabled={!props.object.vttFile && !video.vtt}
                              ></input>
                              <div role="alert">
                                <ErrorMessage
                                  name="filePath"
                                  component="div"
                                  className="validation-error-message"
                                />
                              </div>
                             
                              <div
                                className={
                                  showProgress
                                    ? "video-percentage-green-icon"
                                    : "none"
                                }
                              >
                                {progressInstance}
                                {progress === 100 ? (
                                  <img
                                    src={publishIcon}
                                    alt="Illume Video"
                                    className="green-yes-icon"
                                  />
                                ) : null}
                              </div>
                            </div>
                          </label>
                        </div>

                        <div className="required-star">(*): Erforderlich</div>
                        {/* changed classname from popup-btn to popup-upload-buttons */}
                        <div className="popup-upload-buttons">
                          {/* Added closeModal on Upload button because the scroll bar wasn't appearing after clicking on the upload button  */}
                          <DefaultButton type="submit" onClick={closeModal}>
                            Hochladen
                          </DefaultButton>
                          {/* added className="cancel-reset-password"*/}
                          <DefaultButton
                            onClick={cancelPopup}
                            className="cancel-reset-password"
                          >
                            Abbrechen
                          </DefaultButton>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
}
