// Create the FontSize Reducer
import { CHANGE_FONTSIZE } from "../actions/types";

type fontSizeReducerAction = {
  type: "CHANGE_FONTSIZE";
  payload: any;
};

//get the font size from the local storage
const fontSize = localStorage.getItem("fontSize");

//set the font size to 22 as an initial state
const initialState = fontSize ? fontSize : "22";

const FontSizeReducer = (
  state = initialState,
  action: fontSizeReducerAction
) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_FONTSIZE:
      return payload;
    default:
      return state;
  }
};
export default FontSizeReducer;
