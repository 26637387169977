import Switch from "react-switch";
import "./CreateEditQuestionAnswer.css";
import { useEffect, useState } from "react";
import EditableTitle from "components/EditableTitle/EditableTitle";

// Images:
import add from "assets/Icons/Sidebar/add-icon.png";

// component:
import ImageUploadPopup from "components/ImageUploadPopup/ImageUploadPopup";

type CreateEditQuestionAnswerProps = {
  quiz?: any;
  course?: any;
  question?: any;
  answers?: any;
  handleInputQuestionTextChange?: any;
  handleInputAnswerTextChange?: any;
  handleAnswerImageChange?: any;
  setRight?: any;
  updateAnswer?: any;
  isAnswerTypePopup?: any;
  setAnswerTypePopup?: any;
  handleInputQuizChange?: any;
};

function CreateEditQuestionAnswer(props: CreateEditQuestionAnswerProps) {
  const [editableText, setEditableText] = useState<any>(false);
  const [isAnswerTypeText, setAnswerTypeText] = useState(true);
  const [disabled, setDisabled] = useState(false);

  // state for the image uploader popup
  const [imageUploadPopup, setImageUploadPopup] = useState({
    answerID: "",
    type: "",
    label: "",
    existingAlt: "",
    existingImage: "",
    imageUploadPopup: false,
  });

  useEffect(() => {
    props.answers[0]?.text && setDisabled(true);
    props.answers[0]?.image?.image && setDisabled(true);
    props.answers[0]?.image?.image && setAnswerTypeText(false);
    return () => {
      setAnswerTypeText(true);
      setDisabled(false);
    };
  }, [props.answers]);

  const handleSwitchChange = () => {
    setAnswerTypeText(!isAnswerTypeText);
  };

  const handleAnswerImageChange = (
    answerID: string,
    type: string,
    alt: string,
    image: any
  ) => {
    setImageUploadPopup({
      imageUploadPopup: false,
      answerID: "",
      type: "",
      label: "",
      existingAlt: "",
      existingImage: "",
    });

    props.handleAnswerImageChange({ answerID, alt, image });
  };

  return (
    <>
      {imageUploadPopup.imageUploadPopup ? (
        <ImageUploadPopup
          closeImageUploadPopup={() =>
            setImageUploadPopup({
              imageUploadPopup: false,
              answerID: "",
              type: "",
              label: "",
              existingAlt: "",
              existingImage: "",
            })
          }
          id={imageUploadPopup.answerID}
          type={imageUploadPopup.type}
          action={handleAnswerImageChange}
          label={imageUploadPopup.label}
          existingAlt={imageUploadPopup.existingAlt}
          existingImage={imageUploadPopup.existingImage}
        />
      ) : null}

      <div className="editor-quiz-questions-answers-main-container">
        <h1 className="course-title-quiz-p">{props.course.title}</h1>
        <div className="course-quiz-titles-div">
          <EditableTitle
            courseID={props.course.courseID}
            quizID={props.quiz.quizID}
            type="quiz"
            action={props.handleInputQuizChange}
            isOpen={true}
            order={props.course?.chapters?.length + 1}
            title={props.quiz?.title}
          />
        </div>

        <div className="editor-quiz-text-container">
          <h3>Frage {props.question?.order}</h3>

          <EditableTitle
            type="question"
            title={props.question?.text}
            order={props.question?.order}
            questionID={props.question?.questionID}
            isOpen={false}
            action={props.handleInputQuestionTextChange}
            isMultiline={true}
          />
        </div>

        <div className="editor-quiz-answers-section">
          <h3>Antworten </h3>
          <div className="answer-switch-div">
            <Switch
              // add onKeyPress function for the navigation
              onKeyPress={handleSwitchChange}
              onChange={handleSwitchChange}
              checked={isAnswerTypeText ? true : false}
              height={40}
              width={100}
              disabled={disabled}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 20,
                    color: "white",
                    paddingRight: 2,
                  }}
                >
                  Bild
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 20,
                    color: "white",
                    paddingRight: 2,
                  }}
                >
                  Text
                </div>
              }
            />
          </div>

          {isAnswerTypeText ? (
            <div className="editor-quiz-answers-text-container">
              {props.answers &&
                props.answers.map((answer: any) => {
                  return (
                    <div
                      key={answer.answerID}
                      className="editor-quiz-one-answer"
                    >
                      <div className="answer-text-image">
                        <EditableTitle
                          type="answer"
                          title={answer?.text}
                          order={answer?.order}
                          answerID={answer?.answerID}
                          isOpen={false}
                          action={props.handleInputAnswerTextChange}
                          isMultiline={true}
                        />
                      </div>
                      <div
                        className={
                          answer.isRight
                            ? "answer-right-flag"
                            : "answer-wrong-flag"
                        }
                        onClick={() => {
                          const { answerID, text, questionID, order } = answer;
                          let isRight = !answer.isRight;
                          props.updateAnswer({
                            answerID,
                            text,
                            questionID,
                            order,
                            isRight,
                          });
                        }}
                      >
                        <p> {answer.isRight ? "richtig" : "falsch"}</p>
                      </div>
                    </div>
                  );
                })}

              {props.question
                ? props.answers.length < 4 && (
                    <div className="editor-quiz-one-answer">
                      <div
                        className="new-answer-text"
                        onClick={() => {
                          setEditableText(true);
                        }}
                        
                        tabIndex={0}
                        onKeyPress={() => {
                          setEditableText(true);
                        }}
                      >
                        {!editableText ? (
                          <img
                            src={add}
                            alt="neue Antwort"
                            title="neue Antwort"
                          />
                        ) : (
                          <EditableTitle
                            type="answer"
                            isOpen={true}
                            autoFocus={true}
                            action={props.handleInputAnswerTextChange}
                            isMultiline={true}
                          />
                        )}
                      </div>
                    </div>
                  )
                : "Erstelle zuerst eine Frage!"}
            </div>
          ) : (
            <div className="editor-quiz-answers-text-container">
              {props.answers &&
                props.answers.map((answer: any) => {
                  return (
                    <div
                      key={answer.answerID}
                      className="editor-quiz-one-answer"
                    >
                      <div
                        className="answer-text-image"
                        onClick={() => {
                          setImageUploadPopup({
                            imageUploadPopup: true,
                            answerID: answer.answerID,
                            type: "",
                            label: "Answer Image",
                            existingAlt: answer?.image?.alt,
                            existingImage: answer?.image?.image,
                          });
                        }}
                      >
                        {/* eslint-disable-next-line */}
                        <img
                          src={"data:image/jpeg;base64," + answer?.image?.image}
                          alt={answer.image?.alt}
                          title={answer.image?.title}
                        />
                      </div>
                      <div
                        className={
                          answer.isRight
                            ? "answer-right-flag"
                            : "answer-wrong-flag"
                        }
                        onClick={() => {
                          const { answerID, image, questionID, order } = answer;
                          let isRight = !answer.isRight;
                          props.updateAnswer({
                            answerID,
                            image,
                            questionID,
                            order,
                            isRight,
                          });
                        }}
                      >
                        <p> {answer.isRight ? "richtig" : "falsch"}</p>
                      </div>
                    </div>
                  );
                })}

              {props.question
                ? props.answers.length < 6 && (
                    <div className="editor-quiz-one-answer">
                      <div
                        className="new-answer-image"
                        onClick={() => {
                          setImageUploadPopup({
                            imageUploadPopup: true,
                            answerID: "",
                            type: "",
                            label: "Answer Image",
                            existingAlt: "",
                            existingImage: "",
                          });
                        }}
                        // add onKeyPress function for the navigation
                        onKeyPress={() => {
                          setImageUploadPopup({
                            imageUploadPopup: true,
                            answerID: "",
                            type: "",
                            label: "Answer Image",
                            existingAlt: "",
                            existingImage: "",
                          });
                        }}
                      >
                        <img
                          src={add}
                          alt="neue Antwort"
                          title="neue Antwort"
                        />
                      </div>
                    </div>
                  )
                : " Erstelle zuerst eine Frage!"}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CreateEditQuestionAnswer;
