import "./CourseDetails.css";
import React, { Fragment, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { setMessage } from "store/actions/messageAction";
import { removeLoading, setLoading } from "store/actions/loadingAction";
import { getAllTranslations } from "store/actions/translationAction";

//Services
import SlideService from "services/slideService";
import CourseService from "services/courseService";
import LearningModuleService from "services/learningModuleService";

// Components:
import Loader from "components/Loader/Loader";
import UserHeader from "components/Header/Header";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import MessageNotification from "components/MessageNotification/MessageNotification";
import CookiesPopup from "components/CookiesPopup/CookiesPopup";
import MobileTranslationPopup from "components/MobileTranslationPopup/MobileTranslationPopup";

//images
import QuizService from "services/quizService";
import speaker from "assets/Icons/speaker-tts-icon.png";
import contrastSpeaker from "assets/Icons/ttsblack.png";
import leftArrow from "assets/Icons/button_arrow-left.png";
import rightArrow from "assets/Icons/button_arrow-right.png";
import greenSpeaker from "assets/Icons/speaker-icon.png";
import greenContrastSpeaker from "assets/Icons/speaker-contrast-icon.png";
import StartQuizIcon from "assets/Icons/Quiz/start-quiz-icon.png";
import redCloseIcon from "assets/Icons/Header/button_close_red.png";
import contrastCloseButton from "assets/Icons/Header/close_contrast.png";
import burgerMenuIcon from "assets/Icons/burger-menu-icon.png";

function CourseDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let params: any = useParams();
  const { learningModuleID, courseID, chapterIndex, slideIndex } = params;

  //redux states
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const contrast = useSelector((state: any) => state.ContrastReducer);
  const isCookiesPopup = useSelector((state: any) => state.cookiesReducer);
  const fontSize = useSelector((state: any) => state.FontSizeReducer);
  const translations = useSelector((state: any) => state.TranslationReducer);
  //set the fontSizeValues to 22px and 28px
  const fontSizeValues = fontSize === "28" ? "fontSize-28" : "fontSize-22";

  // tabIndex variables
  const topTapTrap = useRef() as any;
  const bottomTapTrap = useRef() as any;
  const firstFocusableElement = useRef() as any;
  const lastFocusableElement = useRef() as any;

  //local states
  //learning module, courses and chapter states
  const [learningModule, setLearningModule] = useState<any>();

  const [course, setCourse] = useState<any>();

  const [chapterTitle, setChapterTitle] = useState<any>();

  const [chapters, setChapters] = useState<any>();

  //myChapterIndex state to get the index of chapters
  const [myChapterIndex, setChapterIndex] = useState(0);

  //slide state to get the first slide in each chapter
  const [slide, setSlide] = useState<any>();

  //slides state to get all the slides in each chapter
  const [slides, setSlides] = useState<any>();

  //mySlideIndex state to get the index of slides in each chapter
  const [mySlideIndex, setSlideIndex] = useState(0);

  //state to get the translation text
  const [translatedTextSlide, setTranslatedTextSlide] = useState<any>();

  //state to store the term
  const [translatedTerm, setTranslatedTerm] = useState<any>();

  //state to store the initial values for the translated text popup
  const [isTranslatedText, setTranslatedText] = useState(false);

  // state for the quiz on the course
  const [quiz, setQuiz] = useState<any>();

  //error state
  const [error, setError] = useState<any>(null);

  const [isBurgerMenuOpened, setBurgerMenuOpened] = useState(false);

  //State to know the slide number
  const [slideNumber, setSlideNumber] = useState();

  //get the languageState from local storage
  const languageState = localStorage.getItem("isNormalLanguage")!;

  // declaring utterance that will be used for TTS
  var utterance = new SpeechSynthesisUtterance();

  // word index that will be used for highlighting based in word index
  var wordIndex = 0;

  // variable used for stopping onclick on buttons  when  in function
  var clicked = 0;

  //  variable j to change the p tag taken from the div
  let j = 0;
  let li = 0;

  // declaring text to be read by TTS
  let text = " ";

  // word by word reading function
  function highlight_wordByWord_style2(text4: any, length: any, text5: any) {
    // used to know when reading and to stop reading onclick
    clicked = 1;

    // like taking backup of the origin text
    let text2 = text4;

    // for returning the p tag to its origin at end of highlighting.
    let origin = text2?.innerHTML;

    //taking the text sent in the p tag
    text = text2?.innerText;

    // splitting the main text by spaces
    var words = text?.split(" ");

    // give the tts library the text
    utterance.text = text;

    // give the tts library the lang to be used
    utterance.lang = "de-DE";

    // start speaking the given text
    speechSynthesis.speak(utterance);

    // onboundary means:
    utterance.onboundary = async function (event) {
      // call the draw text to panel function, we put it outside the on boundary it colors all the worlds
      drawTextInPanel(words, text2);

      // if clicked = 0 so speaking = true, so we cancel it and we return the original text
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // else if the event is a word (needed in the library cz we have "word" and "sentence")
      else if (event.name === "word") {
        try {
          // we give the specified word background color yellow
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.backgroundColor = "yellow";

          // we give the specified word color black
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.color = "black";
        } catch (e) {}

        // increment the word index to get the next word
        wordIndex++;
      }
    };

    // onend:
    utterance.onend = function () {
      // here we loop over the childe to check if they have data or no and we send the not empty
      for (let h = j; h < length; h++) {
        if (text5[0]?.children[0]?.children[h]?.childNodes.length !== 0) {
          j = h;
          break;
        }
      }

      // condition to let it stop if we re click the speaker
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }

      // condition to let it open only at the end
      else if (wordIndex > 0) {
        if (text2) {
          //returning the p tag to its origin
          text2.innerHTML = origin;
        }

        // length here is the length of the parent div
        if (j < length) {
          // console.log("j < length 1");

          // check if the parent p have an anchor tag
          if (!text5[0]?.children[0]?.children[j]?.children[0]?.tagName) {
            highlight_wordByWord_style2(
              text5[0]?.children[0]?.children[j],
              length,
              text5
            );
          } else if (
            text5[0]?.children[0]?.children[j]?.children[0]?.tagName !== "A"
          ) {
            if (
              text5[0]?.children[0]?.children[j].tagName === "UL" ||
              text5[0]?.children[0]?.children[j].tagName === "OL"
            ) {
              li = 0;
              highlight_wordByWord_style2_list(
                text5[0]?.children[0]?.children[j].children[0],
                text5[0]?.children[0]?.children[j].children.length,
                text5[0]?.children[0]?.children[j],
                length,
                text5
              );
            } else
              highlight_wordByWord_style2(
                text5[0]?.children[0]?.children[j],
                // text5[0]?.children[0]?.children[j]?.children[0],
                length,
                text5
              );
          } else {
            highlight_wordByWord_style2(
              text5[0]?.children[0]?.children[j],
              length,
              text5
            );
          }
        }

        // if j = length we stop the reading, so the div's are finished
        if (j === length) {
          // console.log("j === length");

          // j is the counter
          j = 0;
          speechSynthesis.cancel();
          // we re put the original speaker
          let headerSpeakerIcon =
            document.getElementsByClassName("header-help-logo");
          headerSpeakerIcon[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === "true"
              ? contrastSpeaker
              : speaker
          );
          clicked = 0;

          document.querySelectorAll("a").forEach((tags) => {
            let target: any;
            let test2: any;
            let translationID: any;

            tags.addEventListener("click", (event) => {
              cancelSpeaking();
              target = event.target;
              test2 = target.href;
              translationID = test2?.split("=", 3)[1];
              if (translationID !== undefined) {
                getTranslationByID(translationID);
                setTranslatedText(true);
              }
            });
          });
        }
        // we increment the j to get the next child
        j++;
      }
      // and we put the word index to 0 cz we will start from the beginning in the next step
      wordIndex = 0;
    };
  }

  function highlight_wordByWord_style2_list(
    text4: any,
    length: any,
    text5: any,
    originalLength: number,
    originalText5: any
  ) {
    // used to know when reading and to stop reading onclick
    clicked = 1;

    // like taking backup of the origin text
    let text2 = text4;

    // for returning the p tag to its origin at end of highlighting.
    let origin = text2?.innerHTML;

    //taking the text sent in the p tag
    text = text2?.innerText;

    // splitting the main text by spaces
    var words = text?.split(" ");

    // give the tts library the text
    utterance.text = text;

    // give the tts library the lang to be used
    utterance.lang = "de-DE";

    // start speaking the given text
    speechSynthesis.speak(utterance);

    // onboundary means:
    utterance.onboundary = async function (event) {
      // call the draw text to panel function, we put it outside the on boundary it colors all the worlds
      drawTextInPanel(words, text2);

      // if clicked = 0 so speaking = true, so we cancel it and we return the original text
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // else if the event is a word (needed in the library cz we have "word" and "sentence")
      else if (event.name === "word") {
        try {
          // we give the specified word background color yellow
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.backgroundColor = "yellow";

          // we give the specified word color black
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.color = "black";
        } catch (e) {}

        // increment the word index to get the next word
        wordIndex++;
      }
    };

    // onend:
    utterance.onend = function () {
      // condition to let it stop if we re click the speaker
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }

      // condition to let it open only at the end
      else if (wordIndex > 0) {
        if (text2) {
          //returning the p tag to its origin
          text2.innerHTML = origin;
        }

        // length here is the length of the parent div
        if (li < length - 1) {
          // console.log("li < length");

          li++;
          // check if the parent p have an anchor tag
          highlight_wordByWord_style2_list(
            text5?.children[li],
            length,
            text5,
            originalLength,
            originalText5
          );
        }

        // if j = length we stop the reading, so the div's are finished
        if (li === length - 1) {
          // console.log("li === length");
          // check if the parent p have an anchor tag
          highlight_wordByWord_style2(
            text5?.children[li],
            originalLength,
            originalText5
          );
        }
      }
      // and we put the word index to 0 cz we will start from the beginning in the next step
      wordIndex = 0;
    };
  }

  // word by word reading function
  function highlight_wordByWord_style2_popup(
    text4: any,
    length: any,
    text5: any,
    text6: any
  ) {
    // used to know when reading and to stop reading onclick
    clicked = 1;

    // like taking backup of the origin text
    let text2 = text4;

    // for returning the p tag to its origin at end of highlighting.
    let origin = text2?.innerText;

    //taking the text sent in the p tag
    text = text2?.innerHTML;

    // splitting the main text by spaces
    var words = text?.split(" ");

    // give the tts library the text
    utterance.text = text;

    // give the tts library the lang to be used
    utterance.lang = "de-DE";

    // start speaking the given text
    speechSynthesis.speak(utterance);

    // onboundary means:
    utterance.onboundary = async function (event) {
      // call the draw text to panel function, we put it outside the on boundary it colors all the worlds
      drawTextInPanel_popup(words, text2);

      // if clicked = 0 so speaking = true, so we cancel it and we return the original text
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // else if the event is a word (needed in the library cz we have "word" and "sentence")
      else if (event.name === "word") {
        try {
          // we give the specified word background color yellow
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.backgroundColor = "yellow";

          // we give the specified word color black
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.color = "black";
        } catch (e) {}

        // increment the word index to get the next word
        wordIndex++;
      }
    };

    // onend:
    utterance.onend = function () {
      // condition to let it stop if we re click the speaker
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // condition to let it open only at the end
      else if (wordIndex > 0) {
        if (text2) {
          //returning the p tag to its origin
          text2.innerHTML = origin;
        }

        // length here is the length of the parent div
        if (j < length) {
          // then we read the image alt, at the onClick we start by reading the h2 then we read the alt here, then from inside this we re-call the read word by word to read the h4
          highlight_wordByWord_style2_popup(text5, length, text5, text6);
        }

        // if j = length we stop the reading, so the div's are finished
        if (j === length) {
          // console.log("text6", text6[0]);
          // j is the counter
          j = 0;
          speechSynthesis.cancel();
          // we re put the original speaker
          text6[0]?.children[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === "true"
              ? contrastSpeaker
              : speaker
          );
          // allow onclick to be pressed
          clicked = 0;
        }

        // we increment the j to get the next child
        j++;
      }
      // and we put the word index to 0 cz we will start from the beginning in the next step
      wordIndex = 0;
    };
  }

  // draw text in panel function: we give it words_array, and the text: the original text, and it split it and give each word a span and we used it to give background color
  function drawTextInPanel(words_array: any, text: any) {
    // accepting array of splatted words of the text and the text p tag
    if (text) {
      // clearing the p tag for filling it with the text but each word of a span
      text.innerHTML = "";
    }
    // panel is container with the spans for each word
    var panel = text;

    // inserting the new text in the panel
    for (var i = 0; i < words_array?.length; i++) {
      // for loop for filling the panel(p tag) with text each word of a span
      var html =
        // eslint-disable-next-line no-useless-concat
        '<span id="word_span_' + i + '">' + words_array[i] + " " + "</span>";
      (panel as any).innerHTML += html;
    }
  }
  // draw text in panel function: we give it words_array, and the text: the original text, and it split it and give each word a span and we used it to give background color
  function drawTextInPanel_popup(words_array: any, text: any) {
    // accepting array of splatted words of the text and the text p tag
    if (text) {
      // clearing the p tag for filling it with the text but each word of a span
      text.innerHTML = "";
    }
    // panel is container with the spans for each word
    var panel = text;

    // inserting the new text in the panel
    for (var i = 0; i < words_array?.length; i++) {
      // for loop for filling the panel(p tag) with text each word of a span
      var html;
      if (j === 0) {
        html =
          // eslint-disable-next-line no-useless-concat
          '<span id="word_span_' +
          i +
          '">' +
          words_array[i] +
          "\xa0" +
          "</span>";
        (panel as any).innerHTML += html;
      } else {
        html =
          // eslint-disable-next-line no-useless-concat
          '<span id="word_span_' + i + '">' + words_array[i] + " " + "</span>";
        (panel as any).innerHTML += html;
      }
    }
  }

  const cancelSpeaking = () => {
    utterance = new SpeechSynthesisUtterance();
    clicked = 0;
    speechSynthesis.cancel();
    let headerSpeakerIcon = document.getElementsByClassName("header-help-logo");
    headerSpeakerIcon[0]?.setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );

    document.querySelectorAll("a").forEach((tags) => {
      let target: any;
      let test2: any;
      let translationID: any;

      tags.addEventListener("click", (event) => {
        cancelSpeaking();
        target = event.target;
        test2 = target.href;
        translationID = test2?.split("=", 3)[1];
        if (translationID !== undefined) {
          getTranslationByID(translationID);
          setTranslatedText(true);
        }
      });
    });
  };

  const ttsOnClick = () => {
    // src={
    //   contrast && contrast.isContrast === "true"
    //     ? contrastSpeaker
    //     : speaker
    // }
    let length;
    if (clicked === 0) {
      let t = document.getElementsByClassName("chapter-title-top");
      let chapterTitleText = t[0].children[0];
      // console.log("chapterTitleText", chapterTitleText);
      j = 0;
      let pTagsContainer = document.getElementsByClassName("user-slide-text");
      let slideText = pTagsContainer[0];
      length = slideText?.children[0]?.children?.length
        ? slideText?.children[0]?.children?.length
        : 0;

      highlight_wordByWord_style2(chapterTitleText, length, pTagsContainer);
      let headerSpeakerIcon =
        document.getElementsByClassName("header-help-logo");
      headerSpeakerIcon[0].setAttribute(
        "src",
        contrast && contrast.isContrast === "true"
          ? greenContrastSpeaker
          : greenSpeaker
      );
    } else {
      let headerSpeakerIcon =
        document.getElementsByClassName("header-help-logo");
      headerSpeakerIcon[0].setAttribute(
        "src",
        contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
      );
      clicked = 0;
      speechSynthesis.cancel();
    }
  };

  // function that get all courses
  const getCourseByID = (courseID: string) => {
    dispatch(setLoading());
    CourseService.getCourseByID(courseID)
      .then(
        (response: any) => {
          setCourse(response.data);
          document.title = response.data?.title;
          setChapters(response.data?.chapters);
          setChapterTitle(response.data?.chapters[0]?.title);
          response.data?.chapters[0] &&
            getSlidesByChapterID(response.data?.chapters[0]?.chapterID, "");
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        setError(err.message);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // function that get the slides for each chapter
  const getSlidesByChapterID = (chapterID: string, type: string) => {
    dispatch(setLoading());
    SlideService.getSlideByChapterID(chapterID)
      .then(
        (response: any) => {
          setSlides(response.data);
          if (type === "prev") {
            setSlideIndex(response.data?.length - 1);
            setSlide(response.data[response.data?.length - 1]);
          } else {
            setSlide(response.data[0]);
          }
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        setError(err.message);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  const updateCourseViews = (courseID: string) => {
    dispatch(setLoading());
    CourseService.updateCourseViews(courseID)
      .then(
        (response: any) => {
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        setError(err.message);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // function that get the translations ID for each translated term
  const getTranslationByID = (translationID: string) => {
    // dispatch(setLoading());
    // translationService
    //   .getTranslationByID(translationID)
    //   .then(
    //     (response: any) => {
    //       setTranslatedTextSlide(response.data?.translationText);
    //       setTranslatedTerm(response.data?.term);
    //       dispatch(removeLoading());
    //     },
    //     (error) => {
    //       const message =
    //         (error.response &&
    //           error.response.data &&
    //           error.response.data.message) ||
    //         error.message ||
    //         error.toString();
    //       dispatch(setMessage(message, "error"));
    //       setTranslatedText(false);
    //       dispatch(removeLoading());
    //     }
    //   )
    //   .catch((err: any) => {
    //     setError(err.message);
    //     dispatch(removeLoading());
    //     setTranslatedText(false);
    //     dispatch(setMessage(err.message, "error"));
    //   });

    let translationArray: any = [];
    translationArray = translations.filter(
      (translation: any) => translation.translationID === translationID
    );
    setTranslatedTextSlide(translationArray[0].translationText);
    setTranslatedTerm(translationArray[0].term);
  };

  useEffect(() => {
    translations.length < 1 && dispatch(getAllTranslations(true));
  }, [dispatch, translations.length]);

  // get quiz function by ID:
  const GetQuizByCourseID = (courseID: string) => {
    dispatch(setLoading());
    // API call
    QuizService.getQuizByCourseID(courseID)
      .then(
        (response: any) => {
          setQuiz(response.data);
          // setQuestions(response.data.questions);
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          if (message !== "Request fehlgeschlagen. Statuscode 404") {
            dispatch(setMessage(message, "error"));
          }
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log(err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  useEffect(() => {
    chapterIndex && setChapterIndex(chapters?.length - 1);
    slideIndex && setSlideIndex(slides?.length === 0 ? 0 : slides?.length - 1);

    chapterIndex &&
      chapters &&
      getSlidesByChapterID(chapters[chapterIndex].chapterID, "prev");
    chapterIndex && chapters && setChapterTitle(chapters[chapterIndex].title);
    // eslint-disable-next-line
  }, [slides?.length]);

  useEffect(() => {
    courseID && getCourseByID(courseID);
    courseID && GetQuizByCourseID(courseID);
    LearningModuleService.getLearningModuleByID(learningModuleID)
      .then((response) => {
        setLearningModule(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseID, dispatch]);

  //function to detect which key is pressed and trigger navigation based on it

  // eslint-disable-next-line
  const handleKeyDown = (e: any) => {
    if (e.keyCode === 39) {
      handleNextSlide();
    } else if (e.keyCode === 37) {
      handlePrevSlide();
    }
  };

  //event listener to detect keyboard presses
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  //function that gets the previous slides
  const handlePrevSlide = () => {
    cancelSpeaking();
    if (chapterIndex && slideIndex) {
      navigate(`/course-details/${learningModuleID}/${course.courseID}/`);
    }
    if (mySlideIndex > 0) {
      setSlideIndex(mySlideIndex - 1);
      setSlide(slides[mySlideIndex - 1]);
      setTranslatedText(false);
    } else if (myChapterIndex > 0) {
      setChapterIndex(myChapterIndex - 1);
      chapters &&
        getSlidesByChapterID(chapters[myChapterIndex - 1].chapterID, "prev");
      setChapterTitle(chapters[myChapterIndex - 1].title);
      setTranslatedText(false);
    }
    document.getElementById("to-scroll-div")?.scrollIntoView();
  };

  //function that gets the next slides
  const handleNextSlide = () => {
    cancelSpeaking();
    if (mySlideIndex < slides?.length - 1) {
      setSlideIndex(mySlideIndex + 1);
      setSlide(slides[mySlideIndex + 1]);
      setTranslatedText(false);
    } else if (myChapterIndex < chapters?.length - 1) {
      //update the no. of views once the user finished the first chapter and starts with the second one
      if (myChapterIndex === 0) {
        updateCourseViews(courseID);
        //call the last visited courses function
        setLastVisitedCourseDate();
      }
      setChapterIndex(myChapterIndex + 1);
      chapters &&
        getSlidesByChapterID(chapters[myChapterIndex + 1].chapterID, "next");
      setChapterTitle(chapters[myChapterIndex + 1].title);
      setSlideIndex(0);
      setTranslatedText(false);
    }
    document.getElementById("to-scroll-div")?.scrollIntoView();
  };

  //create Last visited courses function to track the user's action every time he visits a course and store it in local storage
  const setLastVisitedCourseDate = () => {
    const date = new Date();

    const getLastVisitedCourses = localStorage.getItem("Courses");

    let coursesViewed =
      getLastVisitedCourses !== null ? JSON.parse(getLastVisitedCourses) : [];

    //use the filter method to check the courseID and display the date for each one
    const newListOfViewedCourses = coursesViewed.filter(
      (item: any) => item.courseID !== course.courseID
    );

    coursesViewed = [
      ...newListOfViewedCourses,
      { date: date, courseID: course?.courseID },
    ];

    localStorage.removeItem("Courses");

    localStorage.setItem("Courses", JSON.stringify(coursesViewed));
  };

  useEffect(() => {
    cancelSpeaking();
    contrast &&
      contrast.isContrast === "true" &&
      document.body.classList.add("enable-contrast");

    document.querySelectorAll("a").forEach((tags) => {
      let target: any;
      let test2: any;
      let translationID: any;

      tags.addEventListener("click", (event) => {
        cancelSpeaking();
        target = event.target;
        test2 = target.href;
        translationID = test2?.split("=", 3)[1];

        if (translationID !== undefined) {
          getTranslationByID(translationID);
        }
      });
    });
  });

  const burgerMenuClicked = () => {
    let sidebar = document.getElementById("course-container-sidebar");
    let courseContainerBody = document.getElementById("course-container-body");
    if (!isBurgerMenuOpened) {
      sidebar && sidebar.classList.add("show");
      courseContainerBody && courseContainerBody.classList.add("hidden");
      setBurgerMenuOpened(true);
    } else {
      sidebar && sidebar.classList.remove("show");
      courseContainerBody && courseContainerBody.classList.remove("hidden");
      setBurgerMenuOpened(false);
    }
  };

  // function to add a shadow on the header on scroll phone and tablet version
  window.addEventListener("scroll", (e) => {
    const nav = document.querySelector(".course-details-burger-menu");
    if (window.pageYOffset > 0) {
      nav?.classList.add("add-shadow");
    } else {
      nav?.classList.remove("add-shadow");
    }
  });

  const hideMobileTranslationPopup = () => {
    setTranslatedText(false);
  };

  useEffect(() => {
    course?.expandedSlides && slide &&
      // eslint-disable-next-line array-callback-return
      course?.expandedSlides.map((slides: any, index: any) => {
        if (slides.slideID === slide.slideID) {
          console.log(course.expandedSlides)
          console.log(slide)
          console.log(true)
          setSlideNumber(index + 1 ) 
        }
      })
  })
  console.log(slideNumber)

  useEffect(() => {
    document.addEventListener("focusin", trapFocus);

    return function cleanup() {
      document.removeEventListener("focusin", trapFocus);
    };

    function trapFocus(event: any) {
      if (event.target === topTapTrap.current) {
        lastFocusableElement.current.focus();
      }
      if (event.target === bottomTapTrap.current) {
        firstFocusableElement.current.focus();
      }
    }
  }, [firstFocusableElement, lastFocusableElement]);

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {!isCookiesPopup && !loading && (
        <>
          <CookiesPopup />
        </>
      )}

      {isTranslatedText && window.innerWidth <= 915 && (
        <>
          <MobileTranslationPopup
            translatedTerm={translatedTerm}
            translatedTextSlide={translatedTextSlide}
            fontSizeValues={fontSizeValues}
            contrast={contrast}
            setTranslatedText={hideMobileTranslationPopup}
          />
        </>
      )}

      {loading ? (
        <>
          <Loader />
          <div className="overlay"></div>
        </>
      ) : (
        <>
          <div>{error}</div>

          <main
            aria-label="Course Details"
            className="course-container"
            style={
              isTranslatedText
                ? {
                    backgroundColor:
                      contrast && contrast.isContrast === "true"
                        ? "black"
                        : learningModule && learningModule?.lightDarker,
                  }
                : { backgroundColor: "" }
            }
          >
            <div
              className="course-details-burger-menu"
              style={{
                backgroundColor: learningModule && learningModule.normal,
              }}
            >
              <img
                role="button"
                src={burgerMenuIcon}
                alt="burger-menu-icon"
                title="Burger Menu Icon"
                className="course-details-burger-menu-icon"
                onClick={burgerMenuClicked}
              />
              <h1 className="course-details-burger-menu-title">Menü</h1>
            </div>
            <div
              id="course-container-sidebar"
              className={`course-container-sidebar background-color ${fontSizeValues}`}
              style={{
                backgroundColor: learningModule && learningModule.light,
              }}
            >
              <div className="course-container-sidebar-header">
                <h1 className="course-container-sidebar-header-title">
                  ILLUME
                </h1>
                <img
                  className="course-container-sidebar-header-close-button"
                  role="button"
                  src={redCloseIcon}
                  alt="Quiz-beenden"
                  title="Quiz beenden"
                  onClick={burgerMenuClicked}
                />
              </div>

              <img
                style={{
                  backgroundColor: learningModule && learningModule.normal,
                }}
                className={
                  isTranslatedText
                    ? "sidebar-course-icon my-opacity"
                    : "sidebar-course-icon"
                }
                src={
                  course?.icon?.image
                    ? "data:image/jpeg;base64," + course?.icon?.image
                    : ""
                }
                alt={`Icon für ${course?.icon?.alt}`}
                title={`${course?.icon?.title}`}
              />

              {/* User Sidebar */}
              <ul
                role="navigation"
                aria-label="Chapter Title"
                className="chapter-title border sidebar-list-content "
              >
                {course?.chapters.map((chapter: any, index: any) => {
                  return (
                    <li
                      key={index}
                      tabIndex={0}
                      style={{
                        backgroundColor:
                          chapter.title === chapterTitle
                            ? contrast && contrast.isContrast === "true"
                              ? "#fff"
                              : learningModule && learningModule.normal
                            : contrast && contrast.isContrast === "true"
                            ? "black"
                            : learningModule && learningModule?.lightDarker,
                        color:
                          chapter.title === chapterTitle
                            ? contrast && contrast.isContrast === "true"
                              ? "black"
                              : ""
                            : contrast && contrast.isContrast === "true"
                            ? "#fff"
                            : learningModule && learningModule.normal,
                      }}
                      className={
                        isTranslatedText
                          ? `chapter-title my-opacity border sidebar-list-content ${fontSizeValues}`
                          : `chapter-title border sidebar-list-content ${fontSizeValues}`
                      }
                      onClick={() => {
                        if (chapterIndex && slideIndex) {
                          navigate(
                            `/course-details/${learningModuleID}/${course.courseID}/`
                          );
                        }
                        cancelSpeaking();
                        setChapterTitle(chapter?.title);
                        getSlidesByChapterID(chapter?.chapterID, "");
                        setChapterIndex(index);
                        setSlideIndex(0);
                        setTranslatedText(false);
                      }}
                      onKeyPress={() => {
                        if (chapterIndex && slideIndex) {
                          navigate(
                            `/course-details/${learningModuleID}/${course.courseID}/`
                          );
                        }

                        cancelSpeaking();
                        setChapterTitle(chapter?.title);
                        getSlidesByChapterID(chapter?.chapterID, "");
                        setChapterIndex(index);
                        setSlideIndex(0);
                        setTranslatedText(false);
                      }}
                    >
                      {chapter.title}
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* Course Body */}
            <div id="course-container-body" className="course-container-body">
              {window.innerWidth > 915 ? "" : <div id="to-scroll-div"></div>}
              {isTranslatedText ? (
                <>
                  <h1 className="user-translation-popup-dictionary text-content">
                    Wörterbuch
                  </h1>
                </>
              ) : (
                ""
              )}
              <div
                className={
                  isTranslatedText
                    ? `course-title my-opacity  ${fontSizeValues}`
                    : `course-title ${fontSizeValues}`
                }
                style={{
                  color: learningModule && learningModule.normal,
                }}
                id="course-title-top"
              >
                <div className="text text-content">
                  <h1>{course?.title}</h1>
                </div>
                <div
                  className="chapter-title-top  text-content"
                  id="chapter-title-top"
                >
                  <h2>{chapterTitle}</h2>
                </div>
              </div>
              <div
                id="course-user-header"
                className={
                  isTranslatedText
                    ? "course-user-header  my-opacity"
                    : "course-user-header"
                }
              >
                <UserHeader
                  colorCode={learningModule && learningModule.normal}
                  learningModule={learningModule}
                  isTranslatedText={isTranslatedText}
                  ttsOnClick={ttsOnClick}
                  cancelSpeaking={cancelSpeaking}
                />
              </div>

              <div className="course-container-main">
                <div className="course-container-inner1">
                  {/* Slide Video */}
                  {slide?.video !== undefined ? (
                    <div className="user-slide-video-container">
                      <VideoPlayer
                        filePath={slide?.video?.filePath}
                        mimeType={slide?.video?.mimeType}
                        vttFile={slide?.vttFile ? slide?.vttFile : ""}
                        type="slide"
                      />
                    </div>
                  ) : (
                    <div className="user-text-image-container">
                      {/* text image container */}
                      <div
                        onClick={() => {
                          // if (translatedTerm !== undefined) {
                          //   setTranslatedText(!isTranslatedText);
                          // }
                          cancelSpeaking();
                        }}
                        className={
                          isTranslatedText
                            ? `user-slide-text my-opacity ${fontSizeValues} `
                            : `user-slide-text ${fontSizeValues}`
                        }
                      >
                        {/* add a condition to check the languageState if it is "on"
                          then display the easyText,otherwise display the normal one */}

                        {slide?.textNormalLanguage && languageState === "On" ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: slide?.textEasyLanguage,
                            }}
                          ></p>
                        ) : (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: slide?.textNormalLanguage,
                            }}
                          ></p>
                        )}
                      </div>

                      {isTranslatedText && window.innerWidth > 915 ? (
                        <div className="user-slide-image-translation-container">
                          <span ref={topTapTrap} tabIndex={0} />
                          <div className="user-slide-translation-div translated-section-container border-left">
                            <div className="translation-text-popup-header">
                              <div className="translated-term-header">
                                <h3
                                  className={`translated-term-slide-popup ${fontSizeValues}`}
                                  tabIndex={0}
                                  ref={firstFocusableElement}
                                >
                                  {translatedTerm}
                                </h3>
                              </div>

                              <div className="translation-text-popup-header2">
                                <img
                                  role="button"
                                  src={
                                    contrast && contrast.isContrast === "true"
                                      ? contrastSpeaker
                                      : speaker
                                  }
                                  alt="Vorlesefunktion"
                                  title="Vorlesefunktion."
                                  className="translated-text-speaker-button"
                                  onClick={() => {
                                    if (clicked === 0) {
                                      let myTerm =
                                        document.getElementsByClassName(
                                          "translated-term-slide-popup"
                                        );

                                      let mtText =
                                        document.getElementsByClassName(
                                          "translated-text-user-popup"
                                        );

                                      let mySpeaker =
                                        document.getElementsByClassName(
                                          "translation-text-popup-header2"
                                        );

                                      highlight_wordByWord_style2_popup(
                                        myTerm[0],
                                        1,
                                        mtText[0],
                                        mySpeaker
                                      );

                                      j = 0;

                                      mySpeaker[0]?.children[0].setAttribute(
                                        "src",
                                        contrast &&
                                          contrast.isContrast === "true"
                                          ? greenContrastSpeaker
                                          : greenSpeaker
                                      );
                                      // to remove green close btn from the terms side
                                      let translationContainer =
                                        document.getElementsByClassName(
                                          "user-dictionary-terms-side"
                                        );
                                      for (
                                        let i = 0;
                                        i <
                                        translationContainer[0]?.children
                                          .length;
                                        i++
                                      ) {
                                        translationContainer[0]?.children[
                                          i
                                        ]?.children[0]?.children[0].setAttribute(
                                          "src",
                                          contrast &&
                                            contrast.isContrast === "true"
                                            ? contrastSpeaker
                                            : speaker
                                        );
                                      }
                                    } else {
                                      // to remove green close btn from the terms side
                                      let translationContainer =
                                        document.getElementsByClassName(
                                          "user-dictionary-terms-side"
                                        );
                                      for (
                                        let i = 0;
                                        i <
                                        translationContainer[0]?.children
                                          .length;
                                        i++
                                      ) {
                                        translationContainer[0]?.children[
                                          i
                                        ]?.children[0]?.children[0].setAttribute(
                                          "src",
                                          contrast &&
                                            contrast.isContrast === "true"
                                            ? contrastSpeaker
                                            : speaker
                                        );
                                      }
                                      clicked = 0;
                                      speechSynthesis.cancel();
                                      let mySpeaker =
                                        document.getElementsByClassName(
                                          "translation-text-popup-header2"
                                        );
                                      mySpeaker[0]?.children[0].setAttribute(
                                        "src",
                                        contrast &&
                                          contrast.isContrast === "true"
                                          ? contrastSpeaker
                                          : speaker
                                      );
                                    }
                                  }}
                                  tabIndex={0}
                                  onKeyPress={() => {
                                    if (clicked === 0) {
                                      let myTerm =
                                        document.getElementsByClassName(
                                          "translated-term-slide-popup"
                                        );

                                      let mtText =
                                        document.getElementsByClassName(
                                          "translated-text-user-popup"
                                        );

                                      let mySpeaker =
                                        document.getElementsByClassName(
                                          "translation-text-popup-header2"
                                        );

                                      highlight_wordByWord_style2_popup(
                                        myTerm[0],
                                        1,
                                        mtText[0],
                                        mySpeaker
                                      );

                                      j = 0;

                                      mySpeaker[0]?.children[0].setAttribute(
                                        "src",
                                        contrast &&
                                          contrast.isContrast === "true"
                                          ? greenContrastSpeaker
                                          : greenSpeaker
                                      );
                                      // to remove green close btn from the terms side
                                      let translationContainer =
                                        document.getElementsByClassName(
                                          "user-dictionary-terms-side"
                                        );
                                      for (
                                        let i = 0;
                                        i <
                                        translationContainer[0]?.children
                                          .length;
                                        i++
                                      ) {
                                        translationContainer[0]?.children[
                                          i
                                        ]?.children[0]?.children[0].setAttribute(
                                          "src",
                                          contrast &&
                                            contrast.isContrast === "true"
                                            ? contrastSpeaker
                                            : speaker
                                        );
                                      }
                                    } else {
                                      // to remove green close btn from the terms side
                                      let translationContainer =
                                        document.getElementsByClassName(
                                          "user-dictionary-terms-side"
                                        );
                                      for (
                                        let i = 0;
                                        i <
                                        translationContainer[0]?.children
                                          .length;
                                        i++
                                      ) {
                                        translationContainer[0]?.children[
                                          i
                                        ]?.children[0]?.children[0].setAttribute(
                                          "src",
                                          contrast &&
                                            contrast.isContrast === "true"
                                            ? contrastSpeaker
                                            : speaker
                                        );
                                      }
                                      clicked = 0;
                                      speechSynthesis.cancel();
                                      let mySpeaker =
                                        document.getElementsByClassName(
                                          "translation-text-popup-header2"
                                        );
                                      mySpeaker[0]?.children[0].setAttribute(
                                        "src",
                                        contrast &&
                                          contrast.isContrast === "true"
                                          ? contrastSpeaker
                                          : speaker
                                      );
                                    }
                                  }}
                                />
                                <img
                                  src={
                                    contrast && contrast.isContrast === "true"
                                      ? contrastCloseButton
                                      : redCloseIcon
                                  }
                                  alt="Quiz-beenden"
                                  title="Quiz beenden "
                                  className="translated-text-close-button"
                                  onClick={() => {
                                    setTranslatedText(false);
                                    cancelSpeaking();
                                  }}
                                  tabIndex={0}
                                  onKeyPress={() => {
                                    setTranslatedText(false);
                                    cancelSpeaking();
                                  }}
                                  ref={lastFocusableElement}
                                />
                              </div>
                            </div>
                            <div
                              className={`translated-text-user-popup ${fontSizeValues}`}
                              tabIndex={0}
                            >
                              {translatedTextSlide}
                            </div>
                          </div>
                          <span ref={bottomTapTrap} tabIndex={0} />
                        </div>
                      ) : (
                        <div className="user-slide-image-translation-container-2">
                          {slide?.image !== undefined ? (
                            // eslint-disable-next-line
                            <img
                              className="user-slide-image border-left"
                              src={
                                slide?.image?.image
                                  ? "data:image/jpeg;base64," +
                                    slide?.image?.image
                                  : ""
                              }
                              alt={`${slide?.image.alt}`}
                              title={`Title für ${slide?.image.title}`}
                            />
                          ) : (
                            <div className="user-slide-image no-image-slide border-left" />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="user-slide-navigator-start-quiz-button-container">
                  {/* start of slides navigation */}
                  {course?.expandedSlides.length > 1 ? (
                    <div
                      className={
                        isTranslatedText
                          ? "user-nav-slides my-opacity "
                          : "user-nav-slides"
                      }
                    >
                      <div
                        className="nav-circle-container border"
                        style={{
                          backgroundColor:
                            contrast && contrast.isContrast === "true"
                              ? "black"
                              : learningModule && learningModule.normal,
                        }}
                      >
                        <div
                          className={
                            myChapterIndex !== 0 || mySlideIndex !== 0
                              ? "nav-circle-inner"
                              : "nav-circle-inner-first"
                          }
                        >
                          {myChapterIndex !== 0 || mySlideIndex !== 0 ? (
                            <img
                              role="button"
                              onClick={() => {
                                handlePrevSlide();
                              }}
                              tabIndex={0}
                              onKeyPress={() => {
                                handlePrevSlide();
                              }}
                              className="nav-arrow left"
                              src={leftArrow}
                              alt="Zurück"
                              title="Zurück."
                            />
                          ) : (
                            ""
                          )}

                          {myChapterIndex !== chapters?.length - 1 ||
                          (myChapterIndex === chapters?.length - 1 &&
                            mySlideIndex !== slides?.length - 1) ? (
                            window.innerWidth <= 915 ? (
                                  <div className="course-details-slide-number">{slideNumber} von {course?.expandedSlides.length}
                                  </div>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}

                          {course?.expandedSlides &&
                            course?.expandedSlides.map(function (
                              expandedSlide: any,
                              index: string
                            ) {
                              return (
                                <>
                                  <span
                                    role="navigation"
                                    key={index}
                                    className={
                                      expandedSlide.slideID === slide?.slideID
                                        ? "nav-circle active"
                                        : "nav-circle"
                                    }
                                  ></span>
                                </>
                              );
                            })}

                          {myChapterIndex !== chapters?.length - 1 ||
                          (myChapterIndex === chapters?.length - 1 &&
                            mySlideIndex !== slides?.length - 1) ? (
                            <img
                              role="button"
                              onClick={() => {
                                handleNextSlide();
                              }}
                              tabIndex={0}
                              onKeyPress={() => {
                                handleNextSlide();
                              }}
                              className="nav-arrow right"
                              src={rightArrow}
                              alt="Nächste"
                              title="Nächste."
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Start Quiz Button */}
                  {quiz &&
                  myChapterIndex === chapters?.length - 1 &&
                  (mySlideIndex === slides?.length - 1 ||
                    slides?.length === mySlideIndex) ? (
                    <div
                      className="user-start-quiz-button-container"
                      onClick={() =>
                        navigate(
                          `/course-quiz/${learningModuleID}/${course.courseID}/${myChapterIndex}/${mySlideIndex}/`
                        )
                      }
                    >
                      <img
                        style={{
                          backgroundColor:
                            // learningModule && learningModule.normal,
                            contrast && contrast.isContrast === "true"
                              ? "black"
                              : learningModule && learningModule.normal,
                        }}
                        src={StartQuizIcon}
                        alt="Quiz starten"
                        title="Quiz starten."
                      />
                      <button
                        className="user-start-quiz-button border"
                        style={{
                          backgroundColor:
                            contrast && contrast.isContrast === "true"
                              ? "black"
                              : learningModule && learningModule.normal,
                        }}
                        tabIndex={0}
                        onKeyPress={() =>
                          navigate(
                            `/course-quiz/${learningModuleID}/${course.courseID}/${myChapterIndex}/${mySlideIndex}/`
                          )
                        }
                      >
                        Quiz starten
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
}

export default CourseDetails;
