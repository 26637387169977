import authHeader from "./authHeader";
import { TranslationAPI } from "./http-common";

const getTranslationByID = (id: string) => {
  return TranslationAPI.get(`/Translation/GetById/${id}`, {
    headers: authHeader() as any,
  });
};

const getTranslationByTerm = (term: string) => {
  return TranslationAPI.get(`/Translation/GetByTerm/${term}`, {
    headers: authHeader() as any,
  });
};

const getAllTranslations = () => {
  return TranslationAPI.get(`/Translation`, { headers: authHeader() as any });
};

const getAllTranslationsGrouped = () => {
  return TranslationAPI.get(`/Translation/GetGrouped/`, {
    headers: authHeader() as any,
  });
};

const createTranslation = async (data: any) => {
  const res = await TranslationAPI.post("/Translation", data, {
    headers: authHeader() as any,
  });
  return res;
};

const updateTranslation = (id: string, data: any) => {
  return TranslationAPI.put(`/Translation/${id}`, data, {
    headers: authHeader() as any,
  });
};

const deleteTranslation = (id: string) => {
  return TranslationAPI.delete(`/Translation/${id}`, {
    headers: authHeader() as any,
  });
};

const TranslationService = {
  getTranslationByID,
  getTranslationByTerm,
  getAllTranslationsGrouped,
  getAllTranslations,
  createTranslation,
  updateTranslation,
  deleteTranslation,
};

export default TranslationService;
