import "./Login.css";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoginAction } from "../../store/actions/authAction";
import { LoginSchema } from "../../validations/loginValidations";

//components
import Input from "../../components/Input/Input";
import Loader from "../../components/Loader/Loader";
import DefaultButton from "../../components/DefaultButton/DefaultButton";
import MessageNotification from "../../components/MessageNotification/MessageNotification";
import { removeContrast } from "../../store/actions/contrastAction";

function AdminEditorLogin() {
  const dispatch = useDispatch<any>();

  //redux state
  const { user } = useSelector((state: any) => state.LoginReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { isLoggedIn } = useSelector((state: any) => state.LoginReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  // useFormik react hook: to handle the login form:
  const { handleSubmit, handleChange, values, touched, errors, handleBlur } =
    useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema: LoginSchema,
      onSubmit: ({ username, password }) => {
        dispatch(LoginAction(username, password));
      },
    });


  useEffect(() => {
    document.title = "Anmelden - ILLUME";
  }, []);
  
  //TODO: each user is redirected to the correct section based on his role
  if (isLoggedIn && user && user.userRoles?.includes("Admin")) {
    //to be implemented in another way but for now added remove contrast so editor/admin are not affected with the contrast feature
    dispatch(removeContrast()) 
    document.body.classList.remove("enable-contrast");
    return <Navigate to="/" />;
  } else if (isLoggedIn && user && user.userRoles?.includes("Editor")) {
    dispatch(removeContrast())
    document.body.classList.remove("enable-contrast");
    return <Navigate to="/" />;
  } else if (isLoggedIn && user && user.userRoles?.includes("User")) {
    //TODO: user will later have a separate login form
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className="login-container background-color">
        <div className="login-title text-content">
          <h1>Illume</h1>
        </div>
        <div className="login">
          {messageText ? (
            <MessageNotification
              messageText={messageText}
              messageType={messageType}
            />
          ) : null}

          {loading ? (
            <Loader />
          ) : (
              <>
                {/* change to h2 for the HTML structure */}
              <h2 className="login-form-title text-content">Anmelden</h2>
              <h3 className="text-above-login-form text-content">
                Bitte verwenden Sie ein lokales Konto für die Anmeldung.
              </h3>
                <hr className="login-hr" />
                {/* change the login form add sectionand add the autocomplete */}
              <form onSubmit={handleSubmit} className="login-form">
                <section className="username-section">
                  <div className="login-label text-content" id="username">
                    <label htmlFor="username">Username</label>
                  </div>

                  <div className="admin-login-input">
                    <Input
                      id="username"
                      name="username"
                      type="text"
                      value={values.username}
                      placeholder="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      aria_labelledby="username"
                      aria_required={true}
                      autocomplete="username"
                    />
                    {touched.username && errors.username ? (
                      <div
                        className="login-error-message error-content"
                        role="alert"
                      >
                        {errors.username}
                      </div>
                    ) : null}
                  </div>
                </section>

                <section className="password-section">
                  <div className="login-label text-content " id="password">
                    <label htmlFor="password">Kennwort</label>
                  </div>

                  <div className="admin-login-input">
                    <Input
                      id="password"
                      type="password"
                      name="password"
                      value={values.password}
                      placeholder="* * * * * * * *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      aria_labelledby="password"
                      aria_required={true}
                      autocomplete="current-password"
                    />
                    {touched.password && errors.password ? (
                      <div
                        className="login-error-message error-content"
                        role="alert"
                      >
                        {errors.password}
                      </div>
                    ) : null}
                  </div>
                </section>

                <div className="login-btn">
                  <DefaultButton type="submit">Anmelden</DefaultButton>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminEditorLogin;
