import { UserAPI } from "./http-common";
import authHeader from "./authHeader";

const getUser = (id: string) => {
  return UserAPI.get(`/User/${id}`, { headers: authHeader() as any });
};

const getAllUsers = () => {
  return UserAPI.get("/User", { headers: authHeader() as any });
};

const createUser = (data: any) => {
  return UserAPI.post("/User/register", data, { headers: authHeader() as any });
};

const updateUser = (id: string, data: any) => {
  return UserAPI.put(`/User/${id}`, data, { headers: authHeader() as any });
};

const deleteUser = (id: string) => {
  return UserAPI.delete(`/User/${id}`, { headers: authHeader() as any });
};

const resetUserPassword = (data: any) => {
  return UserAPI.put("/User/ResetPassword/", data, { headers: authHeader() as any });
};

const UserService = {
  getUser,
  getAllUsers,
  createUser,
  updateUser,
  deleteUser,
  resetUserPassword
};

export default UserService;
