import { Fragment } from "react";
import "./Loader.css";

function Loader() {
  return (
    <Fragment>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Fragment>
  );
}

export default Loader;
