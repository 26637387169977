import * as Yup from "yup";

// learning module
export const LearningModuleSchema = Yup.object().shape(
  {
    editedText: Yup.string().max(50).required("Erforderlich!"),
  },
  [["video", "vttFile"]]
);

// subject
export const SubjectSchema = Yup.object().shape({
  editedText: Yup.string().max(50).required("Erforderlich!"),
  description: Yup.string().max(250),
});

// course
export const CourseSchema = Yup.object().shape({
  editedText: Yup.string().max(150).required("Erforderlich!"),
  description: Yup.string().max(250),
});

// chapter
export const ChapterSchema = Yup.object().shape({
  editedText: Yup.string().max(50).required("Erforderlich!"),
  description: Yup.string().max(250),
});

// Icons, Thumbnails in the popup
export const UploadImageSchema = Yup.object().shape({
  image: Yup.mixed().required("Erforderlich!"),
  alt: Yup.string().required("Erforderlich!"),
});

// video in the popup
export const UploadVideoSchema = Yup.object().shape({
  filePath: Yup.string().required("Erforderlich!"),
  vtt: Yup.mixed().required("Erforderlich!"),
});

export const SlideSchema = Yup.object().shape({});

// question
export const QuizQuestionSchema = Yup.object().shape({
  editedText: Yup.string().max(50).required("Erforderlich!"),
});

// translations popup
export const CreateTranslationSchema = Yup.object().shape({
  term: Yup.string().max(250).required("Erforderlich!"),
  translationText: Yup.string().required("Erforderlich!"),
});

// translation links
export const CreateTranslationLinkSchema = Yup.object().shape({
  selectedTerm: Yup.string().max(250).required("Erforderlich!"),
  translationText: Yup.string().required("Erforderlich!"),
});

export const editedTextSchema = Yup.object().shape({
  editedText: Yup.string().max(250).required("Erforderlich!"),
});

export const translationTextSchema = Yup.object().shape({
  editedText: Yup.string().required("Erforderlich!"),
});
