//Combine Reducers
import { combineReducers } from "redux";
import UserReducer from "./userReducer";
import LoginReducer from "./authReducer";
import LoadingReducer from "./loadingReducer";
import MessageReducer from "./messageReducer";
import ContrastReducer from "./contrastReducer";
// add Font Size Reducer
import FontSizeReducer from "./fontSizeReducer";
import LearningModuleReducer from "./learningModuleReducer";
import HelpPopupReducer from "./helpPopupReducer";
import TranslationReducer from "./translationReducer";
import cookiesReducer from "./cookiesReducer";

export default combineReducers({
  UserReducer,
  LoginReducer,
  MessageReducer,
  LoadingReducer,
  ContrastReducer,
  FontSizeReducer,
  HelpPopupReducer,
  LearningModuleReducer,
  TranslationReducer,
  cookiesReducer,
});

export type RootState = ReturnType<typeof combineReducers>;
