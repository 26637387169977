import "./DefaultPopup.css";
import { useState } from "react";
import DefaultButton from "../DefaultButton/DefaultButton";

type DefaultPopupProps = {
  label: string;
  closePopup: () => void;
  action: any;
  yesButtonText?: string;
  noButtonText?: string;
  method: string;
};

function DefaultPopup(props: DefaultPopupProps) {
  //local state
  const [defaultPopup, setDefaultPopup] = useState(true);

  const toggleModal = () => {
    setDefaultPopup(!defaultPopup);
  };

  const cancelPopupOnDeleteButton = () => {
    closeModal();
    props.action();
    props.closePopup();
  };

  const cancelPopup = () => {
    closeModal();
    toggleModal();
    props.closePopup();
  };

  if (defaultPopup) {
    document.body.classList.add("active-modal");
    document.body.style.overflowY = "hidden";
  } else {
    document.body.classList.remove("active-modal");
    document.body.style.overflowY = "visible";
  }

  const closeModal = () => {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = "";
    body.style.top = "";
    body.style.height = "";
    body.style.overflowY = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
    document.body.classList.remove("active-modal");
    document.body.style.overflowY = "visible";
  };

  return (
    <>
      <div className="modal">
        <div
          onClick={toggleModal}
          className={
            props.method === "delete" ? "overlay delete-background" : "overlay"
          }
        ></div>
        <div className="modal-content">
          <div className="model-text">
            <h2>{props.label}</h2>
          </div>
          <div className="popup-btns">
            <DefaultButton
              onClick={cancelPopupOnDeleteButton}
              className={props.method}
            >
              {props.yesButtonText ? props.yesButtonText : "Löschen"}
            </DefaultButton>
            <DefaultButton onClick={cancelPopup} className="cancel">
              {props.noButtonText ? props.noButtonText : "Abbrechen"}
            </DefaultButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default DefaultPopup;
