import {
  CREATE_LEARNING_MODULE,
  GET_LEARNING_MODULE,
  DELETE_LEARNING_MODULE,
} from "../actions/types";

const initialState = [] as any;

type contrastReducerAction = {
  type:
    | "CREATE_LEARNING_MODULE"
    | "GET_LEARNING_MODULE"
    | "DELETE_LEARNING_MODULE";
  payload: any;
};

function LearningModuleReducer(
  learningModules = initialState,
  action: contrastReducerAction
) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_LEARNING_MODULE:
      return [...learningModules, payload];

    case GET_LEARNING_MODULE:
      return payload;

    case DELETE_LEARNING_MODULE:
      return learningModules.filter(
        ({ id }: { id: string }) => id !== payload.id
      );

    default:
      return learningModules;
  }
}

export default LearningModuleReducer;
