import { ADD_CONTRAST, REMOVE_CONTRAST} from "./types";

export const addContrast = () => ({
    type: ADD_CONTRAST
});

export const removeContrast= () => ({
    type: REMOVE_CONTRAST
});

