import "./FilterCourses.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import CourseService from "services/courseService";

import { removeLoading, setLoading } from "store/actions/loadingAction";
import { setMessage } from "store/actions/messageAction";

//Components
import Header from "components/Header/Header";
import Loader from "components/Loader/Loader";
import SideBar from "components/SideBar/SideBar";
import MessageNotification from "components/MessageNotification/MessageNotification";

//icons:
import inEdit from "assets/Icons/Sidebar/in-edit.png";
import trash from "assets/Icons/Sidebar/trash.png";
import defaultImage from "assets/Images/default-image.png";
import readyToBePublished from "assets/Icons/Sidebar/ready-to-be-published.png";

function FilterCourses() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //local state
  const [filteredLearningModules, setFilteredLearningModules] = useState([]);

  //redux state
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const learningModules = useSelector(
    (state: any) => state.LearningModuleReducer
  );
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  let params: any = useParams();
  const { status } = params;

  // Get courses by status
  const getCourseByStatus = (status: string) => {
    dispatch(setLoading());
    CourseService.getCourseByStatus(status)
      .then(
        (response: any) => {
          setFilteredLearningModules(response.data);
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  useEffect(() => {
    document.title = "Kurse filtern -ILLUME ";
  }, []);

  useEffect(() => {
    getCourseByStatus(status);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, dispatch]);

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      <Header />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="create-edit-module-subject-container">
            <div className="create-edit-module-subject-sidebar">
              <SideBar
                learningModules={learningModules}
              />
            </div>
            <div className="create-edit-module-subject-body filtered">
              <section>
                {/*Course-status design */}
                {filteredLearningModules && (
                  <>
                    <div className="filtered-learning-module-title">
                      {status === "InEdit" ? (
                        <>
                          <h1>Kurse in Bearbeitung</h1>
                        </>
                      ) : status === "ReadyToBePublished" ? (
                        <>
                          <h1>Fertige Kurse zum Veröffentlichen</h1>
                        </>
                      ) : status === "deleted" ? (
                        <>
                          <h1>Gelöschte Kurse</h1>
                        </>
                      ) : (
                        " "
                      )}
                    </div>

                    {filteredLearningModules.map(
                      (filteredLearningModule: any, index: any) => (
                        <div className="container1" key={index}>
                          <div className="my-learning-module">
                            {filteredLearningModule.learningModuleName}
                          </div>
                          <div className="my-container">
                            {filteredLearningModule.courses.map(
                              (course: any) => {
                                return (
                                  <div
                                    key={course.courseID}
                                    className={
                                      course.status === "InEdit"
                                        ? "my-one-course my-size in-edit-box"
                                        : course.status === "ReadyToBePublished"
                                        ? "my-one-course  my-size published-box "
                                        : course.status === "Deleted"
                                        ? "my-one-course  my-size deleted-box "
                                        : ""
                                    }
                                  >
                                    <div className="float-left course-icon-container">
                                      <img
                                        onClick={() =>
                                          navigate(
                                            `/create-edit-course-chapter-slide/${course.learningModuleID}/${course.subjectID}/${course.courseID}/${course.status}`
                                          )
                                        }
                                        className="course-icon"
                                        src={
                                          course.icon?.image
                                            ? "data:image/jpeg;base64," +
                                              course.icon?.image
                                            : defaultImage
                                        }
                                        alt={course.icon?.alt}
                                        title={course.icon?.title}
                                      />
                                    </div>
                                    <div
                                      className="float-left course-desc-container"
                                      onClick={() =>
                                        navigate(
                                          `/create-edit-course-chapter-slide/${course.learningModuleID}/${course.subjectID}/${course.courseID}/${course.status}`
                                        )
                                      }
                                    >
                                      <h4 className="course-desc">
                                        {course.title}
                                      </h4>
                                    </div>
                                    {course.status === "ReadyToBePublished" ? (
                                      <img
                                        className="course-status-icon"
                                        src={readyToBePublished}
                                        alt={course.title}
                                      />
                                    ) : course.status === "InEdit" ? (
                                      <img
                                        className="course-status-icon"
                                        src={inEdit}
                                        alt={course.title}
                                      />
                                    ) : course.status === "Deleted" ? (
                                      <img
                                        className="course-status-icon"
                                        src={trash}
                                        alt={course.title}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}
              </section>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default FilterCourses;
