import axios from "axios";

export const UserAPI = axios.create({
  baseURL: "https://usermanagement.illume.nrw/",
  // baseURL: " http://192.168.0.6:32315/",
  // baseURL: "http://localhost:32315/",
  headers: {
    "Content-type": "application/json",
  },
});

export const CoursesAPI = axios.create({
  baseURL: "https://courses.illume.nrw/",
  // baseURL: "http://192.168.0.7:32100/",
  // baseURL: "http://localhost:8267/",
  headers: {
    "Content-type": "application/json",
  },
});

export const StreamingAPI = axios.create({
  baseURL: "https://streaming.illume.nrw/",
  // baseURL: "http://192.168.0.8:32200/",
  // baseURL: "http://localhost:44695/",
  headers: {
    "content-type": "multipart/form-data",
  },
});

export const TranslationAPI = axios.create({
  baseURL: "https://translations.illume.nrw/",
  // baseURL: "http://192.168.0.10:32300/",
  // baseURL: "http://localhost:8268/",
  headers: {
    "Content-type": "application/json",
  },
});

export const QuizAPI = axios.create({
  baseURL: "https://quizzes.illume.nrw/",
  // baseURL: "http://192.168.0.11:32400/",
  // baseURL: "http://localhost:8269/",
  headers: {
    "content-type": "multipart/form-data",
  },
});

export const StreamingAPIforVideo = "https://streaming.illume.nrw/";
// export const StreamingAPIforVideo = "http://192.168.0.8:32200/";
// export const StreamingAPIforVideo = "http://localhost:44695/";
