import "./CreateEditCourseChapter.css";
import { useEffect, useState } from "react";

//components
import EditableTitle from "components/EditableTitle/EditableTitle";
import LearningModuleService from "services/learningModuleService";

// Icons
import deleteIcon from "assets/Icons/delete-course-icon.png";

type CreateEditCourseChapterProps = {
  course: any;
  chapter: any;
  handleInputCourseChange: any;
  handleInputChapterChange: any;
  learningModuleID: any;
  setPopup: any;
};

function CreateEditCourseChapter(props: CreateEditCourseChapterProps) {
  //local state
  const [colorCode, setColorCode] = useState<any>();

  useEffect(() => {
    //api call to get the color code of learning module
    LearningModuleService.getLearningModuleByID(props.learningModuleID)
      .then((response: any) => {
        setColorCode(response.data.normal);
      })
      .catch((e) => {
        console.log(e);
      });

    return function cleanup() {
      setColorCode(null);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {props.chapter ? (
        <>
          <p className="course-title-p" style={{ color: colorCode }}>
            {props.course.title}
          </p>
          <div className="editor-chapter-name-container">
            {/* eslint-disable-next-line */}
            <p style={{ color: colorCode }}>
              {/* eslint-disable-next-line */}
              {props.chapter.order + "." + " "}
            </p>
            <EditableTitle
              type="chapter"
              title={props.chapter.title}
              chapterID={props.chapter.chapterID}
              action={props.handleInputChapterChange}
              order={props.chapter.order}
              isOpen={false}
              colorCode={colorCode}
            />
            <img
              className="editor-subject-delete-icon"
              src={deleteIcon}
              alt="Kapitel löschen"
              title="Kapitel löschen"
              onClick={() => {
                props.setPopup({
                  isPopup: true,
                  id: props.chapter.chapterID,
                  method: "delete",
                  type: "chapter",
                  yesButtonText: "Ja ich bin mir sicher",
                  noButtonText: "Nein, abbrechen",
                  label: "Möchtest du diesen Kapitel wirklich löschen?",
                });
              }}
            />
          </div>
        </>
      ) : (
        <div className="editor-course-name-container">
          <h1>Kurstitel:</h1>
          {props.course ? (
            <EditableTitle
              type="course"
              title={props.course?.title}
              isOpen={false}
              action={props.handleInputCourseChange}
              colorCode={colorCode}
              isMultiline={true}
            />
          ) : (
            <EditableTitle
              type="course"
              isOpen={true}
              action={props.handleInputCourseChange}
              colorCode={colorCode}
            />
          )}
        </div>
      )}
    </>
  );
}

export default CreateEditCourseChapter;
