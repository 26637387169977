//declare actions types

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SHOW_LOADING = "SHOW_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const GET_USERS = "GET_USERS";

export const CREATE_LEARNING_MODULE = "CREATE_LEARNING_MODULE";
export const GET_LEARNING_MODULE = "GET_LEARNING_MODULE";
export const GET_LEARNING_MODULE_ID = "GET_LEARNING_MODULE_ID";
export const DELETE_LEARNING_MODULE = "DELETE_LEARNING_MODULE";

export const GET_SUBJECT = "GET_SUBJECT";

export const ADD_CONTRAST = "ADD_CONTRAST";
export const REMOVE_CONTRAST = "REMOVE_CONTRAST";

//add the font size type
export const CHANGE_FONTSIZE = "CHANGE_FONTSIZE";

export const SHOW_HELPPOPUP = "SHOW_HELPPOPUP";
export const REMOVE_HELPPOPUP = "REMOVE_HELPPOPUP";

export const GET_TRANSLATIONS = "GET_TRANSLATIONS";

// cookies
export const SHOW_ACCEPT_COOKIES = "SHOW_ACCEPT_COOKIES";
