import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "store/actions/userAction";
import { NavLink } from "react-router-dom";
import UserService from "services/userService";
import { setMessage } from "store/actions/messageAction";

//components
import Loader from "components/Loader/Loader";
import MessageNotification from "components/MessageNotification/MessageNotification";

//material ui
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Tooltip } from "@material-ui/core";
import DeletePopup from "components/DefaultPopup/DefaultPopup";

function UserList() {
  const dispatch = useDispatch<any>();

  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const users = useSelector((state: any) => state.UserReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  const [isDeletePopup, setDeletePopup] = useState(false);
  const [deleteUser, setDeleteUser] = useState("");

  //get all users from DB:
  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const handleDeleteUser = (userID: string) => {
    setDeletePopup(true);
    setDeleteUser(userID);
  };

  const DeleteUser = () => {
    // delete user
    UserService.deleteUser(deleteUser)
      .then(() => {
        dispatch(
          setMessage("Die Benutzer wurde erfolgreich gelöscht", "success")
        );
        setDeletePopup(false);
        dispatch(getAllUsers());
      })
      .catch((e: string) => {
        console.log(e);
      });
  };

  //create user type
  type user = {
    firstname: string;
    lastname: string;
    email: string;
    username: string;
    roles: string[];
    userID: string;
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {isDeletePopup ? (
        <tr>
          <td>
            <DeletePopup
              // added type prop
              method="delete"
              label="Soll diese Benutzer wirklich gelöscht werden?"
              action={DeleteUser}
              closePopup={() => setDeletePopup(false)}
            />
          </td>
        </tr>
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="wrapper list">
            <div className="list-title">
              <h1>Benutzerliste</h1>
            </div>
            <div className="create-table full-width list-items">
              <div className="create-row">
                <div className="create-cell">
                  <div className="float-left full-width">
                    <div className="float-right add-button">
                      <NavLink to={{ pathname: "/create-edit-user/" }}>
                        <Tooltip
                          title={<div className="tool-tip">Neuen Benutzer</div>}
                        >
                          <AddCircleOutlineIcon
                            className="svg-icon"
                            fontSize="large"
                          ></AddCircleOutlineIcon>
                        </Tooltip>
                      </NavLink>
                    </div>
                  </div>
                  {/* set the changes for HTML structure */}
                  <div className="scroll-list">
                    <div className="float-left full-width">
                      <div className="float-left list-item header-title text-content">
                        <h2>Vorname</h2>
                      </div>
                      <div className="float-left list-item header-title text-content">
                        {" "}
                        <h2>Nachname</h2>
                      </div>
                      <div className="float-left list-item header-title text-content">
                        <h2>Benutzername</h2>
                      </div>
                      <div className="float-left list-item header-title text-content">
                        <h2>Email</h2>
                      </div>
                      <div className="float-left list-item header-title text-content">
                        <h2>Rolle(n)</h2>
                      </div>
                    </div>
                    {users &&
                      users.length > 0 &&
                      users.map((user: user, index: number) => (
                        <div
                          className="float-left full-width list-row"
                          key={index}
                        >
                          <div className="float-left list-item text-content">
                            {" "}
                            <h3>{user.firstname}</h3>
                          </div>
                          <div className="float-left list-item text-content">
                            {" "}
                            <h3>{user.lastname}</h3>
                          </div>
                          <div className="float-left list-item text-content">
                            {" "}
                            <h3>{user.username}</h3>
                          </div>
                          <div className="float-left list-item text-content">
                            {" "}
                            <h3>{user.email}</h3>
                          </div>
                          <div className="float-left list-item text-content">
                            {" "}
                            {user.roles.join(", ")}
                          </div>
                          <div className="float-right detele-icon">
                            <Tooltip
                              title={
                                <div className="tool-tip">Benutzer löschen</div>
                              }
                            >
                              <DeleteIcon
                                className="svg-icon"
                                onClick={() => handleDeleteUser(user.userID)}
                              ></DeleteIcon>
                            </Tooltip>
                          </div>
                          <div className="float-right">
                            <NavLink to={"/create-edit-user/" + user.userID}>
                              <Tooltip
                                title={
                                  <div className="tool-tip">
                                    Benutzer Bearbeiten
                                  </div>
                                }
                              >
                                <EditIcon className="svg-icon" />
                              </Tooltip>
                            </NavLink>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default UserList;
