import "./SlideImageVideoUpload.css";

// components:
import DefaultButton from "components/DefaultButton/DefaultButton";
import { Fragment } from "react";

type SlideImageVideoUploadProps = {
  isSlideTypePopup: any;
  slide: any;
  setImageUploadPopup: any;
  isNormalLang: boolean;
  setNormalLang: any;
  setVideoUploadPopup: any;
};

function SlideImageVideoUpload(props: SlideImageVideoUploadProps) {
  return (
    <div className="slide-image-video-upload-section">
      {props.isSlideTypePopup.type !== "video" &&
        !props.slide?.video?.filePath && (
          <div>
            <DefaultButton
              onClick={() =>
                props.setImageUploadPopup({
                  type: "image",
                  imageUploadPopup: true,
                  label: "Bild Slide",
                  id: props.slide.slideID,
                  existingImage: props.slide?.image?.image,
                  existingAlt: props.slide?.image?.alt,
                })
              }
            >
              Bild hochladen
            </DefaultButton>
          </div>
        )}

      {!props.slide?.video?.filePath &&
        props.isSlideTypePopup.type !== "video" && (
          <div className="slide-easy-normal-lang">
            <div className="slide-select-languages-div">
              <button
                className={props.isNormalLang ? "normal-lang" : "easy-lang"}
                onClick={() => props.setNormalLang(true)}
              >
                Alltagssprache
              </button>
              <button
                className={!props.isNormalLang ? "normal-lang" : "easy-lang"}
                onClick={() => props.setNormalLang(false)}
              >
                Leichte Sprache
              </button>
            </div>
          </div>
        )}

      {(props.isSlideTypePopup.type === "video" ||
        props.slide?.video?.filePath) && (
        <Fragment>
          <DefaultButton
            onClick={() =>
              props.setVideoUploadPopup({
                type: "video",
                videoUploadPopup: true,
                label: "Video Slide",
                id: props.slide.slideID,
              })
            }
          >
            Video hochladen
          </DefaultButton>
        </Fragment>
      )}
    </div>
  );
}

export default SlideImageVideoUpload;
