import "./VideoPlayer.css";
import screenfull from "screenfull";
import ReactPlayer from "react-player";
import { useState, useRef, useEffect } from "react";
import { StreamingAPIforVideo } from "../../services/http-common";
import VideoPlayerControls from "./VideoPlayerControls/VideoPlayerControls";

//icon
import PlayArrowIcon from "assets/Icons/VideoPlayer/play_black.png";

// Add props for all variables send by props
type VideoPlayerProps = {
  filePath: string;
  videoTitle?: string;
  mimeType: string;
  vttFile: string;
  type?: string;
  colorCode?: any;
  videoThumbnail?: any;
  isHomepage?: any;
  cancelSpeaking?: any;
  NotificationMessageBrakes?: any;
  incrementNotificationMessageBrakes?: any;
};

function VideoPlayer(props: VideoPlayerProps) {
  //local states:

  //state for playing video
  const [isPlaying, setPlaying] = useState(false);

  //state for muting video
  const [isMute, setMute] = useState(false);

  //state for volume of video
  const [volume, setVolume] = useState(0.5);

  //state for speed rate of video
  const [playbackRate, setPlaybackRate] = useState(1.0);

  //state for seconds played of the video
  const [played, setPlayed] = useState(0);

  //state for seeking the time (current time/duration)
  const [isSeek, setSeek] = useState(false);

  //state for adding captions
  const [isCaption, setCaption] = useState(false);

  //state for current caption text
  const [currentCaptionText, setCurrentCaptionText] = useState(" ");

  //state for showing normal time vs remaining
  const [timeDisplayFormat, setTimeDisplayFormat] = useState("normal");

  //state for showing full screen or not
  const [isFullScreen, setFullScreen] = useState(false)

  // state to define if its full screen and we click on the video
  const [isFullScreenAndVideoClicked, setFullScreenAndVideoClicked] = useState(false)


  //ref for the video player
  const playerRef = useRef(null) as any;

  //ref for the video container
  const playerContainerRef = useRef(null) as any;

  const handlePlayPause = () => {
    setPlaying(!isPlaying);
    props.cancelSpeaking && props.cancelSpeaking();
    if (isPlaying) {
      setCurrentCaptionText(currentCaptionText);
    } else {
      setCurrentCaptionText(" ");
    }
  };

  // useEffect to trigger a pause in the video
  useEffect(() => {
    const abortController = new AbortController();
    setPlaying(false);
    return function cleanup() {
      setPlaying(false);
      abortController.abort();
    };
  }, [props.filePath]);

  //get current time and rewind 10 seconds
  const handleRewind = () => {
    if (playerRef && playerRef.current) {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
    }
  };

  //get current time and fastforward 10 seconds
  const handleFastForward = () => {
    if (playerRef && playerRef.current) {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
    }
  };

  //handle if video is muted
  const handleMute = () => {
    setMute(!isMute);
  };

  //handle if volume is changed
  const handleVolumeChange = (e: any, newValue: any) => {
    var volumeChange = newValue / 100 + "";
    setVolume(parseFloat(volumeChange));
    setMute(newValue === 0 ? true : false);
  };

  const handleVolumeSeekUp = (e: any, newValue: any) => {
    var volumeChange = newValue / 100 + "";
    setVolume(parseFloat(volumeChange));
    setMute(newValue === 0 ? true : false);
  };

  // for speed rate of video
  const handlePlaybackRateChange = (rate: any) => {
    setPlaybackRate(rate);
  };

  // for viewing a video in full screen
  const toggleFullScreen = () => {
    if (playerContainerRef && playerContainerRef.current) {
      screenfull.toggle(playerContainerRef.current);
      setFullScreen(!isFullScreen)
    }
  };

  // handles for setting the progress of seconds played so far
  const handleProgress = (changeState: any) => {
    if (!isSeek) {
      setPlayed(changeState.played);
      setPlaying(isPlaying);
      if (
        playerRef.current.player.player.player.textTracks[0].cues.length > 0
      ) {
        const track = playerRef.current.player.player.player.textTracks[0];
        //hide caption text when caption end time is undefined which means when it passed the played time
        if (track?.activeCues[0]?.endTime === undefined) {
          setCurrentCaptionText(" ");
        }
      }
      // else {
      //   if (props.NotificationMessageBrakes === 0) {
      //     console.log(true)
      //     dispatch(
      //       setMessage("Subtitle Not Supported in this video ", "error")
      //     );
      //   }
      // }
    }
  };

  const handleSeekChange = (e: any, newValue: any) => {
    var seekChange = newValue / 100 + "";
    setPlayed(parseFloat(seekChange));
  };

  const handleSeekMouseDown = (e: any) => {
    setSeek(true);
  };

  const handleSeekMouseUp = (e: any, newValue: any) => {
    setSeek(false);
    if (playerRef && playerRef.current) {
      playerRef.current.seekTo(newValue / 100);
    }
  };

  const format = (seconds: any) => {
    if (isNaN(seconds)) {
      return "00:00";
    }

    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds();
    if (hh) {
      return `${hh}:${mm.toString()}:${ss}`;
    }
    return `${mm}:${ss}`;
  };

  //get current time and duration of a video to display them
  let elapsedTime: any, totalDuration: any, currentTime: any;

  if (playerRef && playerRef.current) {
    currentTime = playerRef.current
      ? playerRef.current.getCurrentTime()
      : "00:00";
    const duration = playerRef.current
      ? playerRef.current.getDuration()
      : "00:00";

    //get total time of video
    elapsedTime =
      timeDisplayFormat === "normal"
        ? format(currentTime)
        : `-${format(duration - currentTime)}`;
    totalDuration = format(duration);
  }

  //on click on time toggle between the remaining time and normal time
  const handleChangeDisplayFormat = () => {
    setTimeDisplayFormat(
      timeDisplayFormat === "normal" ? "remaining" : "normal"
    );
  };

  var reactPlayerDiv = document.getElementById("react-player-vid");
  if (
    reactPlayerDiv !== null &&
    playerRef.current !== null &&
    playerRef.current.player.player !== undefined
  ) {
    const track = playerRef.current.player.player.player.textTracks[0];
    track.addEventListener("cuechange", () => {
      if (track.activeCues != null && track.activeCues[0] !== undefined) {
        const cue = track.activeCues[0];
        if (cue !== undefined) {
          setCurrentCaptionText(track.activeCues[0].text);
        }
      }
    });
  }

  //show captions based on state
  const toggleCaption = () => {
    setCaption(!isCaption);
  };

  const handleClickForFullScreen = () => {
    if (isFullScreen) {
        setFullScreenAndVideoClicked(!isFullScreenAndVideoClicked)
      }
  }

  return (
    <>
      {/* {props.videoTitle && !props.isHomepage && (
        <h1
          className="learning-module-name-above-video"
          style={{
            color: props.colorCode,
          }}
        >
          {props.videoTitle}
        </h1>
      )} */}

      <div
        ref={playerContainerRef}
        className={
          !props.videoTitle && props.type !== "slide"
            ? "video-player-container small"
            : "video-player-container normal"
        }
        id="react-player"
        onClick={handleClickForFullScreen}
      >
        <ReactPlayer
          id="react-player-vid"
          pip={false}
          ref={playerRef}
          url={
            !props.isHomepage
              ? StreamingAPIforVideo +
                "Streaming?filePath=" +
                props.filePath +
                "&mimeType=" +
                props.mimeType
              : props.filePath
          }
          type="video/mp4"
          className="react-player-vid"
          playing={isPlaying}
          muted={isMute}
          volume={volume}
          playbackRate={playbackRate}
          onProgress={handleProgress}
          config={{
            file: {
              attributes: {
                crossOrigin: "anonymous",
              },
              tracks: [
                {
                  kind: "subtitles",
                  src: !props.isHomepage
                    ? "data:file/vtt;base64," + props.vttFile
                    : props.vttFile, //show the subtitles dynamically
                  srcLang: "de",
                  label: "German",
                  default: true,
                },
              ],
            },
          }}
        />{" "}
        {(!isPlaying && props.videoThumbnail) ||
        (elapsedTime === totalDuration && props.isHomepage) ? (
          <img
            style={{ backgroundColor: props?.colorCode }}
            className={isFullScreen ? "home-page-th-1-full" : "home-page-th-1"}
            src={props.videoThumbnail}
            alt={props.videoTitle}
            title={`${props.videoTitle} video`}
          />
        ) : (
          ""
        )}
        <div id="controller">
          {props.videoTitle ? (
            <VideoPlayerControls
              videoTitle={props.videoTitle}
              onPlayPause={handlePlayPause}
              playing={isPlaying}
              onRewind={handleRewind}
              onFastForward={handleFastForward}
              muted={isMute}
              onMute={handleMute}
              onvolumeChange={handleVolumeChange}
              onVolumeSeekUp={handleVolumeSeekUp}
              volume={volume}
              playbackRate={playbackRate}
              onPlaybackRateChange={handlePlaybackRateChange}
              onToggleFullScreen={toggleFullScreen}
              played={played}
              onSeek={handleSeekChange}
              onSeekMouseDown={handleSeekMouseDown}
              onSeekMouseUp={handleSeekMouseUp}
              elapsedTime={elapsedTime}
              totalDuration={totalDuration}
              onChangeDisplayFormat={handleChangeDisplayFormat}
              onCaption={toggleCaption}
              caption={isCaption}
              fullScreenVideoClicked={isFullScreenAndVideoClicked}
            />
          ) : (
            <VideoPlayerControls
              videoTitle={props.videoTitle}
              onPlayPause={handlePlayPause}
              playing={isPlaying}
              onRewind={handleRewind}
              onFastForward={handleFastForward}
              muted={isMute}
              onMute={handleMute}
              onvolumeChange={handleVolumeChange}
              onVolumeSeekUp={handleVolumeSeekUp}
              volume={volume}
              playbackRate={playbackRate}
              onPlaybackRateChange={handlePlaybackRateChange}
              onToggleFullScreen={toggleFullScreen}
              played={played}
              onSeek={handleSeekChange}
              onSeekMouseDown={handleSeekMouseDown}
              onSeekMouseUp={handleSeekMouseUp}
              elapsedTime={elapsedTime}
              totalDuration={totalDuration}
              onChangeDisplayFormat={handleChangeDisplayFormat}
              onCaption={toggleCaption}
              caption={isCaption}
              fullScreenVideoClicked={isFullScreenAndVideoClicked}
            />
          )}
        </div>
        {/* show caption when video is playing and caption is enabled */}
        {currentCaptionText !== " " &&
        isCaption &&
        elapsedTime !== totalDuration ? (
          <div className="current-caption" id="current-caption">
            {currentCaptionText}
          </div>
        ) : (
          ""
        )}
      </div>
      {(!isPlaying || elapsedTime === totalDuration) &&
      props.isHomepage /* this section is only shown for video players on start page */ ? (
        <>
          <div className="home-page-vid-title">{props.videoTitle}</div>
          <div className="home-page-play-container">
            <img
              className="control-icon play bottom"
              src={PlayArrowIcon}
              alt="Abspielen"
              title="Abspielen."
            />
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default VideoPlayer;
