import "./CourseLocation.css";
import { Fragment } from "react";

// icons
import publishIcon from "assets/Icons/Sidebar/published.png";

type CourseLocationProps = {
  learningModules: any;
  subjectID: string;
};

function CourseLocation(props: CourseLocationProps) {
  return (
    <div className="course-location-section">
      <div className="course-location-section-title">
        <h1>Einsortierung des Kurses</h1>
      </div>

      <div className="course-location-learning-modules">
        {props.learningModules.map((learningModule: any) => {
          return (
            <div key={learningModule.learningModuleID}>
              <div
                className="course-detail-learning-module-name"
                style={{ color: learningModule?.normal }}
              >
                {learningModule.name}
              </div>
              <Fragment>
                {learningModule.subjects.map((subject: any) => {
                  return (
                    <div key={subject.subjectID}>
                      <div
                        className="course-detail-subject-name"
                        style={{ color: learningModule?.normal }}
                      >
                        {subject.subjectID === props.subjectID ? (
                          <h2 className="course-location-bold-subject-name">
                            {subject.name}
                            <img
                              src={publishIcon}
                              alt="ILLUME-Kurse"
                              title="ILLUME-Kurse"
                              className="course-location-bold-subject-icon"
                            />
                          </h2>
                        ) : (
                          <h2>{subject.name}</h2>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Fragment>
              <br />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CourseLocation;
