import "./VideoPlayerControls.css";
import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";

//custom video icons
import PlayArrowIcon from "assets/Icons/VideoPlayer/play_black.png";
import PauseIcon from "assets/Icons/VideoPlayer/pause_green.png";
import enableCaption from "assets/Icons/VideoPlayer/subtitles_black.png";
import disableCaption from "assets/Icons/VideoPlayer/subtitles_green.png";
import enableFullScreenIcon from "assets/Icons/VideoPlayer/full_screen_black.png";
import disableFullScreenIcon from "assets/Icons/VideoPlayer/full_screen_green.png";

import FastRewindIcon from "assets/Icons/VideoPlayer/prev_icon_black.png";
import FastForwardIcon from "assets/Icons/VideoPlayer/next.png";
import { useLocation } from "react-router-dom";

const PrettoSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    color: "black",
    height: 20,
    width: 20,
    backgroundColor: "black",
    border: "2px solid currentColor",
    marginTop: -7,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    color: "black",
    height: 8,
    borderRadius: 4,
  },
  rail: {
    color: "black",
    height: 4,
    borderRadius: 4,
  },
})(Slider);

// Add props for all variables send by props

type VideoPlayerControlsProps = {
  videoTitle?: string;
  onPlayPause?: () => void;
  playing?: boolean;
  onRewind?: () => void;
  onFastForward?: () => void;
  muted?: boolean;
  onMute?: () => void;
  onvolumeChange?: any;
  onVolumeSeekUp?: any;
  volume?: any;
  onPlaybackRateChange?: any;
  playbackRate?: number;
  onToggleFullScreen?: () => void;
  played?: any;
  onSeek?: any;
  onSeekMouseDown?: any;
  onSeekMouseUp?: any;
  elapsedTime?: any;
  totalDuration?: any;
  onChangeDisplayFormat?: any;
  onCaption?: any;
  caption?: any;
  fullScreenVideoClicked?: any;
};

function VideoPlayerControls(props: VideoPlayerControlsProps) {
  //state for full screen video
  const [isFullScreen, setFullScreen] = useState(false);

  //handle for fullscreen inorder to update state to change icon color
  const handleFullScreen = () => {
    setFullScreen(!isFullScreen);
  };

  //Capture ESC event when exiting full screen mode
  const document: any = window.document;
  if (document.addEventListener) {
    document.addEventListener("webkitfullscreenchange", exitHandler, false);
    document.addEventListener("mozfullscreenchange", exitHandler, false);
    document.addEventListener("fullscreenchange", exitHandler, false);
    document.addEventListener("MSFullscreenChange", exitHandler, false);
  }
  function exitHandler() {
    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setFullScreen(false);
    }
  }

  //get current path name
  const currentUrl = useLocation();
  const currentPathName = currentUrl.pathname;

  var controllerContainer = document.getElementById("video-controls-container");

  if (props.fullScreenVideoClicked) {
    controllerContainer?.classList.add("hide-video-controls-container");
  } else {
    controllerContainer?.classList.remove("hide-video-controls-container");
  }

  // const showVideoControllers = document.getElementById(
  //   "video-controls-container"
  // );

  return (
    <ul>
      <li className="controlsWrapper" tabIndex={0}>
      {/* Top controls */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className="bottom-control"
      >
        {props.videoTitle && (
          <Grid item>
            {/*  Remove the variant="h1" from the Typography */}
            <Typography className="video-title"></Typography>
          </Grid>
        )}
      </Grid>

      <div
        tabIndex={0}
        className={
          isFullScreen ||
          currentPathName.includes("create-edit-course-chapter-slide") ||
          currentPathName.includes("create-edit-module-subject") ||
          currentPathName.includes("overview-module-subject") ||
          currentPathName.includes("course-details")
            ? "video-controls-container full-screen"
            : "video-controls-container"
        }
      >
        <div className="play-container">
          <div onClick={props.onPlayPause}>
            {props.playing && props.elapsedTime !== props.totalDuration ? (
              <img
                role="button"
                id="play-button"
                className="control-icon play bottom"
                src={PauseIcon}
                alt="Pause"
                title="Pause."
                onClick={props.onPlayPause}
                tabIndex={0}
                onKeyPress={props.onPlayPause}
              />
            ) : (
              <img
                className="control-icon play bottom"
                src={PlayArrowIcon}
                alt="Abspielen"
                title="Abspielen."
                tabIndex={0}
                onKeyPress={props.onPlayPause}
              />
            )}{" "}
          </div>
        </div>

        {isFullScreen ||
        currentPathName.includes("create-edit-course-chapter-slide") ||
        currentPathName.includes("create-edit-module-subject") ||
        currentPathName.includes("overview-module-subject") ||
        currentPathName.includes(
          "course-details"
        ) /*  these controls should appear when video is full screen or big in size  */ ? (
          <>
            <div className="fast-rewind-icon-container">
              <img
                role="button"
                className="fast-rewind-icon"
                src={FastRewindIcon}
                alt="-10 Sek"
                title="-10 Sek."
                onClick={props.onRewind}
                tabIndex={0}
                onKeyPress={props.onRewind}
              />
            </div>
            <div className="fast-forward-icon-container">
              <img
                role="button"
                className="fast-forward-icon"
                src={FastForwardIcon}
                alt="+10 Sek"
                title="+10 Sek."
                onClick={props.onFastForward}
                tabIndex={0}
                onKeyPress={props.onFastForward}
              />
            </div>
          </>
        ) : (
          ""
        )}

        <div className="pretto-slider-container">
          <PrettoSlider
            min={0}
            max={100}
            value={props.played * 100}
            onChange={props.onSeek}
            onMouseDown={props.onSeekMouseDown}
            onChangeCommitted={props.onSeekMouseUp}
          />{" "}
        </div>

        {/* time display */}

        <Tooltip title={<div className="tool-tip">Dauer</div>}>
          <div className="duration-time" onClick={props.onChangeDisplayFormat}>
            {props.totalDuration}
          </div>
        </Tooltip>

        <div className="caption-icon-container">
          {props.caption ? (
            <img
              role="button"
              src={disableCaption}
              className="caption-icon"
              alt="Untertitel deaktivieren"
              title="Untertitel-deaktivieren"
              onClick={props.onCaption}
              tabIndex={0}
              onKeyPress={props.onCaption}
            />
          ) : (
            <img
              role="button"
              src={enableCaption}
              className="caption-icon"
              alt="Untertitel aktivieren"
              title="Untertitel-aktivieren"
              onClick={props.onCaption}
              tabIndex={0}
              onKeyPress={props.onCaption}
            />
          )}
        </div>

        <div className="fullscreen-icon-container" onClick={handleFullScreen}>
          {!isFullScreen ? (
            <img
              role="button"
              src={enableFullScreenIcon}
              className="fullscreen-icon"
              alt="Vollbildschirm aktivieren"
              title="Vollbildschirm-aktivieren"
              onClick={props.onToggleFullScreen}
              tabIndex={0}
              onKeyPress={props.onToggleFullScreen}
            />
          ) : (
            <img
              role="button"
              src={disableFullScreenIcon}
              className="fullscreen-icon"
              alt="Vollbildschirm deaktivieren"
              title="Vollbildschirm-deaktivieren"
              onClick={props.onToggleFullScreen}
              tabIndex={0}
              onKeyPress={props.onToggleFullScreen}
            />
          )}
        </div>
      </div>
    </li>
  </ul>
  );
}
export default VideoPlayerControls;
