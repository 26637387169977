import "./CourseSidebar.css";
import { useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


// components
import Loader from "components/Loader/Loader";
import EditableTitle from "components/EditableTitle/EditableTitle";

// Images:
import add from "assets/Icons/Sidebar/add-icon.png";
import editIcon from "assets/Icons/Sidebar/in-edit.png";
import videoIcon from "assets/Icons/Sidebar/video-icon.png";
import publishIcon from "assets/Icons/Sidebar/published.png";
import readyToBePublished from "assets/Icons/Sidebar/ready-to-be-published.png";
import LearningModuleService from "services/learningModuleService";
import { Fragment, useEffect, useState } from "react";

type CourseSidebarProps = {
  chapterAction?: any;
  quizAction?: any;
  course?: any;
  slides?: any;
  chapters?: {}[];
  courseID?: string;
  setImageUploadPopup?: any;
  setSlide?: any;
  setChapter?: any;
  getSlidesByChapterID?: any;
  setSlideID?: any;
  slideID?: any;
  chapterID?: any;
  setChapterID?: any;
  setSlideTypePopup?: any;
  updateChapterOrder?: any;
  updateSlideOrder?: any;
  learningModuleID?: any;
  quiz?: any;
  isQuizOpen?: any;
  setQuizOpen?: any;
  questions: {}[];
  setQuestion: any;
  questionID: string;
  setQuestionID: any;
  GetAnswersByQuestionID: any;
  updateQuestionsOrder: any;
  setAnswers: any;
  setAnswerTypePopup: any;
  setChapterIndex: any;
  setSlideIndex: any;
  setSlideNormalEasyTexts: any;
};

function CourseSidebar(props: CourseSidebarProps) {
  // redux states
  const { loading } = useSelector((state: any) => state.LoadingReducer);

  //local state
  const [colorCode, setColorCode] = useState<any>();

  useEffect(() => {
    //api call to get the color code of learning module
    LearningModuleService.getLearningModuleByID(props.learningModuleID)
      .then((response) => {
        setColorCode(response.data.normal);
      })
      .catch((e) => {
        console.log(e);
      });

    return function cleanup() {
      setColorCode(null);
    };
    // eslint-disable-next-line
  }, []);

  // handle slides container
  const handleSlidesContainer = (ChapterID: string) => {
    props.chapterID === ChapterID
      ? props.setChapterID("")
      : props.setChapterID(ChapterID);
    props.getSlidesByChapterID(ChapterID);
  };

  // When the user clicks on the + icon of slide , scroll to the top of the page
  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="course-sidebar-container">
            <label
              className="icon-input"
              htmlFor="icon-input"
              onClick={
                props.course?.title !== undefined
                  ? () =>
                      props.setImageUploadPopup({
                        type: "icon",
                        imageUploadPopup: true,
                        label: "Course Icon",
                        id: props.courseID,
                        existingAlt: props.course?.icon?.alt,
                        existingImage: props.course?.icon?.image,
                      })
                  : () =>
                      props.setImageUploadPopup({
                        imageUploadPopup: false,
                        id: "",
                        type: "",
                        label: "",
                        existingAlt: "",
                        existingImage: "",
                      })
              }
              tabIndex={0}
              onKeyPress={
                props.course?.title !== undefined
                  ? () =>
                      props.setImageUploadPopup({
                        type: "icon",
                        imageUploadPopup: true,
                        label: "Course Icon",
                        id: props.courseID,
                        existingAlt: props.course?.icon?.alt,
                        existingImage: props.course?.icon?.image,
                      })
                  : () =>
                      props.setImageUploadPopup({
                        imageUploadPopup: false,
                        id: "",
                        type: "",
                        label: "",
                        existingAlt: "",
                        existingImage: "",
                      })
              }
            >
              <img
                style={{
                  width: props.course?.icon?.image ? "13rem" : "5rem",
                  height: props.course?.icon?.image ? "13rem" : "5rem",
                  backgroundColor: props.course?.icon?.image
                    ? colorCode
                    : "transparent",
                }}
                className={
                  props.course?.title !== undefined
                    ? "course-sidebar-icon"
                    : "course-sidebar-icon disabled"
                }
                src={
                  props.course?.icon?.image
                    ? "data:image/jpeg;base64," + props.course?.icon?.image
                    : add
                }
                alt={
                  props.course?.icon?.alt
                    ? props.course?.icon?.alt
                    : "Einen Kurs hinzufügen"
                }
                title="Einen Kurs hinzufügen"
              />
              {props.course?.icon?.image ? (
                <p>Icon Editieren</p>
              ) : (
                <p>Icon hinzufügen</p>
              )}
            </label>
          </div>

          <br />

          <DragDropContext
            onDragEnd={(param: any) => {
              if (!props.chapterID) {
                if (param) {
                  const sourceIndex = param.source.index;
                  const destinationIndex = param.destination?.index;
                  props.chapters?.splice(
                    destinationIndex,
                    0,
                    props.chapters.splice(sourceIndex, 1)[0]
                  );

                  const values: any = [...(props.chapters as any)];
                  for (let j = 0; j < values.length; j++) {
                    values[j].order = j + 1;
                  }
                  props.updateChapterOrder(values);
                }
              }
            }}
          >
            <Droppable droppableId="droppable-1">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {props.chapters &&
                    props.chapters.map((chapter: any, index: any) => {
                      return (
                        <Draggable
                          key={index}
                          draggableId={chapter.chapterID}
                          index={index}
                          isDragDisabled={props.chapterID ? true : false}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={
                                props.chapterID === chapter.chapterID
                                  ? "course-sidebar-chapter-container is-slides-container"
                                  : "course-sidebar-chapter-container"
                              }
                            >
                              <div
                                onClick={() => {
                                  props.setChapter(chapter);
                                  props.setChapterIndex(index);
                                  // props.setQuestionID("");
                                  props.setQuizOpen(false);
                                  handleSlidesContainer(chapter.chapterID);
                                  props.setSlideTypePopup({
                                    type: "",
                                    isOpen: false,
                                  });
                                }}
                                // add tabIndex and onKeyPress function for the navigation
                                tabIndex={0}
                                onKeyPress={() => {
                                  props.setChapter(chapter);
                                  props.setChapterIndex(index);
                                  props.setQuizOpen(false);
                                  handleSlidesContainer(chapter.chapterID);
                                  props.setSlideTypePopup({
                                    type: "",
                                    isOpen: false,
                                  });
                                }}
                                className={
                                  props.chapterID === chapter.chapterID
                                    ? "course-sidebar-chapter-title is-slides-container"
                                    : "course-sidebar-chapter-title"
                                }
                              >
                                {index + 1}
                                {"."}
                                <p className="p-chapter-title">
                                  {chapter.title}
                                </p>
                              </div>

                              <DragDropContext
                                onDragEnd={(param: any) => {
                                  if (param) {
                                    const sourceIndex = param.source.index;
                                    const destinationIndex =
                                      param.destination?.index;

                                    props.slides?.splice(
                                      destinationIndex,
                                      0,
                                      props.slides.splice(sourceIndex, 1)[0]
                                    );

                                    const values: any = [
                                      ...(props.slides as any),
                                    ];
                                    for (let j = 0; j < values.length; j++) {
                                      values[j].order = j + 1;
                                    }

                                    props.updateSlideOrder(values);
                                  }
                                }}
                              >
                                <Droppable droppableId="droppable-2">
                                  {(provided) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {props.chapterID ===
                                        chapter.chapterID && (
                                        <Fragment>
                                          {props.slides?.map(
                                            (slide: any, index: any) => {
                                              return (
                                                <Draggable
                                                  key={slide.slideID}
                                                  draggableId={slide.slideID}
                                                  index={index}
                                                >
                                                  {(provided, snapshot) => (
                                                    <div
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                    >
                                                      <div
                                                        className={
                                                          props.slideID ===
                                                          slide.slideID
                                                            ? "one-slide-container selected"
                                                            : "one-slide-container"
                                                        }
                                                        onClick={() => {
                                                          props.setSlide(slide);
                                                          props.setSlideNormalEasyTexts(
                                                            {
                                                              textNormalLanguage:
                                                                slide?.textNormalLanguage,
                                                              textEasyLanguage:
                                                                slide?.textEasyLanguage,
                                                            }
                                                          );
                                                          props.setSlideIndex(
                                                            index
                                                          );
                                                          props.setSlideID(
                                                            slide.slideID
                                                          );
                                                          props.setChapter(
                                                            chapter
                                                          );
                                                          props.setSlideTypePopup(
                                                            {
                                                              type: "",
                                                              isOpen: false,
                                                            }
                                                          );
                                                        }}
                                                      >
                                                        {slide?.image ? (
                                                          // eslint-disable-next-line
                                                          <img
                                                            className="one-slide-image"
                                                            src={
                                                              "data:image/jpeg;base64," +
                                                              slide?.image
                                                                ?.image
                                                            }
                                                            alt={
                                                              slide?.image?.name
                                                            }
                                                            title={
                                                              slide?.image?.name
                                                            }
                                                          />
                                                        ) : slide?.video ? (
                                                          // eslint-disable-next-line
                                                          <img
                                                            className="one-slide-image"
                                                            src={videoIcon}
                                                            alt="Video-Folie"
                                                            title="Video-Folie"
                                                          />
                                                        ) : (
                                                          <>
                                                            {slide
                                                              ?.textNormalLanguage
                                                              ?.length > 10 ? (
                                                              <p
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    slide?.textNormalLanguage,
                                                                }}
                                                              ></p>
                                                            ) : (
                                                              <p
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    slide?.textEasyLanguage,
                                                                }}
                                                              ></p>
                                                            )}
                                                          </>
                                                        )}
                                                      </div>
                                                    </div>
                                                  )}
                                                </Draggable>
                                              );
                                            }
                                          )}
                                          <div
                                            className="new-slide-container"
                                            onClick={() => {
                                              props.setSlideTypePopup({
                                                type: "",
                                                isOpen: true,
                                              });
                                              topFunction();
                                              props.setSlideNormalEasyTexts({
                                                textNormalLanguage: "",
                                                textEasyLanguage: "",
                                              });
                                            }}
                                            tabIndex={0}
                                            onKeyPress={() => {
                                              props.setSlideTypePopup({
                                                type: "",
                                                isOpen: true,
                                              });
                                              topFunction();
                                              props.setSlideNormalEasyTexts({
                                                textNormalLanguage: "",
                                                textEasyLanguage: "",
                                              });
                                            }}
                                          >
                                            <img
                                              className="new-slide-image"
                                              src={add}
                                              alt="Neue Slide"
                                              title="Neue Slide"
                                            />
                                          </div>
                                        </Fragment>
                                      )}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {/* Chapter */}
          <Fragment>
            {props.course && (
              <div className="new-chapter">
                <EditableTitle
                  courseID={props.courseID}
                  type="chapter"
                  action={props.chapterAction}
                  isOpen={true}
                  order={props.course?.chapters?.length + 1}
                />
              </div>
            )}
          </Fragment>

          <br />

          {/* Quiz */}
          {props.course && props.chapters && props.slides && !props.quiz && (
            <div className="new-quiz">
              <EditableTitle
                courseID={props.courseID}
                type="quiz"
                action={props.quizAction}
                isOpen={true}
                order={props.course?.chapters?.length + 1}
                title={props.quiz?.title}
              />
            </div>
          )}

          {/* Quiz List */}
          {props.quiz && (
            <div
              className={
                props.isQuizOpen
                  ? "course-sidebar-quiz-container is-questions-container"
                  : "course-sidebar-quiz-container"
              }
            >
              <div
                onClick={() => {
                  props.setQuizOpen(!props.isQuizOpen);
                  props.setChapterID("");
                  props.setSlideTypePopup({
                    type: "",
                    isOpen: false,
                  });
                }}
                
                tabIndex={0}
                onKeyPress={() => {
                  props.setQuizOpen(!props.isQuizOpen);
                  props.setChapterID("");
                  props.setSlideTypePopup({
                    type: "",
                    isOpen: false,
                  });
                }}
                className={
                  props.isQuizOpen
                    ? "course-sidebar-quiz-title is-questions-container"
                    : "course-sidebar-quiz-title"
                }
              >
                <p className="p-quiz-title">{props.quiz?.title}</p>
              </div>

              <DragDropContext
                onDragEnd={(param: any) => {
                  if (param) {
                    const sourceIndex = param.source.index;
                    const destinationIndex = param.destination?.index;
                    props.questions?.splice(
                      destinationIndex,
                      0,
                      props.questions.splice(sourceIndex, 1)[0]
                    );
                    const values: any = [...(props.questions as any)];
                    for (let j = 0; j < values.length; j++) {
                      values[j].order = j + 1;
                    }
                    props.updateQuestionsOrder(values);
                  }
                }}
              >
                <Droppable droppableId="droppable-3">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {props.isQuizOpen && (
                        <div>
                          {props.questions?.map((question: any, index: any) => {
                            return (
                              <Draggable
                                key={question.questionID}
                                draggableId={question.questionID}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div
                                      className={
                                        props.questionID === question.questionID
                                          ? "one-question-container selected"
                                          : "one-question-container"
                                      }
                                      onClick={() => {
                                        props.setQuestion(question);
                                        props.GetAnswersByQuestionID(
                                          question.questionID
                                        );
                                        props.setQuestionID(
                                          question.questionID
                                        );
                                     
                                      }}
                                      
                                      tabIndex={0}
                                      onKeyPress={() => {
                                        props.setQuestion(question);
                                        props.GetAnswersByQuestionID(
                                          question.questionID
                                        );
                                        props.setQuestionID(
                                          question.questionID
                                        );
                                       
                                      }}
                                    >
                                      <p>Frage {question.order}</p>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          <div
                            className="new-question-container"
                            onClick={() => {
                              props.setAnswers("");
                              props.setQuestion("");
                              props.setQuestionID("");
                              
                              topFunction();
                            }}
                          
                            tabIndex={0}
                            onKeyPress={() => {
                              props.setAnswers("");
                              props.setQuestion("");
                              props.setQuestionID("");
                              topFunction();
                            }}
                          >
                            <img
                              className="new-question-image"
                              src={add}
                              alt="Neue Frage"
                              title="Neue Frage"
                            />
                          </div>
                        </div>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}

          {props.course && (
            <div className="course-sidebar-status">
              {props.course?.status === "InEdit" ? (
                <>
                  <img
                    src={editIcon}
                    className="course-sidebar-status-icon"
                    alt="Kurs in Bearbeitung"
                    title="Kurs in Bearbeitung"
                  />
                  <p>Kurs in Bearbeitung</p>
                </>
              ) : props.course?.status === "ReadyToBePublished" ? (
                <>
                  <img
                    src={readyToBePublished}
                    className="course-sidebar-status-icon"
                    alt="Kurs als fertig markieren"
                    title="Kurs als fertig markieren"
                  />
                  <p>Kurs als fertig markieren</p>
                </>
              ) : props.course?.status === "Published" ? (
                <>
                  <img
                    src={publishIcon}
                    className="course-sidebar-status-icon"
                    alt="Veröffentlicht"
                    title="Veröffentlicht"
                  />
                  <p>Veröffentlicht</p>
                </>
              ) : (
                ""
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default CourseSidebar;
