import { CoursesAPI } from "./http-common";
import authHeader from "./authHeader";

const getSubjectByID = (id: string) => {
  return CoursesAPI.get(`/Subject/${id}`, { headers: authHeader() as any });
};

const getSubjectByLearningModuleID = (id: string) => {
  return CoursesAPI.get(`/Subject/GetByLearningModuleID/${id}`, {
    headers: authHeader() as any,
  });
};
const getByLearningModuleWithPublishedCourses = (id: string) => {
  return CoursesAPI.get(
    `/Subject/GetByLearningModuleWithPublishedCourses/${id}`,
    {
      headers: authHeader() as any,
    }
  );
};

const getAllSubjects = () => {
  return CoursesAPI.get(`/Subject`, { headers: authHeader() as any });
};

const createSubject = (data: any) => {
  return CoursesAPI.post("/Subject", data, {
    headers: authHeader() as any,
  });
};

const updateSubject = (id: string, data: any) => {
  return CoursesAPI.put(`/Subject/${id}`, data, {
    headers: authHeader() as any,
  });
};

const deleteSubject = (id: string) => {
  return CoursesAPI.delete(`/Subject/${id}`, { headers: authHeader() as any });
};

const SubjectService = {
  getSubjectByID,
  getSubjectByLearningModuleID,
  getByLearningModuleWithPublishedCourses,
  getAllSubjects,
  createSubject,
  updateSubject,
  deleteSubject,
};

export default SubjectService;
