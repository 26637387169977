import "./CourseRightSideBar.css";
import { useNavigate, useParams } from "react-router-dom";

// icons
import closeIcon from "assets/Icons/Header/close-icon.png";
import deleteIcon from "assets/Icons/delete-course-icon.png";
import dictionary from "assets/Icons/Sidebar/sidebar-dictionary.png";
import { Fragment } from "react";

type CourseRightSideBarProps = {
  course?: any;
  quiz?: any;
  learningModuleID: string;
  isQuizOpen?: boolean;
  setPopup: any;
  slide?: any;
  setTranslationLinkPopup: any;
};

function CourseRightSideBar(props: CourseRightSideBarProps) {
  const navigate = useNavigate();

  let params: any = useParams();
  const { status } = params;

  return (
    <div
      className="course-right-sidebar"
      role="navigation"
      aria-label="Course Chapter Menu"
    >
      <Fragment>
        <img
          className="course-right-sidebar-icons close-course-icon"
          src={closeIcon}
          alt="Kurs schließen"
          title="Kurs schließen"
          onClick={() => {
            if (status === "InEdit") {
              navigate(`/filter-courses-page/${status}`);
            } else if (status === "ReadyToBePublished") {
              navigate(`/filter-courses-page/${status}`);
            } else if (status === "Deleted") {
              navigate(`/filter-courses-page/${status}`);
            } else {
              navigate(`/create-edit-module-subject/${props.learningModuleID}`);
            }
          }}
        />
      </Fragment>

      {props.slide && !props.isQuizOpen && (
        <img
          className="course-right-sidebar-icons translation-link-icon"
          src={dictionary}
          alt="Wörterbuch-Symbol"
          title="Wörterbuch-Symbol"
          onClick={() =>
            props.setTranslationLinkPopup({
              isPopup: true,
            })
          }
        />
      )}

      {props.course && (
        <img
          className="course-right-sidebar-icons delete-course-icon"
          src={deleteIcon}
          alt="Kurs löschen"
          title="Kurs löschen"
          onClick={() =>
            props.setPopup({
              isPopup: true,
              id: props.course.courseID,
              method: "delete",
              type: "course",
              yesButtonText: "Ja ich bin mir sicher",
              noButtonText: "Nein, abbrechen",
              label: "Möchtest du diesen Kurs wirklich löschen?",
            })
          }
        />
      )}

      {props.quiz && props.isQuizOpen && (
        <img
          className="course-right-sidebar-icons delete-course-icon"
          src={deleteIcon}
          alt="Quiz löschen"
          title="Quiz löschen"
          onClick={() =>
            props.setPopup({
              isPopup: true,
              id: props.quiz.quizID,
              method: "delete",
              type: "quiz",
              yesButtonText: "Ja ich bin mir sicher",
              noButtonText: "Nein, abbrechen",
              label: "Möchtest du diesen quiz wirklich löschen?",
            })
          }
        />
      )}
    </div>
  );
}

export default CourseRightSideBar;
