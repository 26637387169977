import React from "react";
import "./Input.css";

// Add props for all variables send by props

type InputProps = {
  id?: string;
  name?: string;
  type?: string;
  value?: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  readOnly?: boolean;
  aria_labelledby: string;
  aria_required: any;
  autocomplete: string;
};
function Input(props: InputProps) {
  return (
    <input
      className="my-input"
      id={props.id}
      name={props.name}
      type={props.type}
      value={props.value}
      placeholder={props.placeholder}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      accept={props.accept}
      readOnly={props.readOnly}
      aria-label={props.aria_labelledby}
      aria-required={props.aria_required}
      autoComplete={props.autocomplete}
    />
  );
}

export default Input;
