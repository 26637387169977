import { SET_MESSAGE, CLEAR_MESSAGE } from "../actions/types";

const initialState = { messageText: "", messageType: "" };

type messageReducerAction = {
  type: "SET_MESSAGE" | "CLEAR_MESSAGE";
  payload: any;
};


const MessageReducer = (state = initialState, action: messageReducerAction) => {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      return {
        messageText: payload.messageText,
        messageType: payload.messageType,
      };

    case CLEAR_MESSAGE:
      return { messageText: "", messageType: "" };

    default:
      return state;
  }
};
export default MessageReducer;
