import "./DefaultButton.css";

type DefaultButtonProps = {
  type?: "button" | "reset" | "submit";
  onClick?: () => void;
  disabled?: boolean;
  id?: string;
  children?: React.ReactNode;
  className?: string;
};
function DefaultButton(props: DefaultButtonProps) {
  return (
    <button
      id={props.id}
      className={
        props.disabled
          ? "my-button disabled"
          : props.className === "delete"
          ? "my-button delete-button"
          : props.className === "cancel"
          ? "my-button  cancel"
          : props.className === "cancel-reset-password"
          ? "my-button cancel-reset-password-button"
          : props.className === "change"
          ? "my-button change-button"
          : "my-button  publish-button"
      }
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}

export default DefaultButton;
