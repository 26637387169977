import "./CreateEditTranslations.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMessage } from "store/actions/messageAction";
import { getAllTranslations } from "store/actions/translationAction";
import TranslationService from "services/translationService";

//components
import Loader from "components/Loader/Loader";
import MessageNotification from "components/MessageNotification/MessageNotification";
import Header from "components/Header/Header";
import SideBar from "components/SideBar/SideBar";
import EditableTitle from "components/EditableTitle/EditableTitle";
import CreateTranslationPopup from "components/CreateTranslationPopUp/CreateTranslationPopUp";

//icons
import deleteIcon from "assets/Icons/delete-course-icon.png";
import add from "assets/Icons/Sidebar/add-icon.png";

import { removeLoading, setLoading } from "store/actions/loadingAction";
import DefaultPopup from "components/DefaultPopup/DefaultPopup";
import { getLearningModules } from "store/actions/learningModuleAction";

function CreateEditTranslations() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  //redux states
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const translations = useSelector((state: any) => state.TranslationReducer);
  const learningModules = useSelector(
    (state: any) => state.LearningModuleReducer
  );
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //local states
  // main translation state
  const [translation, setTranslation] = useState<any>();

  // state for the general popup
  const [isPopup, setPopup] = useState({
    isPopup: false,
    id: "",
    type: "",
    method: "",
    label: "",
    yesButtonText: "",
    noButtonText: "",
  });

  //set the page title
  useEffect(() => {
    document.title = "Wörterbuch - ILLUME";
  }, []);

  //get all users from DB:
  useEffect(() => {
    dispatch(getAllTranslations());
    dispatch(getLearningModules());
  }, [dispatch]);

  // Update Translation Term:
  const updateTranslationTerm = (transID: string, data: any) => {
    const { newTitle, translationID } = data;
    var translationText = document.getElementById(
      "translation-text-" + translationID
    ) as any;

    let formData = new FormData();
    if (translationText !== null) {
      formData.append("term", newTitle);
      formData.append(
        "translationText",
        translationText.value && translationText.value
      );
    }
    // API call
    dispatch(setLoading());
    TranslationService.updateTranslation(translationID, formData).then(
      (response: any) => {
        dispatch(
          setMessage("Die Übersetzung wurde erfolgreich erstellt.", "success")
        );
        dispatch(removeLoading());
        dispatch(getAllTranslations());
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        if (error.response.status === 400) {
          dispatch(
            setMessage(
              "Übersetzungsbegriff und -text sind beide erforderlich",
              "error"
            )
          );
        }
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  };

  // Update Translation text:
  const updateTranslationText = (transID: string, data: any) => {
    const { newTitle, translationID } = data;
    var term = document.getElementById("term-" + translationID) as any;

    let formData = new FormData();
    if (term !== null) {
      formData.append("term", term.value && term.value);
      formData.append("translationText", newTitle);
    }
    dispatch(setLoading());

    // API call
    TranslationService.updateTranslation(translationID, formData).then(
      (response: any) => {
        dispatch(
          setMessage("Die Übersetzung wurde erfolgreich erstellt.", "success")
        );
        dispatch(removeLoading());
        dispatch(getAllTranslations());
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        if (error.response.status === 400) {
          dispatch(
            setMessage(
              "Übersetzungsbegriff und -text sind beide erforderlich",
              "error"
            )
          );
        }
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  };

  const deleteTranslation = (transID: string) => {
    dispatch(setLoading());
    TranslationService.deleteTranslation(transID)
      .then(
        (res: any) => {
          dispatch(getAllTranslations());
          dispatch(getLearningModules());
          navigate(`/create-edit-translations`);
          dispatch(setMessage("Translation erfolgreich gelöscht", "success"));
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // handle the new translation input change:
  const handleTranslationChange = (event: any) => {
    const { name, value } = event.target;

    setTranslation({ ...translation, [name]: value });
    const term = translation && translation.term;
    const translationText = translation && translation.translationText;

    if (term !== undefined && translationText !== undefined) {
      TranslationService.createTranslation(translation)
        .then((response: any) => {
          if (response) {
            navigate("/create-edit-translations");
            dispatch(
              setMessage(
                "Die Übersetzung wurde erfolgreich erstellt.",
                "success"
              )
            );
          }
        })
        .catch((e: any) => {
          console.log(e);
          const message =
            (e.response && e.response.data && e.response.data.message) ||
            e.message ||
            e.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        });
    }
  };

  // state for the image uploader popup
  const [createTranslationPopup, setCreateTranslationPopup] = useState({
    id: "",
    type: "",
    label: "",
    term: "",
    translationText: "",
    createTranslationPopup: false,
  });

  //create translation type
  type trans = {
    translationID: string;
    term: string;
    translationText: string;
  };

  return (
    <>
      <Header />

      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {isPopup.isPopup ? (
        <DefaultPopup
          label={isPopup.label}
          method={isPopup.method}
          yesButtonText={isPopup.yesButtonText}
          noButtonText={isPopup.noButtonText}
          action={
            isPopup.method === "delete" &&
            isPopup.type === "dictionary-translation"
              ? () => deleteTranslation(isPopup.id)
              : ""
          }
          closePopup={() =>
            setPopup({
              isPopup: false,
              id: "",
              type: "",
              method: "",
              label: "",
              yesButtonText: "",
              noButtonText: "",
            })
          }
        />
      ) : null}

      {createTranslationPopup.createTranslationPopup ? (
        <CreateTranslationPopup
          closeCreateTranslationPopup={() =>
            setCreateTranslationPopup({
              createTranslationPopup: false,
              id: "",
              type: "",
              label: "",
              term: "",
              translationText: "",
            })
          }
          type={createTranslationPopup.type}
          action={handleTranslationChange}
          label={createTranslationPopup.label}
          term={createTranslationPopup.term}
          translationText={createTranslationPopup.translationText}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="create-edit-module-subject-container">
            <div className="create-edit-module-subject-sidebar">
              <SideBar
                learningModules={learningModules}
              />
            </div>
            <div className="list translations">
              <div className="list-title">
                <h1>Wörterbuch</h1>
              </div>
              <div className="create-table full-width list-items">
                <div className="create-row">
                  <div className="create-cell">
                    <div className="scroll-list">
                      <div
                        className="float-right text-content new-translation-container"
                        onClick={() =>
                          setCreateTranslationPopup({
                            type: "image",
                            createTranslationPopup: true,
                            label: "Translation",
                            id: "",
                            term: "",
                            translationText: "",
                          })
                        }
                        tabIndex={0}
                        onKeyPress={() =>
                          setCreateTranslationPopup({
                            type: "image",
                            createTranslationPopup: true,
                            label: "Translation",
                            id: "",
                            term: "",
                            translationText: "",
                          })
                        }
                      >
                        <div className="new-translation-inner">
                          <img
                            className="new-course-icon"
                            src={add}
                            alt="neue Übersetzung"
                            title="neue Übersetzung"
                          />
                          <h4>Neue Übersetzung</h4>
                        </div>
                      </div>
                      <div className="float-left full-width">
                        <div className="float-left list-item header-title translation-term text-content">
                          <h3>Wort</h3>
                        </div>
                        <div className="float-left list-item header-title translation-text text-content">
                          {" "}
                          <h3>Übersetzung</h3>
                        </div>
                      </div>
                      {translations &&
                        translations.length > 0 &&
                        translations.map((trans: trans, index: number) => (
                          <div
                            className="float-left full-width list-row"
                            key={index}
                          >
                            <div className="float-left list-item text-content translation-term">
                              {" "}
                              <EditableTitle
                                id={"term-" + trans.translationID}
                                type="dictionary-translation"
                                title={trans.term}
                                translationID={trans.translationID}
                                action={updateTranslationTerm}
                                isOpen={false}
                                isMultiline={true}
                                validation="term"
                              />
                            </div>
                            <div className="float-left list-item translation-text text-content">
                              {" "}
                              <EditableTitle
                                id={"translation-text-" + trans.translationID}
                                type="dictionary-translation"
                                translationText={trans.translationText}
                                translationID={trans.translationID}
                                action={updateTranslationText}
                                isOpen={false}
                                isMultiline={true}
                                validation="translationText"
                              />
                            </div>

                            <div className="float-right detele-icon">
                              <img
                                className="delete-translation-icon"
                                src={deleteIcon}
                                alt="Übersetzung löschen"
                                title="Übersetzung löschen"
                                onClick={() =>
                                  setPopup({
                                    isPopup: true,
                                    id: trans.translationID,
                                    method: "delete",
                                    type: "dictionary-translation",
                                    yesButtonText: "Ja ich bin mir sicher",
                                    noButtonText: "Nein, abbrechen",
                                    label:
                                      "Möchtest du diese Übersetzung wirklich löschen?",
                                  })
                                }
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default CreateEditTranslations;
