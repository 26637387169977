import { QuizAPI } from "./http-common";
import authHeader from "./authHeader";

const getQuizByCourseID = (id: string) => {
  return QuizAPI.get(`/Quiz/GetByCourseID/${id}`, {
    headers: authHeader() as any,
  });
};

const getQuizByID = (id: string) => {
  return QuizAPI.get(`/Quiz/${id}`, {
    headers: authHeader() as any,
  });
};

const createQuiz = (data: any) => {
  return QuizAPI.post("/Quiz", data, {
    headers: authHeader() as any,
  });
};

const updateQuiz = (id: string, data: any) => {
  return QuizAPI.put(`/Quiz/${id}`, data, {
    headers: authHeader() as any,
  });
};

const deleteQuiz = (id: string) => {
  return QuizAPI.delete(`/Quiz/${id}`, {
    headers: authHeader() as any,
  });
};

const QuizService = {
  getQuizByCourseID,
  getQuizByID,
  createQuiz,
  updateQuiz,
  deleteQuiz,
};

export default QuizService;
