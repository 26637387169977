import { useState } from "react";
import "./CreateEditLearningModule.css";

// Images:
import add from "assets/Icons/Sidebar/add-icon.png";

// Components:
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import EditableTitle from "components/EditableTitle/EditableTitle";
import ImageUploadPopup from "components/ImageUploadPopup/ImageUploadPopup";
import VideoUploadPopup from "components/VideoUploadPopup/VideoUploadPopup";

// Add props for all variables send by props
type CreateEditLearningModuleProps = {
  action?: any;
  learningModule?: any;
  learningModuleID?: string;
  setLearningModule?: any;
};

function CreateEditLearningModule(props: CreateEditLearningModuleProps) {
  //local states
  const [imageUploadPopup, setImageUploadPopup] = useState({
    id: "",
    type: "",
    label: "",
    existingAlt: "",
    existingImage: "",
    imageUploadPopup: false,
  });

  const [videoUploadPopup, setVideoUploadPopup] = useState({
    id: "",
    type: "",
    label: "",
    videoUploadPopup: false,
  });

  const { learningModule } = props;

  // handle the name input change:
  const handleInputChange = (name: string) => {
    props.setLearningModule({
      learningModule,
      name: name,
    });

    // check if we have learningModuleID so we send it also so the action is updated else created
    props.learningModuleID
      ? props.action(props.learningModuleID, { name })
      : props.action({ name });
  };

  // handle image change
  const handleImageChange = async (
    id: string,
    type: string,
    alt: string,
    image: any
  ) => {
    let name = learningModule.name;
    props.action(id, { type, name, alt, image });
  };

  // handle video change
  const handleVideoChange = async (
    type: string,
    title: string,
    videoName: string,
    extension: string,
    filePath: string,
    mimeType: string,
    vtt: string
  ) => {
    let name = props.learningModule.name;
    props.action(props.learningModuleID, {
      type,
      name,
      title,
      videoName,
      extension,
      filePath,
      mimeType,
      vtt,
    });
  };

  return (
    <>
      {imageUploadPopup.imageUploadPopup ? (
        <ImageUploadPopup
          closeImageUploadPopup={() =>
            setImageUploadPopup({
              imageUploadPopup: false,
              id: "",
              type: "",
              label: "",
              existingAlt: "",
              existingImage: "",
            })
          }
          id={props.learningModuleID!}
          type={imageUploadPopup.type}
          action={handleImageChange}
          label={imageUploadPopup.label}
          existingImage={imageUploadPopup.existingImage}
          existingAlt={imageUploadPopup.existingAlt}
        />
      ) : null}

      {videoUploadPopup.videoUploadPopup ? (
        <VideoUploadPopup
          closeVideoUploadPopup={() =>
            setVideoUploadPopup({
              videoUploadPopup: false,
              id: "",
              type: "",
              label: "",
            })
          }
          id={props.learningModuleID!}
          type={videoUploadPopup.type}
          action={handleVideoChange}
          label={videoUploadPopup.label}
          title={learningModule?.name}
          object={learningModule}
        />
      ) : null}

      <div className="learning-module-section">
        <div className="learning-module-icon-container">
          <label
            className="icon-input"
            htmlFor="icon-input"
            onClick={
              learningModule?.name !== undefined
                ? () =>
                    setImageUploadPopup({
                      type: "icon",
                      imageUploadPopup: true,
                      label: "Learning Module Icon",
                      id: props.learningModule!.learningModuleID,
                      existingImage: props.learningModule?.icon?.image,
                      existingAlt: props.learningModule?.icon?.alt,
                    })
                : () =>
                    setImageUploadPopup({
                      imageUploadPopup: false,
                      id: "",
                      type: "",
                      label: "",
                      existingAlt: "",
                      existingImage: "",
                    })
            }
            //add tabIndex and onKeyPress function
            tabIndex={0}
            onKeyPress={
              learningModule?.name !== undefined
                ? () =>
                    setImageUploadPopup({
                      type: "icon",
                      imageUploadPopup: true,
                      label: "Learning Module Icon",
                      id: props.learningModule!.learningModuleID,
                      existingImage: props.learningModule?.icon?.image,
                      existingAlt: props.learningModule?.icon?.alt,
                    })
                : () =>
                    setImageUploadPopup({
                      imageUploadPopup: false,
                      id: "",
                      type: "",
                      label: "",
                      existingAlt: "",
                      existingImage: "",
                    })
            }
          >
            <img
              style={{ backgroundColor: learningModule?.normal }}
              className={
                learningModule?.name !== undefined
                  ? "learning-module-top-icon"
                  : "learning-module-top-icon disabled"
              }
              src={
                learningModule?.icon?.image
                  ? "data:image/jpeg;base64," + learningModule?.icon?.image
                  : add
              }
              alt={
                learningModule?.icon?.alt ? learningModule?.icon?.alt : "add"
              }
              title="Lernmodul-Symbol"
            />
            {learningModule?.icon?.image ? (
              <p>Icon Editieren</p>
            ) : (
              <p>Icon hinzufügen</p>
            )}
          </label>
        </div>

        <div
          className="learning-module-top-name"
          style={{ color: learningModule?.normal }}
        >
          <h3>Lernmodul:</h3>
          {learningModule ? (
            <EditableTitle
              type="module"
              title={learningModule?.name}
              learningModuleID={props.learningModuleID}
              isOpen={false}
              action={handleInputChange}
              colorCode={learningModule?.normal}
            />
          ) : (
            <EditableTitle
              type="module"
              isOpen={true}
              action={handleInputChange}
            />
          )}
        </div>
      </div>

      <br />
      {learningModule?.name !== undefined && (
        <div className="learning-module-thumbnail-video-container">
          <div className="learning-module-thumbnail-container">
            <label
              className="thumbnail-input"
              htmlFor="thumbnail-input"
              onClick={
                learningModule?.name !== undefined
                  ? () =>
                      setImageUploadPopup({
                        type: "thumbnail",
                        imageUploadPopup: true,
                        label: "Learning Module Thumbnail",
                        id: learningModule.learningModuleID,
                        existingImage: learningModule?.thumbnail?.image,
                        existingAlt: learningModule?.thumbnail?.alt,
                      })
                  : () =>
                      setImageUploadPopup({
                        imageUploadPopup: false,
                        id: "",
                        type: "",
                        label: "",
                        existingAlt: "",
                        existingImage: "",
                      })
              }
              tabIndex={0}
              onKeyPress={
                learningModule?.name !== undefined
                  ? () =>
                      setImageUploadPopup({
                        type: "thumbnail",
                        imageUploadPopup: true,
                        label: "Learning Module Thumbnail",
                        id: learningModule.learningModuleID,
                        existingImage: learningModule?.thumbnail?.image,
                        existingAlt: learningModule?.thumbnail?.alt,
                      })
                  : () =>
                      setImageUploadPopup({
                        imageUploadPopup: false,
                        id: "",
                        type: "",
                        label: "",
                        existingAlt: "",
                        existingImage: "",
                      })
              }
            >
              <img
                className={
                  learningModule?.name !== undefined
                    ? "learning-module-top-thumbnail"
                    : "learning-module-top-thumbnail disabled"
                }
                src={
                  learningModule?.thumbnail?.image
                    ? "data:image/jpeg;base64," +
                      learningModule?.thumbnail?.image
                    : add
                }
                alt={learningModule?.thumbnail?.alt}
                title="Learnmodul-Miniaturansicht"
              />
              {learningModule?.thumbnail?.image ? (
                <p>Thumbnail Editieren</p>
              ) : (
                <p>Thumbnail hinzufügen</p>
              )}
            </label>
          </div>

          <div className="learning-module-video-container">
            {learningModule?.video ? (
              <>
                <VideoPlayer
                  filePath={learningModule.video.filePath}
                  mimeType={learningModule.video.mimeType}
                  vttFile={learningModule.vttFile}
                  type="learningModule"
                />
                <p
                  onClick={
                    learningModule?.name !== undefined
                      ? () =>
                          setVideoUploadPopup({
                            type: "video",
                            videoUploadPopup: true,
                            label: "Learning Module Video",
                            id: props.learningModule.learningModuleID,
                          })
                      : () =>
                          setVideoUploadPopup({
                            videoUploadPopup: false,
                            id: "",
                            type: "",
                            label: "",
                          })
                  }
                  className="edit-video"
                  style={{ backgroundColor: learningModule?.normal }}
                >
                  Video Editieren
                </p>
              </>
            ) : (
              <label
                className="video-input"
                htmlFor="video-input"
                onClick={
                  learningModule?.name !== undefined
                    ? () =>
                        setVideoUploadPopup({
                          type: "video",
                          videoUploadPopup: true,
                          label: "Learning Module Video",
                          id: learningModule.learningModuleID,
                        })
                    : () =>
                        setVideoUploadPopup({
                          videoUploadPopup: false,
                          id: "",
                          type: "",
                          label: "",
                        })
                }
                tabIndex={0}
                onKeyPress={
                  learningModule?.name !== undefined
                    ? () =>
                        setVideoUploadPopup({
                          type: "video",
                          videoUploadPopup: true,
                          label: "Learning Module Video",
                          id: learningModule.learningModuleID,
                        })
                    : () =>
                        setVideoUploadPopup({
                          videoUploadPopup: false,
                          id: "",
                          type: "",
                          label: "",
                        })
                }
              >
                <img
                  style={{ backgroundColor: learningModule?.normal }}
                  className={
                    learningModule?.name !== undefined
                      ? "learning-module-top-video"
                      : "learning-module-top-video disabled"
                  }
                  src={
                    learningModule?.video?.filePath
                      ? "data:image/jpeg;base64," +
                        learningModule?.thumbnail?.image
                      : add
                  }
                  alt={learningModule?.thumbnail?.alt}
                  title="Learnmodul-Video"
                />

                <p>Video hinzufügen</p>
              </label>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default CreateEditLearningModule;
