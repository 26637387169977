import React, { useState } from "react";
import "./ImageUploadPopup.css";
import { useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { UploadImageSchema } from "../../validations/editorValidations";

// Components:
import Loader from "../Loader/Loader";
import DefaultButton from "../DefaultButton/DefaultButton";

//Images
import Image from "../../assets/Images/default-image.png";

type ImageUploadPopupProps = {
  id: string;
  type: string;
  label: string;
  action: any;
  existingAlt: string;
  existingImage: string;
  closeImageUploadPopup: () => void;
};

export default function ImageUploadPopup(props: ImageUploadPopupProps) {
  //redux state
  const { loading } = useSelector((state: any) => state.LoadingReducer);

  //local state
  const [image, setImage] = useState({
    image: props.existingImage,
    alt: props.existingAlt,
  });
  const [ImageUploadPopup, setImageUploadPopup] = useState(true);
  const [baseImage, setBaseImage] = useState<any>();

  //input change handler
  const handleImageChange = async (event: any) => {
    const file = event.target.files[0];
    setImage((previousState) => {
      return {
        ...previousState,
        image: file,
      };
    });
    const base64 = await convertBase64(file);
    setBaseImage(base64);
  };

  // function that changes the password
  const UploadImage = (values: any, errors: any) => {
    props.action(props.id, props.type, values.alt, image.image);
    setImageUploadPopup(false);
    document.body.classList.remove("active-modal");
    document.body.style.overflowY = "visible";
  };

  const toggleModal = () => {
    setImageUploadPopup(!ImageUploadPopup);
  };

  // function to convert selected image to base64 to display it as preview:
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const cancelPopup = () => {
    closeModal();
    props.closeImageUploadPopup();
  };

  if (ImageUploadPopup) {
    document.body.classList.add("active-modal");
    document.body.style.overflowY = "hidden";
  } else {
    document.body.classList.remove("active-modal");
    document.body.style.overflowY = "visible";
  }

  const closeModal = () => {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = "";
    body.style.top = "";
    body.style.height = "";
    body.style.overflowY = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={image}
            validationSchema={UploadImageSchema}
            onSubmit={UploadImage}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form>
                    <div className="modal">
                      <div onClick={toggleModal} className="overlay"></div>
                      <div className="modal-content change-password">
                        <h2>Hochladen {props.label}:</h2>
                        <div className="form-input">
                          <label htmlFor="image" className="form-label">
                            <div>
                              {props.type === "icon"
                                ? "Icon"
                                : props.type === "thumbnail"
                                ? "Thumbnail"
                                : "Image"}
                              :<span className="required-star">*</span>
                            </div>

                            <img
                              className="image-preview"
                              src={
                                baseImage
                                  ? baseImage
                                  : props.existingImage
                                  ? "data:image/jpeg;base64," +
                                    props.existingImage
                                  : Image
                              }
                              alt="Bild hinzufügen"
                              title="Bild hinzufügen"
                            />
                            <div className="form-image">
                              <Field
                                id="image"
                                name="image"
                                type="file"
                                value=""
                                onChange={handleImageChange}
                              />
                              <div role="alert">
                                <ErrorMessage
                                  name="image"
                                  component="div"
                                  className="error-message"
                                />
                              </div>
                            </div>
                          </label>
                        </div>

                        <div className="form-input">
                          <label htmlFor="alt" className="form-label">
                            {props.type === "icon"
                              ? "Icon Alternative Text:"
                              : props.type === "thumbnail"
                              ? "Thumbnail Alternative Text:"
                              : "Alternativtext:"}
                            <span className="required-star">*</span>
                          </label>
                          <div className="form-field">
                            <Field
                              id="alt"
                              name="alt"
                              type="text"
                              placeholder={
                                props.type === "icon"
                                  ? "Icon Alternative Text:"
                                  : props.type === "thumbnail"
                                  ? "Thumbnail Alternative Text:"
                                  : ""
                              }
                            />
                            <div role="alert">
                              <ErrorMessage
                                name="alt"
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="required-star">(*): Erforderlich</div>
                        <div className="popup-upload-buttons">
                          <DefaultButton type="submit">Hochladen</DefaultButton>
                          <DefaultButton
                            onClick={cancelPopup}
                            className="cancel-reset-password"
                          >
                            Abbrechen
                          </DefaultButton>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
}
