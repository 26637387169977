import { GET_TRANSLATIONS } from "../actions/types";

const initialState = [] as any;


type TranslationReducerAction = {
  type: "GET_TRANSLATIONS" ;
  payload: string;
};

function TranslationReducer(users=initialState, action: TranslationReducerAction) {
  const { type, payload } = action;

  switch (type) {
    case GET_TRANSLATIONS:
      return payload;

    default:
      return users;
  }
}

export default TranslationReducer;
