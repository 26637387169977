import { EditorState } from "draft-js";
import "./CreateEditCourseChapterSlide.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// services
import QuizService from "services/quizService";
import SlideService from "services/slideService";
import CourseService from "services/courseService";
import AnswerService from "services/answerService";
import ChapterService from "services/chapterService";
import QuestionService from "services/questionService";

// actions
import { setMessage } from "store/actions/messageAction";
import { getLearningModules } from "store/actions/learningModuleAction";
import { removeLoading, setLoading } from "store/actions/loadingAction";

//components
import Loader from "components/Loader/Loader";
import CourseSidebar from "./CourseSidebar/CourseSidebar";
import CourseLocation from "./CourseLocation/CourseLocation";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import DefaultPopup from "components/DefaultPopup/DefaultPopup";
import SlideTypePopup from "components/SlideTypePopup/SlideTypePopup";
import ChangeCourseStatus from "./ChangeCourseStatus/ChangeCourseStatus";
import CourseRightSideBar from "./CourseRightSideBar/CourseRightSideBar";
// import AnswerTypePopup from "components/AnswerTypePopup/AnswerTypePopup";
import ImageUploadPopup from "components/ImageUploadPopup/ImageUploadPopup";
import VideoUploadPopup from "components/VideoUploadPopup/VideoUploadPopup";
import SlideImageVideoUpload from "./SlideImageVideoUpload/SlideImageVideoUpload";
import MessageNotification from "components/MessageNotification/MessageNotification";
import CreateEditCourseChapter from "./CreateEditCourseChapter/CreateEditCourseChapter";
import SlideTextImageContainer from "./SlideTextImageContainer/SlideTextImageContainer";
import TranslationLinkPopup from "components/TranslationLinkPopup/TranslationLinkPopup";
import CreateEditQuestionAnswer from "./CreateEditQuestionAnswer/CreateEditQuestionAnswer";

// icons
import leftArrow from "assets/Icons/button_arrow-left.png";
import deleteIcon from "assets/Icons/delete-course-icon.png";
import rightArrow from "assets/Icons/button_arrow-right.png";

function CreateEditCourseChapterSlide() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // get the learningModuleID from the url parameter
  let params: any = useParams();
  const { learningModuleID, subjectID, courseID } = params;

  // needed to kill any call after return in useEffect
  const abortController = new AbortController();
  const signal = abortController.signal;

  //course add or update mode
  const isAddMode = !courseID;

  // redux states
  const learningModules = useSelector(
    (state: any) => state.LearningModuleReducer
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  // local states
  // main course state
  const [course, setCourse] = useState<any>();

  // state for list of chapters of the course
  const [chapters, setChapters] = useState<any>();

  // selected chapter state
  const [chapter, setChapter] = useState<any>();

  // chapterID state to know the selected chapter
  const [chapterID, setChapterID] = useState<any>("");

  // slides state for all slide for a selected chapter
  const [slides, setSlides] = useState<any>();

  // selected slide state
  const [slide, setSlide] = useState<any>();

  // state used to send it while saving translation text to also save the slide texts
  const [newFields, setNewFields] = useState<any>();

  // state we use it in the text content editor
  const [slideNormalEasyTexts, setSlideNormalEasyTexts] = useState<any>({
    textNormalLanguage: "",
    textEasyLanguage: "",
  });

  // state to know if the view is now for the normal or easy language
  const [isNormalLang, setNormalLang] = useState<any>(true);

  // slideID state to know the selected slide ID
  const [slideID, setSlideID] = useState<any>();

  //chapterIndex state to get the index of chapters
  const [chapterIndex, setChapterIndex] = useState(0);

  //slideIndex state to get the index of slides in each chapter
  const [slideIndex, setSlideIndex] = useState(0);

  // state for the slide type popup
  const [isSlideTypePopup, setSlideTypePopup] = useState({
    isOpen: false,
    type: "",
  });

  // state for the quiz on the course
  const [quiz, setQuiz] = useState<any>();

  // state for the quiz container on the course
  const [isQuizOpen, setQuizOpen] = useState(false);

  // questions for a specific quiz
  const [questions, setQuestions] = useState<any>();

  // the selected question
  const [question, setQuestion] = useState<any>();

  // the questionID of the selected question
  const [questionID, setQuestionID] = useState<any>();

  // answers for the question
  const [answers, setAnswers] = useState<any>();

  // state to know the selected word from the slide text
  const [selectedTerm, setSelectedTerm] = useState<any>("");

  // state to know the new link to be replaced in the slide text string to make it link
  const [selectedTermLink, setSelectedTermLink] = useState<any>("");

  // state to know the selected term tag name
  const [selectedTermTag, setSelectedTermTag] = useState<any>("");

  // state to know the selected term parent tag name
  const [selectedTermParentNode, setSelectedTermParentNode] = useState<any>("");

  // state for the slide type popup
  const [isAnswerTypePopup, setAnswerTypePopup] = useState({
    isOpen: false,
    type: "",
  });

  // state for the general popup
  const [isPopup, setPopup] = useState({
    isPopup: false,
    id: "",
    type: "",
    method: "",
    label: "",
    yesButtonText: "",
    noButtonText: "",
  });

  // state for the translation link popup
  const [translationLinkPopup, setTranslationLinkPopup] = useState({
    isPopup: false,
    label: "",
    term: "",
  });

  // state for the image uploader popup
  const [imageUploadPopup, setImageUploadPopup] = useState({
    id: "",
    type: "",
    label: "",
    existingAlt: "",
    existingImage: "",
    imageUploadPopup: false,
  });

  // state for the video uploader popup
  const [videoUploadPopup, setVideoUploadPopup] = useState({
    id: "",
    type: "",
    label: "",
    videoUploadPopup: false,
  });

  // get course function by ID:
  const GetCourseByID = (courseID: string, signal: any) => {
    dispatch(setLoading());
    // API call
    CourseService.getCourseByID(courseID)
      .then(
        (response: any) => {
          setCourse(response.data);
          setChapters(response.data.chapters);
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log(err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // create course function:
  const createCourse = (course: any) => {
    const { name } = course;
    dispatch(setLoading());
    // create data as specific typescript data type that support images in it:
    let data = new FormData();
    data.append("Title", name);
    data.append("SubjectID", subjectID);
    // here we call the API directly without redux cz i need the returned object
    //  and it returns undefined from action response
    CourseService.createCourse(data).then(
      (res: any) => {
        // get the learning module to update redux after creating new one
        dispatch(getLearningModules());
        setCourse(res.data);
        dispatch(setMessage("Kurs erfolgreich erstellt", "success"));
        navigate(
          `/create-edit-course-chapter-slide/${learningModuleID}/${subjectID}/${res.data.courseID}/`
        );
        dispatch(removeLoading());
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  };

  // Update Course function:
  const updateCourse = (course: any) => {
    const { type, name, alt, image } = course;
    let data = new FormData();
    if (type === "icon") {
      data.append("Title", name);
      data.append("SubjectID", subjectID);
      data.append("Icon.Title", alt);
      data.append("Icon.Name", alt);
      data.append("Icon.Alt", alt);
      data.append("Icon.Image", image);
    } else {
      data.append("Title", name);
      data.append("SubjectID", subjectID);
    }
    dispatch(setLoading());
    // API call using redux dispatch
    CourseService.updateCourse(courseID, data)
      .then(
        (res: any) => {
          dispatch(setMessage(" Kurs erfolgreich editiert", "success"));
          // get learning module by ID
          courseID && GetCourseByID(courseID, { signal: signal });
          setImageUploadPopup({
            imageUploadPopup: false,
            id: "",
            type: "",
            label: "",
            existingAlt: "",
            existingImage: "",
          });
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // change course status (inEdit - readyToBePublished - published) function:
  const changeCourseStatus = (courseStatus: string) => {
    dispatch(setLoading());
    CourseService.changeCourseStatus(courseID, { courseStatus })
      .then(
        (res: any) => {
          // get the learning module to update redux after creating new one
          dispatch(getLearningModules());
          dispatch(setMessage("Kursstatus erfolgreich editiert", "success"));
          courseID && GetCourseByID(courseID, { signal: signal });
          setPopup({
            isPopup: false,
            id: "",
            type: "",
            method: "",
            label: "",
            yesButtonText: "",
            noButtonText: "",
          });
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // create course function:
  const deleteCourse = (courseID: string) => {
    dispatch(setLoading());
    CourseService.deleteCourse(courseID)
      .then(
        (res: any) => {
          // get the learning module to update redux after creating new one
          dispatch(getLearningModules());
          navigate(`/create-edit-module-subject/${learningModuleID}`);
          dispatch(setMessage("Kurs erfolgreich gelöscht", "success"));
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // create Chapter function:
  const createChapter = (data: any) => {
    const { newTitle, order } = data;
    let formData = new FormData();
    formData.append("Title", newTitle);
    formData.append("Order", order);
    formData.append("courseID", courseID);
    dispatch(setLoading());
    // API call
    ChapterService.createChapter(formData)
      .then(
        (response: any) => {
          dispatch(setMessage("Kapitel erfolgreich erstellt", "success"));
          GetCourseByID(courseID, { signal: signal });
          setSlide(undefined);
          setSlideID("");
          setSlides([]);
          setChapterID(response.data.chapterID);
          setChapter(response.data);
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(setMessage(err.message, "error"));
        dispatch(removeLoading());
      });
  };

  // Update Chapter function:
  const updateChapter = (data: any) => {
    const { chapterID, newTitle, order } = data;
    let formData = new FormData();
    formData.append("Title", newTitle);
    formData.append("Order", order);
    formData.append("courseID", courseID);
    dispatch(setLoading());
    // API call
    ChapterService.updateChapter(chapterID, formData)
      .then(
        (response: any) => {
          setChapter(response.data);
          dispatch(setMessage("Kapitel erfolgreich editiert", "success"));
          GetCourseByID(courseID, { signal: signal });
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // delete Chapter function:
  const deleteChapter = (data: any) => {
    dispatch(setLoading());
    // API call
    ChapterService.deleteChapter(chapterID)
      .then(
        (response: any) => {
          dispatch(setMessage("Kapitel erfolgreich gelöscht", "success"));
          GetCourseByID(courseID, { signal: signal });
          setChapters(response.data.chapters);
          setChapter("");
          setChapterID("");
          setSlide(undefined);
          setSlides([]);
          setSlideID("");
          setChapterIndex(0);
          setSlideIndex(0);
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // function to update the chapters order via drag and drop
  const updateChapterOrder = (values: any) => {
    let newChapters: any = [];
    values.map((val: any) => {
      return newChapters?.push({ entityID: val.chapterID, order: val.order });
    });
    ChapterService.updateChapterOrder(newChapters)
      .then(
        (res) => {
          dispatch(
            setMessage(
              "Die Reihenfolge der Kapitel wurde erfolgreich aktualisiert",
              "success"
            )
          );
          GetCourseByID(courseID, { signal: signal });
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // get slides by chapter ID
  const getSlidesByChapterID = (ChapterID: string, nav: any) => {
    dispatch(setLoading());
    // API Call
    SlideService.getSlideByChapterID(ChapterID)
      .then((res: any) => {
        setSlides(res.data);
        if (nav === "prev") {
          setSlideIndex(res.data?.length - 1);
          setSlide(res.data[res.data?.length - 1]);
          setSlideNormalEasyTexts({
            textNormalLanguage:
              res.data[res.data?.length - 1]?.textNormalLanguage,
            textEasyLanguage: res.data[res.data?.length - 1]?.textEasyLanguage,
          });
          setSlideID(res.data[res.data.length - 1].slideID);
        } else if (nav === "next") {
          setSlide(res.data[0]);
          setSlideNormalEasyTexts({
            textNormalLanguage: res.data[0]?.textNormalLanguage,
            textEasyLanguage: res.data[0]?.textEasyLanguage,
          });
          setSlideID(res.data[0].slideID);
        }
        dispatch(removeLoading());
      })
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
      });
  };

  // create Slide function:
  const createSlide = (data: any) => {
    const {
      chapterID,
      order,
      textNormalLanguage,
      textEasyLanguage,
      type,
      alt,
      image,
      title,
      videoName,
      extension,
      filePath,
      mimeType,
      vtt,
    } = data;
    let formData = new FormData();
    // image
    if (type === "image") {
      order
        ? formData.append("Order", slides.length + 1)
        : formData.append("Order", slides.length + 1);
      formData.append("ChapterID", chapterID);
      formData.append("Image.Title", alt);
      formData.append("Image.Name", alt);
      formData.append("Image.Alt", alt);
      formData.append("Image.Image", image);

      textNormalLanguage && typeof textNormalLanguage !== "object"
        ? formData.append("TextNormalLanguage", textNormalLanguage)
        : formData.append("TextNormalLanguage", "<p></p>\n");

      textEasyLanguage
        ? formData.append("TextEasyLanguage", textEasyLanguage)
        : formData.append("TextEasyLanguage", "");
    }
    // video
    else if (type === "video") {
      order
        ? formData.append("Order", slides.length + 1)
        : formData.append("Order", slides.length + 1);
      formData.append("ChapterID", chapterID);
      formData.append("VideoInputModel.Title", title);
      formData.append("VideoInputModel.Name", videoName);
      formData.append("VideoInputModel.MimeType", mimeType);
      formData.append("VideoInputModel.Extension", extension);
      formData.append("VideoInputModel.FilePath", filePath);
      // vtt
      formData.append("VideoInputModel.VttFile", vtt);
      textNormalLanguage && typeof textNormalLanguage !== "object"
        ? formData.append("TextNormalLanguage", textNormalLanguage)
        : formData.append("TextNormalLanguage", "<p></p>\n");

      textEasyLanguage
        ? formData.append("TextEasyLanguage", textEasyLanguage)
        : formData.append("TextEasyLanguage", "");
    }
    // text
    else {
      order
        ? formData.append("Order", slides.length + 1)
        : formData.append("Order", slides.length + 1);
      formData.append("ChapterID", chapter?.chapterID);

      textNormalLanguage && typeof textNormalLanguage !== "object"
        ? formData.append("TextNormalLanguage", textNormalLanguage)
        : formData.append("TextNormalLanguage", "<p></p>\n");

      textEasyLanguage
        ? formData.append("TextEasyLanguage", textEasyLanguage)
        : formData.append("TextEasyLanguage", "");
    }
    dispatch(setLoading());
    // API call
    SlideService.createSlide(formData)
      .then(
        (response: any) => {
          dispatch(setMessage("Slide erfolgreich erstellt", "success"));
          GetCourseByID(courseID, { signal: signal });
          getSlidesByChapterID(chapter.chapterID, "");
          setImageUploadPopup({
            imageUploadPopup: false,
            id: "",
            type: "",
            label: "",
            existingAlt: "",
            existingImage: "",
          });
          setVideoUploadPopup({
            videoUploadPopup: false,
            id: "",
            type: "",
            label: "",
          });
          setSlideID(response.data.slideID);
          setSlide(response.data);
          setChapterID(chapter.chapterID);
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // Update Slide function:
  const updateSlide = (data: any) => {
    const {
      chapterID,
      textNormalLanguage,
      textEasyLanguage,
      type,
      alt,
      image,
      title,
      videoName,
      extension,
      filePath,
      mimeType,
      vtt,
    } = data;
    let formData = new FormData();
    // image
    if (type === "image") {
      formData.append("ChapterID", chapterID);
      formData.append("Image.Title", alt);
      formData.append("Image.Name", alt);
      formData.append("Image.Alt", alt);
      formData.append("Image.Image", image);

      textNormalLanguage
        ? formData.append("TextNormalLanguage", textNormalLanguage)
        : formData.append("TextNormalLanguage", "");

      textEasyLanguage
        ? formData.append("TextEasyLanguage", textEasyLanguage)
        : formData.append("TextEasyLanguage", "");
    }
    // video
    else if (type === "video") {
      formData.append("ChapterID", chapterID);
      formData.append("VideoInputModel.Title", title);
      formData.append("VideoInputModel.Name", videoName);
      formData.append("VideoInputModel.MimeType", mimeType);
      formData.append("VideoInputModel.Extension", extension);
      formData.append("VideoInputModel.FilePath", filePath);
      // vtt
      formData.append("VideoInputModel.VttFile", vtt);
      textNormalLanguage
        ? formData.append("TextNormalLanguage", textNormalLanguage)
        : formData.append("TextNormalLanguage", "");

      textEasyLanguage
        ? formData.append("TextEasyLanguage", textEasyLanguage)
        : formData.append("TextEasyLanguage", "");
    } else {
      formData.append("ChapterID", chapter?.chapterID);
      textNormalLanguage
        ? formData.append("TextNormalLanguage", textNormalLanguage)
        : formData.append("TextNormalLanguage", "");
      textEasyLanguage
        ? formData.append("TextEasyLanguage", textEasyLanguage)
        : formData.append("TextEasyLanguage", "");
    }
    dispatch(setLoading());
    // API call
    SlideService.updateSlide(slideID, formData)
      .then(
        (response: any) => {
          dispatch(setMessage("Slide erfolgreich editiert", "success"));
          getSlidesByChapterID(chapter.chapterID, "");
          setImageUploadPopup({
            imageUploadPopup: false,
            id: "",
            type: "",
            label: "",
            existingAlt: "",
            existingImage: "",
          });
          setVideoUploadPopup({
            videoUploadPopup: false,
            id: "",
            type: "",
            label: "",
          });
          setSlideID(slideID);
          setSlide(response.data);
          setChapterID(chapter.chapterID);
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // function to update the slides order, used in the drag and drop function
  const updateSlideOrder = (values: any) => {
    let newSlides: any = [];
    values.map((val: any) => {
      return newSlides?.push({ entityID: val.slideID, order: val.order });
    });
    SlideService.updateSlideOrder(newSlides)
      .then(
        (res) => {
          dispatch(
            setMessage(
              "Die Reihenfolge der Slides wurde erfolgreich aktualisiert",
              "success"
            )
          );
          getSlidesByChapterID(chapter.chapterID, "");
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // delete Slide function:
  const deleteSlide = (slideID: any) => {
    dispatch(setLoading());
    SlideService.deleteSlide(slideID)
      .then(
        (res: any) => {
          // get the learning module to update redux after creating new one
          dispatch(getLearningModules());
          dispatch(setMessage("Slide erfolgreich gelöscht", "success"));
          setChapterID(chapter.chapterID);
          getSlidesByChapterID(chapter.chapterID, "");
          setPopup({
            isPopup: false,
            id: "",
            type: "",
            method: "",
            label: "",
            yesButtonText: "",
            noButtonText: "",
          });
          if (slideIndex - 1 !== -1) {
            setSlideIndex(slideIndex - 1);
            setSlide(slides[slideIndex - 1]);
            setSlideID(slides[slideIndex - 1].slideID);
          } else {
            setSlide(undefined);
            setSlideID("");
          }
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // onSubmit general function that checks if the form is created or edited and gives the correct function:
  function onSubmit(fields: any) {
    if (!slideID) {
      createSlide(fields);
    } else {
      updateSlide(fields);
    }
  }

  // get quiz function by ID:
  const GetQuizByCourseID = (courseID: string, signal: any) => {
    dispatch(setLoading());
    // API call
    QuizService.getQuizByCourseID(courseID)
      .then(
        (response: any) => {
          setQuiz(response.data);
          setQuestions(response.data.questions);
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          if (message !== "Request fehlgeschlagen. Statuscode 404") {
            dispatch(setMessage(message, "error"));
          }
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log(err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // create quiz function:
  const createQuiz = (data: any) => {
    const { courseID, newTitle } = data;
    let formData = new FormData();
    formData.append("courseID", courseID);
    formData.append("title", newTitle);
    dispatch(setLoading());
    // API call
    QuizService.createQuiz(formData)
      .then(
        (response: any) => {
          dispatch(setMessage("Quiz erfolgreich erstellt", "success"));
          GetCourseByID(courseID, { signal: signal });
          courseID && GetQuizByCourseID(courseID, { signal: signal });
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // Update Quiz function:
  const updateQuiz = (data: any) => {
    const { quizID, courseID, newTitle } = data;
    let formData = new FormData();
    formData.append("courseID", courseID);
    formData.append("title", newTitle);
    dispatch(setLoading());
    // API call
    QuizService.updateQuiz(quizID, formData)
      .then(
        (response: any) => {
          dispatch(setMessage("Quiz erfolgreich editiert", "success"));
          GetCourseByID(courseID, { signal: signal });
          courseID && GetQuizByCourseID(courseID, { signal: signal });
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // delete quiz function:
  const deleteQuiz = (quizID: any) => {
    dispatch(setLoading());
    QuizService.deleteQuiz(quizID)
      .then(
        (res: any) => {
          // get the learning module to update redux after creating new one
          dispatch(getLearningModules());
          GetCourseByID(courseID, { signal: signal });
          courseID && GetQuizByCourseID(courseID, { signal: signal });
          dispatch(setMessage("Quiz erfolgreich gelöscht", "success"));
          setQuiz("");
          setQuestionID("");
          setQuestions("");
          setAnswers("");
          setQuizOpen(false);
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log(err);
        dispatch(removeLoading());
      });
  };

  // create question function:
  const createQuestion = (data: any) => {
    const { newTitle } = data;
    let formData = new FormData();
    formData.append("quizID", quiz.quizID);
    formData.append("text", newTitle);
    formData.append("order", questions?.length + 1);
    dispatch(setLoading());
    // API call
    QuestionService.createQuestion(formData)
      .then(
        (response: any) => {
          dispatch(setMessage("Frage erfolgreich erstellt", "success"));
          setQuestion(response.data);
          setQuestionID(response.data.questionID);
          GetCourseByID(courseID, { signal: signal });
          courseID && GetQuizByCourseID(courseID, { signal: signal });
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // Update Question function:
  const updateQuestion = (data: any) => {
    const { questionID, newTitle } = data;
    let formData = new FormData();
    formData.append("quizID", quiz.quizID);
    formData.append("text", newTitle);
    dispatch(setLoading());
    // API call
    QuestionService.updateQuestion(questionID, formData)
      .then(
        (response: any) => {
          dispatch(setMessage("Frage erfolgreich editiert", "success"));
          setQuestion(response.data);
          GetQuizByCourseID(courseID, { signal: signal });
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // function that update the question order via drag and drop
  const updateQuestionsOrder = (values: any) => {
    let newQuestions: any = [];
    values.map((val: any) => {
      return newQuestions?.push({
        entityID: val.questionID,
        order: val.order,
      });
    });
    // API call
    QuestionService.updateQuestionOrder(newQuestions)
      .then(
        (res: any) => {
          dispatch(
            setMessage(
              "Die Reihenfolge der Fragen wurde erfolgreich aktualisiert",
              "success"
            )
          );
          GetCourseByID(courseID, { signal: signal });
          courseID && GetQuizByCourseID(courseID, { signal: signal });
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // get answers function by question ID:
  const GetAnswersByQuestionID = (questionID: string) => {
    dispatch(setLoading());
    // API call
    AnswerService.GetByQuestionID(questionID, "")
      .then(
        (response: any) => {
          setAnswers(response.data);
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          if (message !== "Request fehlgeschlagen. Statuscode 404") {
            dispatch(setMessage(message, "error"));
          }
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log(err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // create answer function:
  const createAnswer = (data: any) => {
    const { type, newTitle, alt, image } = data;
    let formData = new FormData();
    // answer with image
    if (type === "image") {
      formData.append("questionID", question.questionID);
      formData.append("image.Title", alt);
      formData.append("image.Name", alt);
      formData.append("image.Alt", alt);
      formData.append("image.Image", image);
      formData.append("order", answers.length + 1);
      formData.append("IsRight", "true");
    }
    // answer with only text
    else {
      formData.append("questionID", question.questionID);
      formData.append("text", newTitle);
      formData.append("order", answers.length + 1);
      formData.append("IsRight", "true");
    }
    dispatch(setLoading());
    // API call
    AnswerService.createAnswer(formData)
      .then(
        (response: any) => {
          dispatch(setMessage("Antwort erfolgreich erstellt", "success"));
          GetAnswersByQuestionID(question.questionID);
          GetCourseByID(courseID, { signal: signal });
          courseID && GetQuizByCourseID(courseID, { signal: signal });
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  // Update answer function:
  const updateAnswer = (data: any) => {
    const { type, answerID, newTitle, text, isRight, alt, image } = data;
    let formData = new FormData();
    // answer with image
    if (type === "image") {
      formData.append("questionID", question.questionID);
      formData.append("image.Title", alt);
      formData.append("image.Name", alt);
      formData.append("image.Alt", alt);
      formData.append("image.Image", image);
      formData.append("IsRight", isRight ? "true" : "false");
    }
    // answer with only text
    else {
      formData.append("questionID", question.questionID);
      newTitle && formData.append("text", newTitle);
      text && formData.append("text", text);
      formData.append("IsRight", isRight ? "true" : "false");
    }
    dispatch(setLoading());
    // API call
    AnswerService.updateAnswer(answerID, formData)
      .then(
        (response: any) => {
          dispatch(setMessage("Antwort erfolgreich editiert", "success"));
          // setQuestion(response.data);
          GetAnswersByQuestionID(question.questionID);
          GetQuizByCourseID(courseID, { signal: signal });
          dispatch(removeLoading());
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((err: any) => {
        console.log("err", err);
        dispatch(removeLoading());
        dispatch(setMessage(err.message, "error"));
      });
  };

  //set the title page
  useEffect(() => {
    document.title = "Armaturenbrett -ILLUME ";
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    // get course by ID
    courseID && GetCourseByID(courseID, { signal: signal });
    // get quiz by course ID
    courseID && GetQuizByCourseID(courseID, { signal: signal });
    if (learningModules?.length === 0) {
      dispatch(getLearningModules());
    }
    return function cleanup() {
      abortController.abort();
      // empty local states
      setCourse("");
      setChapters("");
    };
    // eslint-disable-next-line
  }, [courseID]);

  useEffect(() => {
    dispatch(getLearningModules());
    // eslint-disable-next-line
  }, []);

  // handle the name input change:
  const handleInputCourseChange = (name: string) => {
    setCourse({
      ...course,
      name: name,
    });
    // check if we have courseID so we send it also so the action is updated else created
    isAddMode ? createCourse({ name }) : updateCourse({ name });
  };

  // handle course icon change
  const handleCourseIconChange = (
    id: string,
    type: string,
    alt: string,
    image: any
  ) => {
    let name = course.title;
    updateCourse({ type, name, alt, image });
  };

  // handle the chapter name input change:
  const handleInputChapterChange = (data: any) => {
    const { chapterID } = data;
    // check if we have chapterID so we send it also so the action is updated else created
    !chapterID ? createChapter(data) : updateChapter(data);
  };

  // handle slide image change
  const handleSlideImageChange = (
    id: string,
    type: string,
    alt: string,
    image: any
  ) => {
    let order = 1;
    const { textNormalLanguage, textEasyLanguage } = slideNormalEasyTexts;
    setSlide((previousState: any) => {
      return {
        ...previousState,
        image: {
          image: image,
          alt: alt,
          title: alt,
          name: alt,
        },
      };
    });
    !slideID &&
      createSlide({
        chapterID,
        order,
        type,
        alt,
        image,
        textNormalLanguage,
        textEasyLanguage,
      });
    slideID &&
      updateSlide({
        slideID,
        chapterID,
        type,
        alt,
        image,
        textNormalLanguage,
        textEasyLanguage,
      });
  };

  // handle video change
  const handleVideoChange = async (
    type: string,
    title: string,
    videoName: string,
    extension: any,
    filePath: any,
    mimeType: any,
    vtt: any
  ) => {
    // let chapterID = chapter.chapterID;
    let order = 1;
    !slideID &&
      createSlide({
        chapterID,
        order,
        type,
        title,
        videoName,
        extension,
        filePath,
        mimeType,
        vtt,
      });
    slideID &&
      updateSlide({
        slideID,
        chapterID,
        type,
        title,
        videoName,
        extension,
        filePath,
        mimeType,
        vtt,
      });
  };

  // function called when we click in the sidebar on new slide
  const newSlide = (data: any) => {
    const { isOpen, type } = data;
    setSlideTypePopup({
      isOpen: isOpen,
      type: type,
    });
    setSlideID("");
    setSlide({
      chapterID: chapterID,
      order: slides.length + 1,
      captionNormalLanguage: "",
      captionEasyLanguage: "",
      textNormalLanguage: EditorState.createEmpty(),
      textEasyLanguage: EditorState.createEmpty(),
      video: {
        filePath: "",
        name: "",
        title: "",
        extension: "",
        mimeType: "",
        vttFile: "",
      },
      audio: {
        filePath: "",
        name: "",
        title: "",
        extension: "",
        mimeType: "",
        vttFile: "",
      },
      image: {
        name: "",
        alt: "",
        title: "",
        image: "",
      },
    });
  };


  // handle the quiz name input change:
  const handleInputQuizChange = (data: any) => {
    const { quizID } = data;
    // check if we have quizID so we send it also so the action is updated else created
    !quizID ? createQuiz(data) : updateQuiz(data);
  };

  // handle the quiz question text input change:
  const handleInputQuestionTextChange = (data: any) => {
    const { questionID } = data;
    questionID ? updateQuestion(data) : createQuestion(data);
  };

  // handle the quiz answer text input change:
  const handleInputAnswerTextChange = (data: any) => {
    const { answerID } = data;
    answerID ? updateAnswer(data) : createAnswer(data);
  };

  // handle answer image change
  const handleAnswerImageChange = (data: any) => {
    let type = "image";
    const { answerID, alt, image } = data;
    answerID
      ? updateAnswer({ answerID, type, alt, image })
      : createAnswer({ type, alt, image });
  };

  //function that gets the previous slides
  const handlePrevSlide = () => {
    if (slideIndex > 0) {
      setSlideIndex(slideIndex - 1);
      setSlide(slides[slideIndex - 1]);
      setSlideNormalEasyTexts({
        textNormalLanguage: slides[slideIndex - 1]?.textNormalLanguage
          ? slides[slideIndex - 1].textNormalLanguage
          : null,
        textEasyLanguage: slides[slideIndex - 1]?.textEasyLanguage
          ? slides[slideIndex - 1]?.textEasyLanguage
          : null,
      });
      setSlideID(slides[slideIndex - 1]?.slideID);
    } else if (chapterIndex > 0) {
      setSlideID(slides[slides?.length - 1]?.slideID);
      setChapterIndex(chapterIndex - 1);
      chapters &&
        getSlidesByChapterID(chapters[chapterIndex - 1].chapterID, "prev");
      setChapter(chapters[chapterIndex - 1]);
      setChapterID(chapters[chapterIndex - 1]?.chapterID);
    }
  };

  //function that gets the next slides
  const handleNextSlide = () => {
    if (slideIndex < slides?.length - 1) {
      setSlideIndex(slideIndex + 1);
      setSlide(slides[slideIndex + 1]);
      setSlideNormalEasyTexts({
        textNormalLanguage: slides[slideIndex + 1]?.textNormalLanguage
          ? slides[slideIndex + 1].textNormalLanguage
          : null,
        textEasyLanguage: slides[slideIndex + 1]?.textEasyLanguage
          ? slides[slideIndex + 1]?.textEasyLanguage
          : null,
      });
      setSlideID(slides[slideIndex + 1]?.slideID);
    } else if (chapterIndex < chapters?.length - 1) {
      setChapterIndex(chapterIndex + 1);
      chapters &&
        getSlidesByChapterID(chapters[chapterIndex + 1]?.chapterID, "next");
      setSlideIndex(0);
      setChapter(chapters[chapterIndex + 1]);
      setChapterID(chapters[chapterIndex + 1]?.chapterID);
    }
  };

  return (
    <>
      {imageUploadPopup.imageUploadPopup ? (
        <ImageUploadPopup
          closeImageUploadPopup={() =>
            setImageUploadPopup({
              imageUploadPopup: false,
              id: "",
              type: "",
              label: "",
              existingAlt: "",
              existingImage: "",
            })
          }
          id={imageUploadPopup.id}
          type={imageUploadPopup.type}
          action={
            imageUploadPopup.type === "icon"
              ? handleCourseIconChange
              : handleSlideImageChange
          }
          label={imageUploadPopup.label}
          existingAlt={imageUploadPopup.existingAlt}
          existingImage={imageUploadPopup.existingImage}
        />
      ) : null}

      {videoUploadPopup.videoUploadPopup ? (
        <VideoUploadPopup
          closeVideoUploadPopup={() =>
            setVideoUploadPopup({
              videoUploadPopup: false,
              id: "",
              type: "",
              label: "",
            })
          }
          id={slideID}
          type={videoUploadPopup.type}
          action={handleVideoChange}
          label={videoUploadPopup.label}
          title={chapter?.title}
          object={slide}
        />
      ) : null}

      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {isPopup.isPopup && (
        <DefaultPopup
          label={isPopup.label}
          method={isPopup.method}
          yesButtonText={isPopup.yesButtonText}
          noButtonText={isPopup.noButtonText}
          action={
            isPopup.method === "delete" && isPopup.type === "course"
              ? () => deleteCourse(isPopup.id)
              : isPopup.method === "delete" && isPopup.type === "chapter"
              ? () => deleteChapter(isPopup.id)
              : isPopup.method === "delete" && isPopup.type === "slide"
              ? () => deleteSlide(isPopup.id)
              : isPopup.method === "delete" && isPopup.type === "quiz"
              ? () => deleteQuiz(isPopup.id)
              : isPopup.method === "readyToBePublished"
              ? () => changeCourseStatus("ReadyToBePublished")
              : () => changeCourseStatus("Published")
          }
          closePopup={() =>
            setPopup({
              isPopup: false,
              id: "",
              type: "",
              method: "",
              label: "",
              yesButtonText: "",
              noButtonText: "",
            })
          }
        />
      )}

      {isSlideTypePopup.isOpen && (
        <SlideTypePopup
          type={isSlideTypePopup.type}
          action={newSlide}
          closePopup={() =>
            setSlideTypePopup({
              isOpen: false,
              type: "",
            })
          }
        />
      )}

      {translationLinkPopup.isPopup && (
        <TranslationLinkPopup
          label={translationLinkPopup.label}
          selectedTerm={selectedTerm}
          setSelectedTermLink={setSelectedTermLink}
          setSelectedTerm={setSelectedTerm}
          selectedTermLink={selectedTermLink}
          selectedTermTag={selectedTermTag}
          selectedTermParentNode={selectedTermParentNode}
          closeTranslationLinkPopup={() =>
            setTranslationLinkPopup({
              isPopup: false,
              term: "",
              label: "",
            })
          }
          onSubmit={onSubmit}
          newFields={newFields}
          slideNormalEasyTexts={slideNormalEasyTexts}
        />
      )}

      {loading ? (
        <Loader />
      ) : (
        <div className="create-edit-course-chapter-slide-container">
          {/* Course Sidebar */}
          <div className="create-edit-course-chapter-slide-sidebar">
            <CourseSidebar
              chapters={chapters}
              courseID={courseID}
              setImageUploadPopup={setImageUploadPopup}
              chapterAction={handleInputChapterChange}
              quizAction={handleInputQuizChange}
              course={course}
              quiz={quiz}
              isQuizOpen={isQuizOpen}
              setQuizOpen={setQuizOpen}
              setSlide={setSlide}
              getSlidesByChapterID={getSlidesByChapterID}
              slides={slides}
              setSlideID={setSlideID}
              slideID={slideID}
              setChapter={setChapter}
              setChapterID={setChapterID}
              chapterID={chapterID}
              setSlideTypePopup={setSlideTypePopup}
              updateChapterOrder={updateChapterOrder}
              updateSlideOrder={updateSlideOrder}
              questions={questions}
              setQuestion={setQuestion}
              questionID={questionID}
              setQuestionID={setQuestionID}
              GetAnswersByQuestionID={GetAnswersByQuestionID}
              updateQuestionsOrder={updateQuestionsOrder}
              setAnswers={setAnswers}
              setAnswerTypePopup={setAnswerTypePopup}
              learningModuleID={learningModuleID}
              setChapterIndex={setChapterIndex}
              setSlideIndex={setSlideIndex}
              setSlideNormalEasyTexts={setSlideNormalEasyTexts}
            />
          </div>

          {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}

          {/* Quiz Section View */}
          {isQuizOpen && question !== undefined ? (
            <div className="create-edit-course-chapter-slide-body">
              <CreateEditQuestionAnswer
                quiz={quiz}
                course={course}
                question={question}
                answers={answers}
                handleInputQuestionTextChange={handleInputQuestionTextChange}
                handleInputAnswerTextChange={handleInputAnswerTextChange}
                setAnswerTypePopup={setAnswerTypePopup}
                isAnswerTypePopup={isAnswerTypePopup}
                updateAnswer={updateAnswer}
                handleAnswerImageChange={handleAnswerImageChange}
                handleInputQuizChange={handleInputQuizChange}
              />

              <CourseRightSideBar
                learningModuleID={learningModuleID}
                quiz={quiz}
                isQuizOpen={isQuizOpen}
                setPopup={setPopup}
                setTranslationLinkPopup={setTranslationLinkPopup}
              />
            </div>
          ) : (
            //  ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            // Create Edit Course Chapter Section

            <div className="create-edit-course-chapter-slide-body">
              <CreateEditCourseChapter
                chapter={chapter}
                course={course}
                handleInputCourseChange={handleInputCourseChange}
                handleInputChapterChange={handleInputChapterChange}
                learningModuleID={learningModuleID}
                setPopup={setPopup}
              />

              {/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}

              {/* Slide Section Views */}
              {slide !== undefined ? (
                <>
                  <div key={slide.slideID} className="slide-content-container">
                    {/* Slide Image Video Upload Section */}
                    <SlideImageVideoUpload
                      isSlideTypePopup={isSlideTypePopup}
                      slide={slide}
                      setImageUploadPopup={setImageUploadPopup}
                      isNormalLang={isNormalLang}
                      setNormalLang={setNormalLang}
                      setVideoUploadPopup={setVideoUploadPopup}
                    />

                    {/* -------------------------------------------------------------------------------------------------- */}

                    {/* Slide Video Player */}
                    {slide?.video?.filePath ||
                    isSlideTypePopup.type === "video" ? (
                      <div>
                        <div className="slide-video-container">
                          <VideoPlayer
                            // videoTitle={learningModule?.name}
                            filePath={slide?.video?.filePath}
                            mimeType={slide?.video?.mimeType}
                            vttFile={slide?.vttFile}
                            type="slide"
                          />
                        </div>
                      </div>
                    ) : (
                      // Slide text Image Container (draft.js)
                      <SlideTextImageContainer
                        isNormalLang={isNormalLang}
                        isSlideTypePopup={isSlideTypePopup}
                        slide={slide}
                        onSubmit={onSubmit}
                        selectedTerm={selectedTerm}
                        setSelectedTerm={setSelectedTerm}
                        selectedTermLink={selectedTermLink}
                        setSelectedTermTag={setSelectedTermTag}
                        setSelectedTermLink={setSelectedTermLink}
                        slideNormalEasyTexts={slideNormalEasyTexts}
                        setSlideNormalEasyTexts={setSlideNormalEasyTexts}
                        setSelectedTermParentNode={setSelectedTermParentNode}
                        setNewFields={setNewFields}
                      />
                    )}

                    {/* -------------------------------------------------------------------------------------------------- */}

                    {/* Slide Delete icon */}
                    {slide.slideID && (
                      <img
                        className="delete-slide-icon"
                        src={deleteIcon}
                        alt="Slide löschen"
                        title="Slide löschen"
                        onClick={() =>
                          setPopup({
                            isPopup: true,
                            id: slide.slideID,
                            method: "delete",
                            type: "slide",
                            yesButtonText: "Ja ich bin mir sicher",
                            noButtonText: "Nein, abbrechen",
                            label:
                              "Möchtest du diesen gleiten wirklich löschen?",
                          })
                        }
                      />
                    )}

                    {/* start of slides navigation */}
                    {course?.expandedSlides?.length > 1 ? (
                      <div className="nav-slides">
                        <div className="nav-circle-container">
                          <div className="nav-circle-inner">
                            {chapterIndex !== 0 || slideIndex !== 0 ? (
                              <img
                                onClick={() => {
                                  handlePrevSlide();
                                }}
                                className="nav-arrow left"
                                src={leftArrow}
                                alt="Zurück"
                                title="Zurück"
                              />
                            ) : null}
                            {course?.expandedSlides &&
                              course?.expandedSlides.map(function (
                                expandedSlide: any,
                                index: number
                              ) {
                                return (
                                  <span
                                    key={index}
                                    className={
                                      expandedSlide.slideID === slide?.slideID
                                        ? "nav-circle active"
                                        : "nav-circle"
                                    }
                                  ></span>
                                );
                              })}
                            {(chapterIndex !== chapters?.length - 1 &&
                              slides &&
                              slides.length === 0) ||
                            (chapterIndex === chapters?.length - 1 &&
                              slideIndex === slides?.length - 1) ? null : (
                              <img
                                onClick={() => {
                                  handleNextSlide();
                                }}
                                className="nav-arrow right"
                                src={rightArrow}
                                alt="Nächste"
                                title="Nächste"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </>
              ) : (
                // Course change location section
                <CourseLocation
                  learningModules={learningModules}
                  subjectID={subjectID}
                />
              )}

              {/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}

              {/* Course change status section (published - in edit...) */}
              {course && !slide && (
                <ChangeCourseStatus course={course} setPopup={setPopup} />
              )}

              {/* Course right sidebar ( close button + dictionary button + delete course or quiz button) */}
              <CourseRightSideBar
                learningModuleID={learningModuleID}
                course={course}
                setPopup={setPopup}
                isQuizOpen={isQuizOpen}
                slide={slide}
                setTranslationLinkPopup={setTranslationLinkPopup}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default CreateEditCourseChapterSlide;
