import "./SideBar.css";
import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LogOutAction } from "../../store/actions/authAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getLearningModules } from "../../store/actions/learningModuleAction";

//icons:
import add from "../../assets/Icons/Sidebar/add-icon.png";
import logout from "../../assets/Icons/Sidebar/log-out.png";
import users from "../../assets/Icons/Sidebar/user-icon.jpg";
import login from "../../assets/Icons/Sidebar/login-icon.png";
import start from "../../assets/Icons/Sidebar/sidebar-start.png";
import defaultImage from "../../assets/Images/default-image.png";
import trash from "../../assets/Icons/Sidebar/menu-icon_deleted.png";
import statistic from "../../assets/Icons/Sidebar/statistic-icon.gif";
import menuInEdit from "../../assets/Icons/Sidebar/menu-icon_edit.png";
import dictionary from "../../assets/Icons/Sidebar/sidebar-dictionary.png";
import menuReadyToBePublished from "../../assets/Icons/Sidebar/menu-icon_ready.png";

type EditorSideBarProps = {
  action?: any;
  cancelSpeaking?: any;
  setFilter?: any;
  learningModules: {}[];
  learningModuleID?: string;
  setFilteredLearningModules?: any;
  filter?: "inEdit" | "readyToBePublished" | "deleted";
  mobileVersionHideSidebar?: any;
};

function SideBar(props: EditorSideBarProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  //redux states
  const { user } = useSelector((state: any) => state.LoginReducer);
  const fontSize = useSelector((state: any) => state.FontSizeReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const contrast = useSelector((state: any) => state.ContrastReducer);

  //set the fontSizeValues to 22px and 28px
  const fontSizeValues = fontSize === "28" ? "fontSize-28" : "fontSize-22";

  const [orderedLearningModules, setOrderedLearningModules] = useState<any>([]);

  let params: any = useParams();
  const { status } = params;

  const logoutBtnHandler = () => {
    dispatch(LogOutAction());
    navigate("/login");
  };

  let orderedArray: any = [];

  // function that add the order field to the ordered learning module objects
  const addOrderField = (arr: any) => {
    return arr.map((obj: any) => ({ ...obj, order: null }));
  };

  useEffect(() => {
    if (props.learningModules === undefined) {
      dispatch(getLearningModules());
    }

    // statically order the learning module in the frontend, later will do it from the backend
    orderedArray.push(addOrderField(props.learningModules));
    for (let i = 0; i < orderedArray[0].length; i++) {
      if (orderedArray[0][i].name === "Computer verstehen") {
        orderedArray[0][i].order = 1;
      } else if (orderedArray[0][i].name === "Computer einrichten") {
        orderedArray[0][i].order = 2;
      } else if (orderedArray[0][i].name === "Programm nutzen") {
        orderedArray[0][i].order = 3;
      } else if (orderedArray[0][i].name === "Internet verstehen") {
        orderedArray[0][i].order = 4;
      } else if (orderedArray[0][i].name === "Internet einrichten") {
        orderedArray[0][i].order = 5;
      } else if (orderedArray[0][i].name === "Im Internet surfen") {
        orderedArray[0][i].order = 6;
      } else {
        orderedArray[0][i].order = orderedArray[0].length - 1;
      }
    }

    // function that reorder the array according to the order property
    let test = orderedArray[0].sort(
      (a: any, b: any) => parseFloat(a.order) - parseFloat(b.order)
    );

    // we set the sorted one in the ordered state
    setOrderedLearningModules(test);

    // eslint-disable-next-line
  }, [props.learningModules.length]);

  // When the user clicks on the + icon of LM , scroll to the top of the document
  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    isLoggedIn &&
      user?.userRoles &&
      user?.userRoles?.includes("Editor") &&
      localStorage.setItem("fontSize", "22");
    contrast &&
      contrast.isContrast === "true" &&
      document.body.classList.add("enable-contrast");
  }, [isLoggedIn, user?.userRoles, contrast, dispatch]);

  //get current path name
  const currentUrl = useLocation();
  const currentPathName = currentUrl.pathname;

  return (
    <aside>
      <div>
        {isLoggedIn && user?.userRoles && user?.userRoles?.includes("Editor") && (
          <div
            className="sidebar-box background-color border"
            onClick={() => navigate(`/`)}
            tabIndex={0}
            onKeyPress={() => navigate(`/`)}
          >
            <img
              className="sidebar-icon"
              src={statistic}
              alt="ILLUME-Statistiken"
              title="Illume-Statistiken"
            />
            <h3 className={` text-content ${fontSizeValues}`}>
              Projektübersicht
            </h3>
          </div>
        )}

        {isLoggedIn && user?.userRoles && user?.userRoles?.includes("Admin") && (
          <>
            <div
              className="sidebar-box background-color border"
              onClick={() => navigate(`/`)}
              tabIndex={0}
              onKeyPress={() => navigate(`/`)}
            >
              <img
                className="sidebar-icon"
                src={users}
                alt={"Illume-Benutzer"}
                title={"Illume-Benutzer"}
              />
              <h3 className={` text-content ${fontSizeValues}`}>Benutzer</h3>
            </div>
          </>
        )}

        {isLoggedIn && user?.userRoles && user?.userRoles?.includes("Editor") && (
          <>
            <div
              className="sidebar-box background-color border"
              onClick={() => navigate(`/create-edit-translations`)}
              tabIndex={0}
              onKeyPress={() => navigate(`/create-edit-translations`)}
            >
              <img
                className="sidebar-icon"
                src={dictionary}
                alt={"Illume-Wörterbuch"}
                title={"Illume Wörterbuch"}
              />
              <h3 className={` text-content ${fontSizeValues}`}>Wörterbuch</h3>
            </div>
          </>
        )}
      </div>

      <div
        role="navigation"
        aria-label="Startmenü"
        className="sidebar-top-item"
      >
        <ul
          className={
            currentPathName === "/"
              ? contrast && contrast.isContrast === "true"
                ? "sidebar-box start-end-button border in-landing-contrast sidebar-list-content "
                : "sidebar-box start-end-button background-color border in-landing sidebar-list-content"
              : "sidebar-box start-end-button background-color border sidebar-list-content "
          }
          onClick={() => {
            navigate(`/`);
            props.cancelSpeaking && props.cancelSpeaking();
          }}
          tabIndex={0}
          onKeyPress={() => navigate(`/`)}
        >
          <img
            className="sidebar-icon"
            src={start}
            alt={"Illume-Startseite"}
            title={"Illume Startseite "}
          />
          <li className={` text-content ${fontSizeValues}`}>Startseite</li>
        </ul>

        <ul className="sidebar-list-content">
          {orderedLearningModules &&
            orderedLearningModules.map((learningModule: any, index: number) => {
              return (
                <li
                  tabIndex={0}
                  onKeyPress={() => {
                    navigate(
                      isLoggedIn &&
                        user?.userRoles &&
                        user?.userRoles?.includes("Editor")
                        ? `/create-edit-module-subject/${learningModule.learningModuleID}/`
                        : `/overview-module-subject/${learningModule.learningModuleID}/`
                    );
                  }}
                  key={index}
                  onClick={() => {
                    props.cancelSpeaking && props.cancelSpeaking();
                    navigate(
                      isLoggedIn &&
                        user?.userRoles &&
                        user?.userRoles?.includes("Editor")
                        ? `/create-edit-module-subject/${learningModule.learningModuleID}/`
                        : `/overview-module-subject/${learningModule.learningModuleID}/`
                    );
                    props.mobileVersionHideSidebar();
                  }}
                  style={{
                    backgroundColor:
                      learningModule.learningModuleID === props.learningModuleID
                        ? contrast && contrast.isContrast === "true"
                          ? "#fff"
                          : learningModule?.normal
                        : learningModule?.light,
                    color:
                      learningModule.learningModuleID === props.learningModuleID
                        ? ""
                        : learningModule?.normal,
                  }}
                  className={
                    learningModule.learningModuleID === props.learningModuleID
                      ? contrast && contrast.isContrast === "true"
                        ? "sidebar-box-selected contrast"
                        : "sidebar-box-selected"
                      : "sidebar-box background-color border"
                  }
                >
                  <img
                    className="sidebar-icon"
                    src={
                      learningModule.icon?.image
                        ? "data:image/jpeg;base64," + learningModule.icon?.image
                        : defaultImage
                    }
                    alt=""
                    // alt={`Icon für : ${
                    //   learningModule.icon?.alt ? learningModule.icon?.alt : ""
                    // }`}
                    // title={`${
                    //   learningModule.icon?.title
                    //     ? learningModule.icon?.title
                    //     : ""
                    // }`}
                    title=""
                  />

                  <div
                    className={
                      learningModule.learningModuleID === props.learningModuleID
                        ? `  ${fontSizeValues}`
                        : `text-content sidebar-list-content ${fontSizeValues}`
                    }
                  >
                    {learningModule.name}
                  </div>
                </li>
              );
            })}
        </ul>

        {isLoggedIn && user?.userRoles && user?.userRoles?.includes("Editor") && (
          <>
            <div
              className="sidebar-box "
              onClick={() => navigate(`/create-edit-module-subject`)}
            >
              <img
                className="sidebar-icon"
                src={add}
                alt={"Illume-Lernmodul"}
                title={"Illume Lernmodul"}
                onClick={() => {
                  topFunction();
                }}
                tabIndex={0}
                onKeyPress={() => {
                  topFunction();
                }}
              />
              <h3
                className={` ${fontSizeValues}`}
                onClick={() => {
                  topFunction();
                }}
              >
                Neues Lernmodul
              </h3>
            </div>
          </>
        )}
      </div>

      <div role="navigation" aria-label="Filter Courses Menu">
        {isLoggedIn && user?.userRoles && user?.userRoles?.includes("Editor") && (
          <>
            <div
              className={
                status === "InEdit"
                  ? "sidebar-box in-edit "
                  : "sidebar-box inEdit-not-clicked "
              }
              onClick={() => navigate(`/filter-courses-page/${"InEdit"}`)}
              tabIndex={0}
              onKeyPress={() => navigate(`/filter-courses-page/${"InEdit"}`)}
            >
              <img
                className="sidebar-icon"
                src={menuInEdit}
                alt={"Kurse in Bearbeitung-Symbol"}
                title={"Kurse in Bearbeitung-Symbol"}
              />
              <h3 className={` ${fontSizeValues}`}>Kurse in Bearbeitung</h3>
            </div>
            <div
              className={
                status === "ReadyToBePublished"
                  ? "sidebar-box publish "
                  : "sidebar-box publish-not-clicked "
              }
              onClick={() =>
                navigate(`/filter-courses-page/${"ReadyToBePublished"}`)
              }
              tabIndex={0}
              onKeyPress={() =>
                navigate(`/filter-courses-page/${"ReadyToBePublished"}`)
              }
            >
              <img
                className="sidebar-icon"
                src={menuReadyToBePublished}
                alt={"Fertige Kurse zum Veröffentlichen-Symbol"}
                title={"Fertige Kurse zum Veröffentlichen-Symbol"}
              />
              <h3 className={` ${fontSizeValues}`}>
                Fertige Kurse zum Veröffentlichen
              </h3>
            </div>

            <div
              className="sidebar-box"
              onClick={() => navigate(`/filter-courses-page/${"deleted"}`)}
              tabIndex={0}
              onKeyPress={() => navigate(`/filter-courses-page/${"deleted"}`)}
            >
              <img
                src={trash}
                className="sidebar-icon"
                alt="Gelöschte Kurse-Symbol"
                title="Gelöschte Kurse-Symbol"
              />
              <span className={` ${fontSizeValues}`}>
                <h3>Gelöschte Kurse</h3>
              </span>
            </div>
          </>
        )}
      </div>

      <div role="navigation" aria-label="Abmelden">
        {isLoggedIn && user?.userRoles && user?.userRoles?.includes("Editor") && (
          <>
            <div
              className="sidebar-box start-end-button background-color border"
              onClick={logoutBtnHandler}
              tabIndex={0}
              onKeyPress={logoutBtnHandler}
            >
              <img
                src={logout}
                className="sidebar-icon"
                alt="Abmelden"
                title="Abmelden"
              />
              <span className={`text-content ${fontSizeValues}`}>
                <h3>Abmelden</h3>
              </span>
            </div>
          </>
        )}
      </div>

      <div role="navigation" aria-label="Wörterbuch-Anmelden navigation ">
        {!isLoggedIn && (
          <>
            <ul
              role="navigation"
              aria-label="Wörterbuch "
              className={
                currentPathName === "/user-dictionary/"
                  ? contrast && contrast.isContrast === "true"
                    ? "sidebar-box start-end-button border in-landing-contrast sidebar-list-content"
                    : "sidebar-box start-end-button background-color border in-landing sidebar-list-content"
                  : "sidebar-box start-end-button background-color border sidebar-list-content"
              }
              onClick={() => {
                navigate(`/user-dictionary/`);
                props.cancelSpeaking && props.cancelSpeaking();
              }}
              tabIndex={0}
              onKeyPress={() => navigate(`/user-dictionary`)}
            >
              <img
                className="sidebar-icon"
                src={dictionary}
                alt={"Illume-Wörterbuch"}
                title={"Illume Wörterbuch "}
              />
              <li className={` text-content ${fontSizeValues}`}>Wörterbuch</li>
            </ul>

            {/* Check if tablet of phone dont show the login button */}
            {window.innerWidth > 820 ? (
              <ul
                role="navigation"
                aria-label="Anmelden "
                className="sidebar-box start-end-button background-color border sidebar-list-content"
                onClick={() => navigate("/login")}
                tabIndex={0}
                onKeyPress={() => {
                  props.cancelSpeaking && props.cancelSpeaking();
                  navigate("/login");
                }}
              >
                <img
                  src={login}
                  className="sidebar-icon"
                  alt="Anmelden "
                  title="Anmelden."
                />
                <li className={`text-content ${fontSizeValues}`}>Anmelden</li>
              </ul>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </aside>
  );
}

export default SideBar;
