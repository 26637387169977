import "./PrivacyPolicyPage.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setHelpPopup } from "store/actions/helpPopupAction";

// Images:
import helpLogo from "assets/Icons/Header/helpLogo.png";
import contrastHelpLogo from "assets/Icons/Header/help_contrast.png";
import redCloseIcon from "assets/Icons/Header/button_close_red.png";
import contrastCloseButton from "assets/Icons/Header/close_contrast.png";
import { useEffect } from "react";

// Icons
import speaker from "assets/Icons/speaker-tts-icon.png";
import contrastSpeaker from "assets/Icons/ttsblack.png";
import greenSpeaker from "assets/Icons/speaker-icon.png";
import greenContrastSpeaker from "assets/Icons/speaker-contrast-icon.png";

function PrivacyPolicyPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //redux state
  const contrast = useSelector((state: any) => state.ContrastReducer);
  const fontSize = useSelector((state: any) => state.FontSizeReducer);
  //set the fontSizeValues to 22px and 28px
  const fontSizeValues = fontSize === "28" ? "fontSize-28" : "fontSize-22";

  // declaring utterance that will be used for TTS
  var utterance = new SpeechSynthesisUtterance();

  // word index that will be used for highlighting based in word index
  var wordIndex = 0;

  // variable used for stopping onclick on buttons  when  in function
  var clicked = 0;

  //  variable j to change the p tag taken from the div
  let j = 0;

  // declaring text to be read by TTS
  let text = " ";

  // word by word reading function
  function highlight_wordByWord_style2(
    text4: any,
    length: any,
    text5: any,
    text6: any
  ) {
    // used to know when reading and to stop reading onclick
    clicked = 1;

    // like taking backup of the origin text
    let text2 = text4;

    // for returning the p tag to its origin at end of highlighting.
    let origin = text2?.innerText;

    //taking the text sent in the p tag
    text = text2?.innerHTML;

    // splitting the main text by spaces
    var words = text?.split(" ");

    // give the tts library the text
    utterance.text = text;

    // give the tts library the lang to be used
    utterance.lang = "de-DE";

    // start speaking the given text
    speechSynthesis.speak(utterance);

    // onboundary means:
    utterance.onboundary = async function (event) {
      // call the draw text to panel function, we put it outside the on boundary it colors all the worlds
      drawTextInPanel(words, text2);

      // if clicked = 0 so speaking = true, so we cancel it and we return the original text
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // else if the event is a word (needed in the library cz we have "word" and "sentence")
      else if (event.name === "word") {
        try {
          // we give the specified word background color yellow
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.backgroundColor = "yellow";

          // we give the specified word color black
          (
            document.getElementById("word_span_" + wordIndex) as any
          ).style.color = "black";
        } catch (e) {}

        // increment the word index to get the next word
        wordIndex++;
      }
    };

    // onend:
    utterance.onend = function () {
      // condition to let it stop if we re click the speaker
      if (clicked === 0) {
        speechSynthesis.cancel();
        text2.innerHTML = origin;
      }
      // condition to let it open only at the end
      else if (wordIndex > 0) {
        if (text2) {
          //returning the p tag to its origin
          text2.innerHTML = origin;
        }

        // length here is the length of the parent div
        if (j < length) {
          // then we read the image alt, at the onClick we start by reading the h2 then we read the alt here, then from inside this we re-call the read word by word to read the h4
          highlight_wordByWord_style2(text5?.children[j], length, text5, text6);
        }

        // if j = length we stop the reading, so the div's are finished
        if (j === length) {
          // j is the counter
          j = 0;
          speechSynthesis.cancel();
          // we re put the original speaker
          text6[0]?.setAttribute(
            "src",
            contrast && contrast.isContrast === "true"
              ? contrastSpeaker
              : speaker
          );
          // allow onclick to be pressed
          clicked = 0;
        }

        // we increment the j to get the next child
        j++;
      }
      // and we put the word index to 0 cz we will start from the beginning in the next step
      wordIndex = 0;
    };
  }

  // draw text in panel function: we give it words_array, and the text: the original text, and it split it and give each word a span and we used it to give background color
  function drawTextInPanel(words_array: any, text: any) {
    // accepting array of splatted words of the text and the text p tag
    if (text) {
      // clearing the p tag for filling it with the text but each word of a span
      text.innerHTML = "";
    }
    // panel is container with the spans for each word
    var panel = text;

    // inserting the new text in the panel
    for (var i = 0; i < words_array?.length; i++) {
      // for loop for filling the panel(p tag) with text each word of a span
      var html =
        // eslint-disable-next-line no-useless-concat
        '<span id="word_span_' + i + '">' + words_array[i] + " " + "</span>";
      (panel as any).innerHTML += html;
    }
  }

  const cancelSpeaking = () => {
    clicked = 0;
    speechSynthesis.cancel();
    let speaker1 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p1"
    );
    speaker1[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker2 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p2"
    );
    speaker2[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker3 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p3"
    );
    speaker3[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker4 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p4"
    );
    speaker4[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker5 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p5"
    );
    speaker5[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );
    let speaker6 = document.getElementsByClassName(
      "footer-pages-speaker-icon-p6"
    );
    speaker6[0].setAttribute(
      "src",
      contrast && contrast.isContrast === "true" ? contrastSpeaker : speaker
    );

    clicked = 0;
    speechSynthesis.cancel();
  };

  const ttsOnClick = (textClassName: string, speakerIconClassName: string) => {
    let length;
    if (clicked === 0) {
      let t = document.getElementsByClassName(textClassName);
      let chapterTitleText = t[0].children[0];
      // console.log("chapterTitleText", chapterTitleText);
      // console.log("t[0].children.length", t[0].children.length);
      // console.log("t[0].children[1]", t[0].children[1]);
      j = 1;
      length = t[0].children.length;
      // let pTagsContainer = document.getElementsByClassName("user-slide-text");
      // let slideText = pTagsContainer[0];
      // length = slideText?.children[0]?.children?.length
      //   ? slideText?.children[0]?.children?.length
      //   : 0;

      let speakerIcon = document.getElementsByClassName(speakerIconClassName);
      highlight_wordByWord_style2(chapterTitleText, length, t[0], speakerIcon);
      // console.log("speakerIcon", speakerIcon);

      speakerIcon[0].setAttribute(
        "src",
        contrast && contrast.isContrast === "true"
          ? greenContrastSpeaker
          : greenSpeaker
      );
    } else {
      cancelSpeaking();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // this because if we go from the cookies popup the scroll is stopped so i activate it here
    document.body.classList.remove("active-modal");
    document.body.style.overflowY = "visible";

    contrast &&
      contrast.isContrast === "true" &&
      document.body.classList.add("enable-contrast");
  }, [contrast]);

  window.addEventListener("scroll", (e) => {
    const nav = document.querySelector(".footer-page-header");
    const title = document.getElementById("footer-page-header-title");
    if (window.innerWidth <= 800) {
      if (window.pageYOffset > 0) {
        nav?.classList.add("add-shadow");
        title?.classList.add("title-show");
      } else {
        nav?.classList.remove("add-shadow");
        title?.classList.remove("title-show");
      }
    }
  });

  return (
    <main className="footer-pages-main-container background-color">
      <div className="footer-pages-main">
        {/* Help Logo */}
        <div tabIndex={0} className="footer-page-header">
          <div
            className="footer-page-header-title"
            id="footer-page-header-title"
          >
            Datenschutzhinweis
          </div>
          <img
            role="button"
            className="user-close-logo"
            src={
              contrast && contrast.isContrast === "true"
                ? contrastCloseButton
                : redCloseIcon
            }
            onClick={() => {
              cancelSpeaking();
              navigate("/");
            }}
            tabIndex={0}
            onKeyPress={() => {
              navigate("/");
              cancelSpeaking();
            }}
            alt="Datenschutzhinweis beenden"
            title="Datenschutzhinweis beenden."
          />
          <img
            role="button"
            className="user-footer-help-logo"
            src={
              contrast && contrast.isContrast === "true"
                ? contrastHelpLogo
                : helpLogo
            }
            onClick={() => {
              dispatch(setHelpPopup());
              cancelSpeaking();
            }}
            tabIndex={0}
            onKeyPress={() => {
              dispatch(setHelpPopup());
              cancelSpeaking();
            }}
            alt="Hilfe-Einstellungen"
            title="Hilfe Einstellungen"
          />
        </div>

        {/* Page title */}
        <div className="footer-pages-header-container">
          <h1 className="footer-page-title">Datenschutzhinweis</h1>
        </div>

        {/* Body */}
        {/* Divide the page into sections, and divide the sections into articles */}
        <section className="footer-pages-body-container">
          <>
            <section className="privacy-policy-p1-main">
              <img
                onClick={() =>
                  ttsOnClick(
                    "privacy-policy-p1",
                    "footer-pages-speaker-icon-p1"
                  )
                }
                className="footer-pages-speaker-icon-p1"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion."
                title="Vorlesefunktion"
              />
              <article className="privacy-policy-p1 text-content">
                <h1 className={` ${fontSizeValues}`}>
                  § 1 Information über die Erhebung personenbezogener Daten
                </h1>

                <p className={` ${fontSizeValues}`}>
                  (1) Im Folgenden informieren wir über die Erhebung
                  personenbezogener Daten bei Nutzung der Website www.illume.nrw
                  (nachfolgend Website).
                </p>

                <p className={`${fontSizeValues}`}>
                  Personenbezogene Daten sind alle Daten, die auf Sie persönlich
                  beziehbar sind, also z.B. Name, Adresse, E-Mail-Adressen,
                  Nutzerverhalten.
                </p>

                <p className={`${fontSizeValues}`}>
                  (2) Datenschutzrechtlich verantwortlich ist die In der
                  Gemeinde leben gGmbH
                </p>

                <h2 className={` ${fontSizeValues}`}>
                  Hilfen für Menschen mit Behinderungen
                </h2>

                <h2 className={`display-privacy-policy-p1 ${fontSizeValues}`}>
                  Ludwig-Erhard-Allee 14
                </h2>

                <h2 className={` ${fontSizeValues}`}> 40227 Düsseldorf.</h2>
              </article>
            </section>

            {/* --------------------------------------------------------------------------- */}
            <section className="privacy-policy-p2-main">
              <img
                onClick={() =>
                  ttsOnClick(
                    "privacy-policy-p2",
                    "footer-pages-speaker-icon-p2"
                  )
                }
                className="footer-pages-speaker-icon-p2"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="speaker-icon"
                title="Volume-icon"
              />
              <article className="privacy-policy-p2 text-content">
                <h1 className={`${fontSizeValues}`}>
                  § 2 Informationen über die Art und Zwecke der Verarbeitung und
                  die Empfänger personenbezogener Daten
                </h1>

                <p className={` ${fontSizeValues}`}>
                  (1) Über die Website verarbeiten wir verschiedene Arten
                  personenbezogener Daten zu verschiedenen Zwecken:
                </p>

                <p className={` ${fontSizeValues}`}>
                  (a) Erhebung personenbezogener Daten bei informatorischer
                  Nutzung
                </p>

                <p className={` ${fontSizeValues}`}>
                  (2) Bei der bloß informatorischen Nutzung der Website (also
                  wenn Sie sich nicht zur Nutzung der Website anmelden,
                  registrieren oder uns sonst Informationen übermitteln) erheben
                  wir keine personenbezogenen Daten, mit Ausnahme der Daten, die
                  Ihr Browser übermittelt, um Ihnen den Besuch der Website zu
                  ermöglichen.
                </p>
                <div className={`${fontSizeValues}`}>
                  <h2>Diese Daten umfassen:</h2>
                </div>

                <h2 className={` ${fontSizeValues}`}>1. IP-Adresse</h2>
                <div className={` ${fontSizeValues}`}>
                  <h2>2. Datum und Uhrzeit der Anfrage</h2>
                </div>

                <div className={` ${fontSizeValues}`}>
                  <h2>3. Zeitzonendifferenz zur Greenwich Mean Time (GMT)</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>4. Inhalt der Anforderung (konkrete Seite)</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>5. Zugriffsstatus/ HTTP-Statuscode</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>6. jeweils übertragene Datenmenge</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>7. Website, von der die Anforderung kommt</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>8. Browser</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>9. Betriebssystem und dessen Oberfläche</h2>
                </div>
                <div className={`  ${fontSizeValues}`}>
                  <h2>10. Sprache und Version der Browsersoftware.</h2>
                </div>
                {/* <p>• Sprache und Version der Browsersoftware.</p> */}

                <p className={` ${fontSizeValues}`}>
                  (3) Bei Cookies handelt es sich um kleine Textdateien, die auf
                  Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet
                  gespeichert werden und durch welche der Stelle, die den Cookie
                  setzt (in diesem Fall also uns), bestimmte Informationen
                  zufließen. Cookies können keine Programme ausführen oder Viren
                  auf Ihren Computer übertragen. Sie dienen dazu, das
                  Internetangebot insgesamt nutzerfreundlicher und effektiver zu
                  machen. Die durch den Cookie erzeugten Informationen über Ihre
                  Benutzung dieses Internetangebotes werden auf dem Server des
                  Anbieters in Deutschland gespeichert. Die IP-Adresse wird
                  sofort nach der Verarbeitung und vor deren Speicherung
                  anonymisiert. Sie können die Installation der Cookies durch
                  eine entsprechende Einstellung Ihrer Browser-Software
                  verhindern; wir weisen Sie jedoch darauf hin, dass Sie in
                  diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser
                  Website vollumfänglich nutzen können.
                </p>

                <p className={` ${fontSizeValues}`}>
                  a) Diese Website nutzt Cookies in folgendem Umfang:
                </p>
                <div className={` ${fontSizeValues}`}>
                  <h2>1. Transiente Cookies (temh2orärer Einsatz)</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>
                    2. Persistente Cookies (zeitlich beschränkter Einsatz)
                  </h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>3. Third Party Cookies (von Drittanbietern)</h2>
                </div>
                <div className={` ${fontSizeValues}`}>
                  <h2>4. Flash-Cookies (dauerhafter Einsatz).</h2>
                </div>
                <p className={` ${fontSizeValues}`}>
                  b) Transiente Cookies werden automatisiert gelöscht, wenn Sie
                  den Browser schließen. Dazu zählen insbesondere die
                  Session-Cookies. Diese speichern eine sogenannte Session-ID,
                  mit welcher sich verschiedene Anfragen Ihres Browsers der
                  gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner
                  wiedererkannt werden, wenn Sie auf die Website zurückkehren.
                  Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen
                  oder Sie den Browser schließen.
                </p>
                <p className={` ${fontSizeValues}`}>
                  c) Persistente Cookies werden automatisiert nach einer
                  vorgegebenen Dauer gelöscht, die sich je nach Cookie
                  unterscheiden kann. Sie können die Cookies in den
                  Sicherheitseinstellungen Ihres Browsers jederzeit löschen.
                </p>
                <p className={` ${fontSizeValues}`}>
                  d) Sie können Ihre Browser-Einstellung entsprechend Ihren
                  Wünschen konfigurieren und z.B. die Annahme von
                  Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen
                  Sie jedoch darauf hin, dass Sie dann eventuell nicht alle
                  Funktionen dieser Website nutzen können.
                </p>

                <p className={` ${fontSizeValues}`}>
                  e) Die genutzten Flash-Cookies werden nicht durch Ihren
                  Browser erfasst, sondern durch Ihr Flash-Plug-In. Diese
                  speichern die notwendigen Daten unabhängig von Ihrem
                  verwendeten Browser und haben kein automatisches Ablaufdatum.
                  Wenn Sie keine Verarbeitung der Flash-Cookies wünschen, müssen
                  Sie ein entsprechendes Add-On installieren, z.B. „Better
                  Privacy“ für Mozilla Firefox
                  (https://addons.mozilla.org/de/firefox/addon/betterprivacy/)
                  oder Adobe-Flash-Killer-Cookie für Google Chrome.
                </p>

                <p className={` ${fontSizeValues}`}>
                  (4) Diese mit Hilfe von Cookies gespeicherten Informationen
                  werden getrennt von eventuell weiter bei uns angegeben Daten
                  gespeichert. Insbesondere werden die Daten der Cookies nicht
                  mit Ihren weiteren Daten verknüpft.
                </p>
              </article>
            </section>

            {/* ---------------------------------------------------------------------------- */}

            <div className="privacy-policy-p3-main">
              <img
                onClick={() =>
                  ttsOnClick(
                    "privacy-policy-p3",
                    "footer-pages-speaker-icon-p3"
                  )
                }
                className="footer-pages-speaker-icon-p3"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion."
                title="Vorlesefunktion"
              />
              <div className="privacy-policy-p3 text-content">
                <h1 className={` ${fontSizeValues}`}>§ 3 Ihre Rechte</h1>
                <p className={` ${fontSizeValues}`}>
                  (1) Sie haben das Recht, von uns jederzeit kostenfrei Auskunft
                  zu verlangen über die zu Ihnen bei uns gespeicherten
                  personenbezogenen Daten, sowie zu deren Herkunft, Empfängern
                  oder Kategorien von Empfängern, an die diese personenbezogenen
                  Daten weitergegeben werden und den Zweck der Speicherung.
                </p>
                <p className={` ${fontSizeValues}`}>
                  (2) Sie haben weiterhin das Recht, von und jederzeit
                  Berichtigung, Löschung oder Einschränkung der Verarbeitung
                  Ihrer personenbezogenen Daten zu verlangen. Zudem steht Ihnen
                  das Recht auf Datenübertragbarkeit zu. Ferner haben Sie
                  jederzeit das Recht zur Beschwerde bei der zuständigen
                  Aufsichtsbehörde:
                </p>
                <p className={` ${fontSizeValues}`}>
                  Landesbeauftragte für Datenschutz und Informationsfreiheit
                  Nordrhein-Westfalen, Kavalleriestr. 2-4, 40213 Düsseldorf,
                  Telefon: 0211/38424-0, Fax : 0211/38 424-10, E-Mail:
                  poststelle@ldi.nrw.de.
                </p>

                <p className={` ${fontSizeValues}`}>
                  (3) Sie haben außerdem das Recht, jederzeit der Verarbeitung
                  Ihrer personenbezogenen Daten durch uns zu widersprechen.
                </p>

                <p className={` ${fontSizeValues}`}>
                  (4) Wenn Sie uns eine Einwilligung zur Nutzung von
                  personenbezogenen Daten erteilt haben, können Sie diese
                  jederzeit ohne Angabe von Gründen widerrufen.
                </p>

                <p className={` ${fontSizeValues}`}>
                  (5) Sofern Sie die vorstehenden Rechte geltend machen möchten,
                  wenden Sie sich bitte per E-Mail an info@piksl.net oder
                  schriftlich an die unter §
                </p>
                <p className={` ${fontSizeValues}`}>
                  1 Abs. 2 genannte Adresse.
                </p>
              </div>
            </div>
            {/* ------------------------------------------------------------------------------- */}

            <div className="privacy-policy-p4-main">
              <img
                onClick={() =>
                  ttsOnClick(
                    "privacy-policy-p4",
                    "footer-pages-speaker-icon-p4"
                  )
                }
                className="footer-pages-speaker-icon-p4"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion."
                title="Vorlesefunktion"
              />
              <div className="privacy-policy-p4 text-content">
                <h1 className={` ${fontSizeValues}`}>§ 4 Datensicherheit</h1>
                <p className={` ${fontSizeValues}`}>
                  Wir unterhalten aktuelle technische Maßnahmen zur
                  Gewährleistung der Datensicherheit, insbesondere zum Schutz
                  Ihrer personenbezogenen Daten vor Gefahren bei
                  Datenübertragungen sowie vor Kenntniserlangung durch Dritte.
                  Diese werden dem aktuellen Stand der Technik entsprechend
                  jeweils angepasst.
                </p>
              </div>
            </div>
            {/* --------------------------------------------------------------------------------- */}

            <div className="privacy-policy-p5-main">
              <img
                onClick={() =>
                  ttsOnClick(
                    "privacy-policy-p5",
                    "footer-pages-speaker-icon-p5"
                  )
                }
                className="footer-pages-speaker-icon-p5"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion."
                title="Vorlesefunktion"
              />
              <div className="privacy-policy-p5 text-content">
                <h1 className={` ${fontSizeValues}`}>§ 5 Speicherdauer</h1>
                <p className={` ${fontSizeValues}`}>
                  Wir bewahren Ihre personenbezogenen Daten grundsätzlich so
                  lange auf, wie es für die Zwecke, für die sie gemäß dieser
                  Datenschutzerklärung erhoben wurden, erforderlich ist. Es kann
                  jedoch vorkommen, dass wir gesetzlich verpflichtet sind,
                  bestimmte Daten über einen längeren Zeitraum zu speichern. In
                  diesem Fall tragen wir dafür Sorge, dass Ihre
                  personenbezogenen Daten über den gesamten Zeitraum gemäß
                  dieser Datenschutzerklärung behandelt werden.
                </p>
              </div>
            </div>

            {/* ------------------------------------------------------------------------------------- */}

            <div className="privacy-policy-p6-main">
              <img
                onClick={() =>
                  ttsOnClick(
                    "privacy-policy-p6",
                    "footer-pages-speaker-icon-p6"
                  )
                }
                className="footer-pages-speaker-icon-p6"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastSpeaker
                    : speaker
                }
                alt="Vorlesefunktion."
                title="Vorlesefunktion"
              />
              <div className="privacy-policy-p6 text-content">
                <h1 className={` ${fontSizeValues}`}>§ 6 Änderung</h1>
                <p className={` ${fontSizeValues}`}>
                  Im Rahmen der technischen Weiterentwicklung unseres
                  Dienstleistungsangebotes sowie des gesetzlichen Rahmens werden
                  wir auch unsere Datenschutzerklärung laufend anpassen. Hierzu
                  werden Änderungen der Datenschutzerklärung auf unserer Website
                  veröffentlicht. Bitte lesen Sie daher regelmäßig die jeweils
                  aktuelle Fassung der Datenschutzerklärung unter
                  www.illume.nrw.
                </p>
                <p className={` ${fontSizeValues}`}>
                  Vorbehaltlich der geltenden Rechtsvorschriften treten alle
                  Änderungen der Datenschutzerklärung in Kraft, sobald die
                  aktualisierte Datenschutzerklärung veröffentlicht wird.
                  Sollten wir bereits Daten über Sie erfasst haben und/oder
                  einer gesetzlichen Informationspflicht unterliegen, werden wir
                  Sie zusätzlich über wesentliche Änderungen unserer
                  Datenschutzerklärung informieren und Sie um Ihre Zustimmung
                  bitten, sollte dies gesetzlich erforderlich sein.
                </p>
                <h2 className={` ${fontSizeValues}`}>Februar 2022</h2>
              </div>
            </div>
          </>
        </section>
      </div>
    </main>
  );
}

export default PrivacyPolicyPage;
