import { CoursesAPI } from "./http-common";
import authHeader from "./authHeader";

const getAllChapters = () => {
  return CoursesAPI.get(`/Chapter`, { headers: authHeader() as any });
};

const getChapterByCourseID = (id: string) => {
  return CoursesAPI.get(`/Chapter/GetByCourseID/${id}`, {
    headers: authHeader() as any,
  });
};

const getChapterByID = (id: string) => {
  return CoursesAPI.get(`/Chapter/${id}`, {
    headers: authHeader() as any,
  });
};

const createChapter = (data: any) => {
  return CoursesAPI.post("/Chapter", data, {
    headers: authHeader() as any,
  });
};

const updateChapter = (id: string, data: any) => {
  return CoursesAPI.put(`/Chapter/${id}`, data, {
    headers: authHeader() as any,
  });
};

const updateChapterOrder = (data: {}[]) => {
  return CoursesAPI.put("/Chapter/UpdateOrder", data, {
    headers: authHeader() as any,
  });
};

const deleteChapter = (id: string) => {
  return CoursesAPI.delete(`/Chapter/${id}`, {
    headers: authHeader() as any,
  });
};

const ChapterService = {
  getAllChapters,
  getChapterByCourseID,
  getChapterByID,
  createChapter,
  updateChapter,
  updateChapterOrder,
  deleteChapter,
};

export default ChapterService;
