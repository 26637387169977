/* eslint-disable jsx-a11y/no-redundant-roles */
import "./Header.css";
import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LogOutAction } from "../../store/actions/authAction";
import { setHelpPopup } from "../../store/actions/helpPopupAction";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import helpLogo from "../../assets/Icons/Header/helpLogo.png";
import redCloseIcon from "../../assets/Icons/Header/button_close_red.png";
import contrastHelpLogo from "../../assets/Icons/Header/help_contrast.png";
import contrastCloseButton from "../../assets/Icons/Header/close_contrast.png";

import whiteSpeaker from "assets/Icons/speaker-tts-white.png";
import speaker from "../../assets/Icons/speaker-tts-icon.png";

type UserHeaderProps = {
  colorCode?: any;
  learningModule?: any;
  ttsOnClick?: any;
  cancelSpeaking?: any;
  isTranslatedText?: boolean;
};

function UserHeader(props: UserHeaderProps) {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const navigate = useNavigate();

  //redux state
  const { user } = useSelector((state: any) => state.LoginReducer);
  const isLoggedIn = useSelector((state: any) => state.LoginReducer.isLoggedIn);
  const contrast = useSelector((state: any) => state.ContrastReducer);
  const { helpPopup } = useSelector((state: any) => state.HelpPopupReducer);

  //get the stored language state from the local storage
  const languageState = localStorage.getItem("isNormalLanguage")!;

  //get the stored fontSize state from the local storage
  // const fontSizeState = localStorage.getItem("fontSize")!;

  let params: any = useParams();
  const { learningModuleID } = params;

  const logoutBtnHandler = () => {
    dispatch(LogOutAction());
  };

  return (
    <>
      <nav role="navigation" aria-label="Primary Menu" className="header">
        {!isLoggedIn && location.pathname === "/login" ? (
          <>
            <div className="header1">
              <h1>
                <Link to={{ pathname: "/" }} className="header-logo">
                  ILLUME Akademie
                </Link>
              </h1>
            </div>
          </>
        ) : !isLoggedIn && location.pathname.includes("/course-details") ? (
          <>
            <Fragment></Fragment>

            <div className="header3">
              {!props.isTranslatedText && languageState === "On" && (
                <p
                  className="text-content fontSize-lang-indicator"
                  style={{
                    color:
                      contrast && contrast.isContrast === "true"
                        ? "#fff"
                        : props.learningModule && props.learningModule.normal,
                  }}
                >
                  Leichte Sprache
                </p>
              )}
              {/* {!props.isTranslatedText && fontSizeState && (
                <p
                  className="text-content fontSize-lang-indicator"
                  style={{
                    color: props.learningModule && props.learningModule.normal,
                  }}
                >
                  Font Size : {fontSizeState}
                </p>
              )} */}

              <img
                className="header-help-logo"
                src={
                  contrast && contrast.isContrast === "true"
                    ? whiteSpeaker
                    : speaker
                }
                tabIndex={0}
                onClick={props.ttsOnClick}
                onKeyPress={() => {
                  dispatch(setHelpPopup());
                }}
                alt="Vorlesefunktion."
                title="Vorlesefunktion"
                style={
                  window.innerWidth > 915
                    ? {
                        width: 50,
                        height: 50,
                        marginRight: 20,
                    }
                    : {
                        width: 0,
                        height: 0,
                        marginRight: 0,
                      }
                }
              />
              <img
                className="header-help-logo"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastHelpLogo
                    : helpLogo
                }
                onClick={() => dispatch(setHelpPopup())}
                tabIndex={0}
                onKeyPress={() => {
                  dispatch(setHelpPopup());
                }}
                alt="Hilfe-Einstellungen"
                title="Hilfe Einstellungen"
              />
              <img
                onClick={() => {
                  navigate(`/overview-module-subject/${learningModuleID}/`);
                  props.cancelSpeaking();
                }}
                tabIndex={0}
                onKeyPress={() => {
                  navigate(`/overview-module-subject/${learningModuleID}/`);
                }}
                className="header-close-button"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastCloseButton
                    : redCloseIcon
                }
                alt="Kurs-beenden"
                title="Kurs beenden "
              />
            </div>
          </>
        ) : !isLoggedIn ? (
          <>
            <div className="header1">
              <h1>
                <Link to={{ pathname: "/" }} className="header-logo">
                  ILLUME Akademie
                </Link>
              </h1>
            </div>

            <div className="header2 help-info">
              {!props.isTranslatedText && languageState === "On" && (
                <p
                  className="text-content fontSize-lang-indicator"
                  style={{
                    color: props.learningModule && props.learningModule.normal,
                  }}
                >
                  Leichte Sprache
                </p>
              )}

              {/* {!props.isTranslatedText && fontSizeState && (
                <p
                  className="text-content fontSize-lang-indicator"
                  style={{
                    color: props.learningModule && props.learningModule.normal,
                  }}
                >
                  Font Size : {fontSizeState}
                </p>
              )} */}

              <img
                className="header-help-logo"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastHelpLogo
                    : helpLogo
                }
                alt="ILLUME-Hilfe-Logo"
                title="ILLUME-Hilfe-Logo "
                onClick={() => dispatch(setHelpPopup())}
                tabIndex={0}
                onKeyPress={() => {
                  dispatch(setHelpPopup());
                }}
              />
            </div>
          </>
        ) : isLoggedIn &&
          user?.userRoles &&
          user?.userRoles?.includes("Admin") ? (
          <>
            <div className="header1">
              <h1
                tabIndex={0}
                onKeyPress={() => {
                  navigate(`/`);
                }}
              >
                <Link to={{ pathname: "/" }} className="header-logo">
                  ILLUME Akademie
                </Link>
              </h1>
            </div>

            <div className="header2">
              {/* Logout button */}
              <Link
                to={{ pathname: "/login" }}
                className="header-btn"
                onClick={logoutBtnHandler}
              >
                <h2 tabIndex={0} onKeyPress={logoutBtnHandler}>
                  Abmelden
                </h2>
              </Link>
            </div>
          </>
        ) : user?.userRoles && user?.userRoles?.includes("Editor") ? (
          <>
            <div className="header1">
              <h1
                tabIndex={0}
                onKeyPress={() => {
                  navigate(`/`);
                }}
              >
                <Link to={{ pathname: "/" }} className="header-logo">
                  ILLUME Akademie
                </Link>
              </h1>
            </div>
          </>
        ) : user?.userRoles &&
          user?.userRoles?.includes("User") &&
          !helpPopup ? (
          <>
            <div className="header1">
              <h1>
                <Link to={{ pathname: "/" }} className="header-logo">
                  <h1
                    tabIndex={0}
                    onKeyPress={() => {
                      navigate(`/`);
                    }}
                  >
                    ILLUME Akademie
                  </h1>
                </Link>
              </h1>
            </div>

            <div className="header2">
              <img
                className="header-help-logo"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastHelpLogo
                    : helpLogo
                }
                alt="Hilfe-Einstellungen"
                title="Hilfe Einstellungen"
                tabIndex={0}
                onKeyPress={() => {
                  dispatch(setHelpPopup());
                }}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </nav>
      <hr className="header-hr change-hr-bg-color" />
    </>
  );
}

export default UserHeader;
