import "./SlideTextImageContainer.css";
import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { SlideSchema } from "validations/editorValidations";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//components
import DefaultButton from "components/DefaultButton/DefaultButton";

type SlideTextImageContainerProps = {
  slide: any;
  onSubmit: any;
  selectedTerm: any;
  setSelectedTerm: any;
  isSlideTypePopup: any;
  isNormalLang: boolean;
  selectedTermLink: any;
  setSelectedTermTag: any;
  setSelectedTermLink: any;
  slideNormalEasyTexts: any;
  setSlideNormalEasyTexts: any;
  setSelectedTermParentNode: any;
  setNewFields: any;
};

function SlideTextImageContainer(props: SlideTextImageContainerProps) {
//local state
  const [editorState, setEditorState] = useState<any>(
    EditorState.createEmpty()
  );

  const [content, setContent] = useState<any>({
    slideID: props?.slide?.slideID,
    textNormalLanguage: props?.slide?.textNormalLanguage,
    textEasyLanguage:
      props?.slide?.textEasyLanguage?.length > 0 &&
      props?.slide?.textEasyLanguage,
  });

  // -------------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (props.selectedTerm && props.selectedTermLink) {
      if (props.isNormalLang) {
        setContent({
          ...content,
          textNormalLanguage: content.textNormalLanguage.replaceAll(
            props.selectedTerm,
            props.selectedTermLink
          ),
        });

        let finalTextNormalLanguage;
        if (props.slide.textNormalLanguage?.length > 0) {
          const myTextNormalLanguage = htmlToDraft(
            content.textNormalLanguage.replaceAll(
              props.selectedTerm,
              props.selectedTermLink
            )
          );

          const textNormalLanguageState = ContentState.createFromBlockArray(
            myTextNormalLanguage.contentBlocks
          );

          finalTextNormalLanguage = EditorState.createWithContent(
            textNormalLanguageState
          );
        }

        setEditorState(finalTextNormalLanguage);
        props.setSelectedTermLink("");
        props.setSelectedTerm("");

        // -------------------------------------------------------------------------------------------------------------------------------------------
      } else {
        setContent({
          ...content,
          textEasyLanguage: content.textEasyLanguage.replaceAll(
            props.selectedTerm,
            props.selectedTermLink
          ),
        });

        let finalTextEasyLanguage;
        if (props.slide.textEasyLanguage?.length > 0) {
          const myTextEasyLanguage = htmlToDraft(
            content.textEasyLanguage.replaceAll(
              props.selectedTerm,
              props.selectedTermLink
            )
          );

          const textEasyLanguageState = ContentState.createFromBlockArray(
            myTextEasyLanguage.contentBlocks
          );

          finalTextEasyLanguage = EditorState.createWithContent(
            textEasyLanguageState
          );
        }

        setEditorState(finalTextEasyLanguage);
        props.setSelectedTermLink("");
        props.setSelectedTerm("");
      }
    }
    // eslint-disable-next-line
  }, [props.isNormalLang, props.selectedTerm, props.selectedTermLink]);

  // -------------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    let finalTextNormalLanguage;
    if (content.textNormalLanguage?.length > 0) {
      const myTextNormalLanguage = htmlToDraft(content?.textNormalLanguage);
      const textNormalLanguageState = ContentState.createFromBlockArray(
        myTextNormalLanguage.contentBlocks
      );

      finalTextNormalLanguage = EditorState.createWithContent(
        textNormalLanguageState
      );
    }

    let finalTextEasyLanguage;
    if (content.textEasyLanguage?.length > 0) {
      const myTextEasyLanguage = htmlToDraft(content?.textEasyLanguage);
      const textEasyLanguageState = ContentState.createFromBlockArray(
        myTextEasyLanguage.contentBlocks
      );

      finalTextEasyLanguage = EditorState.createWithContent(
        textEasyLanguageState
      );
    }

    content.textNormalLanguage && props.isNormalLang
      ? setEditorState(finalTextNormalLanguage)
      : setEditorState(finalTextEasyLanguage);
    return () => {};
    // eslint-disable-next-line
  }, [props.slide, props.isNormalLang]);

  // -------------------------------------------------------------------------------------------------------------------------------------------

  // function that get the selected text
  const handleMouseUp = (e: any) => {
    props.setSelectedTerm(window.getSelection()!.toString().trim());
    props.setSelectedTermTag(e.target.parentNode.parentNode.tagName);
    props.setSelectedTermParentNode(e.target.parentNode.outerHTML);
  };

  // -------------------------------------------------------------------------------------------------------------------------------------------

  return (
    <div className="slide-text-image-container" onMouseUp={handleMouseUp}>
      {props.isNormalLang && props.isSlideTypePopup.type !== "video" && (
        <div className="slide-text-container">
          <Formik
            initialValues={props.slide}
            validationSchema={SlideSchema}
            onSubmit={props.onSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form className="slide-textarea">
                    <Editor
                      localization={{
                        locale: "de",
                      }}
                      slide-text-image-container
                      toolbar={{
                        options: [
                          "inline",
                          // "blockType",
                          // "fontSize",
                          // "fontFamily",
                          "list",
                          // "textAlign",
                          // "colorPicker",
                          "link",
                          // "embedded",
                          // "emoji",
                          // "image",
                          // "remove",
                          // "history",
                        ],
                        inline: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: [
                            "bold",
                            "italic",
                            // "underline",
                            // "strikethrough",
                            // "monospace",
                            // "superscript",
                            // "subscript",
                          ],
                        },
                        link: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          popupClassName: undefined,
                          dropdownClassName: undefined,
                          showOpenOptionOnHover: false,
                          defaultTargetOption: "_self",
                          // options: ["link", "unlink"],
                          // link: { icon: link, className: undefined },
                          // unlink: { icon: unlink, className: undefined },
                          // linkCallback: undefined,
                        },
                        list: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: [
                            "unordered",
                            "ordered",
                            // "indent",
                            // "outdent",
                          ],
                        },
                      }}
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={(newState) => {
                        setEditorState(newState);
                        props.setNewFields(values);
                        setContent({
                          ...content,
                          textNormalLanguage: draftToHtml(
                            convertToRaw(newState.getCurrentContent())
                          ),
                        });

                        props.setSlideNormalEasyTexts({
                          ...props.slideNormalEasyTexts,
                          textNormalLanguage: draftToHtml(
                            convertToRaw(newState.getCurrentContent())
                          ),
                        });
                      }}
                    />
                  </Form>
                  <DefaultButton
                    onClick={() => props.onSubmit(content)}
                    type="submit"
                  >
                    Speichern
                  </DefaultButton>
                </>
              );
            }}
          </Formik>
        </div>
      )}

      {!props.isNormalLang && props.isSlideTypePopup.type !== "video" && (
        <div className="slide-text-container">
          <Formik
            initialValues={props.slide}
            validationSchema={SlideSchema}
            onSubmit={props.onSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form className="slide-textarea">
                    <Editor
                      localization={{
                        locale: "de",
                      }}
                      toolbar={{
                        options: [
                          "inline",
                          // "blockType",
                          // "fontSize",
                          // "fontFamily",
                          "list",
                          // "textAlign",
                          // "colorPicker",
                          "link",
                          // "embedded",
                          // "emoji",
                          // "image",
                          // "remove",
                          // "history",
                        ],
                        inline: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: [
                            "bold",
                            "italic",
                            // "underline",
                            // "strikethrough",
                            // "monospace",
                            // "superscript",
                            // "subscript",
                          ],
                        },
                        link: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          popupClassName: undefined,
                          dropdownClassName: undefined,
                          showOpenOptionOnHover: false,
                          defaultTargetOption: "_self",
                          // options: ["link", "unlink"],
                          // link: { icon: link, className: undefined },
                          // unlink: { icon: unlink, className: undefined },
                          // linkCallback: undefined,
                        },
                        list: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: [
                            "unordered",
                            "ordered",
                            // "indent",
                            // "outdent",
                          ],
                        },
                      }}
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={(newState) => {
                        props.setNewFields(content);

                        setEditorState(newState);
                        setContent({
                          ...content,
                          textEasyLanguage: draftToHtml(
                            convertToRaw(newState.getCurrentContent())
                          ),
                        });

                        props.setSlideNormalEasyTexts({
                          ...props.slideNormalEasyTexts,
                          textEasyLanguage: draftToHtml(
                            convertToRaw(newState.getCurrentContent())
                          ),
                        });
                      }}
                    />
                  </Form>
                  <DefaultButton
                    onClick={() => props.onSubmit(content)}
                    type="submit"
                  >
                    Speichern
                  </DefaultButton>
                </>
              );
            }}
          </Formik>
        </div>
      )}

      {props.slide?.image?.image && (
        <div className="slide-image-container">
          {/* eslint-disable-next-line */}
          <img
            className="slide-image"
            src={
              props.slide?.image?.image
                ? "data:image/jpeg;base64," + props.slide?.image?.image
                : ""
            }
            alt={props.slide?.image?.alt}
            title={props.slide?.image?.alt}
          />
        </div>
      )}
    </div>
  );
}

export default SlideTextImageContainer;
