import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, InputBase } from "@material-ui/core";
import { Formik, Form } from "formik";
import {
  LearningModuleSchema,
  SubjectSchema,
  CourseSchema,
  ChapterSchema,
  QuizQuestionSchema,
  editedTextSchema,
  translationTextSchema,
} from "../../validations/editorValidations";


type EditableTitleProps = {
  type?: string;
  title?: string;
  translationText?: string;
  order?: number;
  action?: any;
  isOpen?: boolean;
  courseID?: string;
  chapterID?: string;
  quizID?: string;
  questionID?: string;
  answerID?: string;
  subjectID?: string;
  translationID?: string;
  learningModuleID?: string;
  id?: string;
  isMultiline?: any;
  validation?: string;
  onKeyDown?: any;
  colorCode?: any;
  autoFocus?: any;
};

function EditableTitle(props: EditableTitleProps) {
  const useStyle = makeStyles((theme) => ({
    editableTitleContainer: {
      margin: theme.spacing(1),
    },
    editableTitle: {
      fontSize: "4rem",
      fontWeight: "bolder",
      color: props.colorCode,
    },
    editableTitleSubject: {
      fontSize: "30px",
      fontWeight: "bold",
      color: props.colorCode,
    },
    input: {
      fontSize: "4rem",
      fontWeight: "bolder",
      color: props.colorCode,
      "&:focus": {
        background: "#ddd",
      },
    },

    
    inputCourse: {
      lineHeight: "50px",
      fontSize: "4rem",
      fontWeight: "bolder",
      color: props.colorCode,
      "&:focus": {
        background: "#ddd",
      },
    },
    inputSubject: {
      fontSize: "24px",
      fontWeight: "bold",
      margin: theme.spacing(1),
      color: props.colorCode,
      "&:focus": {
        background: "#ddd",
      },
    },
    editableTitleChapter: {
      fontSize: "30px",
      fontWeight: "bold",
      color: props.colorCode,
    },

    inputChapter: {
      fontSize: "25px",
      fontWeight: "bold",
      padding: "1rem",
      lineHeight: "35px",
      color: props.colorCode,
      "&:focus": {
        background: "#ddd",
      },
    },
   
    chapterInput: {
      width: "100%",
    },

    inputTranslation: {
      padding: "1rem",
      lineHeight: "50px",
      fontSize: "25px",
      color: "#26a0c0",
      "&:focus": {
        background: "#ddd",
      },
    },
  }));
  const classes = useStyle();

  
  const [open, setOpen] = useState(true);
  const [newTitle, setNewTitle] = useState(props.title);
  const [newTranslationText, setTranslationText] = useState(
    props.translationText
  );
  const [errorMessage, setMessageError] = useState<any>();
  
  const [isValidInput, setIsValidInput] = useState<any>();

  // used to avoid titles conflicts
  useEffect(() => {
    setNewTitle(props.title);
    setTranslationText(props.translationText);
    setOpen(true);
  }, [props.title, props.translationText]);

  const handleOnBlur = (e: any) => {
    e.preventDefault();
    if (isValidInput) {
      // check if the title doesn't change we don't fire API call
      // and if the title is equal the default value
      if (props.type === "module") {
        if (props.title !== newTitle && newTitle !== "") {
          if (newTitle && newTitle?.length < 50) {
            props.action(newTitle);
            setOpen(false);
          }
        }
      } else if (props.type === "subject") {
        const { learningModuleID } = props;
        if (props.title !== newTitle && newTitle !== "") {
          if (newTitle && newTitle?.length < 50) {
            props.subjectID &&
              props.action(props.subjectID, {
                learningModuleID,
                newTitle,
              });
            !props.subjectID && props.action({ learningModuleID, newTitle });
            setOpen(false);
          }
        }
      } else if (props.type === "course") {
        if (props.title !== newTitle && newTitle !== "") {
          if (newTitle && newTitle?.length < 150) {
            props.action(newTitle);
            setOpen(false);
          }
        }
      } else if (props.type === "chapter") {
        const { chapterID, order } = props;
        if (props.title !== newTitle && newTitle !== "") {
          if (newTitle && newTitle?.length < 50) {
            props.action({ chapterID, newTitle, order });
            setOpen(false);
          }
        }
      } else if (props.type === "quiz") {
        const { quizID, courseID } = props;
        if (props.title !== newTitle && newTitle !== "") {
          if (newTitle && newTitle?.length < 150) {
            props.action({ quizID, courseID, newTitle });
            setOpen(false);
          }
        }
      } else if (props.type === "question") {
        const { questionID, order } = props;
        if (props.title !== newTitle && newTitle !== "") {
          props.action({ questionID, newTitle, order });
          setOpen(false);
        }
      } else if (props.type === "answer") {
        const { answerID, order } = props;
        if (props.title !== newTitle && newTitle !== "") {
          props.action({ answerID, newTitle, order });
          setOpen(false);
        }
      } else if (props.type === "dictionary-translation") {
        const { translationID } = props;
        if (
          props.title !== newTitle &&
          newTitle !== "" &&
          props.translationText !== "" &&
          newTranslationText &&
          !errorMessage.editedText
        ) {
          props.translationID &&
            props.action(props.translationID, {
              translationID,
              newTitle,
            });
          !props.translationID && props.action({ translationID, newTitle });
          setOpen(false);
        }
      }
    }
  };

  const initialValues = {
    editedText: newTitle ? newTitle : newTranslationText,
  };

  //if we press enter submit and on press shift and enter we skip new line
  const handleKeyPress = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      handleOnBlur(e);
    }
  };
  const setIsValidAndErrors = (isValid: boolean, errors: any) => {
    setMessageError(errors);
    setIsValidInput(isValid);
  };

  return (
    <>
      {open ? (
        <Formik
          initialValues={initialValues}
          validationSchema={
            props.type === "module"
              ? LearningModuleSchema
              : props.type === "subject"
              ? SubjectSchema
              : props.type === "course"
              ? CourseSchema
              : props.type === "chapter"
              ? ChapterSchema
              : props.type === "quiz"
              ? QuizQuestionSchema
              : props.validation === "term"
              ? editedTextSchema
              : translationTextSchema
          }
          onSubmit={handleOnBlur}
          enableReinitialize
        >
          {(formProps) => {
            const { values, touched, errors, setFieldValue, isValid } =
              formProps;

            return (
              <>
                <Form
                  className={
                    props.type === "chapter" || "quiz" || "question"
                      ? classes.chapterInput
                      : ""
                  }
                >
                  <InputBase
                    id={props.id}
                    name="editedText"
                    multiline={props.isMultiline}
                    onChange={(e) => {
                      formProps.handleChange(e);
                      setFieldValue("editedText", e.target.value);
                      setNewTitle(e.target.value);
                      setTranslationText(e.target.value);
                      setIsValidAndErrors(isValid, errors);
                    }}
                    autoFocus={props.autoFocus}
                    value={values.editedText ? values.editedText : ""}
                    placeholder={
                      props.type === "module"
                        ? "+ neues Lernmodul"
                        : props.type === "subject"
                        ? "+ neues Thema"
                        : props.type === "course"
                        ? "+ neues Kurse"
                        : props.type === "chapter"
                        ? "+ neues Kapitel"
                        : props.type === "dictionary-translation"
                        ? "+ enter text"
                        : props.type === "quiz"
                        ? "+ Wissens-Quiz"
                        : props.type === "question"
                        ? "+ neue Frage"
                        : props.type === "answer"
                        ? "+ neue Antwort"
                        : ""
                    }
                    inputProps={{
                      className:
                        props.type === "module"
                          ? classes.input
                          : props.type === "subject"
                          ? classes.inputSubject
                          : props.type === "course"
                          ? classes.inputCourse
                          : props.type === "chapter" || "quiz"
                          ? classes.inputChapter
                          : props.type === "dictionary-translation" ||
                            "question"
                          ? classes.inputTranslation
                          : "",
                    }}
                    fullWidth
                    onBlur={(e) => {
                      handleOnBlur(e);
                      setIsValidAndErrors(isValid, errors);
                    }}
                    onKeyDown={handleKeyPress}
                    error={touched.editedText && Boolean(errors.editedText)}
                  />
                  <div className="error-message">
                    {" "}
                    {errorMessage && errorMessage.editedText}
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      ) : (
        <Typography
          onClick={() => setOpen(!open)}
          className={
            props.type === "module" || "course"
              ? classes.editableTitle
              : classes.editableTitleSubject
          }
        >
          {props.title}
        </Typography>
      )}
    </>
  );
}
export default EditableTitle;
