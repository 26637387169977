import { CoursesAPI } from "./http-common";
import authHeader from "./authHeader";

const getAllCourses = () => {
  return CoursesAPI.get(`/Course`, { headers: authHeader() as any });
};

const getCourseByID = (id: string) => {
  return CoursesAPI.get(`/Course/${id}`, { headers: authHeader() as any });
};

const getCourseBySubjectID = (id: string) => {
  return CoursesAPI.get(`/Course/GetBySubjectID/${id}`, {
    headers: authHeader() as any,
  });
};

const getCourseByTagID = (id: string) => {
  return CoursesAPI.get(`/Course/GetByTagID/${id}`, {
    headers: authHeader() as any,
  });
};

const createCourse = (data: any) => {
  return CoursesAPI.post("/Course", data, {
    headers: authHeader() as any,
  });
};

const attendCourse = (data: any) => {
  return CoursesAPI.post("/Course/AttendCourse/", data, {
    headers: authHeader() as any,
  });
};

const endCourse = (data: any) => {
  return CoursesAPI.post("/Course/EndCourse/", data, {
    headers: authHeader() as any,
  });
};

const changeCourseStatus = (id: string, courseStatus: any) => {
  return CoursesAPI.put(`/Course/UpdateCourseStatus/${id}`, courseStatus, {
    headers: authHeader() as any,
  });
};

const updateCourse = (id: string, data: any) => {
  return CoursesAPI.put(`/Course/${id}`, data, {
    headers: authHeader() as any,
  });
};

const updateCourseViews = (id: string) => {
  return CoursesAPI.put(`/Course/UpdateViews/${id}`, {
    headers: authHeader() as any,
  });
};

const deleteCourse = (id: string) => {
  return CoursesAPI.delete(`/Course/${id}`, { headers: authHeader() as any });
};

const getCourseByStatus = (status: string) => {
  // let qs = require("querystring");
  return CoursesAPI.get(`/Course/GetByStatus?courseStatus=${status}`, {
    headers: authHeader() as any,
  });
}

const CourseService = {
  getCourseByID,
  getCourseBySubjectID,
  getCourseByTagID,
  getAllCourses,
  createCourse,
  attendCourse,
  endCourse,
  changeCourseStatus,
  updateCourse,
  updateCourseViews,
  deleteCourse,
  getCourseByStatus,
};

export default CourseService;
