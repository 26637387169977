import "./ChangePasswordPopup.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { changePasswordSchema } from "../../validations/adminValidations";
import UserService from "../../services/userService";
import { setMessage } from "../../store/actions/messageAction";

// Components:
import Loader from "../Loader/Loader";
import DefaultButton from "../DefaultButton/DefaultButton";

type ChangePasswordPopupProps = {
  closeChangePasswordPopup: () => void;
  userId: string;
};

export default function ChangePasswordPopup(props: ChangePasswordPopupProps) {
  const dispatch = useDispatch<any>();

  //redux states
  const { loading } = useSelector((state: any) => state.LoadingReducer);

  //local states
  const [password, setPassword] = useState({
    password: "",
    confirmedPassword: "",
  });
  const [ChangePasswordPopup, setChangePasswordPopup] = useState(true);

  const navigate = useNavigate();

  //input change handler
  const handleInputChange = (event: any) => {
    const { id, value } = event.target;
    setPassword((previousState) => {
      return {
        ...previousState,
        [id]: value,
      };
    });
  };

  // function that change the password
  const ChangePassword = (password: string, confirmedPassword: string) => {
    // check if the password matches with the confirmedPassword
    if (password === confirmedPassword) {
      const { userId } = props;

      UserService.resetUserPassword({ userId, password })
        .then(() => {
          navigate("/");
          dispatch(
            setMessage("Das Passwort wurde erfolgreich geändert.", "success")
          );
          setChangePasswordPopup(false);
          closeModal();
        })
        .catch((err) => console.log(err));
    }
  };

  const toggleModal = () => {
    setChangePasswordPopup(!ChangePasswordPopup);
  };

  const closeModal = () => {
    const body = document.body;
    const scrollY = body.style.top;
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
    document.body.classList.remove("active-modal");
    document.body.style.overflowY = "visible";
  };

  const closeChangePasswordPopup = () => {
    props.closeChangePasswordPopup();
    closeModal();
  };

  if (ChangePasswordPopup) {
    document.body.classList.add("active-modal");
    document.body.style.overflowY = "hidden";
  } else {
    document.body.classList.remove("active-modal");
    document.body.style.overflowY = "visible";
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={password}
            validationSchema={changePasswordSchema}
            onSubmit={() =>
              ChangePassword(password.password, password.confirmedPassword)
            }
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form>
                    <div className="modal">
                      <div onClick={toggleModal} className="overlay"></div>
                      <div className="modal-content change-password">
                        <h2>Bitte geben Sie ein neues Passwort ein:</h2>
                        <div className="form-input">
                          <label htmlFor="password" className="form-label">
                            {" "}
                            Neues Passwort
                          </label>
                          <div className="form-field">
                            <Field
                              id="password"
                              name="password"
                              type="text"
                              placeholder="* * * * * * "
                              onChange={handleInputChange}
                            />
                            <div role="alert">
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-input">
                          <label
                            htmlFor="confirmedPassword"
                            className="form-label"
                          >
                            {" "}
                            Passwort bestätigen
                          </label>
                          <div className="form-field">
                            <Field
                              id="confirmedPassword"
                              name="confirmedPassword"
                              type="text"
                              placeholder="* * * * * * "
                              onChange={handleInputChange}
                            />
                            <div role="alert">
                              <ErrorMessage
                                name="confirmedPassword"
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="required-star">(*): Erforderlich</div>
                        <div>
                          Das Passwort muss mindestens 8 Zeichen lang sein und
                          mindestens ein Zeichen aus allen der vier folgenden
                          Kategorien enthalten.
                        </div>
                        <div className="popup-buttons ">
                          <DefaultButton type="submit" className="change">
                            Ändern
                          </DefaultButton>
                          <DefaultButton
                            onClick={closeChangePasswordPopup}
                            className="cancel-reset-password"
                          >
                            Abbrechen
                          </DefaultButton>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
}
