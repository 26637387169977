import authHeader from "./authHeader";
import { CoursesAPI } from "./http-common";

const getSlideByID = (id: string) => {
  return CoursesAPI.get(`/Slide/${id}`, {
    headers: authHeader() as any,
  });
};

const getSlideByChapterID = (id: string) => {
  return CoursesAPI.get(`/Slide/GetByChapterID/${id}`, {
    headers: authHeader() as any,
  });
};

const createSlide = async (data: any) => {
  const res = await CoursesAPI.post("/Slide", data, {
    headers: authHeader() as any,
  });
  return res;
};

const updateSlide = (id: string, data: any) => {
  return CoursesAPI.put(`/Slide/${id}`, data, {
    headers: authHeader() as any,
  });
};

const updateSlideOrder = (data: any) => {
  return CoursesAPI.put("/Slide/UpdateOrder", data, {
    headers: authHeader() as any,
  });
};

const deleteSlide = (id: string) => {
  return CoursesAPI.delete(`/Slide/${id}`, {
    headers: authHeader() as any,
  });
};

const SlideService = {
  getSlideByID,
  getSlideByChapterID,
  createSlide,
  updateSlide,
  updateSlideOrder,
  deleteSlide,
};

export default SlideService;
