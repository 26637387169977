import { useEffect,Fragment } from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { clearMessage } from "../../store/actions/messageAction";

// Change type of messageType and messageText from any to string

type MessageNotificationProps = {
  messageType: string;
  messageText: string;
};
function MessageNotification(props: MessageNotificationProps) {
  const dispatch = useDispatch<any>();

  // function to delete messages from component queue:
  const clearWaitingQueue = () => {
    toast.clearWaitingQueue();
  };

  //function that check the message type and send the right success or error message:
  const notify = (messageType: string, messageText: string) => {
    if (messageType === "success") {
      toast.success(messageText, { hideProgressBar: true });
    } else if (messageType === "error") {
      toast.error(messageText, { hideProgressBar: true });
    }
  };

  // delete all messages after 5 seconds, and delete messages when reopen the component:
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(clearMessage());
    }, 5000);
    // eslint-disable-next-line
    return () => (dispatch(clearMessage()), clearTimeout(timer));
  }, [dispatch]);

  return (
    <Fragment>
      {notify(props.messageType, props.messageText)}
      <ToastContainer limit={1} pauseOnFocusLoss={false} />
      {clearWaitingQueue()}
    </Fragment>
  );
}

export default MessageNotification;
