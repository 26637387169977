import {
  GET_USERS
} from "./types";

import UserService from "../../services/userService";
import { setMessage } from "./messageAction";
import { setLoading, removeLoading } from "./loadingAction";

export const getAllUsers = () => async (dispatch: any) => {
  try {
    dispatch(setLoading());

    const res = await UserService.getAllUsers();

    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
    dispatch(removeLoading());

    return Promise.resolve(res.data);
  } catch (err: any) {
    console.log(err);
    dispatch(setMessage(err.message, "error"));
    dispatch(removeLoading());
  }
};

