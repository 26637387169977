//create the font size action
import { CHANGE_FONTSIZE } from "./types";

//send the font size as a payload
export const ChangeFontSize = (fontSize: any) => ({
  type: CHANGE_FONTSIZE,
  payload: fontSize,

});

