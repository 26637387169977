import { SHOW_ACCEPT_COOKIES } from "../actions/types";

const isCookies = JSON.parse(localStorage.getItem("isCookies")!);

const initialState = isCookies ? true : false;

type loadingReducerAction = {
  type: "SHOW_ACCEPT_COOKIES";
};

const cookiesReducer = (state = initialState, action: loadingReducerAction) => {
  const { type } = action;

  switch (type) {
    case SHOW_ACCEPT_COOKIES:
      return true;

    default:
      return state;
  }
};
export default cookiesReducer;
