import "./ChangeCourseStatus.css";

type ChangeCourseStatusProps = {
  course: any;
  setPopup: any;
};

function ChangeCourseStatus(props: ChangeCourseStatusProps) {
  return (
    <>
      {props.course?.status === "InEdit" ? (
        <div className="course-publishing-section">
          <div className="course-publishing-div">
            <button className="ready-to-be-published">
              <p> Kurs in Bearbeitung</p>
            </button>
            <button
              className="publish-course-button"
              onClick={() =>
                props.setPopup({
                  isPopup: true,
                  id: props.course.courseID,
                  type: "course",
                  method: "readyToBePublished",
                  yesButtonText: "Ja",
                  noButtonText: "Nein, abbrechen",
                  label:
                    "Willst du wirklich den Kurs als bereit zum Veröffentlichen markieren?",
                })
              }
            >
              <p>Als bereit zum Veröffentlichen markieren</p>
            </button>
          </div>
        </div>
      ) : props.course?.status === "ReadyToBePublished" ? (
        <div className="course-publishing-section">
          <div className="course-publishing-div">
            <button className="ready-to-be-published">
              <p> Kurs ist noch nicht veröffentlich</p>
            </button>
            <button
              className="publish-course-button"
              onClick={() =>
                props.setPopup({
                  isPopup: true,
                  id: props.course.courseID,
                  method: "publish",
                  type: "course",
                  yesButtonText: "Ja",
                  noButtonText: "Nein, abbrechen",
                  label: "Willst du wirklich den Kurs veröffentlichen?",
                })
              }
            >
              <p> Veröffentlichen </p>
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default ChangeCourseStatus;
