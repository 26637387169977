import { GET_TRANSLATIONS } from "./types";

import TranslationService from "../../services/translationService";
import { setMessage } from "./messageAction";
import { setLoading, removeLoading } from "./loadingAction";

export const getAllTranslations =
  (isBackground?: boolean) => async (dispatch: any) => {
    try {
      // Background call without loading indicator
      if (!isBackground) {
        dispatch(setLoading());
      }
      const res = await TranslationService.getAllTranslations();

      dispatch({
        type: GET_TRANSLATIONS,
        payload: res.data,
      });
      dispatch(removeLoading());

      return Promise.resolve(res.data);
    } catch (err: any) {
      console.log(err);
      dispatch(setMessage(err.message, "error"));
      dispatch(removeLoading());
    }
  };
