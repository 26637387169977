import * as Yup from "yup";

export const UserSchema = Yup.object().shape({
  firstname: Yup.string().max(50).required("Vorname ist Erforderlich!"),

  lastname: Yup.string().max(50).required("Nachname ist Erforderlich!"),

  email: Yup.string()
    .email("Ungültige E-Mail Adresse.")
    .max(50)
    .required("Email ist Erforderlich!"),

  password: Yup.string()
    .min(8, "Das Passwort muss mindestens 8 Zeichen lang sein.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])/,
      "Das Passwort muss aus mindestens einem Großbuchstaben, einer Zahl und einem Sonderzeichen bestehen."
    )
    .max(50)
    .required("Passwort ist Erforderlich!"),

  username: Yup.string().max(50).required("Nutzername ist Erforderlich!"),
});

export const EditUserSchema = Yup.object().shape({
  firstname: Yup.string().max(50).required("Vorname ist Erforderlich!"),

  lastname: Yup.string().max(50).required("Nachname ist Erforderlich!"),

  email: Yup.string()
    .email("Ungültige E-Mail Adresse.")
    .max(50)
    .required("Email ist Erforderlich!"),

  username: Yup.string().max(50).required("Nutzername ist Erforderlich!"),
});

export const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Das Passwort muss mindestens 8 Zeichen lang sein.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])/,
      "Das Passwort muss aus mindestens einem Großbuchstaben, einer Zahl und einem Sonderzeichen bestehen."
    )
    .max(100)
    .required("Erforderlich!"),

  confirmedPassword: Yup.string()
    .max(100)
    .required("Erforderlich!")
    .oneOf(
      [Yup.ref("password"), null],
      "Die Passwörter müssen übereinstimmen."
    ),
});
