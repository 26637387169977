import "./UserHomePage.css";
import React,{ useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setHelpPopup } from "store/actions/helpPopupAction";
import { getLearningModules } from "store/actions/learningModuleAction";

// components
import Loader from "components/Loader/Loader";
import CookiesPopup from "components/CookiesPopup/CookiesPopup";
import SideBar from "components/SideBar/SideBar";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";

// Icons
import helpLogo from "assets/Icons/Header/helpLogo.png";
import contrastHelpLogo from "assets/Icons/Header/help_contrast.png";

// Images
import underConstruction from "assets/Images/under-construction-transparent.png";
// import underConstruction from "assets/Images/under-construction.png";
import burgerMenuIcon from "assets/Icons/burger-menu-icon.png";
import redCloseIcon from "assets/Icons/Header/button_close_red.png";

//static video thumbnails for now
import vid_th1 from "assets/Images/start_image_01.png";
import vid_th2 from "assets/Images/start_image_02.png";
import vid_th3 from "assets/Images/start_image_03.png";
import vid_th4 from "assets/Images/start_image_04.png";

// app version
import { version } from "config";

function UserHomePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // redux states
  const learningModules = useSelector(
    (state: any) => state.LearningModuleReducer
  );
  const contrast = useSelector((state: any) => state.ContrastReducer);
  const isCookiesPopup = useSelector((state: any) => state.cookiesReducer);
  const fontSize = useSelector((state: any) => state.FontSizeReducer);
  const { loading } = useSelector((state: any) => state.LoadingReducer);

  //local states
  const [isBurgerMenClicked, setBurgerMenuClicked] = useState(false);

  //set the fontSizeValues to 22px and 28px
  const fontSizeValues = fontSize === "28" ? "fontSize-28" : "fontSize-22";

  useEffect(() => {
    document.title = "Startseite - Illume";

    // to remove the scroll bar
    if (!isCookiesPopup) {
      document.body.classList.add("active-modal");
      document.body.style.overflowY = "hidden";
    } else {
      document.body.classList.remove("active-modal");
      document.body.style.overflowY = "visible";
    }
    contrast &&
      contrast.isContrast === "true" &&
      document.body.classList.add("enable-contrast");
  }, [isCookiesPopup, contrast]);

  useEffect(() => {
    if (learningModules.length === 0) {
      dispatch(getLearningModules());
    }
  }, [dispatch, learningModules.length]);

  const burgerMenuClicked = () => {
    let sidebar = document.getElementById("home-page-sidebar");
    if (!isBurgerMenClicked) {
      sidebar && sidebar.classList.add("show");
      setBurgerMenuClicked(true);
    } else {
      sidebar && sidebar.classList.remove("show");
      setBurgerMenuClicked(false);
    }
  };

  window.addEventListener("scroll", (e) => {
    const nav = document.querySelector(".home-page-burger-menu");
    if (window.pageYOffset > 0) {
      nav?.classList.add("add-shadow");
    } else {
      nav?.classList.remove("add-shadow");
    }
  });

  return (
    <>
      {loading && (
        <>
          <Loader />
          <div className="overlay"></div>
        </>
      )}

      {!isCookiesPopup && !loading && (
        <>
          <CookiesPopup />
        </>
      )}

      <main
        aria-label="Home Page"
        className={
          isBurgerMenClicked
            ? "home-page-container-hidden"
            : "home-page-container  background-color"
        }
      >
        <div
          className="home-page-sidebar"
          id="home-page-sidebar"
          style={{
            backgroundColor:
              contrast && contrast.isContrast === "true" ? "#000" : "#fff",
          }}
        >
          <div className="home-page-sidebar-header">
            <h1 className="home-page-sidebar-header-title">ILLUME</h1>
            <img
              className="home-page-sidebar-header-close-button"
              role="button"
              src={redCloseIcon}
              alt="Hilfe-beenden"
              title="Hilfe beenden"
              onClick={burgerMenuClicked}
            />
          </div>
          <SideBar
            learningModules={learningModules}
            mobileVersionHideSidebar={burgerMenuClicked}
          />
        </div>

        <div
          className="home-page-burger-menu"
          style={{
            backgroundColor:
              contrast && contrast.isContrast === "true" ? "#000" : "#acacac",
          }}
        >
          <img
            role="button"
            src={burgerMenuIcon}
            alt="burger-menu-icon"
            title="Burger Menu Icon"
            className="home-page-burger-menu-icon"
            onClick={burgerMenuClicked}
          />
          <h1 className="home-page-burger-menu-title">Menü</h1>
        </div>

        <div className="home-page-body background-color">
          <div className="home-page-body-container  background-color">
            {/* Help Logo */}
            <div>
              <img
                className="user-help-logo"
                src={
                  contrast && contrast.isContrast === "true"
                    ? contrastHelpLogo
                    : helpLogo
                }
                onClick={() => dispatch(setHelpPopup())}
                //add tabIndex and onKeyPress function
                tabIndex={0}
                onKeyPress={() => {
                  dispatch(setHelpPopup());
                }}
                alt="Hilfe-Einstellungen"
                title="Hilfe Einstellungen"
              />
            </div>

            <div className="home-page-title">
              <h1>ILLUME Akademie</h1>
            </div>

            {/* phone text */}
            <div className="home-page-temporary-text-phone-only">
              <p
                style={{
                  color:
                    contrast && contrast.isContrast === "true"
                      ? "#fff"
                      : "#333333",
                }}
              >
                Herzlich Willkommen bei der ILLUME Akademie! Die ILLUME Akademie
                ist ein vom Ministerium für Arbeit und Gesundheit, kurz MAGS,
                gefördertes Projekt.
              </p>
            </div>

            <div className="home-page-vid-container">
              <div className="home-page-vid-item">
                <div className="home-page-video-container">
                  {/* we send video information as props IMPORTANT right now they are static*/}
                  <VideoPlayer
                    isHomepage={true}
                    videoTitle="Hallo!"
                    filePath="videos/start-video_01.mp4"
                    mimeType="video/mp4"
                    vttFile="videos/VTTS/Begrüßungsfilm_01-2.mp4.vtt"
                    colorCode="#fff"
                    videoThumbnail={vid_th1}
                  />
                </div>
              </div>

              <div className="home-page-vid-item">
                <div className="home-page-video-container">
                  {/* we send video information as props IMPORTANT right now they are static*/}
                  <VideoPlayer
                    isHomepage={true}
                    videoTitle="Was ist die ILLUME Akademie"
                    filePath="videos/start-video_02.mp4"
                    mimeType="video/mp4"
                    vttFile="videos/VTTS/Begrüßungsfilm_02-2.mp4.vtt"
                    colorCode="#CCECF4"
                    videoThumbnail={vid_th2}
                  />
                </div>
              </div>

              <div className="home-page-vid-item">
                <div className="home-page-video-container">
                  {/* we send video information as props IMPORTANT right now they are static*/}
                  <VideoPlayer
                    isHomepage={true}
                    videoTitle="Wie funktioniert die Website?"
                    filePath="videos/start-video_03.mp4"
                    mimeType="video/mp4"
                    vttFile="videos/VTTS/Begrüßungsfilm 03-2.mp4_NEU.vtt"
                    colorCode="#DFE7CE"
                    videoThumbnail={vid_th3}
                  />
                </div>
              </div>
              <div className="home-page-vid-item">
                <div className="home-page-video-container">
                  {/* we send video information as props IMPORTANT right now they are static*/}
                  <VideoPlayer
                    isHomepage={true}
                    videoTitle="Benötigst du Hilfe?"
                    filePath="videos/start-video_04.mp4"
                    mimeType="video/mp4"
                    vttFile="videos/VTTS/Begrüßungsfilm 04-2.mp4_NEU.vtt"
                    colorCode="#F5DCCC"
                    videoThumbnail={vid_th4}
                  />
                </div>
              </div>
            </div>

            {/* Under Construction text */}
            <div className="home-page-under-construction-div">
              <p>
                Die ILLUME Akademie befindet sich im Aufbau. In den kommenden
                Monaten entstehen hier weitere Kurse und vieles mehr. Aktuell
                kannst du die Akademie am besten mit deinem Laptop oder PC
                besuchen.
              </p>
            </div>
            <img
              src={underConstruction}
              alt="Baustelle"
              title="Baustelle."
              className="home-page-under-construction-img"
            />

            {/* Footer */}
            {/* Section 1 */}
            <div className="home-page-footer">
              <ul className="home-page-footer-section text-content sidebar-list-content">
                <li className={`home-page-footer-title ${fontSizeValues}`}>
                  ILLUME Akademie
                </li>

                <li className={`home-page-footer-title ${fontSizeValues}`}>
                  <span>&copy;</span> In der Gemeinde leben gGmbH
                </li>

                <li className={`home-page-footer-title ${fontSizeValues}`}>
                  PIKSL - piksl.net
                </li>

                <li className={`home-page-footer-title ${fontSizeValues}`}>
                  Ludwig-Erhard-Alle 14
                </li>

                <li className={`home-page-footer-title ${fontSizeValues}`}>
                  40227 Düsseldorf
                </li>
              </ul>

              {/* Section 2 */}
              <ul className="home-page-footer-section  text-content sidebar-list-content">
                <li className={`home-page-footer-title ${fontSizeValues}`}>
                  Gefördert vom:
                </li>

                <li className={`home-page-footer-title ${fontSizeValues}`}>
                  Ministerium für Arbeit,
                </li>

                <li className={`home-page-footer-title ${fontSizeValues}`}>
                  Gesundheit und Soziales
                </li>

                <li className={`home-page-footer-title ${fontSizeValues}`}>
                  des Landes Nordrhein-Westfalen
                </li>
              </ul>

              {/* Section 3 */}
              <ul className="home-page-footer-section text-content sidebar-list-content">
                <li
                  className={` home-page-footer-title links ${fontSizeValues}`}
                  onClick={() => navigate("/privacy-policy")}
                  tabIndex={0}
                  onKeyPress={() => navigate("/privacy-policy")}
                >
                  Datenschutzhinweis
                </li>

                <li
                  className={`home-page-footer-title links ${fontSizeValues}`}
                  onClick={() => navigate("/about-us")}
                  tabIndex={0}
                  onKeyPress={() => navigate("/about-us")}
                >
                  Impressum
                </li>

                <li
                  className={`home-page-footer-title links ${fontSizeValues}`}
                  onClick={() => navigate("/accessibility")}
                  tabIndex={0}
                  onKeyPress={() => navigate("/accessibility")}
                >
                  Barrierefreiheit
                </li>

                <li
                  style={{
                    color:
                      contrast && contrast.isContrast === "true"
                        ? "#000"
                        : "#3c3c3b",
                  }}
                >
                  {version}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default UserHomePage;
