import { ADD_CONTRAST, REMOVE_CONTRAST } from "../actions/types";

type contrastReducerAction = {
  type: "ADD_CONTRAST" | "REMOVE_CONTRAST";
  payload: string;
};

//get the font size from the local storage
const isContrast = localStorage.getItem("isContrast");

const initialState = {
  isContrast: isContrast ? isContrast : "false",
  level: 0,
};

const ContrastReducer = (
  state = initialState,
  action: contrastReducerAction
) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_CONTRAST:
      return {
        ...state,
        isContrast: "true",
        level: payload,
      };
    case REMOVE_CONTRAST:
      return {
        ...state,
        isContrast: "false",
      };
    default:
      return state;
  }
};
export default ContrastReducer;
